import { InlineError } from '@ctek/design-system';
import { FormattedMessage } from 'react-intl';
import { ResultTypes } from 'century-core/core-components/DraggableQuestionTypes/components/LabelPair/LabelPair';
import { AssessmentReveals } from '@ctek/design-system';
import './Feedback.scss';

interface Props {
  answerStatus: ResultTypes | null;
  feedback?: JSX.Element | JSX.Element[];
  handleRevealClicked?: () => void;
  buttonDisabled?: boolean;
  reveal?: boolean;
  revealError?: string;
}

const Feedback = (props: Props) => {
  const { answerStatus, feedback, buttonDisabled, reveal, revealError, handleRevealClicked } = props;

  if (answerStatus === null || answerStatus === ResultTypes.SKIPPED) {
    return null;
  }

  return (
    <>
      <div className="question-type-feedback" role="status">
        {answerStatus === ResultTypes.CORRECT ? (
          <FormattedMessage id="correct-answer" defaultMessage="Correct" />
        ) : (
          <FormattedMessage id="incorrect-answer" defaultMessage="Incorrect" />
        )}
      </div>
      {feedback}
      {!reveal && handleRevealClicked && answerStatus === ResultTypes.INCORRECT && (
        <AssessmentReveals
          btnCorrectLabel={<FormattedMessage id="reveals-studysession-correct-answer" defaultMessage="Correct answer" />}
          onRevealUnlockClick={handleRevealClicked}
          disabled={buttonDisabled}
        />
      )}
      {!!revealError && (
        <InlineError>
          <FormattedMessage id={revealError} defaultMessage="Unable to use to use this feature, try again later." />
        </InlineError>
      )}
    </>
  );
};

export default Feedback;
