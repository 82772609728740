import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { Errors } from 'century-core/entities/ErrorMessage/Errors';
import { Actions, ActionTypes } from '../../actions/goals/goals';
import { createBaseLoadingReducer } from '../baseLoadingReducer/baseLoadingReducer';

export interface AddGoalsModalSettings {
  data: {
    assignmentId: '';
    level: '';
    nuggetId: '';
    subject: '';
  };
  error: ErrorMessage<Errors> | null;
  loaded: boolean;
}

export const defaultData: AddGoalsModalSettings = {
  data: {
    assignmentId: '',
    level: '',
    nuggetId: '',
    subject: '',
  },
  error: null,
  loaded: false,
};

export const addGoalsModalSettings = createBaseLoadingReducer<AddGoalsModalSettings, Actions, typeof ActionTypes>(defaultData, ActionTypes);

export default AddGoalsModalSettings;
