import { PureComponent } from 'react'
import { getQuillHtml } from '../../atoms/Label/Label'
import './MatchingAnswerDraggable.scss'
import { sanitize } from 'dompurify'

export interface Props {
  children: MatchingAnswerDraggableProps;
  qa?: string;
}

export interface MatchingAnswerDraggableProps {
  value: any;
  hideDragHandle?: boolean;
}

export class MatchingAnswerDraggable extends PureComponent<Props, {}> {
  public render() {
    const { qa } = this.props;
    const { value, hideDragHandle = false } = this.props.children;

    const parsedHtml = getQuillHtml(value);

    return (
      <div className="matching-answer-draggable" data-qa={qa} data-testid={qa}>
        <div className="matching-answer-draggable__content" dangerouslySetInnerHTML={{ __html: sanitize(parsedHtml.body.innerHTML) }} />
        <div className="matching-answer-draggable__handle" style={hideDragHandle ? { visibility: 'hidden' } : {}} />
      </div>
    );
  }
}

export default MatchingAnswerDraggable;
