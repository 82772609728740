import { GET } from 'century-core/core-apis/utils';
import { getLearnURL } from '../learn';

export function getStudySessionResult(studySessionId: string, accessToken: string) {
  const url = getLearnURL(`/qla/studysessions/${studySessionId}/results`);

  return GET<Ctek.StudySessionResult>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get study session result for study session id ${studySessionId}, ${err}`);
  });
}
