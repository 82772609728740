import Auth from 'century-core/entities/Auth/Auth';
import { getTokenCssMainClass, getTokenThemeSettings } from 'century-core/core-auth/utils';
import { createContext, useLayoutEffect, useState } from 'react';
import * as React from 'react';
import { useAuthContext } from 'century-core/core-auth/AuthContext';

export const CustomTheme = createContext({ customLogo: null as string | null });

export const CustomThemeProvider = (props: { children: React.ReactNode | React.ReactNode[] }) => {
  const [customLogo, setCustomLogo] = useState(null as string | null);
  const { auth, orgSettings } = useAuthContext();

  useLayoutEffect(() => {
    setBaseSkinClass(auth, orgSettings);

    const themeSettings = getTokenThemeSettings(auth, orgSettings);

    if (!themeSettings || !themeSettings.customLogo || themeSettings.logoUri === '') {
      return;
    }

    setCustomLogo(`https://cdn.glo.century.tech/logos/organisations/${themeSettings.logoUri}`);
  }, [auth, orgSettings]);

  return <CustomTheme.Provider value={{ customLogo }}>{props.children}</CustomTheme.Provider>;
};

export const setBaseSkinClass = (auth: Auth, orgSettings: Ctek.Accounts.DomainResponse | null): void => {
  // Add custom theming class to body of document.
  const cssMainClass = getTokenCssMainClass(auth, orgSettings);
  const bodyClassList = document.body.classList;
  if (cssMainClass && bodyClassList) {
    for (const clazz of Array.from(bodyClassList)) {
      // remove all previous skins
      if (/skin-/.test(clazz)) {
        bodyClassList.remove(clazz);
      }
    }
    bodyClassList.add(cssMainClass);
  }
};
