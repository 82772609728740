import { createContext, useState, useEffect, useCallback } from 'react';
import * as React from 'react';
import { QLANavigationProperties } from 'century-core/core-utils/utils/qla/types'

export interface QLANavigation {
  users?: QLANavigationMapInternal;
  nuggets?: QLANavigationMapInternal & { defaultStudySession?: string }; // for having a study session open automatically
}

interface QLANavigationMapInternal {
  [id: string]: {
    [id: string]: string;
  };
}

export interface QLAAttemptView {
  studySessionId: string
  questionIdx?: number
}

interface QLANavigationInformationProviderProps {
  children: React.ReactNode;
  students: any[];
  nuggets: any[];
  shouldDisplayNugget: (nugget: any, studentId?: string) => boolean;
  getNuggetProp: (nugget: any, prop: QLANavigationProperties) => string;
  getStudentProp: (user: any, prop: QLANavigationProperties) => string;
  getDefaultOpenAttempt?: (user: any) => QLAAttemptView | null
}

const genericGetNavigationPropFunction = (object: any, prop: QLANavigationProperties) => object?.[prop] || '';

export const QLANavigationInformationContext = createContext<{ QLANavigation: QLANavigation | null, getUserAttempt: (user: any) => QLAAttemptView | null } | null>(null);
export default QLANavigationInformationContext;

export const QLANavigationInformationProvider = (props: QLANavigationInformationProviderProps) => {
  const [QLANavigation, setQLANavigation] = useState(null as null | QLANavigation)

  const generateNavigationMap = useCallback(() => {
    const users: QLANavigationMapInternal = {}
    const nuggets: QLANavigationMapInternal = {}

    if (props.nuggets.length > 1) {
      props.students.forEach((student: any) => {
        const studentId = props.getStudentProp(student, 'id');
        users[studentId] = {}
        props.nuggets.forEach(nugget => {
          if (props.shouldDisplayNugget(nugget, student)) {
            users[studentId][props.getNuggetProp(nugget, 'id')] = props.getNuggetProp(nugget, 'name');
          }
        })
      })
    }

    if (props.students.length > 1) {
      props.nuggets.forEach((nugget: any) => {
        const nuggetId = props.getNuggetProp(nugget, 'id');
        nuggets[nuggetId] = {}
        props.students.forEach(student => {
          if (props.shouldDisplayNugget(nugget, student)) {
            nuggets[nuggetId][props.getStudentProp(student, 'id')] = props.getStudentProp(student, 'name');
          }
        })
      })
    }

    return { users, nuggets };
  }, [props])

  const getUserAttempt = useCallback((userId: string) => {
    const student = props.students.find(s => props.getStudentProp(s, 'id') === userId);
    if (student) {
      return props?.getDefaultOpenAttempt?.(student) || null;
    }
    return null;
  }, [props]);

  useEffect(() => {
    setQLANavigation(generateNavigationMap())
  }, [generateNavigationMap]);

  return <QLANavigationInformationContext.Provider value={{ QLANavigation, getUserAttempt }}>
    {props.children}
  </QLANavigationInformationContext.Provider>;
};

QLANavigationInformationProvider.defaultProps = {
  getStudentProp: genericGetNavigationPropFunction,
  getNuggetProp: genericGetNavigationPropFunction,
}