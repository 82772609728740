import { getCmsURL } from './cms';
import { GET, POST } from '../utils';
interface CreateSubscriptionParams {
  items: { price: string; quantity: number }[];
  promotion_code?: string;
}

export async function getUserSubscription<T = never>(accessToken: string): Promise<T> {
  try {
    const url = getCmsURL('/consumer/subscription');
    return await GET<T>({ url, token: accessToken });
  } catch (err) {
    throw err.message;
  }
}

export async function postSubscription<T = never>(params: CreateSubscriptionParams, accessToken: string): Promise<T> {
  try {
    const url = getCmsURL('/consumer/subscription/create');

    return await POST<T>({ url, token: accessToken, body: params });
  } catch (err) {
    throw Error(`Unable to fetch available plans, ${err.message}`);
  }
}

export async function cancelSubscription<T = never>(
  accessToken: string,
  cancellationReason: string,
  cancellationCategory?: string,
  stripeReason?: string
): Promise<T> {
  try {
    const url = getCmsURL('/consumer/subscription/cancel');

    const body = {
      cancellationReason,
      cancellationCategory,
      stripeReason,
    };

    return await POST<T>({ url, token: accessToken, body });
  } catch (err) {
    throw err.message;
  }
}

export async function restoreSubscription<T = never>(accessToken: string): Promise<T> {
  try {
    const url = getCmsURL('/consumer/subscription/restore');

    return await POST<T>({ url, token: accessToken, body: {} });
  } catch (err) {
    throw err.message;
  }
}

export async function updateSubscription<T = never>(accessToken: string, body: object): Promise<T> {
  try {
    const url = getCmsURL('/consumer/subscription/v2/update');
    return await POST<T>({ url, token: accessToken, body });
  } catch (err) {
    throw err.message;
  }
}

export async function cancelScheduledLearnerRemoval<T = never>(accessToken: string, learnerId: string): Promise<T> {
  try {
    const url = getCmsURL(`/consumer/learner/prevent-cancelation/${learnerId}`);
    return await POST<T>({ url, token: accessToken, body: {} });
  } catch (err) {
    throw err.message;
  }
}
