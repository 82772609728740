import { normalize } from 'normalizr';
import { AnyAction, combineReducers } from 'redux';
import api from 'api/api';
import { Auth } from 'century-core/entities/Auth/Auth';
import { ActionTypes as AuthActionTypes } from '../actions/auth/auth';
import auth from './auth/auth';
import { dependants, Dependants } from './dependants/dependants';
import { entities, Entities } from './entities/entities';
import { addGoalsModalSettings, AddGoalsModalSettings } from './goals/goals';
import { recoverPassword, RecoverPassword } from './recoverPassword/recoverPassword';

const appReducer = combineReducers({
  addGoalsModalSettings,
  auth,
  dependants,
  entities,
  recoverPassword,
});

export interface StoreState {
  addGoalsModalSettings: AddGoalsModalSettings;
  auth: Auth;
  dependants: Dependants;
  entities: Entities;
  recoverPassword: RecoverPassword;
}

export interface ThunkExtraArg {
  api: typeof api;
  normalize: typeof normalize;
}

const rootReducer = (state: StoreState | undefined, action: AnyAction) => {
  // Reducers return the initial state when they are called with undefined as the first argument, no matter the action
  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
  if (action.type === AuthActionTypes.LoggedOut) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
