import { createContext, useState, useEffect, useCallback } from 'react';
import * as React from 'react';
import { getUserNuggetAttempts } from 'century-core/core-apis/learn/qla/qla'
import { useAccessToken } from 'century-core/core-auth/hooks/useAuth';

export const QLANuggetAttemptHistoryInformationContext = createContext<{ nuggetAttemptHistory: Ctek.AbacusNuggetHistory | null, nuggetAttemptHistoryError: null | string } | null>(null);
export default QLANuggetAttemptHistoryInformationContext;

export const QLANuggetAttemptHistoryInformationProvider = (props: { studentId: string, nuggetId: string, children: React.ReactNode }) => {
  const accessToken = useAccessToken();
  const [nuggetAttemptHistory, setNuggetAttemptHistory] = useState(null as null | Ctek.AbacusNuggetHistory)
  const [nuggetAttemptHistoryError, setNuggetAttemptHistoryError] = useState(null as null | string)

  const getNuggetAttempt = useCallback(async () => {
    try {
      const nuggetAttemptsHistory = await getUserNuggetAttempts(props.studentId, props.nuggetId, accessToken)
      setNuggetAttemptHistory(nuggetAttemptsHistory);
    } catch (e) {
      setNuggetAttemptHistoryError(e)
    }
  }, [props.nuggetId, props.studentId, accessToken])

  useEffect(() => {
    if (nuggetAttemptHistory?.nuggetId !== props.nuggetId || nuggetAttemptHistory?.userId !== props.studentId) {
      getNuggetAttempt()
    }
  }, [props.nuggetId, props.studentId, accessToken, nuggetAttemptHistory, getNuggetAttempt]);

  return <QLANuggetAttemptHistoryInformationContext.Provider value={{ nuggetAttemptHistory, nuggetAttemptHistoryError }}>{props.children}</QLANuggetAttemptHistoryInformationContext.Provider>;
};
