import { IntlShape } from 'react-intl';

export enum WelcomeWidgetMode {
  'ASSIGNMENTS',
  'MESSAGES',
}

export enum MessageMode {
  'USAGE',
  'COGNITIVE',
}

export const getCognitiveMessages = (intl: IntlShape): string[] => [
  intl.formatMessage({
    id: 'login-message-did-you-know-1',
    defaultMessage: 'Your brain is made up of billions of cells called neurons which fire electrical signals when you learn.',
  }),
  intl.formatMessage({
    id: 'login-message-did-you-know-2',
    defaultMessage: 'Learning is a bit like building roads from one place to another. Remembering is just driving along these roads.',
  }),
  intl.formatMessage({ id: 'login-message-did-you-know-3', defaultMessage: 'Getting a question wrong means your brain grows bigger.' }),
  intl.formatMessage({
    id: 'login-message-did-you-know-4',
    defaultMessage: 'The human brain can hold 2.5million gigabytes of data. When you learn you add to this storage.',
  }),
  intl.formatMessage({
    id: 'login-message-did-you-know-5',
    defaultMessage: 'Your brain can hold billions of bits of information. Learning is just joining all of them up.',
  }),
  intl.formatMessage({
    id: 'login-message-did-you-know-6',
    defaultMessage:
      "The more regularly you learn on CENTURY, the more your brain will hang on to the information; we've built CENTURY to fit your brain.",
  }),
  intl.formatMessage({ id: 'login-message-did-you-know-7', defaultMessage: 'We built CENTURY to work for your brain.' }),
  intl.formatMessage({
    id: 'login-message-did-you-know-8',
    defaultMessage:
      'You exercise your brain just like you do your body, only instead of running regularly to get your brain fitter, you need to learn regularly.',
  }),
  intl.formatMessage({
    id: 'login-message-did-you-know-9',
    defaultMessage:
      'Your brain works like a muscle and needs exercising just like the other muscles in your body. Learning regularly helps keep your brain fit.',
  }),
  intl.formatMessage({
    id: 'login-message-did-you-know-10',
    defaultMessage:
      "The more active your brain is, the better it's learning. Your brain is most active when it's tackling unfamiliar topics.",
  }),
  intl.formatMessage({
    id: 'login-message-did-you-know-11',
    defaultMessage: 'Memories are a bit like pathways through a field. You need to keep walking down the pathway to keep it there.',
  }),
  intl.formatMessage({
    id: 'login-message-did-you-know-12',
    defaultMessage: 'Research has shown that switching between topics means you remember them better.',
  }),
  intl.formatMessage({
    id: 'login-message-did-you-know-13',
    defaultMessage: 'Your brain remembers by repeating. Pathways between neurons are strengthened over time the more they are used.',
  }),
  intl.formatMessage({
    id: 'login-message-did-you-know-14',
    defaultMessage:
      "When revising it's better to mix it up. Complete a nugget and then choose the next from another subject and see how well you can learn.",
  }),
  intl.formatMessage({
    id: 'login-message-did-you-know-15',
    defaultMessage:
      'Just a few minutes of studying is enough to refresh your memory and reinforce your learning. Little and often is the name of the game.',
  }),
  intl.formatMessage({
    id: 'login-message-did-you-know-16',
    defaultMessage:
      "CENTURY recommends the perfect time to review what you've already learnt, so you don't forget it. Just follow your Recommended Learner Path.",
  }),
  intl.formatMessage({
    id: 'login-message-did-you-know-17',
    defaultMessage:
      'The brain contains trillions of connections which electrical signals travel along. Learning is creating new connections in the brain.',
  }),
  intl.formatMessage({
    id: 'login-message-did-you-know-18',
    defaultMessage: 'The best way to remember information is to test yourself on it after some time. This is called the testing effect.',
  }),
  intl.formatMessage({
    id: 'login-message-did-you-know-19',
    defaultMessage:
      'The brain has two types of memory: Short- and long-term memory. To create strong long-term memories, revisit information regularly.',
  }),
  intl.formatMessage({
    id: 'login-message-did-you-know-20',
    defaultMessage: 'Your brain is made up of billions of cells called neurons which fire with electrical signals when you learn.',
  }),
  intl.formatMessage({
    id: 'login-message-did-you-know-21',
    defaultMessage: 'The more you use a memory, the easier it is for you to remember it the next time.',
  }),
  intl.formatMessage({
    id: 'login-message-did-you-know-22',
    defaultMessage:
      'Your Recommended Learner Path has been built with your brain in mind. Follow it to keep progressing towards your goal.',
  }),
];

export const getWelcomeMessages = (intl: IntlShape): string[] => [
  intl.formatMessage({ id: 'welcome-widget-greeting-1', defaultMessage: 'Hello' }),
  intl.formatMessage({ id: 'welcome-widget-greeting-2', defaultMessage: 'Hey' }),
  intl.formatMessage({ id: 'welcome-widget-greeting-3', defaultMessage: 'Hi' }),
  intl.formatMessage({ id: 'welcome-widget-greeting-4', defaultMessage: 'Welcome' }),
  intl.formatMessage({ id: 'welcome-widget-greeting-5', defaultMessage: 'Welcome back' }),
  intl.formatMessage({ id: 'welcome-widget-greeting-6', defaultMessage: 'Welcome to your dashboard' }),
  intl.formatMessage({ id: 'welcome-widget-greeting-7', defaultMessage: 'Good to see you' }),
  intl.formatMessage({ id: 'welcome-widget-greeting-8', defaultMessage: 'Good to see you again' }),
];
