import * as React from 'react';
import './ClickSelectAndDrop.scss';
import ClickSelectAndDrop from './ClickSelectAndDropContext';

interface Props {
  id: string;
  children: React.ReactNode;
}

const ClickSelectItem = (props: Props) => {
  const containerEl = React.useRef<HTMLInputElement>(null);
  const { selectedItemId, setSelectedItem } = React.useContext(ClickSelectAndDrop);
  const handleClick = React.useCallback((event: React.MouseEvent) => {
    if (!selectedItemId || selectedItemId === props.id) {
      event.stopPropagation();
    }
    if (containerEl && containerEl.current && containerEl.current.contains(event.target as any)) {
      setSelectedItem(props.id);
    }

  }, [props, selectedItemId, setSelectedItem]);

  React.useEffect(() => {
    const ref: HTMLInputElement | null = containerEl?.current;
    if (ref) {
      // Bind the event listener
      ref.addEventListener('click', handleClick as unknown as EventListenerOrEventListenerObject);
    }

    return () => {
      if (ref) {
        // Unbind the event listener on clean up
        ref.removeEventListener('click', handleClick as unknown as EventListenerOrEventListenerObject);
      }
    };
  }, [containerEl, handleClick, selectedItemId]);

  return (
    <div ref={containerEl} className={`click-select-item ${selectedItemId === props.id ? 'click-select-item--selected' : ''}`}>
      {props.children}
    </div>
  );
};

export default ClickSelectItem;
