import * as SentryLib from 'century-core/core-utils/lib/sentry';
import { createLocalisedErrorMessage } from 'century-core/entities/ErrorMessage/createLocalisedErrorMessage';
import { Errors } from 'century-core/entities/ErrorMessage/Errors';
import { FetchError, FetchErrorData } from '../fetch/utils';

export const createFetchRequestWithLocalisedError = async <T>(
  apiRequest: () => Promise<T>,
  localisedErrorId: Errors = 'generic-fetch-error',
  bypassDefaultHandler = false
) => {
  try {
    return await apiRequest();
  } catch (err) {
    SentryLib.captureMessage(err?.message);

    if (err instanceof TypeError && (err.message === 'Failed to fetch' || err.message.includes('NetworkError'))) {
      throw createLocalisedErrorMessage('localised-errors-offline');
    }

    // propagate original error
    if (bypassDefaultHandler) {
      throw err;
    }

    throw createLocalisedErrorMessage(localisedErrorId);
  }
};

export const handlePromiseRejection =
  (localisedErrorId: Errors = 'generic-fetch-error') =>
  (err: FetchError<FetchErrorData> | Error) => {
    if (err instanceof TypeError && err.message === 'Failed to fetch') {
      throw createLocalisedErrorMessage('localised-errors-offline');
    }

    throw createLocalisedErrorMessage(localisedErrorId);
  };
