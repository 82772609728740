import { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import DragDropFileUploadWrapper from '../../../DragDrop/DragDropFileUploadWrapper';
import './InputFile.scss';
import { InputDeprecated, InputDeprecatedProps } from './Input';

export interface FileProps extends InputDeprecatedProps {
  accept: string;
  onDrop: (e: any) => void;
}

export class InputFile extends Component<FileProps, {}> {
  public render() {
    const { id, onDrop } = this.props;

    return (
      <div className="input-file">
        <DragDropFileUploadWrapper classNameExt="input-file__drop-target" onDrop={onDrop}>
          <InputDeprecated type="file" id={id} {...this.props} />
          <label htmlFor={id ? `${id}` : undefined}>
            <Fragment>
              <FormattedMessage id="file-drag-label" defaultMessage="Drag here or " />
              <span className="input-file__button btn btn--secondary btn--tiny">
                <FormattedMessage id="upload-btn-label" defaultMessage="upload" />
              </span>
            </Fragment>
          </label>
        </DragDropFileUploadWrapper>
      </div>
    );
  }
}

export default InputFile;
