import layout from 'layout';
import QuestionContent from 'century-core/core-components/QuestionContent/QuestionContent';
import QuestionResponse from 'century-core/features-dashboard/common/QuestionLevelAnalysis/Question/QuestionResponse';
import { Equation } from 'century-core/core-components/Equation/Equation';
import { withErrorBoundaryPartial } from 'century-core/core-components/ErrorBoundary/ErrorBoundary';
import { MatchingQuestionTypeReadonly } from 'century-core/core-components/DraggableQuestionTypes/Matching/MatchingQuestionTypeReadonly';
import { LabellingQuestionTypeReadonly } from 'century-core/core-components/DraggableQuestionTypes/Labelling/LabellingQuestionTypeReadonly';
import { CachedImageUrlContextProvider } from 'century-core/media/hooks/useGetImagePublicUrlCache';
import { MatchingOrLabellingQuestionType } from 'century-core/typings/DraggableQuestionTypes';
import { FormattedMessage } from 'react-intl';
import { getDraggableQuestionFeedback } from 'century-core/core-components/DraggableQuestionTypes/utils';

interface Props {
  question: Ctek.EnrichedQuestion;
  analytics: Ctek.AbacusStudySessionQuestionResult;
  parameters: Ctek.QuestionGroupParams;
  questionGroup: Ctek.EnrichedQuestionGroup;
}

const QLAQuestion = ({ question, parameters, analytics, questionGroup }: Props) => {
  const result =
    analytics.activity === 'attempt-skip' || analytics.activity === 'attempt-leave'
      ? 'skipped'
      : analytics.correct
      ? 'correct'
      : 'incorrect';
  const questionContent = question.content.map((content, i) => <QuestionContent content={content} key={`${content.type}-${i}`} />);
  const answerGroup = question.answerGroups.find(ag => ag.id === analytics.answers?.[0]?.answerGroupId) || question.answerGroups[0];
  const correctAnswerForMatchingOrLabellingQuestion = (answerGroup: Ctek.EnrichedAnswerGroup) => {
    const items = answerGroup.answers.map((answer: any) => {
      return {
        answerGroupAnswerId: answer.id,
        possibleAnswerId: answer.correctPossibleAnswerIds[0],
        isCorrect: true,
        correctAnswerIds: answer.correctAnswerIds,
      };
    });
    return {
      correctAnsweredItems: items,
      revealError: '',
      revealLoading: false,
      handleQuestionRevealsClicked: () => Promise.resolve(),
    };
  };

  const exactAnswerSorting = () => {
    const answer = answerGroup.answers?.find(answer => answer.id === answerGroup.correctAnswerIds?.[0])?.exactInput?.[0] ?? '';
    return question.answerType === 'exact-answer-equation' ? <Equation equation={answer} /> : answer;
  }  

  if (question.answerType === 'multi-one-correct') {
    return (
      <layout.MultipleChoiceQuestion
        qa="qla-question-content"
        selectedId={analytics.answers?.[0]?.answerId}
        correctAnswerId={result === 'incorrect' ? answerGroup.correctAnswerIds?.[0] : undefined}
      >
        {{
          options: answerGroup.answers.map(({ id, content }) => ({
            answerContent: content.map((content, i) => <QuestionContent content={content} key={`${content.type}-${i}`} />),
            id: id || '',
          })),
          question: questionContent,
          response: (
            <>
              <h5 className="qla-attempt__result">
                <FormattedMessage id={`attempt-result-${result}`} defaultMessage={result} />
              </h5>
              <QuestionResponse answerGroup={answerGroup} analytics={analytics} />
            </>
          ),
          result,
        }}
      </layout.MultipleChoiceQuestion>
    );
  }
  if (question.answerType === 'exact-answer' || question.answerType === 'exact-answer-equation') {
    return (
      <layout.ExactAnswer qa="qla-question-content" reveals={result === 'incorrect' && exactAnswerSorting()}>
        {{
          exactAnswer:
            result !== 'skipped' ? (
              question.answerType === 'exact-answer' ? (
                (analytics as any).answers?.[0]?.contentSubmitted
              ) : (
                <Equation equation={analytics.answers?.[0]?.contentSubmitted!} />
              )
            ) : undefined,
          question: questionContent,
          response: (
            <>
              <h5 className="qla-attempt__result">
                <FormattedMessage id={`attempt-result-${result}`} defaultMessage={result} />
              </h5>
              <div className="qla-attempt__explanation">
                <QuestionResponse
                  answerGroup={answerGroup}
                  analytics={analytics}
                  genericIncorrectFeedback={question?.defaultIncorrectResponse}
                />
              </div>
            </>
          ),
          result,
          type: question.answerType === 'exact-answer-equation' ? 'equation' : 'default',
        }}
      </layout.ExactAnswer>
    );
  }
  if (question.answerType === 'matching') {
    return (
      <CachedImageUrlContextProvider>
        <MatchingQuestionTypeReadonly
          dragComponent={parameters.matchType as string}
          result={{
            isCorrect: analytics.correct,
            details: analytics.answers[0]?.answersSubmitted.map(as => ({
              possibleAnswerId: as.possibleAnswerIds[0],
              answerGroupAnswerId: as.answerId,
              isCorrect: !!answerGroup.answers
                .find(a => a.id === as.answerId)
                ?.correctPossibleAnswerIds?.find(cpi => cpi === as.possibleAnswerIds[0]),
            })),
          }}
          question={{
            data: question as unknown as MatchingOrLabellingQuestionType['data'],
            parameters,
            isClosedOnSubmit: false,
            questionGroupId: analytics.questionGroupId,
            assessmentId: analytics.assessmentId,
          }}
          data={{ feedback: getDraggableQuestionFeedback(question, questionGroup, analytics.correct) }}
          revealData={result === 'incorrect' ? correctAnswerForMatchingOrLabellingQuestion(answerGroup) : undefined}
        />
      </CachedImageUrlContextProvider>
    );
  }
  if (question.answerType === 'labelling') {
    return (
      <CachedImageUrlContextProvider>
        <LabellingQuestionTypeReadonly
          dragComponent={parameters.matchType as string}
          result={{
            isCorrect: analytics.correct,
            details: analytics.answers[0]?.answersSubmitted.map(as => ({
              possibleAnswerId: as.possibleAnswerIds[0],
              answerGroupAnswerId: as.answerId,
              isCorrect: !!answerGroup.answers
                .find(a => a.id === as.answerId)
                ?.correctPossibleAnswerIds?.find(cpi => cpi === as.possibleAnswerIds[0]),
            })),
          }}
          question={{
            data: question as unknown as MatchingOrLabellingQuestionType['data'],
            parameters,
            isClosedOnSubmit: false,
            questionGroupId: analytics.questionGroupId,
            assessmentId: analytics.assessmentId,
          }}
          data={{ feedback: getDraggableQuestionFeedback(question, questionGroup, analytics.correct) }}
          revealData={result === 'incorrect' ? correctAnswerForMatchingOrLabellingQuestion(answerGroup) : undefined}
        />
      </CachedImageUrlContextProvider>
    );
  }
  return null;
};

export default withErrorBoundaryPartial(QLAQuestion);
