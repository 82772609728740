import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { withErrorBoundaryPartial } from 'century-core/core-components/ErrorBoundary/ErrorBoundary'
import { QLAAttemptContext } from './QLAAttemptProvider'
import QlaLv1 from '../components/QlaLv1';
import QLANavigationInformationContext from '../QLANavigationInformationProvider'

export interface Props {
  attempt: Ctek.AbacusStudySessionActivity;
  canInspect: boolean;
  order: number;
  expandFirstAttempt?: boolean;
}

const QLAAttemptInformation = ({ attempt, canInspect, order, expandFirstAttempt }: Props) => {
  const QLANavLens = useContext(QLANavigationInformationContext);
  const [expanded, setExpanded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const QLAInfo = useContext(QLAAttemptContext);
  const initialQuestionIndex = useMemo(
    () =>
      attempt.studySessionId === QLANavLens?.getUserAttempt(attempt.userId)?.studySessionId
        ? QLANavLens?.getUserAttempt(attempt.userId)?.questionIdx
        : undefined,
    [attempt.userId, attempt.studySessionId, QLANavLens]
  );

  const onExpand = useCallback(async () => {
    if (!dataLoaded) {
      setLoading(true);
      await QLAInfo?.getAttempt(attempt.studySessionId);
      setDataLoaded(true);
      setLoading(false);
    }
    setExpanded(expanded => !expanded);
  }, [dataLoaded, QLAInfo, attempt.studySessionId]);

  useEffect(() => {
    if (expandFirstAttempt || attempt.studySessionId === QLANavLens?.getUserAttempt(attempt.userId)?.studySessionId) {
      onExpand();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <QlaLv1
        canInspect={canInspect}
        order={order}
        attempt={attempt}
        expanded={expanded}
        onExpand={onExpand}
        loading={loading}
        qlaAttempt={QLAInfo?.attempt}
        initialQuestionIndex={initialQuestionIndex}
      />
    </>
  );
};

export default withErrorBoundaryPartial(QLAAttemptInformation);
