import { Duration, DurationObject, ToRelativeUnit } from 'luxon';
import { ReactElement } from 'react';
import { IntlShape, useIntl } from 'react-intl';

// don't want to use quarters 
type TimeUnit = Exclude<ToRelativeUnit, 'quarters'>;

type translationMappingType = {
  [K in TimeUnit]: string
};

export const translationMapping: translationMappingType = {
  years: 'time-component-years',
  months: 'time-component-months',
  weeks: 'time-component-weeks',
  days: 'time-component-days',
  hours: 'time-component-hours',
  minutes: 'time-component-minutes',
  seconds: 'time-component-seconds'
};

interface Props {
  // pass in as seconds
  time: number;
  // Show shortened time units for more compct output
  shortenUnits?: boolean;
  // The highest magnitude unit wanted in the output 
  capUnit?: TimeUnit;
  // number of units to include after the highest non-zero OR capUnit unit (defaults to 1)
  numSubUnits?: number;
  // Will remove subunits of value zero from the output
  excludeZeros?: boolean;
}
const units: TimeUnit[] = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'];

export function CenturyExactTime(props: Props): ReactElement {
  const intl = useIntl();
  const outputString = getExactTimeString(props, intl)
  return (
    <span>{outputString}</span>
  )
}

export const getExactTimeString = (props: Props, intl: IntlShape) => {
  const { time, capUnit, numSubUnits, excludeZeros } = props;
  const cappedUnits = capUnit ? units.slice(units.indexOf(capUnit)) : units
  // gives interval of time using units up to max unit provided in capUnit e.g. capUnit === hours =>  {hours: 300, minutes: 23, seconds: 9}
  const duration = Duration.fromObject({ seconds: time }).shiftTo(...cappedUnits).mapUnits(u => Math.round(u)).toObject();
  const keys = Object.keys(duration) as TimeUnit[];
  let outputString = '';
  for (let i = 0; i < keys.length; i++) {
    if (shouldOutputValue(duration[keys[i]]!, keys, i, props)) {
      outputString += getTranslatedValueAndUnit(duration[keys[i]]!, keys[i], props, intl)
      if (shouldAppendSubUnits(numSubUnits, duration[keys[i]]!)) {
        outputString = appendSubUnits(outputString, duration, keys.slice(i + 1), props, numSubUnits, excludeZeros, intl);
      }
      break;
    }
  }
  return outputString;
}

const getTranslatedValueAndUnit = (value: number, unit: TimeUnit, props: Props, intl: IntlShape): string => {
  const keyStem = translationMapping[unit];
  const id = value === 1 ? `${keyStem}-singular` : `${keyStem}-plural`
  const translatedUnit = intl.formatMessage({
    id: props.shortenUnits ? `${id}-short` : id,
    defaultMessage: props.shortenUnits ? unit[0] : unit,
  });
  return `${value}${props.shortenUnits ? '' : ' '}${translatedUnit}`;
}

// first non-zero value (descending magnitude) || final value || capValue value
const shouldOutputValue = (value: number, keys: string[], i: number, props: Props): boolean => {
  return value !== 0 || i === keys.length - 1 || (keys[i] === props.capUnit);
}

const shouldAppendSubUnits = (numSubUnits = 1, value: number) => {
  return numSubUnits > 0 && value < 1000;
}

const appendSubUnits = (outputString: string, duration: DurationObject, keys: TimeUnit[], props: Props, numSubUnits = 1, excludeZeros: boolean | undefined, intl: IntlShape): string => {
  for (let j = 0; j < numSubUnits; j++) {
    if (duration[keys[j]] !== undefined) {
      if (duration[keys[j]] === 0 && excludeZeros === true) {
        continue;
      }
      outputString += ` ${getTranslatedValueAndUnit(duration[keys[j]]!, keys[j], props, intl)}`;
    } else {
      break;
    }
  }
  return outputString;
}

export default CenturyExactTime;
