import { AssignmentTableData } from 'century-core/core-apis/learn/assignments/learnAssignments';
import { ActiveStateFilter } from 'century-core/entities/Assignment/Assignment';

export function getAssignmentTableDataAndFilterBarStatus(
  assignments: AssignmentTableData[],
  activeStateFilter: ActiveStateFilter,
  subjectId: string,
  teacherId: string
) {
  let tableData = assignments;
  tableData = filterBySubjectId(tableData, subjectId);
  tableData = filterByTeacherId(tableData, teacherId);
  tableData = filterByActiveState(tableData, activeStateFilter);
  return tableData ?? [];
}

function filterByActiveState(tableData: AssignmentTableData[], activeStateFilter: ActiveStateFilter) {
  switch (activeStateFilter) {
    case 'active':
      return tableData.filter((tableRow: AssignmentTableData) => isAssignmentActive(tableRow));
    case 'past':
      return tableData.filter((tableRow: AssignmentTableData) => !isAssignmentActive(tableRow));
    case 'ALL':
    default:
      return tableData;
  }
}

function filterBySubjectId(tableData: AssignmentTableData[], subjectId: string) {
  const subjectExists = tableData.some(tableRow => tableRow.subjectId === subjectId);
  return !subjectId || subjectId === 'ALL' || !subjectExists ? tableData : tableData.filter(tableRow => tableRow.subjectId === subjectId);
}
function filterByTeacherId(tableData: AssignmentTableData[], teacherId: string) {
  const teacherExists = tableData.some(tableRow => tableRow.assignedBy === teacherId);
  return !teacherId || teacherId === 'ALL' || !teacherExists ? tableData : tableData.filter(tableRow => tableRow.assignedBy === teacherId);
}

function isAssignmentActive(tableRow: { deadline: string }) {
  // We shouldn't have assignments without endTime but if this bug happens
  // we'll just exclude them from the filter
  if (!tableRow.deadline) {
    return false;
  }

  return new Date() < new Date(tableRow.deadline);
}
