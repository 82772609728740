export const clipboard = {
  matchVisual: true,
};

export const emptyDelta = { ops: [] };

export const toolbar = (direction: 'ltr' | 'rtl', videoUpload = false) => [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'], // blocks
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }], // lists
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction }], // text direction
  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // header dropdown
  [{ color: [] }, { background: [] }], // dropdown with defaults
  [{ font: [] }], // font family
  [{ align: [] }], // text align
  videoUpload ? ['image', 'video', { videoUpload: 'image' }, 'formula'] : ['image', 'video', 'formula'],
  ['clean'], // remove formatting
  ['link'], // url links
];

export const formats = [
  'header',
  'font',
  'background',
  'color',
  'code',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'script',
  'align',
  'direction',
  'link',
  'image',
  'code-block',
  'formula',
  'video',
  'width',
  'height',
  'alt',
  'style',
  'class',
  'td',
];

const quillConstants = {
  clipboard,
  formats,
  toolbar,
};

export default quillConstants;
