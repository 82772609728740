import { PossibleAnswer } from '../../../../typings/DraggableQuestionTypes';
import { NoDragLabel } from './Label';

const LabelDragLayer = ({ item }: { item: PossibleAnswer }) => {
  if (!item) {
    return null;
  }

  return <NoDragLabel label={item} inactive={false} />;
};

export default LabelDragLayer;
