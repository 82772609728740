import { QLAAttemptProvider } from './QLAAttemptProvider';
import QLAAttemptInformation, { Props } from './QLAAttemptInformation'

const QLAAttemptWrapper = (props: Props) => {
  return <QLAAttemptProvider>
    <QLAAttemptInformation {...props} />
  </QLAAttemptProvider>
}

export default QLAAttemptWrapper;
