import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, AlertVariants } from '@ctek/design-system';

interface Props {
  count: number;
}

export default function WelcomeWidgetOverdueAssignments(props: Props) {
  const intl = useIntl();
  const assignmentsPlaceholder =
    props.count === 1
      ? intl.formatMessage({ id: 'student-dashboard-assignments-singular', defaultMessage: 'assignment' })
      : intl.formatMessage({ id: 'student-dashboard-assignments-plural', defaultMessage: 'assignments' });
  return props.count > 0 ? (
    <Alert variant={AlertVariants.WARNING}>
      <FormattedMessage
        id="welcome-widget-overdue-assignments"
        values={{ count: props.count, assignmentsPlaceholder }}
        defaultMessage="{count} {assignmentsPlaceholder} due yesterday"
      />
    </Alert>
  ) : null;

}
