import { useState, useCallback, useMemo } from 'react'
import * as L from 'luxon'
import { Widget } from '@ctek/design-system'
import { getUserActivity } from 'century-core/core-apis/roentgen/queries/studentDashboard'
import { withErrorBoundaryPartial } from 'century-core/core-components/ErrorBoundary/ErrorBoundary'
import ActivityCalendar from './ActivityCalendar'
import QueryWrapper from 'century-core/features-dashboard/Widgets/QueryWrapper';
import { usageMetricFragment } from '../LearnerActivityWidget/learnerActivityWidget.graphql'
import { FormattedMessage } from 'react-intl'
import { DashboardUserMode } from 'century-core/core-utils/utils/utils'
import { TestPracticeFilter } from 'century-core/core-apis/roentgen/queries/myCoursesStudent.graphql';
import { useQuery } from '@apollo/client'

interface Props {
  subjectGroupIds?: string[];
  subjectIds?: string[];
  testPracticeFilter?: TestPracticeFilter;
  userId: string;
  widgetMode: DashboardUserMode;
}

// seconds
const ACTIVITY_TIME_LIMIT = 30;

export const activityCalendarQuery = getUserActivity(usageMetricFragment);

const ActivityWidget = (props: Props) => {
  const { subjectIds, subjectGroupIds, testPracticeFilter } = props;
  const [dateRange, setDateRange] = useState({
    startTime: L.DateTime.local().toUTC().startOf('month').toISO(),
    endTime: L.DateTime.local().toUTC().startOf('month').plus({ months: 1 }).toISO(),
  });

  const setNewDateRange = useCallback(
    (start, end) => {
      if (start !== dateRange.startTime && end !== dateRange.endTime) {
        setDateRange({
          startTime: start,
          endTime: end,
        });
      }
    },
    [setDateRange, dateRange]
  );

  const userActivityInputs = useMemo(
    () => ({
      input: {
        userId: props.userId,
        startTime: dateRange.startTime,
        endTime: dateRange.endTime,
        timeBucketType: 'day',
        courseTypes: ['standard', 'assignment'],
        subjectIds,
        subjectGroupIds,
        testPrepFilterSelector: testPracticeFilter,
      },
    }),
    [props.userId, dateRange, subjectGroupIds, subjectIds, testPracticeFilter]
  );

  const userActivityQuery = useQuery(activityCalendarQuery, {
    variables: userActivityInputs,
    fetchPolicy: 'no-cache',
  });

  const selectedDays = useMemo(() => {
    if (!userActivityQuery?.data?.userActivity) {
      return [];
    }
    return userActivityQuery.data.userActivity.timeBuckets
      .filter((bucket: Ctek.Roentgen.UsageMetrics) => (bucket.durationActualSec?.sum || 0) >= ACTIVITY_TIME_LIMIT)
      .map((filteredBucket: Ctek.Roentgen.UsageMetrics) => L.DateTime.fromISO(filteredBucket.startTime).toJSDate());
  }, [userActivityQuery.data]);

  return (
    <Widget qa="widget--activity-calendar">
      <Widget.Title>
        <FormattedMessage id="activity-calendar-title" defaultMessage="Active Days" />
      </Widget.Title>
      <Widget.Body qa="widget--activity-calendar-body">
        <QueryWrapper query={userActivityQuery}>
          <ActivityCalendar
            selectedDays={selectedDays}
            setNewDateRange={setNewDateRange}
            widgetMode={props.widgetMode}
            userId={props.userId}
          />
        </QueryWrapper>
      </Widget.Body>
    </Widget>
  );
};

export default withErrorBoundaryPartial(ActivityWidget);
