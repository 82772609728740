import * as React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import {
  CompletionPercentage,
  CourseTrackerMobile,
  ModalBox,
  ScorePercentage,
  TableCellLink,
  TableHeaderSortable,
  TableHeaderSortableSortDirection,
  TableSimple,
  Text,
} from '@ctek/design-system';
import { SortingType, SortSettings, sortTableData, updateSortSettings } from 'century-core/core-utils/utils/tableSorting/tableSorting';
import {
  getNuggetProp,
  getStudentProp,
  shouldDisplayNugget,
  getCourseProp,
} from 'century-core/core-utils/utils/qla/studentCourseDashboard';
import { getTableDate } from 'century-core/core-utils/utils/helpers';
import { QLANavigationInformationProvider } from 'century-core/features-dashboard/common/QuestionLevelAnalysis/QLANavigationInformationProvider';
import { QLALensInformationProvider } from 'century-core/features-dashboard/common/QuestionLevelAnalysis/QLALensInformationProvider';
import QLAWrapper from 'century-core/features-dashboard/common/QuestionLevelAnalysis/QLAWrapper';
import { withErrorBoundaryPartial } from 'century-core/core-components/ErrorBoundary/ErrorBoundary';
import CurrentCourseContext from 'century-core/features-dashboard/StudentDashboard/StudentDashboardCourses/CurrentCourseProvider';
import { useState } from 'react';
import { useDeviceInfo } from 'century-core/user-preferences/DeviceInfoContext';
import { TrackerNugget } from 'century-core/core-utils/selectors/nuggetActivity';
import { useB2C } from 'century-core/core-utils/hooks/useB2C';
import { CourseBehaviour } from 'century-core/core-utils/utils/behaviours';

type TableLabels = 'name' | 'type' | 'latestActivityTimeClosed' | 'lastCompletion' | 'lastScore' | 'bestScore';

interface QlaState {
  userId: string;
  nuggetId: string;
}

interface Props {
  studentId: string;
  nuggetActivities: TrackerNugget[];
  intl: IntlShape;
  isTestPractice?: boolean;
  showAttemptedNuggets: boolean;
  trackNuggetSort?: (col: string, direction: string) => void;
  courseBehaviour: CourseBehaviour;
}

const NuggetTrackerBody = (props: Props) => {
  const [sortSettings, setSortSettings] = useState<Partial<SortSettings<TableLabels>>>({});
  const [showQlaModal, setShowQlaModal] = useState<boolean>(false);
  const [qla, setQla] = useState<QlaState | undefined>(undefined);
  const { deviceType, orientation } = useDeviceInfo();
  const nuggetActivities = sortTableData(props.nuggetActivities, sortSettings);
  const isB2C = useB2C();

  const renderTableHead = () => {
    return (
      <tr data-testid="nugget-tracker-table-header">
        <th scope="col">
          <TableHeaderSortable onClick={() => sortTable('name', 'string')} sortDirection={getSortingDirectionForHeader('name')}>
            <FormattedMessage id="dependant-overview-nugget-tracker-name" defaultMessage="Nugget Name" />
          </TableHeaderSortable>
        </th>
        {props.courseBehaviour === CourseBehaviour.SUBJECT && (
          <th scope="col">
            <TableHeaderSortable onClick={() => sortTable('type', 'string')} sortDirection={getSortingDirectionForHeader('type')}>
              <FormattedMessage id="dependant-overview-nugget-tracker-type" defaultMessage="Nugget Type" />
            </TableHeaderSortable>
          </th>
        )}
        <th scope="col">
          <TableHeaderSortable
            onClick={() => sortTable('latestActivityTimeClosed', 'date')}
            sortDirection={getSortingDirectionForHeader('latestActivityTimeClosed')}
          >
            <FormattedMessage id="dependant-overview-nugget-tracker-last-attempt" defaultMessage="Last Attempt" />
          </TableHeaderSortable>
        </th>
        <th scope="col">
          <TableHeaderSortable
            onClick={() => sortTable('lastCompletion', 'number')}
            sortDirection={getSortingDirectionForHeader('lastCompletion')}
          >
            <FormattedMessage id="dependant-overview-nugget-tracker-last-completion" defaultMessage="Last Completion" />
          </TableHeaderSortable>
        </th>
        <th scope="col">
          <TableHeaderSortable onClick={() => sortTable('lastScore', 'number')} sortDirection={getSortingDirectionForHeader('lastScore')}>
            <FormattedMessage id="dependant-overview-nugget-tracker-last-score" defaultMessage="Last Score" />
          </TableHeaderSortable>
        </th>
        <th scope="col">
          <TableHeaderSortable onClick={() => sortTable('bestScore', 'number')} sortDirection={getSortingDirectionForHeader('bestScore')}>
            <FormattedMessage id="dependant-overview-nugget-tracker-best-score" defaultMessage="Best Score" />
          </TableHeaderSortable>
        </th>
      </tr>
    );
  };

  const renderTableBody = (nuggetActivities: TrackerNugget[]) => {
    return nuggetActivities.map((datum: TrackerNugget, i: number) => (
      <tr key={datum._id} data-testid={`nugget-tracker-row-${i}`}>
        <td>
          <TableCellLink>{getNuggetName(datum)}</TableCellLink>
        </td>
        {props.courseBehaviour === CourseBehaviour.SUBJECT && <td>{datum.type}</td>}
        <td>{getTableDate(datum.latestActivityTimeClosed as any)}</td>
        <td>{datum.lastCompletion !== null && <CompletionPercentage percentage={datum.lastCompletion} />}</td>
        <td>
          {datum.lastScore !== null &&
            (datum.hasNoQuestions ? (
              <FormattedMessage id="not-applicable" defaultMessage="N/A" />
            ) : (
              <ScorePercentage percentage={datum.lastScore} hasSymbol={false} />
            ))}
        </td>
        <td>
          {datum.bestScore !== null &&
            (datum.hasNoQuestions ? (
              <FormattedMessage id="not-applicable" defaultMessage="N/A" />
            ) : (
              <ScorePercentage percentage={datum.bestScore} hasSymbol={false} />
            ))}
        </td>
      </tr>
    ));
  };

  const sortTable = (name: TableLabels, type: SortingType) => {
    const settings = updateSortSettings(name, type, sortSettings, undefined, undefined, true);
    const sortOrder = settings[name]?.order;
    props.trackNuggetSort?.(sortOrder ? name : 'default', sortOrder || '');
    setSortSettings(settings);
  };

  const getSortingDirectionForHeader = (columnName: string) => {
    const isActiveColumn = Object.keys(sortSettings)[0] === columnName;
    const order =
      Object.values(sortSettings)[0]?.order === 'ASCENDING' ? TableHeaderSortableSortDirection.ASC : TableHeaderSortableSortDirection.DESC;
    return isActiveColumn ? order : undefined;
  };

  const getNuggetName = (datum: TrackerNugget) => {
    return datum.latestActivityTimeClosed ? (
      <button data-testid="qla-trigger-open" onClick={() => openQlaModal(datum)}>
        {datum.name}
      </button>
    ) : (
      datum.name
    );
  };

  const hideModal = () => setShowQlaModal(false);

  const openQlaModal = (nuggetData: TrackerNugget) => {
    setQla({ nuggetId: nuggetData._id, userId: props.studentId });
    setShowQlaModal(true);
  };

  const renderQlaModal = () => {
    return (
      <ModalBox
        dismissLabel={props.intl.formatMessage({ id: 'modal-dismiss-button', defaultMessage: 'Dismiss' })}
        floatingDismiss={deviceType === 'phone' ? false : true}
        modalActive={showQlaModal}
        onExit={hideModal}
        size="qla"
        qa="qla-modal"
      >
        <ModalBox.Body>
          <QLAWrapper nuggetId={qla?.nuggetId || ''} userId={qla?.userId || ''} />
        </ModalBox.Body>
      </ModalBox>
    );
  };

  return (
    <CurrentCourseContext.Consumer>
      {value => {
        return (
          <QLANavigationInformationProvider
            students={[{ _id: props.studentId }]}
            nuggets={nuggetActivities}
            shouldDisplayNugget={shouldDisplayNugget}
            getNuggetProp={getNuggetProp}
            getStudentProp={getStudentProp}
          >
            <QLALensInformationProvider
              nuggets={props.nuggetActivities}
              getProp={(nugget, prop) => getCourseProp(nugget, prop, value?.course)}
            >
              <React.Fragment>
                {!!nuggetActivities && nuggetActivities.length > 0 ? (
                  deviceType === 'phone' ? (
                    <CourseTrackerMobile sticky={orientation === 'portrait' ? true : false}>
                      <TableSimple bordered={false} striped={false} hover={false} sticky={true} qa="nugget-tracker-table">
                        <thead>{renderTableHead()}</thead>
                        <tbody>{renderTableBody(nuggetActivities)}</tbody>
                      </TableSimple>
                    </CourseTrackerMobile>
                  ) : (
                    <TableSimple bordered={false} striped={false} hover={false} sticky={true} qa="nugget-tracker-table">
                      <thead>{renderTableHead()}</thead>
                      <tbody>{renderTableBody(nuggetActivities)}</tbody>
                    </TableSimple>
                  )
                ) : (
                  <div data-testid="nugget-tracker-no-data-msg">
                    <Text.Placeholder>
                      {isB2C && props.showAttemptedNuggets && !props.isTestPractice ? (
                        <FormattedMessage id="no-attempted-nuggets" defaultMessage="No attempted nuggets" />
                      ) : (
                        <FormattedMessage id="no-data" defaultMessage="Sorry, we don't have enough information for this yet" />
                      )}
                    </Text.Placeholder>
                  </div>
                )}
              </React.Fragment>
              {renderQlaModal()}
            </QLALensInformationProvider>
          </QLANavigationInformationProvider>
        );
      }}
    </CurrentCourseContext.Consumer>
  );
};

export default withErrorBoundaryPartial(injectIntl(NuggetTrackerBody));
