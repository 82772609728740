import { Field, FieldProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import { getDataFromStringPath } from 'century-core/core-utils/utils/utils';
import { FormFieldDeprecated, InputDeprecated, InputPasswordDeprecated } from '@ctek/design-system';
interface Props<E extends { [k: string]: string }> {
  type?: 'text' | 'password' | 'date' | 'email';
  name: string;
  validate?: (x: string) => keyof E | undefined | null;
  labelID?: string;
  defaultLabel?: string;
  inputProps?: {};
  formErrors?: E;
  qaInputId?: string;
  errorValues?: { [k: string]: string | number };
}

export function FormikFormField<E extends { [k: string]: string }>(props: Props<E>) {
  return (
    <FormFieldDeprecated>
      {{
        input: (
          <Field name={props.name} validate={props.validate}>
            {({ field, form }: FieldProps) => {
              const errors = getDataFromStringPath(props.name, form.errors);
              const touched = getDataFromStringPath(props.name, form.touched);
              return props.type === 'password' ? (
                <InputPasswordDeprecated
                  qa={props.qaInputId}
                  {...field}
                  {...props.inputProps}
                  errorMessage={
                    props.formErrors && touched && errors ? (
                      <FormattedMessage id={errors!} defaultMessage={props.formErrors[errors!]} values={props.errorValues} />
                    ) : null
                  }
                  canShowPassword={true}
                >
                  <FormattedMessage id="show-password" defaultMessage="Show password" />
                </InputPasswordDeprecated>
              ) : (
                <InputDeprecated
                  type={props.type}
                  {...field}
                  {...props.inputProps}
                  errorMessage={
                    props.formErrors && touched && errors ? (
                      <FormattedMessage id={errors!} defaultMessage={props.formErrors[errors!]} values={props.errorValues} />
                    ) : null
                  }
                />
              );
            }}
          </Field>
        ),
        label: props.labelID && props.defaultLabel && <FormattedMessage id={props.labelID} defaultMessage={props.defaultLabel} />,
      }}
    </FormFieldDeprecated>
  );
}
