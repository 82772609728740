import * as React from 'react';
import { getMediaPublicUrl as getUrlFromApi } from 'century-core/core-apis/mediaLibrary/mediaLibrary';

export const useGetImagePublicUrlCache = () => {
  const [cachedImageUrls, setCachedUrls] = React.useState({});
  const [pendingUrls, setPendingUrls] = React.useState({});

  async function getMediaPublicUrl(id: string, token: string): Promise<string> {
    if (cachedImageUrls[id]) {
      return cachedImageUrls[id] as string;
    }

    // If image has already been requested but hasn't returned yet, return cached promise.
    if (pendingUrls[id]) {
      return await pendingUrls[id];
    }

    // Image has already been requested so we wait for the first request to return.
    const urlPromise = getUrlFromApi(id, token) as Promise<string>;
    setPendingUrls(prevState => {
      const newPendingUrls = Object.assign({}, prevState);
      newPendingUrls[id] = urlPromise;
      return newPendingUrls;
    });

    // Image url has not been cached so get it and add it to cache then return it.
    const url = await urlPromise;
    setCachedUrls(prevState => {
      const newCache = Object.assign({}, prevState);
      newCache[id] = url;
      return newCache;
    });
    // Remove image from pending requests cache as it has now resolved.
    setPendingUrls(prevState => {
      const newPendingUrls = Object.assign({}, prevState);
      delete newPendingUrls[id];
      return newPendingUrls;
    });

    return url;
  }

  return getMediaPublicUrl;
};

const CachedImageUrlContext = React.createContext({ getMediaPublicUrl: (id: string, token: string) => Promise.resolve('') });

export const CachedImageUrlContextProvider = ({ children }: { children: React.ReactNode }) => {
  const getMediaPublicUrl = useGetImagePublicUrlCache();

  return <CachedImageUrlContext.Provider value={{ getMediaPublicUrl }}>{children}</CachedImageUrlContext.Provider>;
};

export default CachedImageUrlContext;

// TODO - remove this when polymer QLA is removed - use CachedImageUrlContextProvider instead.
export const provideCachedGetImageUrlContext = <P extends object>(Component: React.ComponentType<P>) => {
  return (props: any) => (
    <CachedImageUrlContextProvider>
      <Component {...props} />
    </CachedImageUrlContextProvider>
  );
};
