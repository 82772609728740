import { Widget, LoadingSpinner, Text } from '@ctek/design-system';
import FocusStretchNuggets, { FocusStretchMode } from 'century-core/core-components/FocusAndStretch/FocusStretchNuggets';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import { FormattedMessage } from 'react-intl';
interface Props {
  recommendedNuggets: Ctek.Roentgen.FocusAndStretch[];
  mode: FocusStretchMode;
  isLoading: boolean;
  widgetMode: DashboardUserMode;
}

const KtRecommendedNuggets = (props: Props) => {
  const { recommendedNuggets, mode, isLoading, widgetMode } = props;

  const qa = `kt-${mode === FocusStretchMode.FOCUS ? 'focus' : 'stretch'}-`;

  return (
    <Widget qa={`${qa}-widget`}>
      <Widget.Header>
        <Widget.Title qa={`${qa}-title`}>
          {mode === FocusStretchMode.FOCUS ? (
            <FormattedMessage id="kt-focus-header" defaultMessage="Focus on to Improve" />
          ) : (
            <FormattedMessage id="kt-stretch-header" defaultMessage="Stretch With" />
          )}
        </Widget.Title>
      </Widget.Header>
      {!isLoading ? (
        <Widget.Body qa={`${qa}-body`}>
          {!!recommendedNuggets && recommendedNuggets.length > 0 ? (
            <FocusStretchNuggets
              FocusStretchNuggetsList={recommendedNuggets}
              mode={mode}
              pathArea={'my-dashboard/courses'}
              enableNuggetLink={widgetMode === DashboardUserMode.STUDENT}
              viewLocation={'StudentDashboard'}
            />
          ) : (
            <div data-testid={`${qa}-no-data-msg`}>
              <Text.Placeholder>
                <FormattedMessage
                  id="no-focus-and-stretch-data-message"
                  defaultMessage="Sorry, there has not been enough activity for this yet"
                />
              </Text.Placeholder>
            </div>
          )}
        </Widget.Body>
      ) : (
        <div data-testid={`${qa}-loading-spinner`}>
          <LoadingSpinner type={'widget'} />
        </div>
      )}
    </Widget>
  );
};

export default KtRecommendedNuggets;
