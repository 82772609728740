/** Adds style sheet to the DOM head */
export const addStyleSheet = (styleToAdd: string) => {
  const style = document.createElement('link');
  style.rel = 'stylesheet';
  style.type = 'text/css';
  style.href = styleToAdd;
  document.head.appendChild(style);
};

/** Removes stylesheet from the DOM  */
export const removeStyleSheet = (scriptToRemove: string) => {
  const allStyles = document.getElementsByTagName('link');
  for (let i = allStyles.length; i >= 0; i--) {
    if (
      allStyles[i] &&
      allStyles[i].getAttribute('href') !== null &&
      allStyles[i].getAttribute('href')?.indexOf(`${scriptToRemove}`) !== -1
    ) {
      allStyles[i].parentNode?.removeChild(allStyles[i]);
    }
  }
};
