import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import NuggetTrackerBody from './NuggetTrackerBody';
import { LoadingSpinner, Widget, ToggleGroup, ResponsiveGroup } from '@ctek/design-system';
import { SearchBox } from 'century-core/core-components/SearchBar/SearchBox';
import { useAccessToken } from 'century-core/core-auth/hooks/useAuth';
import { getNuggetActivities } from 'century-core/core-apis/learn/nuggetActivity/nuggetActivity';
import { transformNuggetActivities, TrackerNugget } from 'century-core/core-utils/selectors/nuggetActivity';
import { getBatchedRequest } from 'century-core/core-apis/utils/getBatchedRequest';
import { StrandFilter } from './StrandFilter';
import { useIsPhone } from 'century-core/user-preferences/DeviceInfoContext';
import { useMixpanel } from 'century-core/core-components/MixpanelUtils';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import { getMixpanelEventFromViewMode } from '../utils';
import { getNuggetPartnerName, getUniqueSortedNuggetTypes, NuggetPartnerName } from 'century-core/core-components/NuggetPartner';
import { CourseBehaviour } from 'century-core/core-utils/utils/behaviours';

export interface NuggetTrackerProps {
  studentId: string;
  nuggetIds: string[];
  course: Ctek.Course;
  isTestPractice?: boolean;
  userMode: DashboardUserMode;
  courseBehaviour: CourseBehaviour;
}

type NuggetActivityRequestParams = { nuggetIds: string; studentId: string };

/**
 * LX PHASE 1 -
 * Once 'StudentDashboardCourses' does not exist anymore, remove 'isTestPractice' and change what's necessary
 * to act as if 'isTestPractice' is always true.
 */
export default function NuggetTracker(props: NuggetTrackerProps) {
  const { course, userMode, isTestPractice, studentId, nuggetIds, courseBehaviour } = props;

  const [searchString, setSearchString] = useState('');
  const [nuggetActivities, setNuggetActivities] = useState<TrackerNugget[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const accessToken = useAccessToken();
  const requestParams = useRef<Partial<NuggetActivityRequestParams>>({});
  const [strandFilter, setStrandFilter] = useState<string | null>(null);
  const [nuggetTypeFilter, setNuggetTypeFilter] = useState<NuggetPartnerName>(null);
  const [showAttemptedNuggets, setShowAttemptedNuggets] = useState(true);
  const isPhone = useIsPhone();
  const mixpanel = useMixpanel();

  useEffect(() => {
    setStrandFilter(null);
  }, [course]);

  useEffect(() => {
    if (nuggetIds.length === 0) {
      requestParams.current = {
        nuggetIds: nuggetIds.join(','),
        studentId,
      };
      setIsLoading(false);
      return;
    }
    if (nuggetIds.join(',') !== requestParams.current.nuggetIds || studentId !== requestParams.current.studentId) {
      setIsLoading(true);
      setError(null);
      const formattedRequest = ({ entries, studentId, accessToken }: any): Promise<Ctek.AbacusNuggetActivity[]> => {
        return getNuggetActivities(entries, studentId, accessToken);
      };
      if (nuggetIds.length > 0) {
        getBatchedRequest<{ studentId: string; accessToken: string }, Ctek.AbacusNuggetActivity>(formattedRequest, nuggetIds, 20, {
          studentId,
          accessToken,
        })
          .then(v => {
            if (!v.length) {
              return setNuggetActivities(
                course.strands
                  ?.flatMap(s => s.nuggets as Ctek.Nugget)
                  .map(n => ({
                    name: n.name!,
                    _id: n._id!,
                    bestScore: null,
                    lastCompletion: null,
                    lastScore: null,
                    latestActivityTimeClosed: null,
                    userId: '',
                    hasStartedAttempt: null,
                    questionsAnswered: null,
                    hasNoQuestions: false,
                    type: null,
                  })) || []
              );
            }
            return setNuggetActivities(transformNuggetActivities(course, v) || []);
          })
          .catch(e => {
            setError(e);
          })
          .finally(() => setIsLoading(false));
      }
      requestParams.current = {
        nuggetIds: nuggetIds.join(','),
        studentId,
      };
    }
  }, [accessToken, studentId, nuggetIds, course]);

  const mixpanelBaseAttrs = {
    [MixpanelKeys.CourseId]: course._id,
    [MixpanelKeys.CourseName]: course.name,
    [MixpanelKeys.CourseType]: course.type,
    [MixpanelKeys.CourseSubject]: (course.subject as Ctek.Subject)?.name,
    [MixpanelKeys.CourseLevel]: (course.level as Ctek.Level)?.name,
  };

  // Search nuggets
  useEffect(() => {
    // Reset the search string when the viewed course is changed
    setSearchString('');
  }, [nuggetActivities, strandFilter]);

  const onSearchChange = (value: string) => {
    if (!!value) {
      const eventName = getMixpanelEventFromViewMode(courseBehaviour, userMode, {
        [CourseBehaviour.SUBJECT]: {
          [DashboardUserMode.GUARDIAN]: MixpanelEventTypes.GuardianDashboardSubjectsNuggetListSearched,
          [DashboardUserMode.STUDENT]: MixpanelEventTypes.StudentDashboardSubjectsNuggetListSearched,
        },
      });
      mixpanel.track(eventName, {
        ...mixpanelBaseAttrs,
        [MixpanelKeys.SearchTerm]: value,
      });
    }
    setSearchString(value);
  };

  // Filter by Topic
  const onSetStrandFilter = (strandId: string | null) => {
    const hasNoStrandSelected = strandId === null;
    const selectedStrand = !hasNoStrandSelected ? course.strands?.find(s => s.id === strandId) : null;

    const eventName = getMixpanelEventFromViewMode(courseBehaviour, userMode, {
      [CourseBehaviour.TEST_PRACTICE]: {
        [DashboardUserMode.GUARDIAN]: MixpanelEventTypes.GuardianDashboardTestPracticeFilterByTopic,
        [DashboardUserMode.STUDENT]: MixpanelEventTypes.StudentDashboardTestPracticeFilterByTopic,
      },
      [CourseBehaviour.SUBJECT]: {
        [DashboardUserMode.GUARDIAN]: MixpanelEventTypes.GuardianDashboardSubjectsFilterByTopic,
        [DashboardUserMode.STUDENT]: MixpanelEventTypes.StudentDashboardSubjectsFilterByTopic,
      },
    });
    if ((selectedStrand || hasNoStrandSelected) && eventName) {
      mixpanel.track(eventName, {
        ...mixpanelBaseAttrs,
        [MixpanelKeys.StrandId]: selectedStrand?.id,
        [MixpanelKeys.StrandName]: hasNoStrandSelected ? 'all topics' : selectedStrand?.name || 'all topics',
      });
    }
    setStrandFilter(strandId);
  };

  // Sort Nugget List
  const trackNuggetSort = (sortColumn: string, sortDirection: string) => {
    if (!isTestPractice) {
      return;
    }
    const trackingEvent = getMixpanelEventFromViewMode(courseBehaviour, userMode, {
      [CourseBehaviour.TEST_PRACTICE]: {
        [DashboardUserMode.GUARDIAN]: MixpanelEventTypes.GuardianDashboardTestPracticeSortNuggetList,
        [DashboardUserMode.STUDENT]: MixpanelEventTypes.StudentDashboardTestPracticeSortNuggetList,
      },
      [CourseBehaviour.SUBJECT]: {
        [DashboardUserMode.GUARDIAN]: MixpanelEventTypes.GuardianDashboardSubjectsSortNuggetList,
        [DashboardUserMode.STUDENT]: MixpanelEventTypes.StudentDashboardSubjectsSortNuggetList,
      },
    });
    if (trackingEvent && course) {
      mixpanel.track(trackingEvent, {
        ...mixpanelBaseAttrs,
        [MixpanelKeys.SortOrder]: sortDirection,
        [MixpanelKeys.SortedColumn]: sortColumn,
      });
    }
  };

  // Filter by Type
  const uniqueNuggetTypes = useMemo(() => {
    const strands = (strandFilter ? course.strands?.filter(s => s.id === strandFilter) : course.strands) || [];
    const strandNuggets = strands.flatMap(s => s.nuggets) as Ctek.Nugget[];
    return getUniqueSortedNuggetTypes(strandNuggets);
  }, [course.strands, strandFilter]);

  const onFilterChange = (uniqueNuggetType: NuggetPartnerName) => {
    if (!!uniqueNuggetType) {
      const event =
        userMode === DashboardUserMode.GUARDIAN
          ? MixpanelEventTypes.GuardianDashboardSubjectsNuggetsFilterByType
          : MixpanelEventTypes.StudentDashboardSubjectsNuggetsFilterByType;
      mixpanel.track(event, {
        ...mixpanelBaseAttrs,
        [MixpanelKeys.FilterType]: uniqueNuggetType,
      });
    }
    setNuggetTypeFilter(uniqueNuggetType);
  };

  // Filtered Nugget list
  const filteredNuggetActivities = useMemo(() => {
    // Filter nuggets by strand from 'course'
    const strands = (strandFilter ? course.strands?.filter(s => s.id === strandFilter) : course.strands) || [];
    const strandNuggets = strands.flatMap(s => s.nuggets) as Ctek.Nugget[];
    const strandNuggetIds = strandNuggets.map(n => n._id!);

    // Filter nuggets by search, populate type and filter by type
    return nuggetActivities
      .filter(nuggetActivity => strandNuggetIds.includes(nuggetActivity._id))
      .filter(nuggetActivity => nuggetActivity.name.toLowerCase().includes(searchString.toLocaleLowerCase()))
      .map(nuggetActivity => {
        const nuggetLabels = strandNuggets.find(strandNugget => strandNugget._id === nuggetActivity._id)?.labels || [];
        return {
          ...nuggetActivity,
          type: getNuggetPartnerName(nuggetLabels as string[]),
        };
      })
      .filter(nugget => !nuggetTypeFilter || nugget.type === nuggetTypeFilter);
  }, [strandFilter, course.strands, nuggetActivities, searchString, nuggetTypeFilter]);

  // Render components
  const renderedControls = (
    <Widget.Controls>
      <SearchBox
        performSearch={onSearchChange}
        data-testid="nugget-tracker-search"
        placeholderId={'search-placeholder'}
        defaultPlaceholder={'Search'}
      />
    </Widget.Controls>
  );

  return !error ? (
    <Widget qa="nugget-tracker">
      <Widget.Header>
        <Widget.Title qa="nugget-tracker-title">
          <FormattedMessage id="dependant-overview-nugget-tracker-header" defaultMessage="Tracker" />
        </Widget.Title>
        {!isTestPractice && renderedControls}
      </Widget.Header>
      {!isLoading ? (
        <Widget.Body isScrollable={isPhone && isTestPractice} qa="nugget-tracker-body">
          {isTestPractice && (
            <>
              <StrandFilter
                nuggetActivities={nuggetActivities}
                strands={course.strands || []}
                strandFilter={strandFilter}
                setStrandFilter={onSetStrandFilter}
              />

              <Widget.Header>
                <Widget.Title>
                  <FormattedMessage id="nugget-tracker--subject-heading" defaultMessage="Study activity" />
                </Widget.Title>
                {renderedControls}
              </Widget.Header>
            </>
          )}
          <ResponsiveGroup>
            {!!uniqueNuggetTypes.length && (
              <ToggleGroup>
                <ToggleGroup.Button active={!nuggetTypeFilter} onClick={() => onFilterChange(null)} qa="nugget-tracker-type-filter-all">
                  <FormattedMessage id="nugget-tracker--toggle-filter-all" defaultMessage="All" />
                </ToggleGroup.Button>
                {uniqueNuggetTypes.map(uniqueNuggetType => (
                  <ToggleGroup.Button
                    key={uniqueNuggetType}
                    active={nuggetTypeFilter === uniqueNuggetType}
                    onClick={() => onFilterChange(uniqueNuggetType)}
                    qa="nugget-tracker-type-filter"
                  >
                    {uniqueNuggetType}
                  </ToggleGroup.Button>
                ))}
              </ToggleGroup>
            )}
            <ToggleGroup>
              <ToggleGroup.Button
                active={showAttemptedNuggets}
                onClick={() => setShowAttemptedNuggets(!showAttemptedNuggets)}
                qa="nugget-tracker-filter-attempted"
              >
                <FormattedMessage id="nugget-tracker--toggle-filter-studied" defaultMessage="Studied" />
              </ToggleGroup.Button>
              <ToggleGroup.Button
                active={!showAttemptedNuggets}
                onClick={() => setShowAttemptedNuggets(!showAttemptedNuggets)}
                qa="nugget-tracker-filter-all"
              >
                <FormattedMessage id="nugget-tracker--toggle-filter-all" defaultMessage="All" />
              </ToggleGroup.Button>
            </ToggleGroup>
          </ResponsiveGroup>
          <NuggetTrackerBody
            isTestPractice={isTestPractice}
            showAttemptedNuggets={showAttemptedNuggets}
            nuggetActivities={showAttemptedNuggets ? filteredNuggetActivities.filter(n => !!n.hasStartedAttempt) : filteredNuggetActivities}
            studentId={studentId}
            trackNuggetSort={trackNuggetSort}
            courseBehaviour={courseBehaviour}
          />
        </Widget.Body>
      ) : (
        <div data-testid="nugget-tracker-loading">
          <LoadingSpinner type="widget" />
        </div>
      )}
    </Widget>
  ) : null;
}
