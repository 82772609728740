import { CourseBehaviour } from 'century-core/core-utils/utils/behaviours';
import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';

export const getMixpanelEventFromViewMode = (
  courseBehaviour: CourseBehaviour,
  viewMode: DashboardUserMode,
  eventKeys: {
    [s in DashboardUserMode]?: {
      [c in CourseBehaviour]?: MixpanelEventTypes[keyof MixpanelEventTypes];
    };
  }
) => eventKeys[courseBehaviour]?.[viewMode];
