import { FormattedMessage } from 'react-intl';
import { getPercentValue } from 'century-core/core-utils/utils/helpers';
import CenturyExactTime from 'century-core/core-components/CenturyTime/CenturyExactTime';
import {
  Breakdown,
  CdsStatValue,
  CourseInfo,
  CourseInfoMobile,
  StatIndicator,
  StatIndicatorTypes,
  StatIndicatorSizes,
  Heading,
  ProgressThumbnail,
} from '@ctek/design-system';
import CourseImage from 'century-core/media/CourseImage';
import { SubjectColouredLabel } from 'century-core/core-components/Subject/SubjectLabel';

interface Props {
  course: Ctek.Roentgen.CoursePathItem;
}

export default function CoursesWidgetCourseCardMobile(props: Props) {
  return (
    <CourseInfoMobile>
      <CourseInfo qa="widget--courses--course-info">
        <CourseInfo.Thumbnail>
          <ProgressThumbnail
            size={60}
            completionPercentage={getPercentValue(props.course.activity.percentComplete)}
            scorePercentage={getPercentValue(props.course.activity.percentScore)}
          >
            <CourseImage
              item={{
                iconCode: props.course.icon.iconCode,
                subject: {
                  _id: props.course.subject.labelId,
                  name: props.course.subject.name,
                },
                iconUrl: props.course.icon.url,
              }}
            />
          </ProgressThumbnail>
        </CourseInfo.Thumbnail>
        <CourseInfo.Main>
          <SubjectColouredLabel subjectName={props.course.subject.name} />
          <Heading.ItemTitle>{props.course.name}</Heading.ItemTitle>
          <CourseInfo.Breakdown>
            <Breakdown>
              <Breakdown.Item>
                <CdsStatValue>
                  <CdsStatValue.Label>
                    <CdsStatValue.Indicator>
                      <StatIndicator
                        type={StatIndicatorTypes.COMPLETION}
                        size={StatIndicatorSizes.SMALL}
                        score={getPercentValue(props.course.activity.percentScore)}
                      />
                    </CdsStatValue.Indicator>
                    <FormattedMessage id="student-dashboard-courses-widget-completion" defaultMessage="Completion" />
                  </CdsStatValue.Label>
                  <CdsStatValue.Value>
                    {getPercentValue(props.course.activity.percentComplete)}
                    <CdsStatValue.Unit>%</CdsStatValue.Unit>
                    <CdsStatValue.Total>
                      {props.course.activity.completeNuggets}/{props.course.activity.totalNuggets}
                    </CdsStatValue.Total>
                  </CdsStatValue.Value>
                </CdsStatValue>
              </Breakdown.Item>
              <Breakdown.Item>
                <CdsStatValue>
                  <CdsStatValue.Label>
                    <CdsStatValue.Indicator>
                      <StatIndicator type={StatIndicatorTypes.SCORE} size={StatIndicatorSizes.SMALL} score={77} />
                    </CdsStatValue.Indicator>
                    <FormattedMessage id="student-dashboard-average-last-score" defaultMessage="Average Last Score" />
                  </CdsStatValue.Label>
                  <CdsStatValue.Value>
                    {getPercentValue(props.course.activity.percentScore)}
                    <CdsStatValue.Unit>%</CdsStatValue.Unit>
                  </CdsStatValue.Value>
                </CdsStatValue>
              </Breakdown.Item>
              <Breakdown.Item>
                <CdsStatValue>
                  <CdsStatValue.Label>
                    <FormattedMessage id="student-dashboard-time-spent" defaultMessage="Time Spent" />
                  </CdsStatValue.Label>
                  <CdsStatValue.Value>
                    <CenturyExactTime time={props.course.activity.durationActual} shortenUnits={true} />
                  </CdsStatValue.Value>
                </CdsStatValue>
              </Breakdown.Item>
            </Breakdown>
          </CourseInfo.Breakdown>
        </CourseInfo.Main>
      </CourseInfo>
    </CourseInfoMobile>
  );
}
