import { useEffect, useMemo, useState } from 'react';
import { ChartContainerHeights, LoadingSpinner } from '@ctek/design-system';
import QueryWrapper from 'century-core/features-dashboard/Widgets/QueryWrapper';
import { LearnerActivityMetricMode, LearnerActivityTimeMode } from './LearnerActivityWidget';
import { learnerActivityWidgetQuery } from './learnerActivityWidget.graphql';
import LearnerActivityBarChart from './LearnerActivityBarChart';
import LearnerActivityWidgetControls from './LearnerActivityWidgetControls';
import { getTotalNuggetCount, getTotalQuestionCount, getTotalTimeCount } from './utils';
import { TestPracticeFilter } from 'century-core/core-apis/roentgen/queries/myCoursesStudent.graphql';
import { useQuery } from '@apollo/client';

interface Props {
  userId: string;
  startTime: string;
  endTime: string;
  timeBucketType: LearnerActivityTimeMode;
  metricMode: LearnerActivityMetricMode;
  handleMetricModeChange: (e: LearnerActivityMetricMode) => void;
  handleTimeModeChange: (e: LearnerActivityTimeMode) => void;
  handleTimePeriodChange: (value: string) => void;
  handlePrevClick: () => void;
  handleNextClick: () => void;
  rangeOptions: JSX.Element[][];
  rangeIndex: number;
  isLast: boolean;
  isFirst: boolean;
  courseIds?: string[];
  chartHeight: ChartContainerHeights;
  subjectIds?: string[];
  subjectGroupIds?: string[];
  testPracticeFilter?: TestPracticeFilter;
}

/**
 * LX PHASE 1 -
 * Once 'StudentDashboardCourses' does not exist anymore, remove 'isSubjectView' from 'LearnerActivityBarChart'
 * and 'LearnerActivityWidgetControls' and apply the logic as if 'isSubjectView' is true.
 */
const LearnerActivityWidgetBody = ({
  userId,
  startTime,
  endTime,
  timeBucketType,
  metricMode,
  handleMetricModeChange,
  handleTimeModeChange,
  handleTimePeriodChange,
  handleNextClick,
  handlePrevClick,
  rangeOptions,
  rangeIndex,
  isFirst,
  isLast,
  courseIds,
  chartHeight,
  subjectIds,
  subjectGroupIds,
  testPracticeFilter,
}: Props) => {
  const [loading, setLoading] = useState(true);

  const queryOptions = useMemo(() => {
    return {
      input: {
        userId,
        startTime,
        endTime,
        // Only want days shown for the first two options
        timeBucketType: timeBucketType === LearnerActivityTimeMode.MONTH ? LearnerActivityTimeMode.MONTH : LearnerActivityTimeMode.DAY,
        courseTypes: ['standard', 'assignment'],
        courseIds,
        subjectIds,
        subjectGroupIds,
        testPrepFilterSelector: testPracticeFilter,
      },
    };
  }, [userId, startTime, endTime, timeBucketType, courseIds, subjectGroupIds, subjectIds, testPracticeFilter]);

  const query = useQuery(learnerActivityWidgetQuery, {
    variables: queryOptions,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setLoading(true);
  }, [courseIds]);

  useEffect(() => {
    setLoading(false);
  }, [query.data]);

  const onTimeModeChange = (e: LearnerActivityTimeMode) => {
    setLoading(true);
    handleTimeModeChange(e);
  };
  const onTimePeriodChange = (e: string) => {
    setLoading(true);
    handleTimePeriodChange(e);
  };

  const onNextClick = () => {
    if (isLast) {
      return;
    }
    setLoading(true);
    handleNextClick();
  };

  const onPrevClick = () => {
    if (isFirst) {
      return;
    }
    setLoading(true);
    handlePrevClick();
  };

  return (
    <>
      <QueryWrapper query={query} localLoading={true}>
        <LearnerActivityWidgetControls
          handleMetricModeChange={handleMetricModeChange}
          handleTimeModeChange={onTimeModeChange}
          handleTimePeriodChange={onTimePeriodChange}
          handleNextClick={onNextClick}
          handlePrevClick={onPrevClick}
          metricMode={metricMode}
          rangeOptions={rangeOptions}
          rangeIndex={rangeIndex}
          startTime={startTime}
          timeMode={timeBucketType}
          questionCount={query.data ? getTotalQuestionCount(query.data) : 0}
          nuggetCount={query.data ? getTotalNuggetCount(query.data) : 0}
          timeCount={query.data ? getTotalTimeCount(query.data) : 0}
          isLast={isLast}
          isFirst={isFirst}
          isSubjectView={testPracticeFilter === TestPracticeFilter.ALL}
        />
        <div data-testid="learner-activity-widget-chart">
          <LearnerActivityBarChart
            metricMode={metricMode}
            data={query.data}
            timeMode={timeBucketType}
            startTime={startTime}
            chartHeight={chartHeight}
            isSubjectView={testPracticeFilter === TestPracticeFilter.ALL}
          />
        </div>
      </QueryWrapper>
      {loading && <LoadingSpinner type="widget" fadeIn={false} overlay={true} />}
    </>
  );
};

export default LearnerActivityWidgetBody;
