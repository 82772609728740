export const TEST_PRACTICE_LABEL_ID = '34d7c5ec-cf16-448c-819d-2050072f2643';
export const SUBJECT_COURSE_LABEL_ID = '8173f9c3-e4b7-4723-8ac0-fbab13aea9f5';
export const STUDENT_DASHBOARD_HIDDEN_LEGACY_COURSE = 'b2148f39-98ee-4843-af90-8e37658305e4';

export const BOND_CONTENT_LABEL_ID = '006f4fc4-90d4-11ed-a1eb-0242ac120002';
export const BOND_CEM_CONTENT_LABEL_ID = 'd472a793-c11f-4e6b-881f-7eeec7af8159';
export const ISEB_CONTENT_LABEL_ID = '7e18cf84-90d1-11ed-a1eb-0242ac120002';
export const LEARN_CONTENT_LABEL_ID = 'bb66ced5-a163-438c-92c2-82d6f17e25c8';

export enum CourseBehaviour {
  STANDARD,
  TEST_PRACTICE,
  SUBJECT,
}

export const courseSortingLabels: Partial<Record<CourseBehaviour, string>> = {
  [CourseBehaviour.SUBJECT]: SUBJECT_COURSE_LABEL_ID,
};

export const courseHidingLabels: Partial<Record<CourseBehaviour, string>> = {
  [CourseBehaviour.SUBJECT]: STUDENT_DASHBOARD_HIDDEN_LEGACY_COURSE,
};
