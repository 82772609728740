// derive bullet points from regex which checks for prefixes used in blocks
const getBulletRegExp = (bulletString: string[]) => new RegExp('^' + bulletString.map(bullet => `(${bullet})`).join('|'));

// blocks which follow the provided bullet prefix pattern may be intended as entries in a list
export const isListBlock = (contentText: string, bulletString: string[]) => {
  return contentText?.trim().match(getBulletRegExp(bulletString));
};

// combine SirTrevor content to derive markdown lists
export const condenseBulletParagraphsToList = (document: Ctek.PopulatedMediaDocument, bullets: string[]) => {
  if (bullets.length === 0) {
    throw Error('must include at least one bullet prefix');
  }
  const refactoredDocContent: Ctek.MediaContentType[] = [];
  document.content.forEach(contentEntry => {
    const currentData = contentEntry.data as Ctek.SirTrevorContent;
    const previousData = refactoredDocContent[refactoredDocContent.length - 1]?.data as Ctek.SirTrevorContent;

    if (contentEntry.type !== 'text') {
      const newData = { ...contentEntry };
      if (contentEntry.data) {
        newData.data = { ...contentEntry.data };
      }
      refactoredDocContent.push(newData);
      return;
    }

    if (previousData && isListBlock(currentData.originalText, bullets) && isListBlock(previousData.text, ['- '])) {
      previousData.text += `\n${currentData.originalText.replace(getBulletRegExp(bullets), '- ')}`;
    } else if (isListBlock(currentData.originalText, bullets)) {
      refactoredDocContent.push({
        ...contentEntry,
        data: {
          ...currentData,
          format: 'markdown',
          text: currentData.originalText ? currentData?.originalText.replace(getBulletRegExp(bullets), '- ') : undefined,
        },
        type: 'text',
      });
    } else {
      refactoredDocContent.push(contentEntry);
    }
  });
  return refactoredDocContent;
};
