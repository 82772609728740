import { useEffect } from 'react';

export const useHoldEnterManager = (inputElement: HTMLInputElement | null, submit: undefined | (() => void), ignoreHook = false) => {
  // This effect fixes TECH-25286 where holding the enter button after submitting 
  // an answer would skip the feedback and go straight to next question
  // AS-417: ignoreHook added to prevent submission when using textarea element
  useEffect(() => {
    if (inputElement && submit && !ignoreHook) {
      const keyupSubmit = (evt: KeyboardEvent) => {
        if (inputElement && evt.key === 'Enter') submit();
      };

      const preventSubmit = (e: KeyboardEvent) => {
        if (inputElement && e.key === 'Enter') e.preventDefault();
      }
      inputElement.addEventListener('keydown', preventSubmit);
      inputElement.addEventListener('keyup', keyupSubmit);
      return () => {
        inputElement.removeEventListener('keyup', keyupSubmit);
        inputElement.removeEventListener('keydown', preventSubmit);
      }
    }
    return;
  }, [inputElement, submit, ignoreHook]);
}