/**
 * Having Proper error objects in redux makes little sense. We need simple objects
 * we can use to render error messages using formattedMessage.
 */
export class ErrorMessage<T> {
  public innerErrors?: ErrorMessage<T>[] = [];
  public values?: any;
  constructor(public code: T, public message: string) {}
}

export default ErrorMessage;
