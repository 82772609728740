import { ErrorReload } from 'century-core/core-components/ErrorReload/ErrorReload';
import KtRecommendedNuggets from './KtRecommendedNuggets';
import { FocusStretchMode } from 'century-core/core-components/FocusAndStretch/FocusStretchNuggets';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import { useFocusAndStretch } from 'century-core/core-apis/roentgen/hooks/useFocusAndStretch';
import { handleApolloError } from 'century-core/core-utils/utils/sentryErrorLogging/handleApolloError';
import { useState } from 'react';
import { FlexGrid } from '@ctek/design-system';
interface StudentFocusAndStretchProps {
  userId: string;
  courseId: string;
  widgetMode: DashboardUserMode;
  columnLgWidth: 6 | 12;
}

export const StudentFocusAndStretch = (props: StudentFocusAndStretchProps) => {
  const { userId, courseId, widgetMode, columnLgWidth } = props;
  const [retriedError, setRetriedError] = useState(false);

  const focusAndStretchQuery = useFocusAndStretch(userId, !courseId, courseId);
  const { data, loading, error, refetch } = focusAndStretchQuery;

  if (error) {
    if (retriedError) {
      handleApolloError(error, 'loading Student Dashboard Stretch and Focus widgets');
      return <ErrorReload action={() => refetch()} />;
    }
    refetch();
    setRetriedError(true);
  }

  return (
    <>
    <FlexGrid.Col sm={12} lg={columnLgWidth}>
      <KtRecommendedNuggets
        recommendedNuggets={data?.focusAndStretch?.focus}
        mode={FocusStretchMode.FOCUS}
        isLoading={loading}
        widgetMode={widgetMode}
      />
      </FlexGrid.Col>
      <FlexGrid.Col sm={12} lg={columnLgWidth}>
      <KtRecommendedNuggets
        recommendedNuggets={data?.focusAndStretch?.stretch}
        mode={FocusStretchMode.STRETCH}
        isLoading={loading}
        widgetMode={widgetMode}
      />
    </FlexGrid.Col>
    </>
  );
};
