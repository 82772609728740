import { ReactNode } from 'react';
import './LabelPairList.scss';

export interface Props {
  children: Children;
  qa?: string;
}

export interface Children {
  pairs: ReactNode[];
}

export const LabelPairList = (props: Props) => {
  const { qa } = props;
  const { pairs } = props.children;

  return (
    <ul className="label-pair-list" data-qa={qa} data-testid={qa}>
      {pairs.map((item, index) => (
        <li className="label-pair-list__item" key={index}>
          {item}
        </li>
      ))}
    </ul>
  );
};

export default LabelPairList;
