import { FormattedMessage } from 'react-intl'
import RecentlyAttemptedWidgetBody, { Props } from './RecentlyAttemptedWidgetBody';
import { Widget } from '@ctek/design-system';

const RecentlyAttemptedWidget = (props: Props) => {
  return (
    <Widget qa="widget--recently-completed">
      <Widget.Header>
        <Widget.Title qa="widget--recently-completed--title">
          <FormattedMessage id="widget--recently-completed--title" defaultMessage="Recently Completed" />
        </Widget.Title>
      </Widget.Header>
      <Widget.Body qa="widget--recently-completed--body">
        <RecentlyAttemptedWidgetBody
          subjectGroupIds={props.subjectGroupIds}
          subjectIds={props.subjectIds}
          userId={props.userId}
          widgetMode={props.widgetMode}
          testPracticeFilter={props.testPracticeFilter}
        />
      </Widget.Body>
    </Widget>
  );
}

RecentlyAttemptedWidget.defaultProps = {
  subjectIds: [],
  subjectGroupIds: [],
};

export default RecentlyAttemptedWidget;
