import { PageFilter, PageFilterList } from '@ctek/design-system';
import { SubjectFilter } from 'century-core/features-dashboard/TeacherDashboard/TeacherDashboardClassOverview/ClassOverview';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ALL_OPTION, OTHER_GROUP } from './constants';
import SubjectGroupFilter from './SubjectGroupFilter';
import { ErrorReload } from 'century-core/core-components/ErrorReload/ErrorReload';
import { QueryResult } from '@apollo/client';

export type GetDataFromQuery = (q: QueryResult<any, any>) => ({ subjectGroups: Ctek.Label[]; subjects: Ctek.Label[] } | null);

export interface SubjectGroupsFilterProps {
  validSubjectIds?: string[];
  onSubjectFilterSet: (params: { subjectGroups?: SubjectFilter[]; subjects?: SubjectFilter[] }, customGroup?: string) => any;
  initialSubjectId?: string | null;
  initialSubjectGroupId?: string | null;
  query: QueryResult<any, any>;
  getSubjectFilterDataFromQuery: GetDataFromQuery;
  onSubjectsFetched?: (subjectIds: string[]) => void;
}

interface SubjectGroup extends Ctek.Roentgen.Label {
  subjects: Ctek.Roentgen.Label[];
}

const SubjectGroupsFilter = (props: SubjectGroupsFilterProps) => {
  const { validSubjectIds, onSubjectFilterSet, initialSubjectId, initialSubjectGroupId, query, getSubjectFilterDataFromQuery, onSubjectsFetched } = props;
  const intl = useIntl();
  const groupedSubjects = useMemo(() => {
    const data = getSubjectFilterDataFromQuery(query);
    if (!data) {
      return null;
    }
    const otherSubjectGroup = {
      name: 'Other',
      labelId: OTHER_GROUP,
      subjects: data?.subjects.filter(
        (s: Ctek.Roentgen.Label) =>
          !s.parentId &&
          (!validSubjectIds || validSubjectIds.includes(s.labelId)) &&
          !data?.subjectGroups.some((sg: SubjectGroup) => s.labelId === sg.labelId)
      ),
    };
    const definedSubjectGroups = data?.subjectGroups.map((sg: SubjectGroup) => ({
      ...sg,
      subjects: data?.subjects.filter(
        (s: Ctek.Roentgen.Label) =>
          s.parentId === sg.labelId && s.labelId !== sg.labelId && (!validSubjectIds || validSubjectIds.includes(s.labelId))
      ),
    }));

    if (otherSubjectGroup.subjects.length) {
      return [...definedSubjectGroups, otherSubjectGroup];
    }

    return definedSubjectGroups;
  }, [query, getSubjectFilterDataFromQuery, validSubjectIds]);

  const [selectedGroup, setSelectedGroup] = useState<SubjectFilter | undefined>(
    initialSubjectGroupId ? { id: initialSubjectGroupId, name: '' } : undefined
  );

  const onSelectSubject = useCallback(
    (subjectGroup = ALL_OPTION, subject = { id: ALL_OPTION }) => {
      setSelectedGroup(subjectGroup);

      if (subjectGroup.id === OTHER_GROUP) {
        const subjects =
          subject.id !== ALL_OPTION
            ? [{ id: subject.id, name: subject.name }]
            : groupedSubjects
                ?.find((sg: SubjectGroup) => sg.labelId === OTHER_GROUP)
                ?.subjects.map((s: Ctek.Roentgen.Label) => ({ id: s.labelId, name: s.name }));

        onSubjectFilterSet(
          {
            subjectGroups: [],
            subjects,
          },
          subject.id === ALL_OPTION ? ALL_OPTION : undefined
        );

        if (subjects) {
          onSubjectsFetched?.(subjects?.map(s => s.id));
        }
        return;
      }

      const subjects = subject.id !== ALL_OPTION ? [{ id: subject.id, name: subject.name }] : [];

      onSubjectFilterSet({
        subjectGroups: subjectGroup.id !== ALL_OPTION ? [{ id: subjectGroup.id, name: subjectGroup.name }] : [],
        subjects,
      });

      const groupSubjects =
        subject.id !== ALL_OPTION
          ? [{ id: subject.id, name: subject.name }]
          : groupedSubjects
              ?.find((sg: SubjectGroup) => sg.labelId === subjectGroup.id)
              ?.subjects.map((s: Ctek.Roentgen.Label) => ({ id: s.labelId, name: s.name })) || [];

      onSubjectsFetched?.(groupSubjects.map(s => s.id));
    },
    [onSubjectFilterSet, groupedSubjects, onSubjectsFetched]
  );

  if (query.error) {
    return <ErrorReload action={() => query.refetch()} />;
  }

  return Array.isArray(groupedSubjects) && groupedSubjects.length ? (
    <PageFilterList qa="subject-groups-filter">
      <PageFilterList.Item>
        <PageFilter isActive={selectedGroup?.id === ALL_OPTION || !selectedGroup?.id}>
          <PageFilter.Button>
            <button onClick={() => onSelectSubject({ id: ALL_OPTION, name: ALL_OPTION }, { id: ALL_OPTION, name: ALL_OPTION })}>
              {intl.formatMessage({ id: 'all', defaultMessage: 'All' })}
              <PageFilter.SelectedOption>
                {intl.formatMessage({ id: 'subject-group--all-subjects', defaultMessage: 'All subjects' })}
              </PageFilter.SelectedOption>
            </button>
          </PageFilter.Button>
        </PageFilter>
      </PageFilterList.Item>
      {groupedSubjects?.map(
        (subjectGroup: SubjectGroup) =>
          subjectGroup.subjects.length > 0 && (
            <SubjectGroupFilter
              key={subjectGroup.labelId}
              subjectGroupName={subjectGroup.name}
              isGeneratedGroup={subjectGroup.labelId !== OTHER_GROUP}
              isSelected={subjectGroup.labelId === selectedGroup?.id}
              subjects={subjectGroup.subjects}
              onSelectSubject={subjectId => onSelectSubject({ id: subjectGroup.labelId, name: subjectGroup.name }, subjectId)}
              initialSubjectId={initialSubjectId || undefined}
            />
          )
      )}
    </PageFilterList>
  ) : null;
};

export default SubjectGroupsFilter;
