import * as Sentry from '@sentry/browser';

import Auth from 'century-core/entities/Auth/Auth';
import { getTokenSub } from './token';
import { getTokenMainOrgId } from './orgs';

export function addUserAndOrgIdToSentry(auth: Auth) {
  try {
    const userId = getTokenSub(auth);
    const orgId = getTokenMainOrgId(auth);
    Sentry.configureScope(scope => {
      scope.setUser({ id: userId });
      scope.setTag('organisationId', orgId);
    });
  } catch (_) {
    Sentry.captureMessage('Not able to provide user and org id id for the logged in user');
  }
}
