import { getAccountsUrl } from '../accountsV3';
import { GET } from 'century-core/core-apis/utils';

export function getDomainSettingsAccounts(domain: string): Promise<Ctek.Accounts.DomainResponse[]> {
  const url = getAccountsUrl(`/domains/${domain}`);

  return GET<Ctek.Accounts.DomainResponse[]>({ url }).catch((err: Error) => {
    throw Error(`Unable to get domain settings for ${domain}, ${err}`);
  });
}
