import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedDurationKeys } from 'century-core/entities/Assignment/Assignment';
import { formatTime } from 'century-core/core-utils/utils/helpers';

interface Props {
  duration: number | null;
}

export class DurationMessage extends PureComponent<Props, {}> {
  public render() {
    const { duration } = this.props;
    if (duration === null || duration === undefined || duration === 0) {
      return <FormattedMessage id="duration-no-time-spent" defaultMessage="No time spent" />;
    }
    return formatTime(duration).map(interval => {
      switch (interval.key) {
        case FormattedDurationKeys.YEARS:
          return this.renderYears(interval.value);
        case FormattedDurationKeys.MONTHS:
          return this.renderMonths(interval.value);
        case FormattedDurationKeys.DAYS:
          return this.renderDays(interval.value);
        case FormattedDurationKeys.HOURS:
          return this.renderHours(interval.value);
        case FormattedDurationKeys.MINUTES:
          return this.renderMinutes(interval.value);
        case FormattedDurationKeys.SECONDS:
          return this.renderSeconds(interval.value);
        default:
          return '';
      }
    });
  }

  private renderYears(interval: number) {
    return (
      <FormattedMessage
        id="duration-years"
        defaultMessage={'{years, number} {years, plural, one{yr}} '}
        key="years"
        values={{ years: interval }}
      />
    );
  }

  private renderMonths(interval: number) {
    return (
      <FormattedMessage
        id="duration-months"
        defaultMessage={'{months, number} {months, plural, one{mth} other{mths}} '}
        key="months"
        values={{ months: interval }}
      />
    );
  }

  private renderDays(interval: number) {
    return (
      <FormattedMessage
        id="duration-days"
        defaultMessage={'{days, number} {days, plural, one{day} other{days}} '}
        key="days"
        values={{ days: interval }}
      />
    );
  }

  private renderHours(interval: number) {
    return (
      <FormattedMessage
        id="duration-hours"
        defaultMessage={'{hours, number} {hours, plural, one{hr} other{hrs}} '}
        key="hours"
        values={{ hours: interval }}
      />
    );
  }

  private renderMinutes(interval: number) {
    return (
      <FormattedMessage
        id="duration-minutes"
        defaultMessage={'{minutes, number} {minutes, plural, one{min} other{mins}} '}
        key="minutes"
        values={{ minutes: interval }}
      />
    );
  }

  private renderSeconds(interval: number) {
    return (
      <FormattedMessage
        id="duration-seconds"
        defaultMessage={'{seconds, number} {seconds, plural, one{sec} other {secs}} '}
        key="seconds"
        values={{ seconds: interval }}
      />
    );
  }
}
