import { getFeatureFlagData, hasFeature } from 'century-core/core-auth/utils/featureFlags';
import { useAuth } from 'century-core/core-auth/hooks/useAuth';

export const useB2C = () => { // After V1 removal in Feb 2024, we currently only have V2 and flag is read down from 'b2c'
  const auth = useAuth();
  return hasFeature(auth, 'b2c', 'isEnabled');
};

export const useB2CVersion2 = () => {
  const auth = useAuth();
  return getFeatureFlagData(auth, 'b2c', 'version') === 2;
};
