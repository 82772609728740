import { GET } from 'century-core/core-apis/utils';
import { getLearnURL } from '../learn';

export function getUserNuggetAttempts(userId: string, nuggetId: string, accessToken: string) {
  const url = getLearnURL('/qla/learner-nugget-attempts/');
  url.search += '?userId=' + userId + '&nuggetIds=' + nuggetId;

  return GET<Ctek.AbacusNuggetHistory[]>({ url, token: accessToken })
    .then(res => res[0])
    .catch((err: Error) => {
      throw Error(`Unable to get nugget attempts for user id ${userId} and nugget id ${nuggetId}, ${err}`);
    });
}

export const getStudySessionResults = (studySessionId: string, accessToken: string): Promise<Ctek.StudySessionResult> => {
  const url = getLearnURL();
  url.pathname += `/qla/studysessions/${studySessionId}/results`;
  return GET<Ctek.StudySessionResult>({ url, token: accessToken });
};
