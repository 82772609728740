import * as SentryLib from 'century-core/core-utils/lib/sentry';
import { History } from 'history';

import {
  isOpenId,
  isAccessTokenValid,
  parseAuthFromLocalstorage,
  redirectToLogin,
  getTokenSub,
  scheduleRenewToken,
  addUserAndOrgIdToSentry,
} from 'century-core/core-auth/utils';
import renewAccessToken from 'century-core/core-auth/lib/renewAccessToken';
import logout from 'century-core/core-auth/lib/logout';
import { getUser } from 'century-core/core-apis/accountsV2/users/users';
import localStorage from 'century-core/core-utils/utils/localStorage/localStorage';

import Auth from 'century-core/entities/Auth/Auth';
import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { Errors } from 'century-core/entities/ErrorMessage/Errors';

const LOGGED_OUT_PATHS = ['/registration', '/bond'];

export default async function loadLocalStorage(
  renewTokenTimer: NodeJS.Timeout,
  historyPush: (path: History.Path) => void,
  setAuthEverywhere: (auth: Auth) => void
): Promise<Auth | void> {
  if (isOpenId(window.location.hash)) {
    return;
  }

  /**
   * If we are trying to access the "recover-password", do not bother to validate any local tokens, and do not
   * redirect the user.
   * The same goes if refresh_token is appended which means we're authorising straight from it (goal: LTI auth)
   */
  if (window.location.pathname.indexOf('recover-password') > -1 || new URL(window.location.href).searchParams.has('refresh_token')) {
    // We force here a localstorage cleaning to avoid the bug where
    // different AL users get redirected to same Century account (TECH-21285)
    localStorage.remove('auth');
    return;
  }

  /**
   * We're not forcing a localstorage cleaning for user trying to access "registration" as we want logged-in user to be
   * redirected to user's Home Page, depending on the user's role. The redirection function is in App.tsx.
   * For non-logged user trying to access "registration", they will stay on /registration therefore NOT redirect to Login page
   */
  if (LOGGED_OUT_PATHS.some(path => window.location.pathname.match(new RegExp(`^${path}`)))) {
    return;
  }

  /**
   * If we are trying to pass auth0 params, do not bother to validate any local tokens,
   * and do not redirect the user.
   */
  if (window.location.search.indexOf('code') > -1 && window.location.search.indexOf('state') > -1) {
    return;
  }

  const auth = parseAuthFromLocalstorage();
  if (auth === undefined) {
    return redirectToLogin(historyPush);
  }

  const isTokenValid = isAccessTokenValid(auth);
  if (!isTokenValid && auth.refreshToken) {
    return renewAccessToken(auth, renewTokenTimer, setAuthEverywhere);
  }

  if (!isTokenValid && !auth.refreshToken) {
    logout(renewTokenTimer);
    return redirectToLogin(historyPush);
  }

  return getUser(getTokenSub(auth), auth.accessToken || '')
    .then(user => {
      if (user) {
        addUserAndOrgIdToSentry(auth);
      }
      scheduleRenewToken(auth, () => renewAccessToken(auth, renewTokenTimer, setAuthEverywhere), renewTokenTimer);

      return auth;
    })
    .catch((err: ErrorMessage<Errors>) => {
      SentryLib.captureException(new Error(err?.message));
      logout(renewTokenTimer);
    });
}
