import { createContext, useEffect, useState, ReactNode, useContext, useCallback } from 'react';

export const MOBILE_MAX_WIDTH = 1024;
const PHONE_MAX_WIDTH = 430;

export type Orientation = 'portrait' | 'landscape';
export type Device = 'desktop' | 'tablet' | 'phone';

export interface DeviceInfoData {
  orientation: Orientation;
  deviceType: Device;
}

export const DeviceInfoContext = createContext<DeviceInfoData>({ orientation: 'landscape', deviceType: 'desktop' });

export const useDeviceInfo = () => useContext(DeviceInfoContext);

export const useIsPhone = () => useDeviceInfo().deviceType === 'phone';

export const DeviceInfoProvider = (props: { children: ReactNode }) => {
  const [orientation, setOrientation] = useState<Orientation>('landscape');
  const [deviceType, setDeviceType] = useState<Device>('desktop');

  const onResize = useCallback(() => {
    const height = window.innerHeight;
    const width = window.innerWidth;
    const smallerSize = Math.min(width, height);

    setDeviceType(width > MOBILE_MAX_WIDTH ? 'desktop' : smallerSize > PHONE_MAX_WIDTH ? 'tablet' : 'phone');
    setOrientation(height > width ? 'portrait' : width > PHONE_MAX_WIDTH ? 'landscape' : 'portrait');
  }, [setOrientation, setDeviceType]);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  return <DeviceInfoContext.Provider value={{ orientation, deviceType }}>{props.children}</DeviceInfoContext.Provider>;
};
