import { getPercentValue } from '../utils/helpers';

export interface TrackerNugget {
  _id: string;
  bestScore: number | null;
  lastCompletion: number | null;
  lastScore: number | null;
  latestActivityTimeClosed: string | null;
  name: string;
  userId: string;
  hasStartedAttempt: boolean | null;
  questionsAnswered: number | null;
  hasNoQuestions: boolean;
  type: string | null;
}

enum NuggetTrackerColumnNames {
  NUGGET_NAME = 'name',
  LATEST_ACTIVITY_TIME_CLOSED = 'latestActivityTimeClosed',
  LAST_COMPLETION = 'lastCompletion',
  LAST_SCORE = 'lastScore',
  BEST_SCORE = 'bestScore',
  USER_ID = 'userId',
}

export function transformNuggetActivities(course: Ctek.Course, nuggetActivities: Ctek.AbacusNuggetActivity[]) {
  if (!course || !course.strands || !nuggetActivities || !nuggetActivities.length) {
    return null;
  } else {
    const data = ((course as Ctek.Course).strands || [])
      .reduce((accumulator: Ctek.Nugget[], current: Ctek.Strand) => accumulator.concat(current.nuggets as Ctek.Nugget[]), [])
      .map((nugget: Ctek.Nugget) => {
        const nuggetActivity = nuggetActivities.find((na: Ctek.AbacusNuggetActivity) => na.nuggetId === nugget._id);
        return getNuggetActivityRowData(nuggetActivity, nugget);
      });
    return data as unknown as TrackerNugget[];
  }
}

function getNuggetActivityRowData(nuggetActivity: Ctek.AbacusNuggetActivity | undefined, nugget: Ctek.Nugget) {
  return {
    _id: nugget._id,
    [NuggetTrackerColumnNames.BEST_SCORE]: nuggetActivity
      ? nuggetActivity.percentScore && nuggetActivity.percentScore.closed
        ? getPercentValue(nuggetActivity.percentScore.closed.max)
        : null
      : null,
    [NuggetTrackerColumnNames.LAST_COMPLETION]: nuggetActivity
      ? nuggetActivity.percentComplete && nuggetActivity.percentComplete.closed
        ? getPercentValue(nuggetActivity.percentComplete.closed.latest)
        : null
      : null,
    [NuggetTrackerColumnNames.LAST_SCORE]: nuggetActivity
      ? nuggetActivity.percentScore && nuggetActivity.percentScore.closed
        ? getPercentValue(nuggetActivity.percentScore.closed.latest)
        : null
      : null,
    [NuggetTrackerColumnNames.LATEST_ACTIVITY_TIME_CLOSED]: nuggetActivity
      ? nuggetActivity.latestActivityTimeClosed
        ? new Date(nuggetActivity.latestActivityTimeClosed)
        : null
      : null,
    [NuggetTrackerColumnNames.NUGGET_NAME]: nugget.name,
    [NuggetTrackerColumnNames.USER_ID]: nuggetActivity ? (nuggetActivity.userId ? nuggetActivity.userId : null) : null,
    hasStartedAttempt: nuggetActivity ? nuggetActivity.percentComplete.all.max > 0 : null,
    hasNoQuestions: nuggetActivity ? nuggetActivity.questionsAnswered.all.max === 0 && nuggetActivity.percentScore.all.max === -1 : false,
  };
}
