import localStorage from 'century-core/core-utils/utils/localStorage/localStorage';
import { sendMessageToServiceWorker } from 'century-core/core-auth/utils';
import { clearElaMood } from 'century-core/core-components/ELAMood/ELAMoodContext';

export default function logout(renewTokenTimer: NodeJS.Timeout, redirect?: string): void {
  clearTimeout(renewTokenTimer);
  // clear the service worker cache
  sendMessageToServiceWorker('clearCenturyCache');
  localStorage.remove('auth');
  clearElaMood();

  if (window.auth0) {
    window.auth0.logout({});
  }

  const loginPath = `/login/${redirect ? `?redirect=${redirect}` : ''}`;
  window.location.href = loginPath;
}
