import * as React from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import DropTarget from './DropTarget';

interface Props {
  children: React.ReactNode;
  classNameExt?: string;
  onDrop: (item: any) => void;
}

/*
 ** THIS CLASS REQUIRES A DndProvider WRAPPED AROUND IT SOMEWHERE FURTHER UP THE TREE
 */

// Wrapper that will provide drag drop upload file functionality to its child component.
// onDrop is called when a file is dropped
const DragDropFileUpload = (props: Props) => {
  return (
    <DropTarget
      accepts={[NativeTypes.FILE]}
      classNameExt={props.classNameExt}
      onDrop={props.onDrop}
      dropItem={(isOverContainer: boolean, canDropContainer: boolean) => props.children}
    />
  );
};

export default DragDropFileUpload;
