import { getClass } from 'century-core/core-apis/accountsV2/classes/classes';
import { getStudyGroupsForClass } from 'century-core/core-apis/accountsV2/studyGroups/studyGroups';
import { getStudentsByClassId } from 'century-core/core-apis/accountsV2/users/users';

export interface TeacherDashboardBaseData {
  classInfo: Ctek.Class;
  studyGroups: Ctek.StudyGroup[];
  users: Ctek.User[];
}

export async function initialiseData(accessToken: string, classId: string): Promise<TeacherDashboardBaseData> {
  const classInfo: Ctek.Class = await getClass(classId, accessToken);

  const [users, studyGroups] = await Promise.all([
    getStudentsByClassId(classId, classInfo.organisation as string, accessToken),
    getStudyGroupsForClass(classId, accessToken),
  ]);

  return { classInfo, studyGroups, users };
}
