import { PureComponent, Fragment } from 'react';
import layout from 'layout';

type SpinnerType = 'fullpage' | 'independent' | 'simple' | 'widget';

export interface Props {
  type: SpinnerType;
  showContent?: boolean;
}

export class Spinner extends PureComponent<Props, {}> {

  public render() {
    const { showContent } = this.props;

    return showContent ? (
      <Fragment>
        {this.props.children}
      </Fragment>
    ) : (
        <layout.LoadingSpinner type={this.props.type} />
      );
  }
}
export default Spinner;
