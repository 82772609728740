import { createContext, useState, useEffect } from 'react';
import * as React from 'react';
import { CurrentCourse } from 'century-core/core-utils/utils/qla/studentCourseDashboard';

interface CourseProps {
  course: Partial<Ctek.Course>;
  currentCourseImage?: string
  children: React.ReactNode;
}

export const CurrentCourseContext = createContext<{ course: CurrentCourse | null } | null>(null);
export default CurrentCourseContext;

export const CurrentCourseProvider = (props: CourseProps) => {
  const [course, setCourse] = useState(null as null | CurrentCourse)

  useEffect(() => {
    if (props.course) {
      setCourse({
        name: props.course?.name || '',
        icon: props.course?.icon,
        iconCode: props.course?.iconCode || '',
        subject: props.course?.subject as Ctek.Label,
        iconUrl: props.currentCourseImage,
      })
    }
  }, [props.currentCourseImage, props.course]);

  return <CurrentCourseContext.Provider value={{ course }}>
    {props.children}
  </CurrentCourseContext.Provider>;
};
