export default function sanitizeTransactionName() {
  if (window.location.pathname.includes('study-groups')) {
    return 'study-session';
  }
  if (window.location.pathname.includes('leadership')) {
    return 'leadership';
  }
  if (window.location.pathname.includes('learn/my-courses')) {
    return 'learn/my-courses';
  }
  if (window.location.pathname.includes('learn/my-path')) {
    return 'learn/my-path';
  }
  if (window.location.pathname.includes('cms/courses')) {
    return 'CMS/courses';
  }
  if (window.location.pathname.includes('cms/schemes-of-work')) {
    return 'CMS/schemes-of-work';
  }
  if (window.location.pathname.includes('teach')) {
    return 'teach';
  }
  if (window.location.pathname.includes('admin')) {
    return 'admin';
  }
  if (window.location.pathname.includes('assignments')) {
    return 'assignments';
  }
  if (window.location.pathname.includes('user-profile')) {
    return 'user-profile';
  }
  return window.location.pathname;
}
