import { TrackerNugget } from 'century-core/core-utils/selectors/nuggetActivity';
import { QLANavigationProperties, QLALensProperties } from 'century-core/core-utils/utils/qla/types';

export interface CurrentCourse {
  name: string;
  icon: any;
  iconUrl?: string;
  iconCode: string;
  subject?: Ctek.Label;
}

export function shouldDisplayNugget(nugget: TrackerNugget) {
  return !!nugget.bestScore || !!nugget.lastScore;
}

export function getNuggetProp(nugget: TrackerNugget, prop: QLANavigationProperties) {
  if (prop === 'name') {
    return nugget.name;
  }
  if (prop === 'id') {
    return nugget._id;
  }
  return '';
}

export function getStudentProp(student: { _id: string }, prop: QLANavigationProperties) {
  if (prop === 'id') {
    return student._id;
  }
  return '';
}

export function getCourseProp(nugget: TrackerNugget, prop: QLALensProperties, course?: CurrentCourse | null) {
  if (prop === 'id') {
    return nugget._id;
  }
  if (!course) {
    return '';
  }
  if (prop === 'courseName') {
    return course.name;
  }
  if (prop === 'icon') {
    return course.icon;
  }
  if (prop === 'iconUrl') {
    return course.iconUrl;
  }
  if (prop === 'iconCode') {
    return course.iconCode;
  }
  if (prop === 'subjectName') {
    return course.subject?.name;
  }
}
