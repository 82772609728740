import { getAppEnv } from 'century-core/core-utils/utils/config/config';
import { uniqBy } from 'lodash';

const languageLabels = [
  { code: 'en', label: 'English' },
  { code: 'es', label: 'Spanish' },
  { code: 'fr', label: 'French' },
  { code: 'ar', label: 'Arabic' },
];

export const getSubtitleTracks = (allSubtitles: Ctek.Media.Subtitle[], subtitleModePreference: boolean) => {
  const uniqueSubtitles = uniqBy(
    allSubtitles.map(subtitle => {
      const languageCode = subtitle.filename.match(/_([a-z]{2})\.vtt$/)?.[1] ?? 'en';
      return { ...subtitle, languageCode };
    }),
    'filename'
  );

  return uniqueSubtitles.map(sub => ({
    kind: 'subtitles',
    label: languageLabels.find(l => l.code === sub.languageCode)?.label || 'English',
    srcLang: sub.languageCode || 'en',
    src: `https://cdn.${getAppEnv()}.century.tech/media/${sub.filename}`,
    default: subtitleModePreference,
  }));
};

export const getSubtitleConfig = (media: Ctek.EnrichedMedia | undefined, subtitleMode: boolean, hideSubtitles?: boolean) => {
  if (!media) return {};

  const { subtitles, subtitleStatus } = media;
  const hasSubtitles = (subtitles?.length ?? 0) > 0 && subtitleStatus === 'complete' && !hideSubtitles;
  if (!hasSubtitles) return {};

  return {
    file: {
      tracks: getSubtitleTracks(subtitles, subtitleMode),
      attributes: { crossOrigin: 'true' },
    },
  };
};
