import ObjectMapper from 'object-mapper';
import { getTokenData } from 'century-core/core-auth/utils';
import Auth from 'century-core/entities/Auth/Auth';

const mapForRedux = {
  accessToken: [
    {
      key: 'accessToken',
    },
    {
      key: 'accessTokenData',
      transform: (accessToken: string) => getTokenData<Ctek.JWTData>(accessToken),
    },
  ],
  refreshToken: [
    {
      key: 'refreshToken',
    },
    {
      key: 'refreshTokenData',
      transform: (refreshToken: string) => getTokenData(refreshToken),
    },
  ],
};

const mapForLocalStorage = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
};

export const mapAuthForRedux = (source: Auth): Auth => ObjectMapper(source, mapForRedux);
export const mapAuthForLocalStorage = (source: Auth): Auth => ObjectMapper(source, mapForLocalStorage);
