import * as SentryLib from 'century-core/core-utils/lib/sentry';

export default async function initialiseAuth0(orgSettings: Ctek.Accounts.DomainResponse | void) {
  if (orgSettings) {
    try {
      if (
        !window.auth0 &&
        orgSettings.settings.global?.authSettings?.sso &&
        orgSettings.settings.global.authSettings.sso.auth0 &&
        orgSettings.settings.global.authSettings.sso.auth0.isEnabled
      ) {
        // this prevents auth0 from delaying the client initialisation
        // we dont care if about this flag anyway since Auth0 is only
        // used to login a user through Sentinel
        document.cookie = 'auth0.is.authenticated=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

        const { default: createAuth0Client } = await import('@auth0/auth0-spa-js');
        window.auth0 = await createAuth0Client({
          redirect_uri: `${window.location.origin}/login/`,
          client_id: orgSettings.settings.global.authSettings.sso.auth0.clientId,
          domain: orgSettings.settings.global.authSettings.sso.auth0.domain,
          cacheLocation: 'localstorage',
        });
        window.dispatchEvent(new CustomEvent('auth0-client-loaded'));
      }
    } catch (error) {
      SentryLib.captureException(error);
    }
  }
}
