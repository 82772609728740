import { useQuery } from '@apollo/client';
import { FOCUS_AND_STRETCH } from '../queries/focusAndStretch';

export const useFocusAndStretch = (userId: string, skip?: boolean, courseId?: string) => {
  return useQuery(FOCUS_AND_STRETCH, {
    variables: {
      input: {
        userId,
        courseId,
        courseTypes: ['standard'],
        limitPerCategory: 5,
        studyGroupStatus: 'current',
      },
    },
    skip,
    fetchPolicy: 'network-only',
  });
};
