import { useState, useEffect, useContext } from 'react';
import {
  FloatingContextNav,
  Button,
  FloatingStepperNav,
  nextIcon,
  previousIcon,
  Tooltipped,
  TooltippedPositions,
} from '@ctek/design-system';
import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { Spinner } from 'century-core/core-components/Spinner/Spinner';
import { useMixpanel } from 'century-core/core-components/MixpanelUtils';
import { withErrorBoundaryPartial } from 'century-core/core-components/ErrorBoundary/ErrorBoundary';
import QLANavigationInformationContext from './QLANavigationInformationProvider';
import { QLANuggetInformationProvider } from './QLANuggetInformationProvider';
import { QLAStudentInformationProvider } from './QLAStudentInformationProvider';
import { QLANuggetAttemptHistoryInformationProvider } from './QLANuggetAttemptHistoryInformationProvider';
import { QuestionGroupProvider } from './QuestionGroupProvider';
import { FragmentProvider } from './FragmentProvider';
import { QLANavigation } from './QLANavigationInformationProvider';

import QLA from './QLA';

interface Props {
  userId: string;
  nuggetId: string;
  navigation?: QLANavigation | null;
  expandFirstAttempt?: boolean;
}

const QLAWrapper = (props: Props) => {
  const mixpanel = useMixpanel();
  const nav = useContext(QLANavigationInformationContext);
  const [userId, setUserId] = useState(props.userId);
  const [nuggetId, setNuggetId] = useState(props.nuggetId);
  const [previousNugget, setPreviousNugget] = useState({ id: '', name: '' });
  const [nextNugget, setNextNugget] = useState({ id: '', name: '' });
  const [previousUser, setPreviousUser] = useState({ id: '', name: '' });
  const [nextUser, setNextUser] = useState({ id: '', name: '' });

  useEffect(() => {
    if (nav?.QLANavigation && nav.QLANavigation.users?.[userId]) {
      const nuggets = Object.keys(nav.QLANavigation.users?.[userId]);
      const prevNuggetId = nuggets[nuggets.indexOf(nuggetId) - 1];
      const nextNuggetId = nuggets[nuggets.indexOf(nuggetId) + 1];

      setPreviousNugget({ id: prevNuggetId, name: nav.QLANavigation.users[userId][prevNuggetId] });
      setNextNugget({ id: nextNuggetId, name: nav.QLANavigation.users[userId][nextNuggetId] });
    }

    if (nav?.QLANavigation && nav.QLANavigation.nuggets?.[nuggetId]) {
      const users = Object.keys(nav.QLANavigation.nuggets?.[nuggetId]);
      const prevUserId = users[users.indexOf(userId) - 1];
      const nextUserId = users[users.indexOf(userId) + 1];

      setPreviousUser({ id: prevUserId, name: nav.QLANavigation.nuggets[nuggetId][prevUserId] });
      setNextUser({ id: nextUserId, name: nav.QLANavigation.nuggets[nuggetId][nextUserId] });
    }
  }, [props, nav, setPreviousNugget, nuggetId, userId]);

  useEffect(() => {
    const now = Date.now();
    mixpanel.track(MixpanelEventTypes.QLAOpened, {
      [MixpanelKeys.Path]: window.location.pathname,
      [MixpanelKeys.StudentId]: userId,
    });
    return () => {
      mixpanel.track(MixpanelEventTypes.QLAClosed, {
        [MixpanelKeys.Duration]: Math.round((Date.now() - now) / 1000),
        [MixpanelKeys.Path]: window.location.pathname,
        [MixpanelKeys.StudentId]: userId,
      });
    };
  }, [mixpanel, userId]);

  const navigate = (itemId: string, navigationType: 'nugget' | 'student') => {
    mixpanel.track(MixpanelEventTypes.QLANavigated, {
      [MixpanelKeys.Path]: window.location.pathname,
      [MixpanelKeys.StudentId]: userId,
      [MixpanelKeys.NavigationType]: navigationType,
    });
    if (navigationType === 'nugget') {
      setNuggetId(itemId);
    }
    if (navigationType === 'student') {
      setUserId(itemId);
    }
  };

  const previousNuggetBtn = (
    <Button
      variant={!previousNugget.id ? 'default' : 'secondary'}
      size="large"
      icon={previousIcon}
      type="button"
      disabled={!previousNugget.id}
      onClick={() => navigate(previousNugget.id, 'nugget')}
      qa="qla-nav-previous-nugget"
    />
  );

  const nextNuggetBtn = (
    <Button
      variant={!nextNugget.id ? 'default' : 'secondary'}
      size="large"
      icon={nextIcon}
      type="button"
      disabled={!nextNugget.id}
      onClick={() => navigate(nextNugget.id, 'nugget')}
      qa="qla-nav-next-nugget"
    />
  );

  if (!userId || !nuggetId) {
    return <Spinner type={'widget'} showContent={false} />;
  }
  return (
    <>
      <QLANuggetInformationProvider nuggetId={nuggetId}>
        <QLAStudentInformationProvider studentId={userId}>
          <QLANuggetAttemptHistoryInformationProvider studentId={userId} nuggetId={nuggetId}>
            <QuestionGroupProvider>
              <FragmentProvider>
                <QLA nuggetId={nuggetId} expandFirstAttempt={props.expandFirstAttempt} />
              </FragmentProvider>
            </QuestionGroupProvider>
          </QLANuggetAttemptHistoryInformationProvider>
        </QLAStudentInformationProvider>
      </QLANuggetInformationProvider>
      <>
        <FloatingStepperNav>
          <FloatingStepperNav.Control direction="previous">
            {!previousNugget.id ? (
              previousNuggetBtn
            ) : (
              <Tooltipped position={TooltippedPositions.E} label={previousNugget.name}>
                {previousNuggetBtn}
              </Tooltipped>
            )}
          </FloatingStepperNav.Control>
          <FloatingStepperNav.Control direction="next">
            {!nextNugget.id ? (
              nextNuggetBtn
            ) : (
              <Tooltipped position={TooltippedPositions.W} label={nextNugget.name}>
                {nextNuggetBtn}
              </Tooltipped>
            )}
          </FloatingStepperNav.Control>
        </FloatingStepperNav>
        {(previousUser?.id || nextUser?.id) && (
          <FloatingContextNav>
            {previousUser?.id && (
              <FloatingContextNav.Control direction="up">
                <Button onClick={() => navigate(previousUser.id, 'student')} type="button" size="tiny" variant="default">
                  {previousUser.name}
                </Button>
              </FloatingContextNav.Control>
            )}
            {nextUser?.id && (
              <FloatingContextNav.Control direction="down">
                <Button type="button" size="tiny" variant="default" onClick={() => navigate(nextUser.id, 'student')}>
                  {nextUser.name}
                </Button>
              </FloatingContextNav.Control>
            )}
          </FloatingContextNav>
        )}
      </>
    </>
  );
};

export default withErrorBoundaryPartial(QLAWrapper);
