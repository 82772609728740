import { getAppEnv } from 'century-core/core-utils/utils/config/config';
import * as React from 'react';
const ApiContext = React.createContext({
  getApi: (pubApi: PublicApiUrls, version?: number, path?: string) => Promise.resolve(new URL('')),
});

export const useApiContext = () => React.useContext(ApiContext);

export enum PublicApiUrls {
  Accounts = 'accounts',
  AiService = 'aiservice',
  Alexandria = 'alexandria',
  Assessment = 'assessment',
  Assignment = 'assignment',
  Athena = 'athena',
  C3po = 'c3po',
  Cdn = 'cdn',
  Consumer = 'consumer',
  Content = 'content',
  Errands = 'errands',
  Dashboard = 'dashboard',
  Insights = 'insights',
  Learn = 'learn',
  Palpatine = 'palpatine',
  Pong = 'pong',
  Ratchet = 'ratchet',
  Roentgen = 'roentgen',
  Scout = 'scout',
  Sentinel = 'sentinel',
  Shockwave = 'shockwave',
  User = 'user',
  Zeus = 'zeus',
}

export type EndpointPath = `/${string}`;

/*
 * This is a legacy method - use the context below where possible.
 */
export const legacyGetUrl = (pubApi: PublicApiUrls, version?: number, pathName?: EndpointPath): URL => {
  const apiUrls = window?.Century?.apiUrls;
  if (apiUrls) {
    return buildUrl(apiUrls, pubApi, version, pathName);
  }

  return new URL('');
};

export const getElephantUrl = (): string => {
  const env = getAppEnv();
  return `https://elephant${env !== 'prod' ? `.${env}` : ''}.century.tech/elephant`;
};

export const ApiProvider = (props: { children: React.ReactElement }) => {
  const [apis, setApis] = React.useState(null as null | { [key: string]: string });

  const getApi = async (pubApi: PublicApiUrls, version?: number, pathName?: EndpointPath): Promise<URL> => {
    let apiUrlsData = null as null | { [key: string]: string };
    if (!apis) {
      apiUrlsData = await fetch(`/assets/api-urls.${window.ENV || 'int'}.json?v=${Date.now()}`).then((res: Response) => res.json());
      setApis(apiUrlsData);
    }

    const url = buildUrl(apiUrlsData || (apis as { [key: string]: string }), pubApi, version, pathName);

    return url;
  };

  return <ApiContext.Provider value={{ getApi }}>{props.children}</ApiContext.Provider>;
};

const buildUrl = (apiUrls: { [key: string]: string }, pubApi: PublicApiUrls, version?: number, pathName?: EndpointPath) => {
  const url = new URL(apiUrls[pubApi] || '');
  if (version) {
    url.pathname += `/v${version}`;
  }

  if (pathName) {
    url.pathname += pathName;
  }

  return url;
};
