import { EndpointPath, legacyGetUrl, PublicApiUrls } from 'century-core/core-apis/ApiProvider';
import { createFetchRequestWithLocalisedError, GET, POST } from '../utils';

export interface MediaLibraryFile {
  lastModified: number;
  lastModifiedDate?: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath?: string;
}

interface PostMediaToMediaLibraryParams {
  accessToken: string;
  orgId: string;
  quillMediaFile: MediaLibraryFile;
  mediaType?: 'image' | 'video';
}

export async function postMediaToMediaLibrary(params: PostMediaToMediaLibraryParams) {
  if (!params.quillMediaFile.name || !params.accessToken) {
    return Promise.resolve({});
  } else {
    const { accessToken, orgId, quillMediaFile, mediaType = 'image' } = params;
    const imageBody: PostImageMeta = {
      files: [
        {
          filename: quillMediaFile.name,
          mimetype: quillMediaFile.type,
          type: 'original',
        },
      ],
      name: quillMediaFile.name,
      organisation: orgId,
      type: mediaType,
    };

    return createFetchRequestWithLocalisedError(() => postItem(imageBody, accessToken, true), 'localised-errors-media-file-error');
  }
}

export async function getMediaPublicUrl(imageId: string, accessToken: string) {
  if (!imageId || !accessToken) {
    return Promise.resolve({});
  } else {
    return createFetchRequestWithLocalisedError(
      () =>
        getItem(imageId, accessToken).then(mediaItem => {
          const { files } = mediaItem;
          const { url } = files[0];
          return url;
        }),
      'localised-errors-media-file-error'
    );
  }
}

export async function getMediaItem(imageId: string, accessToken: string) {
  if (!imageId || !accessToken) {
    return Promise.resolve({});
  } else {
    return createFetchRequestWithLocalisedError(() => getItem(imageId, accessToken), 'localised-errors-media-file-error');
  }
}

export interface PostImageMeta {
  name: string;
  files: [
    {
      filename: string;
      mimetype: string;
      type: string;
    }
  ];
  type: string;
  organisation: string;
}

export interface PostImageResponse {
  id: string;
  files: [
    {
      filename: string;
      uploadname: string;
      signedUrl: string;
      publicUrl: string;
    }
  ];
  thumbnails: [];
}

export function getContentURL(endpoint?: EndpointPath) {
  return legacyGetUrl(PublicApiUrls.Content, 3, endpoint);
}

export function getFile(itemId: string, fileId: string, acccessToken: string) {
  const url = getContentURL(`/media-library/${itemId}/files/${fileId}`);

  return GET<Ctek.EnrichedFile>({ url, token: acccessToken }).catch((err: Error) => {
    throw Error(`Unable to get file from media library for item id ${itemId} and file id ${fileId}, ${err}`);
  });
}

export function getItem(itemId: string, accessToken: string) {
  const url = getContentURL(`/media-library/${itemId}`);

  return GET<Ctek.Media>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get item from media library for item id ${itemId}, ${err}`);
  });
}

export function postItem(imageBody: PostImageMeta, accessToken: string, getUploadLink = false) {
  const url = getContentURL('/media-library');
  url.search += `&getUploadLink=${getUploadLink}`;

  return POST<boolean>({ url, token: accessToken, body: imageBody }).catch((err: Error) => {
    throw Error(`Unable to post image ${imageBody.name}, ${err}`);
  });
}

export function getSignedURL(itemId: string, fileId: string, accessToken: string) {
  const url = getContentURL(`/media-library/${itemId}/files/${fileId}/download`);

  return GET<{ url: string }>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get signed url from media library for item id ${itemId}, ${err}`);
  });
}
