import { POST } from 'century-core/core-apis/utils';
import { getLearnURL } from '../learn';

export interface DateRange {
  startDate?: string;
  endDate?: string;
}

export interface ScoreRepresentation {
  max: number;
  average: number;
  latest: number;
  sum: number;
}
export interface ScoreCollection {
  all: ScoreRepresentation;
  closed: ScoreRepresentation;
}
export interface TimeBasedNuggetActivity {
  latestActivityTimeAll: string;
  attemptsCompleted: number;
  percentScore: ScoreCollection;
  latestActivityTimeClosed: string;
  percentComplete: ScoreCollection;
  durationTotal: ScoreCollection;
  firstActivityTimeAll: string;
  attemptsClosed: number;
  attemptsAll: number;
  durationActual: ScoreCollection;
  userId: string;
  nuggetId: string;
  name: string;
}

export interface CompletionNuggetStudentItem {
  [key: string]: CompletionNuggetDetails | string;
}
export interface CompletionNuggetDetails {
  id: string;
  bestResult: number;
  lastResult: CompletionNuggetDetailsResult;
  averageResult: number;
}
export interface CompletionNuggetDetailsResult {
  score: number;
  date: string;
}
export interface Student {
  _id: string;
  name: string;
}
export interface NuggetHash {
  [key: string]: NuggetHashItem;
}

export interface NuggetHashItem {
  [key: string]: CompletionNuggetDetails;
}

export interface CompletionStudent {
  _id: string;
  name: string;
  avatar?: string;
  data: CompletionSummary;
}
export interface CompletionSummary {
  percentProgress: number;
  completedNuggets: number;
  averageScore: number;
  studyTime: number;
}
export interface CompletionNugget {
  _id: string;
  name: string;
  students: CompletionNuggetStudentItem[];
  isActive: boolean;
  studentId: string;
}

export function getStudentsNuggetData(accessToken: string, nuggets: CompletionNugget[], students: Student[], dateRange?: DateRange) {
  if (students.length === 0 || nuggets.length === 0) {
    return Promise.resolve([]);
  }

  const body = {
    nuggetIds: nuggets.map((nugget: CompletionNugget) => nugget._id),
    studentIds: students.map(student => student._id),
  } as any;

  if (dateRange) {
    body.startDate = dateRange.startDate;
    body.endDate = dateRange.endDate;
  }
  const url = getLearnURL('/analytics/class-nugget-activities');

  return POST<TimeBasedNuggetActivity[]>({ url, token: accessToken, body }).catch((err: Error) => {
    throw Error(`${err}`);
  });
}
