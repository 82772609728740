export function getBatchedRequest<P = unknown, R = unknown>(
  request: (params: { entries: string[] } & P) => Promise<R[]>,
  entries: string[],
  batchSize = 20,
  otherParams: P
): Promise<R[]> {
  const dividedBatches = [];
  for (let i = 0; i < entries.length; i += batchSize) {
    dividedBatches.push(entries.slice(i, i + batchSize));
  }
  return Promise.all(
    dividedBatches.map((v: string[]) => {
      return request({ entries: v, ...otherParams });
    })
  ).then((resp: R[][]) => {
    return resp.flat(1);
  });
}
