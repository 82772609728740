import { Button, downloadIcon } from '@ctek/design-system';
import { useAccessTokenRef } from 'century-core/core-auth/hooks/useAuth';
import { fetchDocuments } from 'century-core/media/MediaWrapper/fetchDocument';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { downloadCenturyDocument } from './docx.utils';

import { useMixpanel } from 'century-core/core-components/MixpanelUtils';
import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';

export const supportedDownloadableDocumentTypes = ['Accessible'];

interface DocumentDownloadButtonProps {
  documentId: string;
  documentType: string;
  nuggetId: string;
  nuggetName: string;
  fragmentId: string;
  fragmentType: string;
  stopPropagation?: boolean;
}

const downloadDocFromId = (documentId: string, accessToken: string, options?: { namePrefix?: string }) =>
  fetchDocuments(documentId, accessToken).then(res =>
    downloadCenturyDocument(res, options ? { namePrefix: options?.namePrefix } : undefined)
  );

export const DocumentDownloadButton = (props: DocumentDownloadButtonProps) => {
  const { documentId, documentType, fragmentId, fragmentType, nuggetId, nuggetName, stopPropagation } = props;
  const accessTokenRef = useAccessTokenRef();
  const [isLoading, setLoading] = useState(false);
  const [hasError, setError] = useState(null);
  const { formatMessage } = useIntl();
  const mixpanel = useMixpanel();

  const downloadDoc = useCallback(
    (e: Event) => {
      if (stopPropagation) {
        e.stopPropagation();
      }
      setError(null);
      setLoading(true);
      try {
        downloadDocFromId(documentId!, accessTokenRef.current, { namePrefix: nuggetName })
          .finally(() => {
            setLoading(false);

            mixpanel.track(MixpanelEventTypes.AccessibleContentDownloadedBPP, {
              [MixpanelKeys.NuggetId]: nuggetId,
              [MixpanelKeys.NuggetName]: nuggetName,
              [MixpanelKeys.DocumentId]: documentId,
              [MixpanelKeys.DocumentType]: documentType,
              [MixpanelKeys.FragmentId]: fragmentId,
              [MixpanelKeys.FragmentType]: fragmentType,
            });
          })
          .catch(() => setLoading(false));
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    },
    [stopPropagation, documentId, accessTokenRef, nuggetName, mixpanel, nuggetId, documentType, fragmentId, fragmentType]
  );

  return (
    <Button
      onClick={downloadDoc}
      type="button"
      disabled={isLoading}
      loading={isLoading}
      size="tiny"
      aria={formatMessage({ id: 'btn--download-docx', defaultMessage: 'Document Download' })}
      icon={downloadIcon}
      variant={hasError ? 'danger' : undefined}
      qa="button-download-document"
    />
  );
};

export default DocumentDownloadButton;
