import { Dispatch } from 'redux';
import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { Errors } from 'century-core/entities/ErrorMessage/Errors';

const defaultModalSettings = {
  data: {
    assignmentId: '',
    level: '',
    nuggetId: '',
    subject: '',
  },
  error: null,
  loaded: false,
};

export enum ActionTypes {
  Error = 'Goals.Error',
  Loaded = 'Goals.Loaded',
  Loading = 'Goals.Loading',
}

export type Actions = ReturnType<typeof error> | ReturnType<typeof loading> | ReturnType<typeof loaded>;

export const loading = () => ({
  type: ActionTypes.Loading as typeof ActionTypes.Loading,
});

export const loaded = (data: any) => ({
  payload: {
    data,
  },
  type: ActionTypes.Loaded as typeof ActionTypes.Loaded,
});

export const error = (err: ErrorMessage<Errors>) => ({
  error: err,
  type: ActionTypes.Error as typeof ActionTypes.Error,
});

export function resetGoalsModalSettings() {
  return (dispatch: Dispatch<Actions>) => {
    return dispatch(loaded(defaultModalSettings));
  };
}

export function setGoalsModalSettings(modalSettings: any) {
  return (dispatch: Dispatch<Actions>) => {
    return modalSettings && dispatch(loaded(modalSettings));
  };
}
