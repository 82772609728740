import { UserCheck } from 'century-core/entities/User/User';
import { getLearnURL } from '../../../century-core/core-apis/learn/learn';
import { GET, POST } from 'century-core/core-apis/utils';

export interface PreAuthenticateUserPayload {
  classId: string;
  code: string;
  email: string;
  orgId: string;
  password: string;
}

export function preAuthenticateUserWithClassCode(payload: PreAuthenticateUserPayload) {
  const url = getLearnURL('/users/pre-authenticate');

  return POST<Ctek.User>({ url, body: payload }).catch((err: Error) => {
    throw Error(`Unable to pre authenticate user ${payload.email}, ${err}`);
  });
}

export function processNewUserFormWithClassCode(newUser: Partial<Ctek.User>, classCode: string) {
  const url = getLearnURL('/users');
  url.search += '?code=' + classCode;

  return POST<Ctek.User>({ url, body: newUser }).catch((err: Error) => {
    const email: string = newUser.contact ? newUser.contact.emails?.[0].address! : '';
    throw Error(`Unable to process new user ${email}, ${err}`);
  });
}

export function checkUserEmail(email: string) {
  const url = getLearnURL('/users');
  url.search += '?email=' + email;

  return GET<UserCheck>({ url }).catch((err: Error) => {
    throw Error(`Unable to check if ${email} already exists, ${err}`);
  });
}
