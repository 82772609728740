import { useMemo } from 'react';
import { getPercentValue } from 'century-core/core-utils/utils/helpers';
import CenturyExactTime from 'century-core/core-components/CenturyTime/CenturyExactTime';
import CenturyRelativeTime, {
  CenturyRelativeTimeDisplayMode,
  CenturyRelativeTimeFormat,
} from 'century-core/core-components/CenturyTime/CenturyRelativeTime';
import {
  WidgetList,
  NuggetInfo,
  Heading,
  Breakdown,
  StatIndicatorTypes,
  IconSizes,
  StatIndicatorSizes,
  ProgressThumbnail,
  Text,
  resultsDurationIcon,
  resultsDateIcon,
} from '@ctek/design-system';
import CourseImage from 'century-core/media/CourseImage';
import { SubjectColouredLabel } from 'century-core/core-components/Subject/SubjectLabel';

interface Props {
  nugget: Ctek.Roentgen.NuggetPathItem;
  handleNuggetClick: (id: string) => void;
}

const RecentlyAttemptedWidget = ({ nugget, handleNuggetClick }: Props) => {
  const score = useMemo(() => {
    return getPercentValue(nugget.activity?.lastAttempt!.percentScore);
  }, [nugget.activity]);

  const completion = useMemo(() => {
    return getPercentValue(nugget.activity?.lastAttempt!.percentComplete);
  }, [nugget.activity]);

  return (
    <WidgetList.Item>
      <button type="button" onClick={() => handleNuggetClick(nugget.nugget.nuggetId)}>
        <NuggetInfo qa="widget--recently-completed--nugget-info">
          <NuggetInfo.Thumbnail>
            <ProgressThumbnail size={60} completionPercentage={completion} scorePercentage={score}>
              <CourseImage
                item={{
                  iconCode: nugget.courses[0].icon.iconCode,
                  iconUrl: nugget.courses[0].icon.url,
                  subject: {
                    _id: nugget.courses[0].subject.labelId,
                    name: nugget.courses[0].subject.name,
                  },
                }}
              />
            </ProgressThumbnail>
          </NuggetInfo.Thumbnail>
          <NuggetInfo.Main>
            <SubjectColouredLabel subjectName={nugget.courses[0].subject.name} />
            <Heading.ItemTitle>{nugget.nugget.name}</Heading.ItemTitle>
            <Text.Label>
              {nugget.courses.map((course: Ctek.Roentgen.CourseInfo) => (
                <li key={course.courseId}>{course.name}</li>
              ))}
            </Text.Label>
            <NuggetInfo.Breakdown>
              <Breakdown>
                {score > 0 && (
                  <Breakdown.Item>
                    <Breakdown.Indicator ariaLabel="Score" type={StatIndicatorTypes.SCORE} score={score} size={StatIndicatorSizes.SMALL} />
                    <Breakdown.Description>{score}%</Breakdown.Description>
                  </Breakdown.Item>
                )}
                <Breakdown.Item>
                  <Breakdown.Icon label="Duration" size={IconSizes.SMALL} type={resultsDurationIcon} />
                  <Breakdown.Description>
                    <CenturyExactTime time={nugget.activity!.lastAttempt.durationActual!} shortenUnits={true} />
                  </Breakdown.Description>
                </Breakdown.Item>
                <Breakdown.Item>
                  <Breakdown.Icon label="Completed" size={IconSizes.SMALL} type={resultsDateIcon} />
                  <Breakdown.Description>
                    <CenturyRelativeTime
                      dateTime={nugget.activity?.lastAttempt!.endTime!}
                      displayMode={CenturyRelativeTimeDisplayMode.SHORT}
                      timeFormat={CenturyRelativeTimeFormat.RELATIVE_TODAY}
                      alwaysIncludeYear={true}
                      relativeSurroundingDays={true}
                    />
                  </Breakdown.Description>
                </Breakdown.Item>
              </Breakdown>
            </NuggetInfo.Breakdown>
          </NuggetInfo.Main>
        </NuggetInfo>
      </button>
    </WidgetList.Item>
  );
};

export default RecentlyAttemptedWidget;
