import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { Errors } from 'century-core/entities/ErrorMessage/Errors';

// payload is any bc of FSA standards
// https://github.com/redux-utilities/flux-standard-action
interface DefaultAction {
  error?: ErrorMessage<Errors> | null;
  payload?: any;
  type: string;
}

interface DefaultActionTypes {
  Error: string;
  Loading: string;
  Loaded: string;
}

export function createBaseLoadingReducer<T, A extends DefaultAction, AT extends DefaultActionTypes>(defaultData: T, ActionTypes: AT) {
  return (state: T = defaultData, action: A): T => {
    switch (action.type) {
      case ActionTypes.Loading:
        return { ...state, loaded: false };
      case ActionTypes.Loaded:
        return { ...state, ...action.payload, loaded: true };
      case ActionTypes.Error:
        return { ...state, error: action.error, loaded: true };
      default:
        return state;
    }
  };
}
