import queryString from 'query-string';
import { Auth } from 'century-core/entities/Auth/Auth';
import { getTokenMainOrg, getTokenOrgs } from './orgs';
import { FeatureOverrideDefaultValues, getFeatureFlagData, hasFeature, isFeatureOverrideEnabled } from './featureFlags';
import { getFeatureOverrideByName } from 'century-core/core-auth/utils';

/**
 * Returns the url to redirect based on the user roles.
 *
 * If the token is undefined, it returns ''.
 */
export const getRedirectRouteByRole = (auth: Auth, hasLXJourneyEnabled?: boolean, isB2Cloading?: boolean): string => {
  const userRoles: string[] = getAllUserRoles(auth);

  const { redirect } = queryString.parse(window.location.search);
  // If the redirect is to login, we do not want to go there as we are already logged in.
  if (redirect && !(redirect as string).endsWith('/login/')) {
    return redirect as string;
  }

  // LX PHASE 1 - we need to wait for product loading if isB2C to check if is BOP or BOPP.
  // Remove this when LX is rollout to every b2c user.
  if (isB2Cloading) {
    return '';
  }

  const assessmentPlayerFeatureFlagData = getFeatureFlagData<{ playerOnly: boolean }>(auth, 'assessments', 'assessment-player');
  if (assessmentPlayerFeatureFlagData?.playerOnly) {
    return '/my-assessments';
  }

  const overrideMyPath = getFeatureOverrideByName('my-path', auth);
  const hasB2CFeature = isFeatureOverrideEnabled(auth, 'b2c', FeatureOverrideDefaultValues.b2c);

  if (userRoles.length > 0) {
    switch (true) {
      case userRoles.includes('guardian'):
        return hasB2CFeature ? '/guardian/subscription/' : '/guardian/';
      case userRoles.includes('teacher'):
        return '/teach/';
      case userRoles.includes('leadership'):
        return '/leadership/';
      case userRoles.includes('admin'):
      case userRoles.includes('manager'):
        return '/admin/';
      case userRoles.includes('editor'):
        return '/cms/';
      case userRoles.includes('student') && hasLXJourneyEnabled:
        return '/learn/my-journey';
      case userRoles.includes('student'):
        return overrideMyPath ? '/learn/my-courses' : '/learn/my-path';
      default:
        throw Error('Invalid roles');
    }
  }

  if (document.location.hostname === 'courses.century.tech') {
    return '/registration';
  }

  return '/login/';
};

/**
 * Checks if user has global 'admin' role.
 *
 * If the token is undefined, it returns false.
 */
export const hasAdminRole = (auth: Auth): boolean => hasSpecificRole(auth, 'admin');

/**
 * Checks if user has global 'manager' role.
 *
 * If the token is undefined, it returns false.
 */
export const hasGlobalManagerRole = (auth: Auth): boolean => hasSpecificRole(auth, 'manager');

/**
 * Checks if user has global 'recoverPassword' role.
 *
 * If the token is undefined, it returns false.
 */
export const hasRecoverPasswordRole = (auth: Auth): boolean => hasSpecificRole(auth, 'recoverPassword');

/**
 * Checks if user has 'teacher' role in main organisation.
 *
 * If the token is undefined, it returns false.
 */
export const hasTeacherRole = (auth: Auth): boolean => hasSpecificRoleOnMainOrg(auth, 'teacher');

/**
 * Checks if user has 'student' role in main organisation.
 *
 * If the token is undefined, it returns false.
 */
export const hasStudentRole = (auth: Auth): boolean => hasSpecificRoleOnMainOrg(auth, 'student');

/**
 * Checks if user has 'learner' role in main organisation.
 *
 * If the token is undefined, it returns false.
 */
export const hasLearnerRole = (auth: Auth): boolean => hasSpecificRoleOnMainOrg(auth, 'learner');

/**
 * Checks if user has 'guardian' role in main organisation.
 *
 * If the token is undefined, it returns false.
 */
export const hasGuardianRole = (auth: Auth): boolean => hasSpecificRoleOnMainOrg(auth, 'guardian');

/**
 * Checks if user has 'leadership' role in main organisation.
 *
 * If the token is undefined, it returns false.
 */
export const hasLeadershipRole = (auth: Auth): boolean => hasSpecificRoleOnMainOrg(auth, 'leadership');

/**
 * Checks if user has 'editor' role in main organisation.
 *
 * If the token is undefined, it returns false.
 */
export const hasEditorRole = (auth: Auth): boolean => hasSpecificRoleOnMainOrg(auth, 'editor');

/**
 * Checks if user has 'manager' role in main organisation.
 *
 * If the token is undefined, it returns false.
 */
export const hasManagerRole = (auth: Auth): boolean => hasSpecificRoleOnMainOrg(auth, 'manager');

/**
 * Checks if user has a specific global role.
 *
 * If the token is undefined, it returns false.
 */
const hasSpecificRole = (auth: Auth, role: Ctek.Roles): boolean => {
  return auth.accessTokenData?.context.roles?.includes(role) || false;
};

/**
 * Checks if user has a specific role in a specific organisation.
 *
 * If the token is undefined, it returns false.
 */
export const hasSpecificRoleInGivenOrg = (auth: Auth, role: Ctek.Roles, orgId: string): boolean => {
  const org = auth.accessTokenData?.context.organisations.find((o: Ctek.JWTDataOrganisation) => o.organisationId === orgId);

  return org?.roles.includes(role) || false;
};

/**
 * Gets user roles in primary organisation.
 *
 * If the token is undefined or there's no org, returns empty list.
 */
export const getPrimaryOrgRoles = (auth: Auth): string[] => {
  const mainOrg = getTokenMainOrg(auth);
  return mainOrg?.roles || [];
};

/**
 * Checks if user has a specific role in main organisation.
 *
 * If the token is undefined, it returns false.
 */
export const hasSpecificRoleOnMainOrg = (auth: Auth, role: Ctek.Roles): boolean => {
  const mainOrgRoles = getPrimaryOrgRoles(auth);
  return mainOrgRoles.includes(role);
};

/**
 * Returns an array with all of the user roles (global ones and the ones listed in each org).
 *
 * If the token is null, it returns an empty array.
 */
const getAllUserRoles = (auth: Auth): string[] => {
  const orgs = getTokenOrgs(auth);
  const orgRoles: string[] = orgs.reduce((acc: string[], curr: Ctek.JWTDataOrganisation) => acc.concat(curr.roles), []);
  const globalRoles: string[] = getTokenGlobalRoles(auth);
  const uniqueRoles = new Set<string>([...globalRoles, ...orgRoles]);

  return [...uniqueRoles];
};

/**
 * Returns an array with all of the user GLOBAL roles.
 *
 * If the token is null, it returns an empty array.
 */
const getTokenGlobalRoles = (auth: Auth): string[] => auth.accessTokenData?.context.roles || [];

// Below are functions that first checks if the user has GLOBAL roles which contains eithr global admin or global manager.
// If not, it checks the user's role(s) for organisation
// Returns true or false

export const isGlobalAdminOrManager = (auth: Auth): boolean => {
  const globalRoles = (role: string) => role === 'admin' || role === 'manager';
  return auth.accessTokenData?.context.roles?.some(globalRoles) || false;
};

export const hasGlobalOrTeacherRole = (auth: Auth): boolean => {
  return isGlobalAdminOrManager(auth) || hasTeacherRole(auth);
};

export const hasGlobalOrStudentRole = (auth: Auth): boolean => {
  return isGlobalAdminOrManager(auth) || hasStudentRole(auth);
};

export const hasGlobalOrLeadershipRole = (auth: Auth): boolean => {
  return isGlobalAdminOrManager(auth) || hasLeadershipRole(auth);
};

export const hasGlobalOrGuardianRole = (auth: Auth): boolean => {
  return isGlobalAdminOrManager(auth) || hasGuardianRole(auth);
};

export const hasGlobalOrManagerRole = (auth: Auth): boolean => {
  return isGlobalAdminOrManager(auth) || hasManagerRole(auth);
};

export const hasGlobalOrEditorOrTeacherRole = (auth: Auth): boolean => {
  return isGlobalAdminOrManager(auth) || hasTeacherRole(auth) || hasEditorRole(auth);
};

export const hasGlobalOrStudentOrLearnerRole = (auth: Auth): boolean => {
  return isGlobalAdminOrManager(auth) || hasStudentRole(auth) || hasLearnerRole(auth);
};

export const hasGlobalOrTeacherOrManagerRole = (auth: Auth): boolean => {
  return isGlobalAdminOrManager(auth) || hasTeacherRole(auth) || hasManagerRole(auth);
};

// Convenience methods to check for specific and widely used feature flags
export const hasAssessmentsFeatureStudentRole = (auth: Auth) => hasFeature(auth, 'assessments', 'assessments') && hasStudentRole(auth);

export const hasAssessmentsFeatureTeacherOrManagerRole = (auth: Auth) =>
  (hasFeature(auth, 'assessments', 'assessments') && (hasTeacherRole(auth) || hasManagerRole(auth))) || isGlobalAdminOrManager(auth);

export const hasAssessmentsFeatureManagerRole = (auth: Auth) =>
  (hasFeature(auth, 'assessments', 'assessments') && hasManagerRole(auth)) || isGlobalAdminOrManager(auth);

/**
 * Gets user's primary organisation and returns user role in that organisation.
 */
export const getRoleFromUser = (user: Ctek.User, orgId: string): string => {
  let userRole = '';
  user?.profile?.groups?.organisations?.some((org: Ctek.UserGroupOrg) => {
    if (org.organisation === orgId) {
      userRole = (org.roles as string[]).includes('teacher') ? 'teacher' : 'student';
      return true;
    }
    return false;
  });
  return userRole;
};
