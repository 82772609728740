import { QlaLevel2, ResultIcon } from '@ctek/design-system';
import CenturyExactTime from 'century-core/core-components/CenturyTime/CenturyExactTime';
import { FormattedMessage } from 'react-intl';
import { Collapse } from 'react-collapse';
import QLAQuestion from '../Question/QLAQuestion';
import { useState } from 'react';

interface Props {
  rowNum: number;
  questionAnalytics: Ctek.AbacusStudySessionQuestionResult;
  question: Ctek.EnrichedQuestion | undefined;
  questionGroup: Ctek.EnrichedQuestionGroup | undefined;
  wasQuestionSkipped: boolean;
  isInitiallyOpen: boolean;
}

interface ContentProps extends Props {
  isContentOpen: boolean;
}

export function QlaLv2Content(props: ContentProps) {
  return (
    <Collapse
      isOpened={props.isContentOpen}
      theme={{ collapse: 'cds-qla-level-2__collapse', content: 'cds-qla-level-2__collapse-content' }}
    >
      <QlaLevel2.Content>
        <QLAQuestion
          parameters={props.questionGroup?.parameters as Ctek.QuestionGroupParams}
          question={props.question as Ctek.EnrichedQuestion}
          analytics={props.questionAnalytics}
          questionGroup={props.questionGroup as Ctek.EnrichedQuestionGroup}
        />
      </QlaLevel2.Content>
    </Collapse>
  );
}

export default function QlaLv2Block(props: Props) {
  const [open, setOpen] = useState(props.isInitiallyOpen);

  return (
    <QlaLevel2.Row qa="qla-question" onClick={() => setOpen(open => !open)} isContentOpen={open}>
      <QlaLevel2.ColControl isContentOpen={open} qa="qla-level-2-button"/>
      <QlaLevel2.ColNumber>{props.rowNum}</QlaLevel2.ColNumber>
      <QlaLevel2.ColName>{props.questionGroup?.name}</QlaLevel2.ColName>
      <QlaLevel2.ColType>
        <FormattedMessage id={`question-type-${props.question?.answerType}`} defaultMessage={props.question?.answerType} />
      </QlaLevel2.ColType>
      <QlaLevel2.Col>
        <CenturyExactTime time={props.questionAnalytics.durationActual} shortenUnits={true} />
      </QlaLevel2.Col>
      <QlaLevel2.Col>
        {props.wasQuestionSkipped ? (
          <ResultIcon attemptResult="skipped">Skipped</ResultIcon>
        ) : props.questionAnalytics.correct ? (
          <ResultIcon attemptResult="correct">Correct</ResultIcon>
        ) : (
          <ResultIcon attemptResult="incorrect">Incorrect</ResultIcon>
        )}
      </QlaLevel2.Col>
      <QlaLevel2.Col>
        {props.wasQuestionSkipped ? null : (
          <FormattedMessage
            id="question-level-analysis-question-detail-marks-stat"
            defaultMessage="{correct}/{total}"
            values={{
              correct: props.questionAnalytics.pointsAchieved,
              total: props.questionAnalytics.pointsPossible,
            }}
          />
        )}
      </QlaLevel2.Col>
      <QlaLv2Content {...props} isContentOpen={open} />
    </QlaLevel2.Row>
  );
}
