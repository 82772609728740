import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import SmallRichTextEditor from '../../../../../layout/textEditors/SmallRichTextEditor/SmallRichTextEditor';
import LabelPairList from '../LabelPairList/LabelPairList';
import './AlternativeBoardSubmitted.scss';

export interface Props {
  children: AlternativeBoardSubmittedProps;
  qa?: string;
}

export interface AlternativeBoardSubmittedProps {
  additionals: React.ReactNode;
  controls: React.ReactNode | React.ReactNode[];
  feedback?: React.ReactNode;
  pairs: React.ReactNode[];
  questionContentValue: object;
  revealCorrect?: React.ReactNode[];
}

export class AlternativeBoardSubmitted extends React.PureComponent<Props, {}> {
  public render() {
    const { qa } = this.props;
    const { additionals, controls, feedback, pairs, questionContentValue, revealCorrect } = this.props.children;

    return (
      <>
        <div className="alternative-board-submitted" data-qa={qa} data-testid={qa}>
          <div className="alternative-board-submitted__question">
            <SmallRichTextEditor
              onChange={(newVal: any) => {
                return;
              }}
              readonly={true}
              value={questionContentValue}
            />
          </div>
          <div className="alternative-board-submitted__submitted-pairs" data-testid="labelling-question-board">
            <LabelPairList>
              {{
                pairs,
              }}
            </LabelPairList>
          </div>
          {additionals && <div className="alternative-board-submitted__options">{additionals}</div>}
          {feedback && (
            <div className="alternative-board-submitted__feedback" data-testid="alternative-board-feedback">
              {feedback}
            </div>
          )}
          {revealCorrect && (
            <div className="alternative-board-submitted__reveals">
              <div className="alternative-board-submitted__reveals-header">
                <FormattedMessage id="correct-answer-reveal" defaultMessage="Correct answer is:" />
              </div>
              <LabelPairList>
                {{
                  pairs: revealCorrect,
                }}
              </LabelPairList>
            </div>
          )}
          <div className="alternative-board-submitted__controls">{controls}</div>
        </div>
      </>
    );
  }
}

export default AlternativeBoardSubmitted;
