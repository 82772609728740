import { getFeatureFlagData, getTokenMainOrgId, hasFeature, hasStudentRole, hasTeacherRole } from 'century-core/core-auth/utils';
import Auth from 'century-core/entities/Auth/Auth';
import { appendScript } from 'century-core/core-utils/utils/dynamicImport/scriptImport';
import { addStyleSheet } from 'century-core/core-utils/utils/dynamicImport/styleImport';

export const addExternalCustomMenu = (auth: Auth) => {
  const hasExternalMenu = hasFeature(auth, 'external-menu', 'hasMenuBanner');
  if (!hasExternalMenu) {
    return;
  }

  // Gets an array of external orgs names
  const externalOrgs = getFeatureFlagData<string[]>(auth, 'external-menu', 'external') || [];
  switch (externalOrgs[0]) {
    case 'eTrackr':
      addEtrackMenu(auth);
      break;
    default:
      return;
  }
};

const addEtrackMenu = (auth: Auth) => {
  // User types for eTrackr
  // 0 = student, 1 = teacher or staff, 2 = parent
  const isStudent = hasStudentRole(auth);
  const isTeacher = hasTeacherRole(auth);
  const userType = isStudent ? 0 : isTeacher ? 1 : 2;

  const orgId = getTokenMainOrgId(auth);
  appendScript('https://static.vlesupport.co.uk/esuite-test/scripts/product-links.js', 'etrackrScript');
  addStyleSheet('https://static.vlesupport.co.uk/esuite-test/styles/product-links.css');
  document.getElementById('etrackrScript')?.addEventListener('load', () => {
    rendereSuiteLinksV2(orgId, 'eTrackr', userType);
    document.body.className = 'esuite-loaded';
  });
};
