import { getLearnURL } from 'century-core/core-apis/learn/learn';
import { POST } from 'century-core/core-apis/utils';
import { getStudyGroupCoursePlanByCourseId, DataPoints } from 'century-core/core-apis/utils/utils';

export interface CategoryCounts {
  needsStretch: number;
  needsPraise: number;
  needsEffort: number;
  needsSupport: number;
}
export interface CourseIntervetionData {
  categoryCounts: CategoryCounts;
  coursePlanId: string;
  averageDuration: number;
  dataPoints: DataPoints[];
}

export async function getCourseIntervetions(
  accessToken: string,
  courseId: string,
  userIds: string[],
  studyGroups: Ctek.StudyGroup[]
): Promise<CourseIntervetionData> {
  const coursePlanId: string = getStudyGroupCoursePlanByCourseId(studyGroups, courseId) || '';
  const url = getLearnURL(`/analytics/interventions/courseplans/${coursePlanId}`);

  return POST<CourseIntervetionData>({ url, token: accessToken, body: userIds }).catch((err: Error) => {
    throw Error(`, ${err}`);
  });
}
