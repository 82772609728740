import { getCourseIntervetions, CourseIntervetionData } from 'century-core/core-apis/learn/getCourseIntervetions/getCourseIntervetions';

// TODO This is not necessary, just remove this and call directly to `getCourseIntervetions` 
export async function getCourseIntervetionData(
  accessToken: string,
  courseId: string,
  users: Ctek.User[],
  studyGroups: Ctek.StudyGroup[]
): Promise<CourseIntervetionData> {
  const userIds: string[] = users.map(user => user._id || '');
  return getCourseIntervetions(accessToken, courseId, userIds, studyGroups)
}
