import { Widget } from '@ctek/design-system';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import RecommendedNuggetsBody from './RecommendedNuggetsBody';

export interface Props {
  subjectIds?: string[],
  subjectGroupIds?: string[],
  userId: string,
  widgetMode: DashboardUserMode
}

const RecommendedNuggetsWidget = (props: Props) => {
  return (
    <Widget qa="widget--recommended-nugget">
      <Widget.Body>
        <RecommendedNuggetsBody
          subjectIds={props.subjectIds}
          subjectGroupIds={props.subjectGroupIds}
          userId={props.userId}
          widgetMode={props.widgetMode}
        />
      </Widget.Body>
    </Widget>
  );
}

export default RecommendedNuggetsWidget;
