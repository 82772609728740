import { getTokenMainOrgId, getTokenLoginSessionId, getTokenMainOrgName, getTokenSub, getTokenData } from 'century-core/core-auth/utils';
import { useEffect, useRef } from 'react';
import { useAuthContext } from '../AuthContext';
import { isRTLLocaleFromTokenLocale } from 'century-core/core-utils/utils/intl/intl';

export const useAuth = () => {
  const { auth } = useAuthContext();

  return auth;
};

export function useStateRef<T>(value: T) {
  const stateRef = useRef(value);
  useEffect(() => {
    stateRef.current = value;
  }, [stateRef, value]);
  return stateRef;
}
export const useAuthRef = () => useStateRef(useAuth());

export const useAccessToken = () => {
  const { auth } = useAuthContext();

  return auth?.accessToken || '';
};

export const useAccessTokenRef = () => {
  const auth = useAuth();
  const accessTokenRef = useRef(auth?.accessToken || '');

  useEffect(() => {
    accessTokenRef.current = auth.accessToken || '';
  }, [auth]);
  return accessTokenRef;
};

export const useOrgId = () => {
  const { auth } = useAuthContext();

  if (!auth) return '';

  return getTokenMainOrgId(auth);
};

export const useLoginSessionId = () => {
  const { auth } = useAuthContext();

  if (!auth) return '';

  return getTokenLoginSessionId(auth);
};

export const useUserId = () => {
  const { auth } = useAuthContext();

  if (!auth || !auth.accessTokenData) return '';

  return getTokenSub(auth);
};

export const useOrgName = () => {
  const { auth } = useAuthContext();

  if (!auth) return '';

  return getTokenMainOrgName(auth) || '';
};

export const useAuthSettings = (item: string) => {
  const accessToken = useAccessTokenRef();
  const tokenData: Ctek.JWTData = getTokenData(accessToken.current);
  return tokenData?.context?.settings?.global?.authSettings?.[item] ?? false;
};
export const useIsRTLLocale = (): boolean => {
  const { auth } = useAuthContext();

  if (!auth) return false;

  return isRTLLocaleFromTokenLocale(auth) ?? false;
};
