import * as React from 'react';
import ClickSelectAndDrop from './ClickSelectAndDropContext';

interface Props {
  children: React.ReactNode;
  onDrop: (id: string) => void;
}

const ClickSelectDrop = (props: Props) => {

  const containerEl = React.useRef<HTMLInputElement>(null);
  const { selectedItemId } = React.useContext(ClickSelectAndDrop);
  const handleClick = React.useCallback((event: React.MouseEvent) => {
    if (containerEl && containerEl.current && containerEl.current.contains(event.target as any) && selectedItemId) {
      props.onDrop(selectedItemId as string);
    }
  }, [props, selectedItemId]);

  React.useEffect(() => {
    const ref: HTMLInputElement | null = containerEl?.current;
    if (ref) {
      // Bind the event listener
      ref.addEventListener('click', handleClick as unknown as EventListenerOrEventListenerObject);
    }

    return () => {
      if (ref) {
        // Unbind the event listener on clean up
        ref.removeEventListener('click', handleClick as unknown as EventListenerOrEventListenerObject);
      }
    };
  }, [selectedItemId, containerEl, handleClick]);

  return (
    <div ref={containerEl} className={`click-select-drop ${selectedItemId ? 'click-select-drop--item-selected' : ''}`}>
      {props.children}
    </div>
  );
};

export default ClickSelectDrop;
