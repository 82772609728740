import { Button, ButtonProps } from '@ctek/design-system';
import SmallRichTextEditor from 'layout/textEditors/SmallRichTextEditor/SmallRichTextEditor';
import { useRef, useEffect, useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import AlternativeBoardSubmitted from 'century-core/core-components/DraggableQuestionTypes/components/AlternativeBoardSubmitted/AlternativeBoardSubmitted';
import { ResultTypes } from 'century-core/core-components/DraggableQuestionTypes/components/LabelPair/LabelPair';
import LabelPairBase from 'century-core/core-components/DraggableQuestionTypes/components/LabelPair/LabelPairBase';
import UploadedImage from 'century-core/core-components/DraggableQuestionTypes/components/UploadedImage/UploadedImage';
import { emptyDelta } from 'century-core/core-utils/utils/quill/constants';
import Feedback from 'century-core/core-components/Feedback/Feedback';
import { AnsweredItem, DraggableQuestionTypeReadOnlyProps, PossibleAnswer } from '../../../typings/DraggableQuestionTypes';
import CachedImageUrlContext, { provideCachedGetImageUrlContext } from 'century-core/media/hooks/useGetImagePublicUrlCache';
import useQuestionTypesButtonHandling from '../useQuestionTypesButtonHandling';
import { getAnswerStatusForQuestion, getPolymerReactToken, getPossibleAnswerById } from '../utils';
import { NoImagePlaceholder } from './DroppableLabelPair';
import { LabelContainer, NoDragLabel } from './QuestionLabels/Label';

export const LabellingQuestionTypeReadonly = (props: DraggableQuestionTypeReadOnlyProps) => {
  const { dragComponent, data, question, result, revealData, withholdResults, hideFeedback = false } = props;
  const nextQuestionBtn = useRef();
  const viewResultsBtn = useRef();

  useEffect(() => {
    if (nextQuestionBtn.current) {
      (nextQuestionBtn.current! as any).focus();
    }

    if (viewResultsBtn.current) {
      (viewResultsBtn.current! as any).focus();
    }
  }, []);

  const { answerGroups, content, possibleAnswers } = question.data;
  const answeredItems = result?.details || [];
  const answerStatus: ResultTypes | null = getAnswerStatusForQuestion(answeredItems.length > 0, result, withholdResults)

  const quillDelta: object = content[0] ? content[0].data : emptyDelta;

  const answers = answerGroups[0].answers;

  const { handleClickNext, handleViewResults, buttonDisabled } = useQuestionTypesButtonHandling({
    handleClickNext: data && data.handleClickNext,
    handleViewResults: data && data.handleViewResults,
  });

  return (
    <AlternativeBoardSubmitted>
      {{
        additionals:
          dragComponent === 'text'
            ? renderNoDragLabels(possibleAnswers, answeredItems)
            : renderNoDragImages(possibleAnswers, answeredItems),
        controls: (
          <>
            {data && data.handleClickNext && (
              <Button
                innerRef={nextQuestionBtn}
                type="button"
                onClick={handleClickNext}
                loading={buttonDisabled}
                variant="secondary"
                qa="button-next-question"
              >
                <FormattedMessage id="question-labelling-next-button" defaultMessage="Next Question" />
              </Button>
            )}
            {data && data.handleViewResults && (
              <DisableOnClickButton
                innerRef={viewResultsBtn}
                type="button"
                onClick={handleViewResults}
                loading={buttonDisabled}
                variant="secondary"
                qa="button-view-results"
              >
                <FormattedMessage id="question-labelling-view-results" defaultMessage="View Results" />
              </DisableOnClickButton>
            )}
          </>
        ),
        feedback:
         !hideFeedback && !withholdResults && !!answerStatus && (answerStatus !== ResultTypes.SKIPPED) ? (
            <Feedback
              answerStatus={answerStatus}
              feedback={
                data && data.feedback ? (
                  <SmallRichTextEditor
                    onChange={(newVal: any) => {
                      return;
                    }}
                    readonly={true}
                    value={data.feedback}
                  />
                ) : undefined
              }
              buttonDisabled={buttonDisabled}
              reveal={!!revealData?.correctAnsweredItems}
              revealError={revealData?.revealError}
              handleRevealClicked={revealData?.handleQuestionRevealsClicked}
            />
          ) : undefined,
        pairs: renderLabelPairs(answers as Ctek.Answer[], answeredItems, possibleAnswers, dragComponent as Ctek.MatchType, !!withholdResults),
        questionContentValue: quillDelta,
        result: answerStatus,
        revealCorrect: !withholdResults && !!revealData?.correctAnsweredItems
          ? renderLabelPairs(
              answers as Ctek.Answer[],
              (revealData?.correctAnsweredItems as AnsweredItem[]) || [],
              possibleAnswers,
              dragComponent as Ctek.MatchType,
              !!withholdResults,
              true
            )
          : undefined,
      }}
    </AlternativeBoardSubmitted>
  );
};
export const DisableOnClickButton = (props: ButtonProps) => {
  const [disabled, setDisabled] = useState(false);

  const handleClick = (...args: any[]) => {
    if (props.onClick) {
      props.onClick(args);
      setDisabled(true);
    }
  };

  return <Button {...props} onClick={handleClick} disabled={props.disabled || disabled} variant="secondary" />;
};

const renderLabelPairs = (
  answers: Partial<Ctek.Answer>[],
  answeredItems: AnsweredItem[],
  possibleAnswers: PossibleAnswer[],
  dragComponent: Ctek.MatchType,
  withholdResults: boolean,
  reveal?: boolean
) => {
  return answers.map((answer, index) => {
    const relatedAnsweredItem = answeredItems.find(item => item.answerGroupAnswerId === answer.id);
    const resultType = getResultType(relatedAnsweredItem);

    const possibleAnswer = relatedAnsweredItem ? getPossibleAnswerById(relatedAnsweredItem.possibleAnswerId, possibleAnswers) : undefined;
    return (
      <LabelPairBase key={index}>
        {{
          image:
            dragComponent === ('text' as Ctek.MatchType) ? (
              <RenderImage
                id={answer.content?.[0].id as string}
                url={(answer.content?.[0].data as Ctek.EnrichedContentData).value?.files[0].url}
              />
            ) : possibleAnswer ? (
              <RenderImage
                id={possibleAnswer.content[0].id}
                url={(possibleAnswer.content?.[0].data as Ctek.EnrichedContentData).value?.files[0].url}
              />
            ) : (
              <NoImagePlaceholder />
            ),
          label: <NoDragLabel label={dragComponent === ('text' as Ctek.MatchType) ? possibleAnswer : (answer as Ctek.Answer)} />,
          result: !withholdResults
            ? reveal
              ? 'reveal'
              : resultType
            : undefined,
        }}
      </LabelPairBase>
    );
  });
};

const getResultType = (answeredItem: AnsweredItem | undefined): ResultTypes => {
  return answeredItem ? (answeredItem.isCorrect ? ResultTypes.CORRECT : ResultTypes.INCORRECT) : ResultTypes.SKIPPED;
};

const renderNoDragLabels = (labels: PossibleAnswer[], answeredItems: AnsweredItem[]) => {
  if (labels.length === answeredItems.length) {
    return null;
  }
  return (
    <LabelContainer>
      {labels.map((label, index) => {
        const inactive = answeredItems.some(item => item.possibleAnswerId === label.id);
        return inactive ? (
          <div className="draggable-label--used">
            <NoDragLabel key={index} label={label} inactive={inactive} noDragHandle={true} />
          </div>
        ) : (
          <NoDragLabel key={index} label={label} inactive={inactive} noDragHandle={true} />
        );
      })}
    </LabelContainer>
  );
};

const renderNoDragImages = (possibleAnswers: PossibleAnswer[], answeredItems: AnsweredItem[]) => {
  if (possibleAnswers.length === answeredItems.length) {
    return null;
  }
  return (
    <LabelContainer>
      {possibleAnswers.map((possibleAnswer: any, index) => {
        const inactive = answeredItems.some(item => item.possibleAnswerId === possibleAnswer.id);
        return (
          <div key={index} style={inactive ? { visibility: 'hidden', height: '0px' } : {}} className="draggable-label-item">
            <RenderImage id={possibleAnswer.content[0].id} />
          </div>
        );
      })}
    </LabelContainer>
  );
};

const RenderImage = ({ id, url }: { id: string; url?: string }) => {
  const [imageUrl, setImageUrl] = useState(null as string | null);
  const token = getPolymerReactToken();
  const { getMediaPublicUrl } = useContext(CachedImageUrlContext);

  useEffect(() => {
    async function getImageUrl() {
      if (url) {
        setImageUrl(url);
        return;
      }
      const imagePublicUrl = (await getMediaPublicUrl(id, token)) as string;
      setImageUrl(imagePublicUrl);
    }

    getImageUrl();
  }, [getMediaPublicUrl, id, token, url]);

  if (!imageUrl) {
    return null;
  }
  return <UploadedImage>{{ imgSrc: imageUrl }}</UploadedImage>;
};

// TODO - remove when Polymer QLA removed
const LabellingQuestionTypeReadonlyWrapper = (props: DraggableQuestionTypeReadOnlyProps) => {
  if (!props.question || !props.result || (props.question.data.answerType !== 'labelling' || props.question.parameters.answerType !== 'labelling')) {
    return null;
  }
  return <LabellingQuestionTypeReadonly {...props} />;
};
// TODO - remove when Polymer QLA removed
export const PolymerLabellingQuestionTypeReadonly = provideCachedGetImageUrlContext(LabellingQuestionTypeReadonlyWrapper);
