import { createContext, useContext } from 'react';

export const createContextAndHook = <TContextData,>(ContextName: string, initialData?: TContextData) => {
  const Context = createContext<TContextData>(initialData as unknown as TContextData);
  Context.displayName = ContextName;

  const useContextHook = () => {
    const context = useContext<TContextData>(Context);

    if (!context) {
      throw new Error(`use${ContextName} must be called inside a child component of ${ContextName}Provider`);
    }

    return context;
  };

  return [Context.Provider, useContextHook] as const;
};
