import { UserCheck } from 'century-core/entities/User/User';
import { validateClassCode } from '../../century-core/core-apis/learn/classes/classes';
import {
  checkUserEmail,
  PreAuthenticateUserPayload,
  preAuthenticateUserWithClassCode,
  processNewUserFormWithClassCode,
} from '../learn/users/users';
import { createFetchRequestWithLocalisedError } from 'century-core/core-apis/utils';

export interface ValidClassCode {
  isValid: boolean;
  classId: string;
  orgId: string;
}

export async function validateCode(classCode: string): Promise<ValidClassCode> {
  return createFetchRequestWithLocalisedError(() => validateClassCode(classCode), 'localised-errors-class-code-error');
}

export async function preAuthenticateUser(payload: PreAuthenticateUserPayload): Promise<Ctek.User> {
  return createFetchRequestWithLocalisedError(() => preAuthenticateUserWithClassCode(payload), 'localised-errors-class-code-error');
}

export async function processNewUserWithClassCode(user: Partial<Ctek.User>, onboardingCode: string): Promise<Ctek.User> {
  return createFetchRequestWithLocalisedError(
    () => processNewUserFormWithClassCode(user, onboardingCode),
    'localised-errors-class-code-error'
  );
}

export async function checkEmailAlreadyExists(email: string): Promise<UserCheck> {
  return createFetchRequestWithLocalisedError(() => checkUserEmail(email), 'localised-errors-class-code-error');
}
