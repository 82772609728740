import { getDependants } from 'century-core/core-utils/selectors/users';
import * as accountsV2Users from 'century-core/core-apis/accountsV2/users/users';
import { createFetchRequestWithLocalisedError } from 'century-core/core-apis/utils/create-fetch-request';

export async function fetchDependants(userId: string, accessToken: string, allow404: boolean = false) {
  return createFetchRequestWithLocalisedError<Ctek.User[]>(async () => {
    const currentUser = await accountsV2Users.getUser(userId, accessToken);
    const getUserById = async (dependantId: string) => {
      try {
        return await accountsV2Users.getUser(dependantId, accessToken);
      } catch (error) {
        if (allow404) {
          return null;
        }
        throw new Error(error);
      }
    };

    const dependants = await Promise.all(getDependants(currentUser).map(getUserById));
    return dependants.filter(dependant => dependant !== null) as Ctek.User[];
  }, 'localised-errors-guardian-dependants-error');
}
