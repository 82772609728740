/** Appends script tag within a React component */
export const appendScript = (scriptToAppend: string, scriptId?: string, attributes?: Record<string, string>) => {
  const script = document.createElement('script');
  script.src = scriptToAppend;
  script.id = scriptId || '';
  script.async = true;

  if (attributes) {
    Object.entries(attributes).forEach(([key, value]) => {
      script.setAttribute(key, value);
    });
  }

  document.head.appendChild(script);
};

/** Removes script from the DOM  */
export const removeScript = (scriptToRemove: string) => {
  const allScripts = document.getElementsByTagName('script');
  for (let i = allScripts.length - 1; i >= 0; i--) {
    if ((allScripts[i].getAttribute('src') || '').toLowerCase().includes(scriptToRemove.toLowerCase())) {
      allScripts[i].parentNode?.removeChild(allScripts[i]);
    }
  }
};
