import { useEffect, useState } from 'react';
import { getCognitiveMessages, MessageMode } from './utils'
import { useIntl } from 'react-intl'
import { Text } from '@ctek/design-system';

interface Props {
  // TODO: Take out hardcoded messages when backend can send them
  // cognitiveMessages: Ctek.Roentgen.CognitiveMessage[] | Ctek.Roentgen.UserProfileCognitiveMessage[];
  messageMode: MessageMode;
  usage?: Ctek.Roentgen.UserWelcomeAnalytics;
}

export default function WelcomeWidgetMessagesMode(props: Props) {
  const intl = useIntl();
  const [message, setMessage] = useState('');

  useEffect(() => {
    // TODO: change this to randomise for userUsage when it's ready
    const messages = getCognitiveMessages(intl);
    const rand = Math.floor(Math.random() * Math.floor(messages.length));
    setMessage(messages[rand]);
  }, [intl])

  return (
    <Text.Message>
      {message}
    </Text.Message>
  );
}
