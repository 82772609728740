import { useIntl } from 'react-intl';
import { Alert, AlertVariants } from '@ctek/design-system';

interface Props {
  todayCount: number
  tomorrowCount: number
}

export default function WelcomeWidgetUpcomingAssignments(props: Props) {
  const intl = useIntl();

  const todayPlaceholder = props.todayCount === 1
    ? intl.formatMessage({ id: 'student-dashboard-assignments-singular', defaultMessage: 'assignment' })
    : intl.formatMessage({ id: 'student-dashboard-assignments-plural', defaultMessage: 'assignments' });

  const tomorrowPlaceholder = props.tomorrowCount === 1
    ? intl.formatMessage({ id: 'student-dashboard-assignments-singular', defaultMessage: 'assignment' })
    : intl.formatMessage({ id: 'student-dashboard-assignments-plural', defaultMessage: 'assignments' });

  const messages = [];

  if (props.todayCount > 0) {
    messages.push(intl.formatMessage({ id: 'welcome-widget-today-assignments', defaultMessage: '{count} {todayPlaceholder} due today' }, { count: props.todayCount, todayPlaceholder }));
  }

  if (props.tomorrowCount > 0) {
    messages.push(intl.formatMessage({ id: 'welcome-widget-tomorrow-assignments', defaultMessage: '{count} {tomorrowPlaceholder} due tomorrow' }, { count: props.tomorrowCount, tomorrowPlaceholder }));
  }

  return messages.length > 0 ? <Alert variant={AlertVariants.INFO}>{messages.join(', ')}</Alert> : null;
}
