import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import LabelElement from 'layout/atoms/Label/Label';
import { emptyDelta } from 'century-core/core-utils/utils/quill/constants';
import ClickSelectDrop from '../../../DragDrop/ClickSelectAndDrop/ClickSelectDrop';
import ClickSelectItem from '../../../DragDrop/ClickSelectAndDrop/ClickSelectItem';
import DragSource from '../../../DragDrop/DragSource';
import { PossibleAnswer } from '../../../../typings/DraggableQuestionTypes';
import './label.scss';

export interface NoDragLabelProps {
  label?: PossibleAnswer | Ctek.Answer;
  inactive?: boolean;
  noDragHandle?: boolean;
}

export const NoDragLabel: React.SFC<NoDragLabelProps> = (props: NoDragLabelProps) => {
  const { label, inactive, noDragHandle } = props;

  return (
    <div className={`draggable-label-item ${inactive ? 'draggable-label-item__inactive' : ''}`}>
      <LabelElement>
        {{
          noDragHandle,
          onChange: (newVal: any) => {
            return;
          },
          readonly: true,
          value: label && label.content?.[0] ? label.content[0].data : undefined,
        }}
      </LabelElement>
    </div>
  );
};

export interface DraggableLabelProps {
  label: PossibleAnswer | Ctek.Answer;
  type: string;
}

export const DraggableLabel = ({ label, type }: DraggableLabelProps) => {
  return (
    <DragSource
      className="draggable-label-item draggable-label-item__active"
      dragItem={(isDragging: boolean) => (
        <ClickSelectItem id={label.id as string}>
          <LabelElement>
            {{
              onChange: (newVal: any) => {
                return;
              },
              readonly: true,
              value: label && label.content?.[0] ? label.content[0].data : emptyDelta,
            }}
          </LabelElement>
        </ClickSelectItem>
      )}
      type={type}
      id={label.id as string}
      item={label}
    />
  );
};

interface LabelContainerProps {
  children: React.ReactNode[];
  handleDrop?: (id: string) => void;
}

export const LabelContainer = (props: LabelContainerProps) => {
  const content = (
    <>
      <div className={'draggable-label-container__heading'}>
        <FormattedMessage id="cms-matching-additional-answers-heading" defaultMessage="Additional Answers" />
      </div>
      <div className="draggable-label-container__content">{props.children}</div>
    </>
  );

  const { handleDrop } = props;

  return (
    <div className="draggable-label-container" data-testid="draggable-label-container">
      {handleDrop ? <ClickSelectDrop onDrop={handleDrop}>{content}</ClickSelectDrop> : content}
    </div>
  );
};
