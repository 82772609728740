import { QLANavigationProperties, QLALensProperties } from 'century-core/core-utils/utils/qla/types';

export function shouldDisplayNugget(nugget: Ctek.Roentgen.NuggetPathItem) {
  return !!nugget.activity?.lastAttempt;
}

export function getNuggetProp(nugget: Ctek.Roentgen.NuggetPathItem, prop: QLANavigationProperties) {
  if (prop === 'name') {
    return nugget.nugget.name;
  }
  if (prop === 'id') {
    return nugget.nugget.nuggetId;
  }
  return '';
}

export function getStudentProp(student: { _id: string }, prop: QLANavigationProperties) {
  if (prop === 'id') {
    return student._id;
  }
  return '';
}

export function getCourseProp(nugget: Ctek.Roentgen.NuggetPathItem, prop: QLALensProperties) {
  switch (prop) {
    case 'id':
      return nugget.nugget.nuggetId;
    case 'courseName':
      return nugget.courses[0].name;
    case 'icon':
      return null;
    case 'iconUrl':
      return nugget.courses[0].icon.url;
    case 'iconCode':
      return nugget.courses[0].icon.iconCode;
    case 'subjectName':
      return nugget.courses[0].subject.name;
    default:
      return '';
  }
}
