import { createContext, ReactNode, useEffect, useRef } from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import TagManager from 'react-gtm-module';

import { getAppEnv } from './utils/config/config';
import * as dataLayer from 'century-core/core-utils/utils/dataLayer/dataLayer';
import { useUserId } from 'century-core/core-auth/components/UserProfile/UserProfileContext';

export const GtmContext = createContext({} as { setGtmActive: () => void });

export const useSetGtmActive = () => useContext(GtmContext).setGtmActive;

export const GtmProvider = (props: { children: ReactNode }) => {
  const gtmEnabledRef = useRef(false);
  const userId = useUserId();

  const setGtmActive = useCallback(() => {
    if (!gtmEnabledRef.current && !document.querySelector('script[src*="https://www.googletagmanager"]') && getAppEnv() === 'prod') {
      TagManager.initialize({
        gtmId: 'GTM-M23V3NK',
      });
      gtmEnabledRef.current = true;
    }
  }, []);

  // required for tracking User IDs on GA
  // see: https://developers.google.com/analytics/devguides/collection/ga4/user-id?client_type=gtm#step_1_update_your_data_layer
  const firstRunUserId = useRef(true);
  useEffect(() => {
    if (!!firstRunUserId.current && userId) {
      dataLayer.push({ user_id: userId });
      firstRunUserId.current = false;
    }
  }, [userId]);

  return <GtmContext.Provider value={{ setGtmActive }}>{props.children}</GtmContext.Provider>;
};
