// FIXME change location of this file
export type ActiveStateFilter = 'ALL' | 'active' | 'past';

export enum AssignmentStatus {
  ACTIVE = 'active',
  PAST = 'past',
  PLANNED = 'planned',
}
export interface FormattedDuration {
  key: string;
  value: number;
}

export enum FormattedDurationKeys {
  YEARS = 'years',
  MONTHS = 'months',
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
}
