import * as React from 'react';
import { ResultTypes } from './LabelPair';
import './LabelPair.scss';

interface LabelPairBaseChildProps {
  image: React.ReactNode;
  label: React.ReactNode;
  qa?: string | undefined;
  result?: ResultTypes | 'reveal';
}
interface LabelPairBaseProps {
  children: LabelPairBaseChildProps;
  classNameExt?: string;

}

export const LabelPairBase = (props: LabelPairBaseProps) => {
  const { image, label, qa, result } = props.children;
  const classNameExt = props.classNameExt ? props.classNameExt : '';

  return (
    <div className={`label-pair ${result && result !== ResultTypes.SKIPPED ? 'label-pair--submitted label-pair--' + result : 'label-pair--viewing'} ${classNameExt}`} data-qa={qa} data-testid={qa}>
      <div className="label-pair__image" data-testid="labelling-question-image">
        {image}
      </div>
      <div className="label-pair__label" data-testid="labelling-question-label">
        {label}
      </div>
    </div>
  );
};

export default LabelPairBase;
