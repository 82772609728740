/* eslint-disable import/no-anonymous-default-export */
import * as auth from '../century-core/core-apis/sentinel/auth/auth';
import * as b2c from './b2cDashboard';
import * as cdn from './cdn/cdn';
import * as classCode from './classCode/classCode';
import * as feedback from '../century-core/core-apis/feedback/feedback';
import * as guardian from 'century-core/features-dashboard/GuardianDashboard/api';
import * as intl from './intl/intl';
import * as userProfile from './userProfile/userProfile';
import * as classAnalytics from 'century-core/features-dashboard/TeacherDashboard/api';

const api = {
  auth,
  b2c,
  cdn,
  classCode,
  feedback,
  guardian,
  intl,
  userProfile,
  classAnalytics,
};

export default api;
