import { DataPoints } from 'century-core/core-apis/utils/utils';
import { FetchError } from 'century-core/core-apis/fetch/utils';
import { GET, POST } from 'century-core/core-apis/utils';
import { getLearnURL } from '../learn';

export interface NuggetAggregationDetails {
  nuggetId: string;
  usersFinished: number;
  usersActive: number;
  usersTotal: number;
  latestCompleted: NADLatestCompleted | null;
  studentStatistics: NADStudentStatistics[];
}
export interface NADLatestCompleted {
  percentScore: Ctek.AbacusGroupMetricsStats;
  durationActual: Ctek.AbacusGroupMetricsStats;
  durationTotal: Ctek.AbacusGroupMetricsStats;
  questionGroupStatistics: NADQuestionGroupStatistics[];
}

export interface NADQuestionGroupStatistics {
  questionGroupId: string;
  usersCorrect: number;
  usersIncorrect: number;
  usersSkipped: number;
  usersAnswered: number;
}

export interface NADStudentStatistics {
  userId: string;
  completed: {
    attempts: number;
    lastStudySessionId: string;
    percentScore: Ctek.AbacusGroupMetricsStats;
    durationActual: Ctek.AbacusGroupMetricsStats;
    durationTotal: Ctek.AbacusGroupMetricsStats;
  };
}

export interface NuggetInterventionData {
  dataPoints: DataPoints[];
  nuggetId: string;
  categoryCounts: {
    needsStretch: number;
    needsPraise: number;
    needsEffort: number;
    needsSupport: number;
  };
  averageDuration: number;
  averageScore: number;
}

export function getNuggetActivities(nuggetIds: string[], userId: string, accessToken: string) {
  const url = getLearnURL('/analytics/nugget-activities/');
  url.search += '?userId=' + userId + '&nuggetIds=' + nuggetIds.join(',');

  return GET<Ctek.AbacusNuggetActivity[]>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get nugget activities for user id ${userId} nugget ids ${nuggetIds.join(',')}, ${err}`);
  });
}

export const getNuggetAggregations = (nuggetId: string, userIds: string[], accessToken: string): Promise<NuggetAggregationDetails> => {
  const url = getLearnURL('/analytics/nugget-aggregations-details');
  url.search += `?nuggetId=${nuggetId}`;
  const body = JSON.stringify(userIds);
  return POST<NuggetAggregationDetails>({ url, token: accessToken, body }).catch((err: FetchError) => {
    if (err.errorCode === 404) {
      return {
        nuggetId,
        usersFinished: 0,
        usersActive: 0,
        usersTotal: userIds.length,
        latestCompleted: null,
        studentStatistics: [],
      };
    }
    throw Error(`Unable to get nugget activities for user ids ${JSON.stringify(userIds)} nugget id ${nuggetId}, ${err}`);
  });
};

export const getNuggetInterventions = (nuggetId: string, userIds: string[], accessToken: string): Promise<NuggetInterventionData> => {
  const url = getLearnURL(`/analytics/interventions/nuggets/${nuggetId}`);
  const body = JSON.stringify(userIds);
  return POST<NuggetInterventionData>({ url, token: accessToken, body });
};
