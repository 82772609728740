import { useMemo } from 'react';
import { withErrorBoundaryPage } from 'century-core/core-components/ErrorBoundary/ErrorBoundary';
import NuggetTracker from './NuggetTracker/NuggetTrackerMain';
import { CurrentCourseProvider } from './CurrentCourseProvider';
import StudentDashboardNoCourses from '../StudentDashboardNoCourses';
import LearnerActivityWidget, {
  LearnerActivityTimeMode,
} from 'century-core/features-dashboard/common/Widgets/LearnerActivityWidget/LearnerActivityWidget';
import { Grid, LoadingSpinner, PageBody, PageContent } from '@ctek/design-system';
import { usePageViewEvent } from 'century-core/core-components/MixpanelUtils';
import { getPercentValue } from 'century-core/core-utils/utils/helpers';
import { getNuggetIds } from 'century-core/core-apis/utils';
import Recommendations from './Recommendations';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import { ErrorReload } from 'century-core/core-components/ErrorReload/ErrorReload';
import LearnerOverviewHeaderStats from './LearnerOverviewHeader/LearnerOverviewHeaderStats';
import { StudentDashboardTestPractice } from './StudentDashboardTestPractice';
import LearnerOverviewHeaderMain from './LearnerOverviewHeader/LearnerOverviewHeaderMain';
import { TestPracticeFilter } from 'century-core/core-apis/roentgen/queries/myCoursesStudent.graphql';
import { useCourseData } from './hooks/useCourseData';
import { CourseBehaviour } from 'century-core/core-utils/utils/behaviours';

interface StudentDashboardCoursesProps {
  userMode: DashboardUserMode;
  studentId: string;
  isTestPractice?: boolean;
}

const mixpanelTestPracticePageViewEvents = {
  [DashboardUserMode.STUDENT]: 'student-dashboard-testpractice',
  [DashboardUserMode.GUARDIAN]: 'guardian-dashboard-testpractice',
};

/**
 * LX PHASE 1 - check comment on 'StudentDashboardSubjects'
 */
const StudentDashboardCourses = (props: StudentDashboardCoursesProps) => {
  const { userMode, studentId, isTestPractice } = props;
  const courseBehaviour = isTestPractice ? CourseBehaviour.TEST_PRACTICE : CourseBehaviour.STANDARD;
  const { coreData, selectedCurrentCourse, selectedCourseId, changeCourseSelection, isLoadingCoreData, error } = useCourseData(
    studentId,
    userMode,
    courseBehaviour
  );

  const coursePlanForCourse = useMemo(() => {
    return coreData.coursePlans?.find(v => v.courseId === selectedCurrentCourse?._id) || {};
  }, [coreData.coursePlans, selectedCurrentCourse?._id]);

  const nuggetIds = useMemo(
    () => (selectedCurrentCourse?._id && coreData.courses?.length ? getNuggetIds(selectedCurrentCourse?._id, coreData.courses) : []),
    [selectedCurrentCourse, coreData.courses]
  );

  const courseIdForLearnerAnalytics = useMemo(
    () => coreData.courses?.map(v => v._id || '').filter(v => selectedCurrentCourse && v && v === selectedCurrentCourse?._id),
    [coreData.courses, selectedCurrentCourse]
  );

  const studyGroupId = useMemo(() => {
    return coreData.studyGroups?.find(v => v.course === selectedCurrentCourse?._id)?._id || '';
  }, [coreData.studyGroups, selectedCurrentCourse?._id]);

  // 'learner-analytics' page name is the legacy name from the polymer-era Student Dashboard
  usePageViewEvent(isTestPractice ? mixpanelTestPracticePageViewEvents[userMode] : 'learner-analytics');

  if (isLoadingCoreData) {
    return <LoadingSpinner type="fullpage" />;
  }
  if (!isLoadingCoreData && coreData.courses?.length === 0) {
    return <StudentDashboardNoCourses />;
  }
  if (error) {
    return <ErrorReload />;
  }
  if (!selectedCurrentCourse) {
    return null;
  }

  const overviewHeader = (
    <LearnerOverviewHeaderMain
      scorePercentage={getPercentValue(coursePlanForCourse?.percentScore || 0)}
      completionPercentage={getPercentValue(coursePlanForCourse?.nuggetsPercentProgress) || 0}
      name={coreData?.user?.personal?.name ? `${coreData?.user?.personal?.name?.first} ${coreData?.user?.personal?.name?.last}` : ''}
      avatar={coreData?.user?.profile?.avatar || ''}
      changeCourseSelection={changeCourseSelection}
      courses={coreData.courses || []}
      selectedCourse={selectedCurrentCourse._id!}
    />
  );

  const activityWidget = (
    <LearnerActivityWidget
      userId={studentId}
      defaultTimeMode={LearnerActivityTimeMode.WEEK}
      chartHeight={232}
      courseIds={courseIdForLearnerAnalytics}
      widgetMode={userMode}
      testPracticeFilter={isTestPractice ? TestPracticeFilter.TEST_PRACTICE_ONLY : TestPracticeFilter.STANDARD_ONLY}
    />
  );

  const nuggetTracker = (
    <NuggetTracker
      studentId={studentId}
      nuggetIds={nuggetIds}
      course={selectedCurrentCourse}
      isTestPractice={isTestPractice}
      userMode={userMode}
      courseBehaviour={courseBehaviour}
    />
  );

  return (
    <CurrentCourseProvider
      course={selectedCurrentCourse as Ctek.Course}
      currentCourseImage={(selectedCurrentCourse as Ctek.Course).icon as string}
    >
      {
        // LX PHASE 1 - Once LX is completely rolled out to all BOP and BOPP users, remove this clause.
        isTestPractice ? (
          <PageBody qa="student-dashboard-test-practice">
            <PageContent>
              <StudentDashboardTestPractice
                coursePlanForCourse={coursePlanForCourse}
                overviewHeader={overviewHeader}
                activityWidget={activityWidget}
                nuggetTracker={nuggetTracker}
                nuggetsInCourse={nuggetIds.length}
              />
            </PageContent>
          </PageBody>
        ) : (
          <PageBody qa="student-dashboard-courses">
            <PageContent>
              <Grid cols={2}>
                <Grid.Item stretch={true}>
                  {overviewHeader}
                  <LearnerOverviewHeaderStats
                    nuggetsCompleted={coursePlanForCourse?.nuggetsCompleted || 0}
                    score={getPercentValue(coursePlanForCourse?.percentScore || 0)}
                  />
                </Grid.Item>
                <Grid.Item stretch={true}>{activityWidget}</Grid.Item>
                <Grid.Item>
                  <Recommendations
                    userId={studentId}
                    coursePlanId={coursePlanForCourse?.coursePlanId!}
                    courseId={selectedCourseId}
                    widgetMode={userMode}
                    studyGroupId={studyGroupId}
                    isCourseTab={true}
                  />
                </Grid.Item>
                <Grid.Item stretch={true} fillSpace={true}>
                  {nuggetTracker}
                </Grid.Item>
              </Grid>
            </PageContent>
          </PageBody>
        )
      }
    </CurrentCourseProvider>
  );
};

export default withErrorBoundaryPage(StudentDashboardCourses);
