import ReactMarkdown from 'react-markdown';
import Showdown from 'showdown';

const converter = new Showdown.Converter();

// We check here whether the '>' is a greater than symbol, an html closing tag or an extra '>' at the beginning.
// HACK this is a hack, we shouldn't do this here. For some reason the '<' have been parsed incorrectly in the DB.
const replaceGreaterThanSymbol = (content: string): string => {
  let isHtml: boolean = false;
  let contentAux: string;

  // remove extra >> from the beginning
  contentAux = content.replace(/^(>)\1+/gm, '');

  // fixed parsing for '>'
  const occurrencesToRemove: number[] = [];
  contentAux.split('').forEach((letter, index) => {
    if (letter === '<') isHtml = true;
    if (letter === '>') {
      if (isHtml) isHtml = false;
      else occurrencesToRemove.push(index);
    }
  });
  occurrencesToRemove.reverse();
  occurrencesToRemove.forEach(occurrence => {
    contentAux = contentAux.substring(0, occurrence) + '&#62;' + contentAux.substring(occurrence + 1);
  });
  return contentAux;
};

export const convertMarkdownToHtml = (markdown: string): string =>
  converter
    .makeHtml(replaceGreaterThanSymbol(markdown))
    .replace(/\^\^(.+?)\^\^/gm, '<sup>$1</sup>')
    .replace(/@@(.+?)@@/gm, '<sub>$1</sub>')
    .replace(/href/g, 'target="_blank" href');

// This is only used for rendering instructions in new assignmetns only
export const allowedMarkdownTypes: ReactMarkdown.NodeType[] = [
  'text',
  'paragraph',
  'emphasis',
  'strong',
  'link',
  // 'list',
  // 'listItem', //TODO: Not needed for now until we test the List component fromt the CDS.
];
