import { TableHeaderSortableSortDirection } from '@ctek/design-system';
import * as SortSelector from 'century-core/core-utils/selectors/sortSelectors/sortSelectors';

export type SortingType = 'number' | 'string' | 'date' | 'boolean';
export type CustomGetter<T, S, R> = (obj: T, val: S, propName: string) => R;

// Tables display either a set of data where columns are property names or where they are listed by id (and we have to supply the property)
// for example (1) a list of assignments with subjects, deadline etc or (2) a list of learners with nugget results where each column is a nugget
// for this we need supply an optional auxiliary propName and a custom function that gets this property
export interface SortingItem {
  order: any;
  type: SortingType;
  propName?: string;
  customGetter?: CustomGetter<any, any, any>;
}
export type SortSettings<T extends string = string> = Record<T, SortingItem | undefined>;

export function getSortingOrder<T extends string>(
  column: T,
  sortSettings: SortSettings,
  prop: string | undefined = ''
): TableHeaderSortableSortDirection {
  const itemSettings = sortSettings && sortSettings[column];
  return prop ? itemSettings && itemSettings.propName === prop && itemSettings.order : itemSettings && itemSettings.order;
}

export function updateSortSettings(
  column: string,
  propType: SortingType,
  sortSettings: SortSettings,
  customGetter?: CustomGetter<any, any, any>,
  propName?: string
): SortSettings {
  const columnSettings = sortSettings[column];

  if (columnSettings) {
    const newSorting =
      columnSettings.order === TableHeaderSortableSortDirection.ASC
        ? TableHeaderSortableSortDirection.DESC
        : TableHeaderSortableSortDirection.ASC;

    return {
      ...sortSettings,
      [column]: { order: newSorting, type: columnSettings.type, customGetter, propName },
    };
  } else {
    // there can be only one. We don't support multi column sorting just yet.
    return {
      [column]: { order: TableHeaderSortableSortDirection.ASC, type: propType, customGetter, propName },
    };
  }
}

export function sortTableData<T = any>(data: T[], sortSettings: SortSettings) {
  let sortedData = data;

  // already prepared for multi column sorting
  Object.keys(sortSettings).forEach(col => {
    const sortSettingsItem = sortSettings[col];
    if (!sortSettingsItem) {
      return;
    }

    const isAscending = sortSettingsItem.order === TableHeaderSortableSortDirection.ASC;
    const { type: propType, customGetter, propName } = sortSettingsItem;
    switch (propType) {
      case 'number':
        sortedData = SortSelector.sortIntegerData(sortedData, col, isAscending, propName, customGetter);
        return;
      case 'string':
        sortedData = SortSelector.sortStringData(sortedData, col, isAscending, propName, customGetter);
        return;
      case 'date':
        sortedData = SortSelector.sortDateData(sortedData, col, isAscending, propName, customGetter);
        return;
      case 'boolean':
        sortedData = SortSelector.sortBooleanData(sortedData, col, isAscending, propName, customGetter);
        return;
      default:
        throw Error(`Unable to sort for type ${propType}`);
    }
  });

  return sortedData;
}
