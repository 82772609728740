import { Paragraph, TextRun, ImageRun, ExternalHyperlink, HeadingLevel, Document, Packer } from 'docx';
import { getTextContentParagraph } from './rehype.utils';
import { condenseBulletParagraphsToList } from './sir-trevor.utils';

const documentStyles = {
  default: {
    heading3: {
      run: {
        size: 28,
        bold: true,
        italics: true,
        color: 'FF0000',
      },
      paragraph: {
        spacing: {
          after: 360,
        },
      },
    },
  },
  paragraphStyles: [
    {
      id: 'paragraphSpacing',
      name: 'Well Spaced',
      basedOn: 'Normal',
      quickFormat: true,
      paragraph: {
        spacing: { line: 276, before: 20 * 72 * 0.1, after: 20 * 72 * 0.05 },
      },
    },
  ],
};

const getGenericDataLinkParagraph = (text: string, link: string, linkText?: string) =>
  new Paragraph({
    children: [
      new TextRun({
        text,
        bold: true,
      }),
      new ExternalHyperlink({
        children: [new TextRun(linkText || link)],
        link,
      }),
    ],
  });

const getImageSizeFromBlob = (dataBlob: Blob) => new Promise<{ width: number, height: number }>(resolve => {
  const dataURL = URL.createObjectURL(dataBlob);
  const img = new Image()
  img.onload = () => {
    resolve({
      height: img.height,
      width: img.width
    })
  }
  img.src = dataURL
});

// TODO: images (improved), equations (doable with mathml), error handling logic
const getMediaContentParagraph = async (mediaContent: Ctek.MediaContentType, allowFailedMedia?: boolean): Promise<Paragraph[]> => {
  switch (mediaContent.type) {
    case 'media-image':
      const imageUrl = (mediaContent.data as Ctek.ImageContentData).value?.files[0].url;
      if (!imageUrl) {
        return [];
      }
      try {
        const maxWidth = 700;
        const maxHeight = 1200;
        const imgArrayBuffer = await window.fetch(imageUrl).then(res => res.arrayBuffer());
        const imgBlob = new Blob([new Uint8Array(imgArrayBuffer)]);
        let { height, width } = await getImageSizeFromBlob(imgBlob);

        // resizing images to retain correct scaling in documents
        const aspectRatio = width / height;
        if (width > maxWidth) {
          width = width > maxWidth ? maxWidth : width;
          height = width / aspectRatio;
        }
        if (height > maxHeight) {
          height = height > maxHeight ? maxHeight : height;
          width = height * aspectRatio;
        }
        
        return [
          new Paragraph({
            children: [
              new ImageRun({
                data: imgArrayBuffer,
                transformation: { height, width },
                
              }),
            ],
          }),
        ];
      } catch (e) {
        if (allowFailedMedia) {
          return [getGenericDataLinkParagraph('Image failed to load in document: ', imageUrl)];
        }
        throw Error(e);
      }
    case 'media-video':
      const videoData = mediaContent.data as Ctek.VideoContentData | Ctek.YoutubeContentData;
      const isYoutube = (videoData.source && videoData.source === 'youtube') || (videoData.format && videoData.format === 'youtube');
      const link = isYoutube ? (videoData as Ctek.YoutubeContentData).url : (videoData as Ctek.VideoContentData).value?.files[0].url;
      if (link) {
        return [getGenericDataLinkParagraph('Video: ', link)];
      }
      throw Error('No url for video available');
    case 'text':
    case 'heading':
      const data = mediaContent.data as Ctek.SirTrevorContent;
      try {
        const resp = await getTextContentParagraph(data, { type: mediaContent.type });
        return resp;
      } catch (e) {
        throw Error(e);
      }
    default:
      throw Error('Invalid media type');
  }
};

export const getCtekDocumentAsParagraphArray = async (document: Ctek.PopulatedMediaDocument, bulletPrefixes?: string[]) => {
  let parsedContent = document.content;

  // Filter out empty content with type text and heading (empty paragraphs)
  parsedContent = parsedContent.filter((paragraph: Ctek.MediaContentType) =>
      !(paragraph.type === 'text' || paragraph.type === 'heading') || isSirTrevorContentWithText(paragraph.data)
  );

  if (bulletPrefixes) {
    parsedContent = condenseBulletParagraphsToList(document, bulletPrefixes);
  }

  const docChildren = await Promise.all(parsedContent.map(async (paragraph: Ctek.MediaContentType) => getMediaContentParagraph(paragraph)));

  return docChildren.flat(1);
};

export const getCtekDocumentAsDocx = async (document: Ctek.PopulatedMediaDocument, bulletPrefixes?: string[]) => {
  const paragraphs = await getCtekDocumentAsParagraphArray(document, bulletPrefixes);
  return new Document({
    sections: [{ children: paragraphs }],
    styles: documentStyles,
  });
};

export interface GroupDocumentData {
  fragment: string;
  documents: Ctek.PopulatedMediaDocument[];
}

export const getDocxFileFromMultipleDocuments = async (documentGroupings: GroupDocumentData[]) => {
  const docsFormatted = await Promise.all(
    documentGroupings.map(async grouping => {
      const newSection = { children: [new Paragraph({ text: grouping.fragment, heading: HeadingLevel.HEADING_1 })] };
      const documents = await Promise.all(
        grouping.documents.map(async doc => {
          const paragraphArray = await getCtekDocumentAsParagraphArray(doc);
          return [new Paragraph({ text: doc.name, heading: HeadingLevel.HEADING_1 }), ...paragraphArray];
        })
      );
      newSection.children.push(...documents.flat(1));
      return newSection;
    })
  );
  return new Document({
    sections: docsFormatted,
  });
};

const downloadDocument = (doc: Document, filename: string, namePrefix?: string) => {
  let formattedFilename = filename?.match(/\.docx$/) ? filename : `${filename}.docx`;
  if (namePrefix) {
    formattedFilename = `${namePrefix} -- ${formattedFilename}`;
  }
  return Packer.toBlob(doc).then(blob => {
    const aElement = window.document.createElement('a');
    aElement.setAttribute('download', formattedFilename);
    const href = URL.createObjectURL(blob);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);
    aElement.remove();
  });
};

/* Converts one populated document to a docx file, named after the document name */
export const downloadCenturyDocument = async (document: Ctek.PopulatedMediaDocument, options?: { namePrefix?: string, bullet?: string[] }) => {
  const docxDocument = await getCtekDocumentAsDocx(document, options?.bullet);
  downloadDocument(docxDocument, document.name || 'downloaded-document', options?.namePrefix);
};

/* Places multiple populated documents into one file separated by headings */
export const downloadCollectedDocumentsDocx = async (documentGroupings: GroupDocumentData[], filename: string) => {
  const document = await getDocxFileFromMultipleDocuments(documentGroupings);

  downloadDocument(document, filename);
};

/** User-defined type guard to determine if the data object is of type Ctek.SirTrevorContent. Returns a boolean */
type ContentTypeData = Ctek.VideoContentData | Ctek.SirTrevorContent | Ctek.ImageContentData | Ctek.EquationData | Ctek.YoutubeContentData;

const isSirTrevorContentWithText = (data: ContentTypeData): data is Ctek.SirTrevorContent => {
  return 'text' in data && data.text.trim() !== '';
};