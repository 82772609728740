import 'katex/dist/katex.min.css'
import { sanitize } from 'dompurify'
import { transformEquation } from 'century-core/core-utils/utils/equationTransformation/transformEquation';
interface Props {
  equation: string;
}

export const Equation = (props: Props) => {
  const html = window.katex.renderToString(transformEquation(props.equation), { throwOnError: false, strict: false, displayMode: true });
  return <div dangerouslySetInnerHTML={{ __html: sanitize(html) }} />;
};
