import Auth from 'century-core/entities/Auth/Auth';
import { Dependant } from 'century-core/entities/User/User';
import { DateTime } from 'luxon';
import { getTokenSettingsLocale } from 'century-core/core-auth/utils/settings';

export function getUserProfile(user: Ctek.User | null): Dependant | null {
  return (
    user && {
      avatar: user.profile?.avatar || '',
      name: `${user.personal?.name?.first} ${user.personal?.name?.last}`,
      userId: user._id!,
    }
  );
}

export function getDependants(user: Ctek.User) {
  return !user.profile ? [] : user.profile.groups?.organisations?.[0].dependants || [];
}

// TODO: needs unit testing
export function getUserName(user: Ctek.User, isRTL: boolean = false, isCommaSeparated: boolean = false) {
  const firstName = user?.personal?.name?.first || '';
  const lastName = user?.personal?.name?.last || '';

  return isCommaSeparated
    ? isRTL
      ? `${firstName}, ${lastName}`
      : `${lastName}, ${firstName}`
    : isRTL
    ? `${lastName} ${firstName}`
    : `${firstName} ${lastName}`;
}

// TODO: needs unit testing
export function getUserEmail(user: Ctek.User): string {
  return user.contact?.emails?.[0]?.address ?? '';
}

export function getUserEmails(userId: string, entities: Record<string, any>): Ctek.UserContactEmail[] | null {
  return !entities || !entities.users || !entities.users[userId] ? null : entities.users[userId].contact.emails;
}

function ordinalFormat(day: number) {
  const suffix = ['th', 'st', 'nd', 'rd'];
  const v = day % 100;
  return day + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);
}

export function getUserDob(user: Ctek.User, auth: Auth): string {
  if (!user?.personal?.birthDate) return '';

  const userLocale = getTokenSettingsLocale(auth);
  // luxon already supports Intl.DateTimeFormat options
  const date = DateTime.fromJSDate(new Date(user?.personal?.birthDate as Date)).toLocaleString({
    locale: userLocale,
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
  const [day, month, year] = date.split(' ');
  return `${ordinalFormat(parseInt(day, 10))} ${month} ${year}` || '';
}

export function getUsernameProp(user: Ctek.User): string {
  return user?.profile?.ids?.username || '';
}

export function getUserFirstName(user: Ctek.User): string {
  return user?.personal?.name?.first || '';
}

export function getUserLastName(user: Ctek.User): string {
  return user?.personal?.name?.last || '';
}

export function getUserEmailsList(user: Ctek.User): string {
  const emailList = user?.contact?.emails?.map(email => email.address).join(', ');
  return emailList || '';
}

export function getUserUPN(user: Ctek.User): string {
  return user?.profile?.ids?.upn || '';
}

export function getUserGender(user: Ctek.User): string {
  return user?.personal?.gender || '';
}

export function getUserPersonalDOB(user: Ctek.User, dateFormat = 'yyy-LL-dd'): string {
  const birthDate = user?.personal?.birthDate;
  return birthDate ? DateTime.fromJSDate(new Date(user?.personal?.birthDate as Date)).toFormat(dateFormat) : '';
}

export function getUserUpdatedBy(user: Ctek.User) {
  const updatedAt = user?.history?.thisVersion ? user.history.thisVersion.updatedAt : user.history?.firstVersion?.createdAt.toString();
  const updatedBy = user?.history?.thisVersion ? user.history.thisVersion.updatedBy : user.history?.firstVersion?.createdBy;
  const updateByUserId = typeof updatedBy === 'object' ? updatedBy._id : updatedBy;
  return { updateByUserId, updatedAt };
}

export function getUserPupilPremium(user: Ctek.User) {
  return user?.profile?.extra?.grantEligibility ?? '';
}

export function getUserEAL(user: Ctek.User): string {
  return user?.profile?.extra?.engAdditionalLang?.status ?? '';
}

export function getUserSEND(user: Ctek.User): { code: string; description: string } {
  const send = user?.profile?.extra?.specialEducationalNeeds;
  if (!send || !send[0]) return { code: '', description: '' };

  return { code: send[0].code || '', description: send[0].description || '' };
}
