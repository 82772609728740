import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import ClickSelectDrop from '../../../DragDrop/ClickSelectAndDrop/ClickSelectDrop';
import MatchingAdditionalList from '../MatchingAdditionalList/MatchingAdditionalList';
import './AlternativeBoardMatching.scss';
import { FormFieldDeprecated, OptionsDropdown, MenuItemData } from '@ctek/design-system';

export interface Props {
  children: AlternativeBoardMatchingProps;
  qa?: string;
}

export interface AlternativeBoardMatchingProps {
  additionals?: ReactNode[];
  additionType: Ctek.MatchType;
  controls?: MenuItemData[];
  correct?: ReactNode | ReactNode[];
  incorrect?: ReactNode | ReactNode[];
  feedback?: ReactNode | ReactNode[];
  footerControls?: ReactNode | null;
  matchingPairs: ReactNode;
  onClickDropAdditional?: (id: string) => void;
  onEdit?: () => void;
  question: ReactNode | ReactNode[];
  revealCorrect?: ReactNode;
  showEditButton?: boolean;
  title?: ReactNode;
}

const AlternativeBoardMatching = (props: Props) => {
  const renderAdditionals = () => {
    const { additionals, additionType } = props.children;

    if (!additionals) {
      return null;
    }

    return (
      <div className={`alternative-board-matching__additional alternative-board-matching__additional--${additionType}`}>
        <MatchingAdditionalList listType={additionType} qa="matching-answer-container">
          {{
            additionals,
          }}
        </MatchingAdditionalList>
      </div>
    );
  };

  const {
    additionals,
    additionType,
    controls,
    correct,
    feedback,
    footerControls,
    incorrect,
    onClickDropAdditional,
    question,
    title,
    matchingPairs,
    revealCorrect,
  } = props.children;

  return (
    <>
      <div className="alternative-board-matching__header">
        {title && <div className="alternative-board-matching__title">{title}</div>}
        {controls && !!controls.length && (
          <div className="alternative-board-matching__controls">
            <OptionsDropdown>
              {{
                menuItems: controls,
                size: 'tiny',
              }}
            </OptionsDropdown>
          </div>
        )}
      </div>
      <div className="alternative-board-matching__body">
        <div className="alternative-board-matching__question">
          <FormFieldDeprecated>
            {{
              input: question as any,
              label: <FormattedMessage id="cms-labelling-qtype-question-label" defaultMessage="Question" />,
            }}
          </FormFieldDeprecated>
        </div>
        <div className={`alternative-board-matching__pairs alternative-board-matching__pairs--${additionType}`}>{matchingPairs}</div>
        {additionals &&
          !!additionals.length &&
          (onClickDropAdditional ? (
            <ClickSelectDrop onDrop={onClickDropAdditional}>{renderAdditionals()}</ClickSelectDrop>
          ) : (
            renderAdditionals()
          ))}
        {correct && <div className="alternative-board-matching__correct-feedback">{correct}</div>}
        {incorrect && <div className="alternative-board-matching__incorrect-feedback">{incorrect}</div>}
        {feedback && <div className="alternative-board-matching__feedback">{feedback}</div>}
        {revealCorrect && (
          <div className="alternative-board-matching__reveals">
            <div className="alternative-board-matching__reveals-header">
              <FormattedMessage id="correct-answer-reveal" defaultMessage="Correct answer is:" />
            </div>
            <div className="alternative-board-matching__pairs">{revealCorrect}</div>
          </div>
        )}
        {footerControls && <div className="alternative-board-matching__footer-controls">{footerControls}</div>}
      </div>
    </>
  );
};

const AlternativeBoardMatchingWrapper = (className: string) => (props: Props) => {
  const { qa, ...otherProps } = props;
  return (
    <div className={className} data-qa={qa} data-testid={qa}>
      <AlternativeBoardMatching {...otherProps} />
    </div>
  );
};

// Used when this is rendered within Polymer. Polymer renders the box so we do not need to render this.
export const AlternativeBoardMatchingWithoutBox = AlternativeBoardMatchingWrapper(
  'alternative-board-matching alternative-board-matching__learn'
);

// By default we do want to render the box.
export default AlternativeBoardMatchingWrapper('alternative-board-matching alternative-board-matching__box');
