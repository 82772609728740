import { gql, DocumentNode } from '@apollo/client';

export const USER_SUBJECTS = gql`
  query userSubjects($input: UserSubjectsInput!) {
    userSubjects(input: $input) {
      subjects {
        labelId
        name
        parentId
      }
      subjectGroups {
        labelId
        name
      }
    }
  }
`;

export const CLASS_SUBJECTS = gql`
query classSubjects($input: ClassSubjectsInput!) {
  classSubjects(input: $input) {
    subjects {
      labelId
      name
      parentId
    }
    subjectGroups {
      labelId
      name
    }
  }
}
`;

// NOTE: this is a very demanding query so each utilisation should be customised to only use required data
export const getNuggetPath = (nuggetFragment: DocumentNode, fragmentName: string) => gql`
  query nuggetPath($input: NuggetPathInput!) {
    nuggetPath(input: $input) {
      count
      nuggets {
        ...${fragmentName}
      }
      userId
    }
  }
  ${nuggetFragment}
`;

export const getCoursePath = (courseFragment: DocumentNode, fragmentName: string) => gql`
  query coursePath($input: CoursePathInput!) {
    coursePath(input: $input) {
      count
      userId
      courses {
        ...${fragmentName}
      }
    }
  }
  ${courseFragment}
`;

export const COURSE_PATH_COUNT = gql`
  query coursePathCount($input: CoursePathInput!) {
    coursePath(input: $input) {
      count
    }
  }
`

export const USER_ACTIVITY = gql`
  query userActivity($input: UserActivityInput!) {
    userActivity(input: $input) {
      summary {
        startTime
        endTime
        nuggetsClosed
        questionsAnswered {
          mean
          median
          sum
        }
      }
      timeBuckets {
        startTime
        endTime
        durationActualSec {
          mean
          median
          sum
        }
      }
      lastUpdated
      timeBucketType
    }
  }
`;

export const getUserActivity = (usageMetricFragment: string) => gql`
  query userActivity($input: UserActivityInput!) {
    userActivity(input: $input) {
      timeBuckets {
        ...usageMetricFragment
      }
      summary {
        nuggetsCompleted
      }
    }
  }
  fragment usageMetricFragment on UsageMetrics {
    ${usageMetricFragment}
  }
`;

export const USER_COURSES = gql`
  query userCourses($param: UserSubjectsInput!) {
    userCourses(param: $param) {
      count
    }
  }
`;
