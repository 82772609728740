import { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { LearnerActivityMetricMode, LearnerActivityTimeMode } from './LearnerActivityWidget';
import BarChartD3, { ChartData } from 'layout/charts/BarChartD3';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { getChartValue, formatTimeItems, getTooltipTimeFrame, getDefaultData } from './utils';
import { DateTime, Duration, ToRelativeUnit } from 'luxon';
import { getExactTimeString, translationMapping } from 'century-core/core-components/CenturyTime/CenturyExactTime';
import { ChartContainer, ChartContainerHeights } from '@ctek/design-system';

interface Props {
  data: { userActivity: Ctek.Roentgen.ActivityOverTimePayload };
  metricMode: LearnerActivityMetricMode;
  startTime: string;
  timeMode: LearnerActivityTimeMode;
  chartHeight: ChartContainerHeights;
  isSubjectView: boolean;
}

export enum FieldKey {
  NUGGETS_KEY = 'nuggetsCompleted',
  QUESTIONS_KEY = 'questionsAnswered',
  TIME_KEY = 'durationActualSec',
}

const DataMetricTranslations = {
  NUGGETS_COMPLETED: { id: 'learner-activity-widget-metric-nuggets', defaultMessage: 'Nuggets Completed' },
  NUGGETS_STUDIED: { id: 'learner-activity-widget-metric-nuggets-studied', defaultMessage: 'Nuggets Studied' },
  QUESTIONS: { id: 'learner-activity-widget-metric-questions', defaultMessage: 'Questions Answered' },
  TIME: { id: 'learner-activity-widget-metric-time', defaultMessage: 'Time Spent Studying' },
};

const TimeTranslations = {
  DAY: { id: 'learner-activity-graph-label-date', defaultMessage: 'Date' },
  WEEK: { id: 'learner-activity-graph-label-week', defaultMessage: 'Week' },
  MONTH: { id: 'learner-activity-graph-label-month', defaultMessage: 'Month' },
};

export default function LearnerActivityBarChart(props: Props) {
  const intl = useIntl();
  const [dataTranslation, setDataTranslation] = useState(
    props.isSubjectView ? DataMetricTranslations.NUGGETS_STUDIED : DataMetricTranslations.NUGGETS_COMPLETED
  );
  const [fieldKey, setFieldKey] = useState(FieldKey.NUGGETS_KEY);
  const [xAxisLabel, setXAxisLabel] = useState(intl.formatMessage(TimeTranslations.DAY));
  const [yAxisLabel, setYAxisLabel] = useState(
    intl.formatMessage(props.isSubjectView ? DataMetricTranslations.NUGGETS_STUDIED : DataMetricTranslations.NUGGETS_COMPLETED)
  );
  const [timeUnit, setTimeUnit] = useState('seconds' as ToRelativeUnit);
  const [items, setItems] = useState([] as ChartData[]);

  useEffect(() => {
    switch (props.metricMode) {
      case LearnerActivityMetricMode.NUGGETS:
        const translation = props.isSubjectView ? DataMetricTranslations.NUGGETS_STUDIED : DataMetricTranslations.NUGGETS_COMPLETED;
        setDataTranslation(translation);
        setFieldKey(FieldKey.NUGGETS_KEY);
        setYAxisLabel(intl.formatMessage(translation));
        break;
      // case LearnerActivityMetricMode.QUESTIONS:
      //   setDataTranslation(DataMetricTranslations.QUESTIONS);
      //   setFieldKey(FieldKey.QUESTIONS_KEY);
      //   setYAxisLabel(intl.formatMessage(DataMetricTranslations.QUESTIONS));
      //   break;
      case LearnerActivityMetricMode.TIME:
        setDataTranslation(DataMetricTranslations.TIME);
        setFieldKey(FieldKey.TIME_KEY);
        setYAxisLabel(intl.formatMessage(DataMetricTranslations.TIME));
        break;
      default:
        break;
    }
  }, [props.metricMode, intl, props.isSubjectView]);

  useEffect(() => {
    switch (props.timeMode) {
      case LearnerActivityTimeMode.DAY:
        setXAxisLabel(intl.formatMessage(TimeTranslations.DAY));
        break;
      case LearnerActivityTimeMode.WEEK:
        setXAxisLabel(intl.formatMessage(TimeTranslations.DAY));
        break;
      case LearnerActivityTimeMode.MONTH:
        setXAxisLabel(intl.formatMessage(TimeTranslations.MONTH));
        break;
      default:
        break;
    }
  }, [props.timeMode, intl]);

  useEffect(() => {
    let newItems: ChartData[];
    if (!props.data?.userActivity?.timeBuckets.length) {
      newItems = getDefaultData(props.timeMode, props.startTime);
    } else {
      newItems = (props.data?.userActivity?.timeBuckets || []).map((bucket: Ctek.Roentgen.UsageMetrics) => {
        return {
          y: getChartValue(bucket, fieldKey),
          x: bucket.startTime,
        };
      });
    }

    if (props.metricMode === LearnerActivityMetricMode.TIME) {
      const { chartItems, chosenUnit } = formatTimeItems(newItems);
      newItems = chartItems;
      setTimeUnit(chosenUnit);
    }
    setItems(newItems);
  }, [props.data?.userActivity?.timeBuckets, props.metricMode, props.timeMode, props.startTime, fieldKey]);

  // useCallback to prevent axis ticks changing while data loads for different metric modes
  const formatXVals = useCallback(
    (x: string) => {
      return props.timeMode === LearnerActivityTimeMode.MONTH
        ? DateTime.fromISO(x).toUTC().toFormat('MMM')
        : DateTime.fromISO(x).toUTC().toFormat('dd');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.data?.userActivity?.timeBuckets]
  );

  const formatTooltip = (data: ChartData) => {
    const formattedValue =
      fieldKey === FieldKey.TIME_KEY
        ? getExactTimeString({ time: Duration.fromObject({ [timeUnit]: data.y }).as('seconds'), shortenUnits: true }, intl)
        : data.y;

    return (
      <>
        <b>{getTooltipTimeFrame(data.x, props.timeMode)}</b>
        <div>{`${intl.formatMessage(dataTranslation)}: ${formattedValue}`}</div>
      </>
    );
  };

  return (
    <ChartContainer chartHeight={props.chartHeight}>
      <ParentSize>
        {({ width, height }) => (
          <BarChartD3
            width={width}
            height={height}
            data={items}
            formatTooltip={formatTooltip}
            formatXVals={formatXVals}
            xAxisLabel={xAxisLabel}
            yAxisLabel={
              props.metricMode === LearnerActivityMetricMode.TIME
                ? `${yAxisLabel} (${intl.formatMessage({ id: `${translationMapping[timeUnit]}-plural`, defaultMessage: `${timeUnit}` })}) `
                : yAxisLabel
            }
          />
        )}
      </ParentSize>
    </ChartContainer>
  );
}
