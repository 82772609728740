import UploadedImage from 'century-core/core-components/DraggableQuestionTypes/components/UploadedImage/UploadedImage';

const ImageDragLayer = (props: { item: string }) => {
  if (!props.item) {
    return null;
  }
  return (
    <div className="drag-image draggable-label-item draggable-label-item__active">
      <UploadedImage>{{ imgSrc: props.item }}</UploadedImage>
    </div>
  );
};

export default ImageDragLayer;
