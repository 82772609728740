import { useState } from 'react'

const useQuestionTypesButtonHandling = (props: { handleViewResults?: () => void; handleClickNext?: () => void }) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleClickNext = () => {
    if (buttonDisabled) {
      return;
    }
    setButtonDisabled(true);
    if (props.handleClickNext) {
      props.handleClickNext();
    }
  };

  const handleViewResults = () => {
    if (buttonDisabled) {
      return;
    }
    setButtonDisabled(true);
    if (props.handleViewResults) {
      props.handleViewResults();
    }
  };

  return {
    buttonDisabled,
    handleClickNext,
    handleViewResults,
  };
};

export default useQuestionTypesButtonHandling;
