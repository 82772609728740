import { BoxSimple, AccessIllustration, Heading, Text } from '@ctek/design-system';
import { FormattedMessage } from 'react-intl';

const StudentDashboardNoCourses = () => (
  <BoxSimple qa="student-dashboard--no-courses">
    <AccessIllustration illustration="no-courses" />
    <Heading.SectionTitle>
      <FormattedMessage
        id="dashboard--user-has-no-courses-title"
        defaultMessage="No courses assigned yet"
      />
    </Heading.SectionTitle>
    <Text.Message>
      <FormattedMessage
        id="dashboard--user-has-no-courses-message"
        defaultMessage="Seems like you don't have any courses assigned to you yet – once you have, you will be able to come here and view your dashboard"
      />
    </Text.Message>
  </BoxSimple>
);

export default StudentDashboardNoCourses;
