import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { useContext, useEffect, useRef } from 'react';
import MixpanelContext, { MixpanelConsumer } from 'react-mixpanel';

export const withMixpanel = (Component: any) => (props: any) => {
  return <MixpanelConsumer>{(mixpanel: any) => <Component mixpanel={mixpanel} {...props} />}</MixpanelConsumer>;
};

export const useMixpanel = (): any => useContext(MixpanelContext);

// ensures page view event is applied consistent and only occurs one time per component lifecycle
export const usePageViewEvent = (pageName: string, skip?: boolean) => {
  const pageViewRef = useRef(false);
  const mixpanel = useMixpanel();

  // resets if page name changes
  useEffect(() => {
    pageViewRef.current = false;
  }, [pageName]);

  useEffect(() => {
    if (mixpanel && !pageViewRef.current && !skip && pageName) {
      pageViewRef.current = true;
      mixpanel.track(MixpanelEventTypes.PageViewed, { [MixpanelKeys.PageName]: pageName });
    }
  }, [mixpanel, pageName, skip]);
};

export interface MixpanelProps {
  mixpanel: {
    track: (...props: any) => void;
  };
}
