import ReactMarkdown from 'react-markdown';
import { Equation } from '../Equation/Equation';
import { AudioPlayer, VideoPlayer } from '@ctek/design-system';
import { withErrorBoundaryPartial } from 'century-core/core-components/ErrorBoundary/ErrorBoundary';
import { OptimisedImage } from 'century-core/core-components/OptimisedImage/OptimisedImage';
import { convertMarkdownToHtml } from 'century-core/core-utils/utils/markdown';
import { VideoWrapper } from 'century-core/media/Video';

interface Props {
  content: Ctek.EnrichedContent;
  studySessionId?: string;
}

export const QuestionContent = ({ content, studySessionId}: Props) => {
  if (content.type === 'heading') {
    return (
      <h1 key={content.id}>
        <QuestionContentData content={content} studySessionId={studySessionId}/>
      </h1>
    );
  }
  return <QuestionContentData content={content} studySessionId={studySessionId} />;
};

const QuestionContentData = ({ content, studySessionId }: Props) => {
  const files = (content.data as Ctek.EnrichedContentData)?.value?.files || [];
  switch ((content.data as Ctek.EnrichedContentData | Ctek.SirTrevorContent).format) {
    case 'markdown':
      // Sir Trevor marks super and subscript weirdly so need to replace these with the correct html.
      const html = convertMarkdownToHtml((content.data as Ctek.SirTrevorContent).text);
      return <ReactMarkdown key={content.id} source={html} escapeHtml={false} linkTarget={'_blank'} />;
    case 'html':
      // Sir Trevor marks super and subscript weirdly so need to replace these with the correct html.
      const mappedData = convertMarkdownToHtml((content.data as Ctek.SirTrevorContent).text);
      return <ReactMarkdown key={content.id} source={mappedData} escapeHtml={false} linkTarget={'_blank'} />;
    case 'image':
      return (
        <>
          {files.length > 0 && (
            <OptimisedImage
              content={
                {
                  ...content,
                  id: files[0].id,
                } as Ctek.MediaContentType
              }
              documentId={content.id}
              key={content.id}
              alt={(content.data as Ctek.EnrichedContentData).value?.name || ''}
              studySessionId={studySessionId}
            />
          )}
        </>
      );
    case 'latex':
      return (
        <div className="question-content-equation">
          <Equation equation={(content.data as Ctek.SirTrevorContent).text || ''} />
        </div>
      );
    case 'audio':
      return <AudioPlayer key={content.id} src={files[0].url || ''} />;
    case 'youtube':
      return <VideoPlayer key={content.id} src={(content.data as unknown as Ctek.EnrichedFile).url || ''} />;
    case 'video':
      try {
        return <VideoWrapper content={content} hideSubtitle={true}/>;
      } catch {
        return <VideoPlayer key={content.id} src={files[0].url || ''} />;
      }
    default:
      return null;
  }
};

export default withErrorBoundaryPartial(QuestionContent);
