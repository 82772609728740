export enum ActionTypes {
  SetEmailAddress = 'RecoverPassword.SetEmailAddress',
}

export type Actions =
  | ReturnType<typeof setEmailAddress>

export const setEmailAddress = (emailAddress: string) => ({
  emailAddress,
  type: ActionTypes.SetEmailAddress as typeof ActionTypes.SetEmailAddress,
});
