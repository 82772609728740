import { createLocalisedErrorMessage } from 'century-core/entities/ErrorMessage/createLocalisedErrorMessage';
import { patchMe } from '../../century-core/core-apis/accountsV2/users/users';
import * as SentryLib from 'century-core/core-utils/lib/sentry';

export async function modifyEmails(userEmails: { contact: { emails: Ctek.UserContactEmail[] } }, accessToken: string) {
  try {
    return await patchMe(accessToken, userEmails);
  } catch (e) {
    SentryLib.captureExceptionWithScope(new Error(e?.message), 'error', { tags: { emails: JSON.stringify(userEmails.contact.emails) } });
    if (e.status === 409) {
      throw createLocalisedErrorMessage('localised-errors-add-email-duplicate-error');
    } else {
      throw createLocalisedErrorMessage('localised-errors-user-profile-generic-error');
    }
  }
}
