import { History } from 'history';

// --- Entities ---
import { Auth } from '../../entities/Auth/Auth';

// --- Services ---
import { getTokenData } from 'century-core/core-auth/utils';
import * as localStorage from 'century-core/core-utils/utils/localStorage/localStorage';

export function populateAuth(auth: Partial<Auth>): Auth {
  return {
    accessToken: auth.accessToken,
    accessTokenData: getTokenData<Ctek.JWTData>(auth.accessToken),
    refreshToken: auth.refreshToken,
    refreshTokenData: getTokenData(auth.refreshToken),
  };
}

export function parseAuthFromLocalstorage(): Auth | undefined {
  const auth = localStorage.read('auth');

  if (auth === undefined) {
    return undefined;
  }

  let parsedAuth: Auth | undefined;
  try {
    parsedAuth = JSON.parse(auth);
  } catch (err) {
    return undefined;
  }

  if (!parsedAuth?.accessToken) {
    return undefined;
  }

  return populateAuth(parsedAuth);
}

export function writeAuthToLocalstorage(auth: Auth): void {
  localStorage.write('auth', {
    accessToken: auth.accessToken,
    refreshToken: auth.refreshToken,
  });
}

export function isOpenId(hash: string): boolean {
  return (
    !!hash &&
    hash.includes('id_token') &&
    hash.includes('access_token') &&
    hash.includes('token_type') &&
    hash.includes('expires_in') &&
    hash.includes('scope') &&
    hash.includes('state')
  );
}

export function redirectToLogin(historyPush: (path: History.Path) => void) {
  const isLoginPage = window.location.href.indexOf('/login') > -1;
  if (isLoginPage) {
    return;
  }

  let redirect;
  if (window.location.pathname && window.location.pathname !== '/') {
    redirect = escape(window.location.pathname);
  }

  const url = redirect ? `/login/?redirect=${redirect}` : '/login/';
  historyPush(url);
}
