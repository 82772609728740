import { createContext, useState, useEffect, useCallback } from 'react';
import * as React from 'react';
import { getUser } from 'century-core/core-apis/accountsV2/users/users'
import { useAccessToken } from 'century-core/core-auth/hooks/useAuth';

export const QLAStudentInformationContext = createContext<{ student: Ctek.User | null, studentError: null | string } | null>(null);
export default QLAStudentInformationContext;

interface AllStudents {
  [id: string]: Ctek.User
}

export const QLAStudentInformationProvider = (props: { studentId: string, children: React.ReactNode }) => {
  const accessToken = useAccessToken();
  const [allStudents, updateAllStudents] = useState({} as {} | AllStudents)
  const [student, setStudent] = useState(null as null | Ctek.User)
  const [studentError, setStudentError] = useState(null as null | string)

  const updateStudent = useCallback(async () => {
    if (allStudents[props.studentId]) {
      setStudent(allStudents[props.studentId]);
    } else {
      try {
        const user = await getUser(props.studentId, accessToken)
        updateAllStudents({ ...allStudents, [user._id as string]: user })
        setStudent(user);
      } catch (e) {
        setStudentError('e')
      }
    }
  }, [props.studentId, accessToken, allStudents])

  useEffect(() => {
    if (student?._id !== props.studentId) {
      updateStudent()
    }
  }, [student, props.studentId, updateStudent])

  return <QLAStudentInformationContext.Provider value={{ student, studentError }}>{props.children}</QLAStudentInformationContext.Provider>;
};
