import { getUserActivity } from 'century-core/core-apis/roentgen/queries/studentDashboard';

// DON'T TOUCH MY INDENTATION
const metricFragment = `
      mean 
      median 
      sum
`;

export const usageMetricFragment = `
    startTime
    endTime
    nuggetsCompleted
    questionsAnswered {
      ${metricFragment}
    }
    durationActualSec {
      ${metricFragment}
    }
`;

export const learnerActivityWidgetQuery = getUserActivity(usageMetricFragment);
