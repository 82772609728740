import { getUserSubscription } from 'century-core/core-apis/cms/subscription';
import { UserSubscriptionHistory, UserCurrentSubscription, PurchasedProduct } from 'century-core/core-subscription/types';
import { getUser } from 'century-core/core-apis/accountsV2/users/users';
import { getStudyGroups } from 'century-core/core-apis/palpatine/studygroups/studygroups';
import { createFetchRequestWithLocalisedError } from 'century-core/core-apis/utils';

export async function fetchUserSubscription(accessToken: string): Promise<UserCurrentSubscription | UserSubscriptionHistory> {
  return createFetchRequestWithLocalisedError<UserCurrentSubscription | UserSubscriptionHistory>(async () => {
    let userSubscription = await getUserSubscription<UserCurrentSubscription | UserSubscriptionHistory>(accessToken);

    if (!!(userSubscription as UserCurrentSubscription)?.active) {
      const productsArr: PurchasedProduct[] = [];
      await Promise.all(
        (userSubscription as UserCurrentSubscription)?.products.map(async (product: PurchasedProduct) => {
          if (product.learner) {
            const userRes = await getUser(product.learner.userId, accessToken);
            const coursesRes = await getStudyGroups(product.learner.userId, accessToken);

            // Below is the temporary FE bugfix B2C-814 to remove duplicat courses that were accidentaly assigned via BE to learners when switched between two Bond products
            // BE fix is on B2C-816. The below filter can perhaps be removed when B2C-816 is done and all duplicates are removed manually
            const reducedCourses = coursesRes.reduce((accumulator: Ctek.StudyGroup[], current) => {
              if (!accumulator.find((item: Ctek.StudyGroup) => item.course === current.course)) {
                accumulator.push(current);
              }
              return accumulator;
            }, []);

            const extendedProduct = {
              learner: {
                userId: product.learner.userId,
                date: product.learner.date,
                firstName: userRes.personal?.name?.first,
                lastName: userRes.personal?.name?.last,
                userName: userRes.profile?.ids?.username,
                avatar: userRes.profile?.avatar,
                courseLength: reducedCourses.length,
                tests: product.learner.tests,
                quotaReached: product.learner.quotaReached,
                quotaRemaining: product.learner.quotaRemaining,
                quota: product.learner.quota!,
                dateOfBirth: userRes.personal?.birthDate,
              },
              subscriptionItem: product.subscriptionItem,
            };
            productsArr.push(extendedProduct);
          } else {
            productsArr.push(product);
          }
        })
      );

      userSubscription = {
        ...(userSubscription as UserCurrentSubscription),
        products: productsArr,
      };
      return userSubscription;
    }
    return userSubscription;
  }, 'b2c-localised-error-fetch-user-subscription-information');
}
