import { ApolloProvider } from '@apollo/client'
import { useCallback } from 'react';
import { Provider } from 'react-redux'
import { Store } from 'redux'
import createApolloClient from './createApolloClient'

interface Props {
  children: JSX.Element;
  store: Store;
}

const Core = (props: Props) => {
  const getApolloClient = useCallback(() => {
    return createApolloClient(props.store);
  }, [props.store]);

  const apolloClient = getApolloClient();

  return (
    <Provider store={props.store}>
      <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
    </Provider>
  );
};

export default Core;
