import { createContext, useState } from 'react';
import * as React from 'react';
import { getQuestionGroup as getQuestionGroupFromAPI } from 'century-core/core-apis/contentV2/questionGroup/questionGroup';
import { useAccessToken } from 'century-core/core-auth/hooks/useAuth';

export const QuestionGroupContext = createContext<{
  getQuestionGroup: (ids: string[]) => Promise<Ctek.EnrichedQuestionGroup[]>;
} | null>(null);
export default QuestionGroupContext;

export const QuestionGroupProvider = (props: { children: React.ReactNode }) => {
  const accessToken = useAccessToken();
  const [allQuestionGroups, updateQuestionGroups] = useState([] as Ctek.EnrichedQuestionGroup[]);

  const getQuestionGroup = async (questionGroupIds: string[]) => {
    const questionGroups: Ctek.EnrichedQuestionGroup[] = [];
    await Promise.all(
      questionGroupIds.map(async id => {
        const cachedQG = allQuestionGroups.find(cqg => cqg._id === id);
        if (cachedQG) {
          questionGroups.push(cachedQG);
        } else {
          try {
            questionGroups.push((await getQuestionGroupFromAPI(id, accessToken, true)) as Ctek.EnrichedQuestionGroup);
          } catch (e) {
            throw e;
          }
        }
      })
    );
    updateQuestionGroups(questionGroups);
    return questionGroups;
  };

  return <QuestionGroupContext.Provider value={{ getQuestionGroup }}>{props.children}</QuestionGroupContext.Provider>;
};
