import * as React from 'react';
import { ConnectDragSource, DragSource, DragSourceConnector, DragSourceMonitor } from 'react-dnd';

export interface ConnectedDragSourceProps extends DragProps {
  dragItem: (isDragging: boolean) => React.ReactNode;
  // Collected Props
  connectDragSource: ConnectDragSource;
  isDragging: boolean;
  className?: string;
}

export interface DragProps {
  type: string;
  id: string;
  item?: object | string;
}

const ConnectedDragSource: React.FC<ConnectedDragSourceProps> = ({ isDragging, connectDragSource, dragItem, className }) => {
  return connectDragSource(
    <div className={className} style={{ transform: 'translate3d(0, 0, 0)', display: 'inline-block', width: '100%' }}>
      {dragItem(isDragging)}
    </div>
  );
};

export default DragSource(
  (props: ConnectedDragSourceProps) => props.type,
  {
    beginDrag: (props: ConnectedDragSourceProps) => ({ id: props.id, item: props.item }),
  },
  (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })
)(ConnectedDragSource);
