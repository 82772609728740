import { PageFilter, PageFilterList } from '@ctek/design-system';
import { useTranslateSubject } from 'century-core/core-components/Subject/SubjectLabel';
import { SubjectFilter } from 'century-core/features-dashboard/TeacherDashboard/TeacherDashboardClassOverview/ClassOverview';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ALL_OPTION } from './constants';

interface SubjectProps {
  subjects: Ctek.Roentgen.Label[];
  onSelectSubject: (subject: SubjectFilter) => any;
  isSelected: boolean;
  subjectGroupName: string;
  isGeneratedGroup: boolean;
  initialSubjectId?: string;
}

const SubjectGroupFilter = (props: SubjectProps) => {
  const { subjects, onSelectSubject, isSelected, initialSubjectId } = props;
  const [selectedSubjectId, setSelectedSubjectId] = useState<string>(initialSubjectId || 'all');
  const currentSubject = useRef<string | undefined>(undefined);
  const intl = useIntl();
  const translateSubject = useTranslateSubject();

  const onSetSelected = useCallback(
    (id, name) => {
      if (id !== selectedSubjectId) {
        setSelectedSubjectId(id);
        // ref required to prevent useEffect loop on subjects updating
        currentSubject.current = id;
      }
      if (id !== selectedSubjectId || !isSelected) {
        onSelectSubject({ id, name });
      }
    },
    [onSelectSubject, selectedSubjectId, isSelected]
  );

  useEffect(() => {
    // if subjects are updated to not include the currently selected option, revert to "all"
    if (subjects.every((subject: Ctek.Roentgen.Label) => subject.labelId !== currentSubject.current) && !!currentSubject.current) {
      setSelectedSubjectId(ALL_OPTION);
    }
  }, [subjects]);

  const selectedSubjectName =
    selectedSubjectId === ALL_OPTION
      ? intl.formatMessage({ id: 'all', defaultMessage: 'All' })
      : props.subjects.find(s => s.labelId === selectedSubjectId)?.name;

  return (
    <PageFilterList.Item>
      <PageFilter isActive={isSelected}>
        <PageFilter.Button>
          <button type="button" onClick={() => onSetSelected(selectedSubjectId || ALL_OPTION, selectedSubjectName || ALL_OPTION)}>
            {translateSubject(props.subjectGroupName)}
            <PageFilter.SelectedOption qa="selected-subject-name">{translateSubject(selectedSubjectName)}</PageFilter.SelectedOption>
          </button>
        </PageFilter.Button>
        {props.subjects.length > 0 && (
          <PageFilter.Menu>
            <select
              value={isSelected ? selectedSubjectId : 'default'}
              onChange={e => onSetSelected(e.target.value, e.target.options[e.target.selectedIndex].text)}
              data-testid={`subject-select-${props.subjectGroupName}`}
            >
              {/* FIXME: hack value required as without it if this group's selected option is selected from dropdown when another group is active, the onChange action will not fire */}
              <option value="default" disabled={true}>
                {intl.formatMessage({ id: 'subject-group--filter-by', defaultMessage: 'Filter By:' })}
              </option>
              <option value={ALL_OPTION}>
                {intl.formatMessage({ id: 'subject-group--all-subjects-in-group', defaultMessage: 'All subjects in group' })}
              </option>
              {props.subjects.map((subject: Ctek.Roentgen.Label) => (
                <option key={subject.labelId} value={subject.labelId}>
                  {translateSubject(subject.name)}
                </option>
              ))}
            </select>
          </PageFilter.Menu>
        )}
      </PageFilter>
    </PageFilterList.Item>
  );
};

export default SubjectGroupFilter;
