import { createContext, useState, useEffect, useCallback } from 'react';
import * as React from 'react';
import { QLALensProperties } from 'century-core/core-utils/utils/qla/types'

export interface QLALens {
  courses: {
    [id: string]: QLACourseInfo;
  }
}

interface QLACourseInfo {
  courseName: string;
  icon: any;
  iconUrl?: string;
  iconCode: string;
  subjectName: string;
}

interface QLALensInformationProviderProps {
  children: React.ReactNode;
  nuggets: any[];
  getProp: (nugget: any, prop: QLALensProperties) => any;
}

export const QLALensInformationContext = createContext<{ getCourseForNugget: (nuggetId: string) => QLACourseInfo | undefined } | null>(null);
export default QLALensInformationContext;

export const QLALensInformationProvider = (props: QLALensInformationProviderProps) => {
  const [QLALens, setQLALens] = useState(null as null | QLALens)

  const generateQLALens = useCallback(() => {
    const courses = {}

    props.nuggets.forEach((nugget: any) => {
      const nuggetId = props.getProp(nugget, 'id');
      courses[nuggetId] = {
        courseName: props.getProp(nugget, 'courseName'),
        // backfilling if we don't get image url directly, which we should do now in most cases
        icon: props.getProp(nugget, 'icon'),
        iconUrl: props.getProp(nugget, 'iconUrl'),
        iconCode: props.getProp(nugget, 'iconCode'),
        subjectName: props.getProp(nugget, 'subjectName')
      }
    })

    return { courses };
  }, [props])

  const getCourseForNugget = (nuggetId: string) => {
    return QLALens?.courses[nuggetId]
  }

  useEffect(() => {
    setQLALens(generateQLALens())
  }, [generateQLALens]);

  return <QLALensInformationContext.Provider value={{ getCourseForNugget }}>
    {props.children}
  </QLALensInformationContext.Provider>;
};

QLALensInformationProvider.defaultProps = {
  getProp: (nugget: any, prop: QLALensProperties) => nugget?.[prop],
}