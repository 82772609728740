import { QlaLevel2, QlaTable } from '@ctek/design-system';
import { FormattedMessage } from 'react-intl';
import { QLAAttempt } from '../Attempt/QLAAttemptProvider';
import QlaLv2Block from './QlaLv2Block';

interface Props {
  attempt: QLAAttempt | undefined | null;
  initialQuestionIndex?: number
}

export default function QlaLv2Table({ attempt, initialQuestionIndex }: Props) {

  if (!attempt) {
    return null;
  }
  return (
    <QlaTable qa="qla-questions">
      <QlaLevel2.HeadRow>
        <QlaLevel2.ColBlankControl />
        <QlaLevel2.ColNumber />
        <QlaLevel2.ColName>
          <FormattedMessage id="question-level-analysis-question-header-title-question-name" defaultMessage="Name" />
        </QlaLevel2.ColName>
        <QlaLevel2.ColType>
          <FormattedMessage id="question-level-analysis-question-header-title-question-type" defaultMessage="Question type" />
        </QlaLevel2.ColType>
        <QlaLevel2.Col>
          <FormattedMessage id="question-level-analysis-question-header-title-activeTime" defaultMessage="Active time" />
        </QlaLevel2.Col>
        <QlaLevel2.Col>
          <FormattedMessage id="question-level-analysis-question-header-title-correct" defaultMessage="Correct" />
        </QlaLevel2.Col>
        <QlaLevel2.Col>
          <FormattedMessage id="question-level-analysis-question-header-title-marks" defaultMessage="Marks" />
        </QlaLevel2.Col>
      </QlaLevel2.HeadRow>
      {attempt.studySessionResult.questions.map((questionAnalytics, i) => {
        const questionGroup = attempt.questionGroups.find(q => q._id === questionAnalytics.questionGroupId);
        const question = questionGroup?.questions.find(q => q._id === questionAnalytics.questionId)
        const wasQuestionSkipped = questionAnalytics.activity === 'attempt-skip' || questionAnalytics.activity === 'attempt-leave';
        return <QlaLv2Block
          key={questionAnalytics.questionId}
          rowNum={i + 1}
          question={question}
          questionGroup={questionGroup}
          questionAnalytics={questionAnalytics}
          wasQuestionSkipped={wasQuestionSkipped}
          isInitiallyOpen={initialQuestionIndex === i}
        />
      })}
    </QlaTable>
  )
}