import { Redirect, NavLink, Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import StudentDashboardCourses from '../features-dashboard/StudentDashboard/StudentDashboardCourses/StudentDashboardCourses';
import StudentDashboardOverview from '../features-dashboard/StudentDashboard/StudentDashboardOverview/StudentDashboardOverview';
import { Tabs } from '@ctek/design-system';
import { FormattedMessage } from 'react-intl';

import StudentDashboardAssignmentsV1 from 'century-core/features-dashboard/StudentDashboard/StudentDashboardAssignmentsV1/StudentDashboardAssignmentsV1';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import { useAuth } from 'century-core/core-auth/hooks/useAuth';
import { shouldOverrideFeature } from 'century-core/core-auth/utils';

const isCurrentTabUrl = (url: string) => window.location.pathname.indexOf(url) > -1;

function StudentDashboardForLeadership(props: { user: any; isLeadership?: boolean }) {
  const auth = useAuth();
  const match = useRouteMatch();
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const searchParamsString = searchParams.toString();
  const hasAssignmentsEnabled = !shouldOverrideFeature('assignments', auth);

  // HACK Having <Tabs /> here when we could use <StudentDashboardMenu /> is an awful hack
  // But since the user comes here from LD in polymer (component 'PolymerToReactComponents') and that is in the top level route '/',
  // is hard to use 'StudentDashboardMenu' and have the active tabs working correctly without many hacky solutions. And since I
  // prefer to pollute this and not the real SD, I moved them here.
  // This is gonna be deprecated soon anyway when the new LD is done.
  return (
    <>
      <Tabs>
        <Tabs.Item isActive={isCurrentTabUrl('/overview')}>
          <NavLink to={`/overview?${searchParams.toString()}`} data-testid="learner-dashboard-overview-tab-link">
            <FormattedMessage id="my-dashboard-overview-tab" defaultMessage="Overview" />
          </NavLink>
        </Tabs.Item>

        <Tabs.Item isActive={isCurrentTabUrl('/courses')}>
          <NavLink to={`/courses?${searchParams.toString()}`} data-testid="learner-dashboard-courses-tab-link">
            <FormattedMessage id="my-dashboard-courses-tab" defaultMessage="Courses" />
          </NavLink>
        </Tabs.Item>

        {hasAssignmentsEnabled && (
          <Tabs.Item isActive={isCurrentTabUrl('/assignments')}>
            <NavLink to={`/assignments?${searchParams.toString()}`} data-testid="learner-dashboard-assignments-tab-link">
              <FormattedMessage id="my-dashboard-assignments-tab" defaultMessage="Assignments" />
            </NavLink>
          </Tabs.Item>
        )}
      </Tabs>
      <Switch>
        <Route path={'/overview'}>
          <StudentDashboardOverview user={props.user} userMode={DashboardUserMode.TEACHER} qa="student-overview--leadership" />
        </Route>

        <Route path={'/courses'}>
          <StudentDashboardCourses studentId={props.user.userId} userMode={DashboardUserMode.TEACHER} />
        </Route>

        {hasAssignmentsEnabled && (
          <Route path={'/assignments'}>
            <StudentDashboardAssignmentsV1 studentId={props.user.userId} userMode={DashboardUserMode.TEACHER} />
          </Route>
        )}

        {history.location.pathname === match.url && (
          <Route path={props.isLeadership ? '' : '/'}>
            <Redirect to={`${match.url}overview?${searchParamsString}`} />
          </Route>
        )}
      </Switch>
    </>
  );
}

export default StudentDashboardForLeadership;
