import { Widget } from '@ctek/design-system';
import { TestPracticeFilter } from 'century-core/core-apis/roentgen/queries/myCoursesStudent.graphql';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import { FormattedMessage } from 'react-intl';
import CoursesWidgetBody from './CoursesWidgetBody';

interface Props {
  userId: string;
  onDataFetch?: (...params: any) => void;
  subjectIds?: string[];
  subjectGroupIds?: string[];
  testPracticeFilter?: TestPracticeFilter;
  widgetMode: DashboardUserMode;
  isBondLearner?: boolean;
}

export default function CoursesWidget(props: Props) {
  const studentView = DashboardUserMode.STUDENT === props.widgetMode;
  const phrasePrefix = studentView ? 'student-dashboard' : 'guardian';
  return (
    <Widget qa={props.testPracticeFilter === TestPracticeFilter.TEST_PRACTICE_ONLY ? 'widget--test-practice' : 'widget--courses'}>
      <Widget.Header>
        <Widget.Title>
          {/* // LX PHASE 1 - initially only show unifed version to Bond users. */}
          {props.testPracticeFilter === TestPracticeFilter.STANDARD_ONLY || props.isBondLearner ? (
            <FormattedMessage id={`${phrasePrefix}-courses-widget-title`} defaultMessage={studentView ? 'My Courses' : 'Courses'} />
          ) : (
            <FormattedMessage
              id={`${phrasePrefix}-test-practice-widget-title`}
              defaultMessage={studentView ? 'My Test Practice' : 'Test Practice'}
            />
          )}
        </Widget.Title>
      </Widget.Header>
      <CoursesWidgetBody
        onDataFetch={props.onDataFetch}
        userId={props.userId}
        widgetMode={props.widgetMode}
        subjectGroupIds={props.subjectGroupIds}
        subjectIds={props.subjectIds}
        testPracticeFilter={props.testPracticeFilter}
        isBondLearner={props.isBondLearner}
      />
    </Widget>
  );
}
