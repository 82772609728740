import Spinner from 'century-core/core-components/Spinner/Spinner';
import { defaultLocale, defaultOrgType } from 'century-core/entities/Intl/defaultIntlValues';
import { TranslationDocument } from 'entities/TranslationDocument/TranslationDocument';
import { getTokenSettingsLocale } from 'century-core/core-auth/utils';
import { pathOr } from 'ramda';
import { ReactNode, useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { CustomFormats, IntlProvider } from 'react-intl';
import PolymerLoadedContext from '../../century-core/polymer/PolymerLoadedContext';
import { showContent, updateLocaleSettings } from './intlUtils';
import { useAuthContext } from 'century-core/core-auth/AuthContext';

const defaultMessage = {};

const Internationalize = (props: { children: ReactNode | ReactNode[] }) => {
  const [currentLocale, setCurrentLocale] = useState(defaultLocale);
  const [currentOrgType, setOrgCurrentOrgType] = useState(defaultOrgType);
  const [messages, setMessages] = useState(null as TranslationDocument | null);
  const { setTranslationsSet, translationsSet } = useContext(PolymerLoadedContext);
  const { auth, orgSettings } = useAuthContext();

  const updateMessagesIfRequired = useCallback(
    (newMessages: TranslationDocument) => {
      const newEntries = Object.entries(newMessages);
      if (newEntries.some(([messageId, messageValue]) => !messages || messages[messageId] !== messageValue)) {
        setMessages(newMessages);
      }
    },
    [messages]
  );

  useEffect(() => {
    let locale;
    const orgType = pathOr(defaultOrgType, ['settings', 'organisationType'], orgSettings);
    if (orgType && currentOrgType !== orgType) {
      setOrgCurrentOrgType(orgType);
    }

    locale = pathOr(defaultLocale, ['settings', 'defaultLocale'], orgSettings);

    if (auth.accessToken) {
      locale = getTokenSettingsLocale(auth);
    }

    if (locale !== currentLocale) {
      setCurrentLocale(locale);
    }
  }, [auth, currentLocale, currentOrgType, orgSettings]);

  useEffect(() => {
    updateLocaleSettings(auth, orgSettings, updateMessagesIfRequired).then(() => {
      if (setTranslationsSet && !translationsSet) {
        setTranslationsSet(true);
      }
    });
  }, [currentLocale, currentOrgType, auth, orgSettings, setTranslationsSet, translationsSet, updateMessagesIfRequired]);

  const formats = useMemo(
    (): CustomFormats => ({
      number: {
        GBP: {
          currency: 'GBP',
          style: 'currency',
        },
      },
    }),
    []
  );

  return (
    <IntlProvider formats={formats} locale={currentLocale} messages={messages || defaultMessage} defaultLocale="en-GB">
      <Spinner type={'fullpage'} showContent={showContent(messages || defaultMessage)}>
        {props.children}
      </Spinner>
    </IntlProvider>
  );
};

export default Internationalize;
