import { ErrorCode, FetchError, mergeHeaders } from './utils';

export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface ReqOptions extends RequestInit {
  headers?: Record<string, string>;
  body?: any;
  method?: HTTPMethods;
}

const defaultOptions = {
  redirect: 'follow',
  credentials: 'omit',
  method: HTTPMethods.GET,
} as const;

const methodsWithBody = [HTTPMethods.POST, HTTPMethods.PUT, HTTPMethods.PATCH];

/**
 * @deprecated using this function introduce a lot of boilerplate/scaffolding line of code, please use the GET, POST, PUT, PATCH, DELETE functions
 *
 * Wrapper function for the fetch API
 * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 *
 *  - handles non 2xx error codes
 *  - normalises headers to loweCase
 *  - sets default values
 *  - handles JSON conversion
 *
 * Usage
 * ```
 * reqJSON({
 *   url: string;
 *   headers?: { [key: string]: string };
 *   method?: HTTPMethods;
 *   body?: object | string
 * })
 * ```
 */
export function reqJSON<T>(url: string, opts: ReqOptions = {}, includeHeaders: boolean = false): Promise<Response & { data: T }> {
  assertFetchAvailable();

  const options = {
    ...defaultOptions,
    ...opts,
    body: opts.body && typeof opts.body !== 'string' && !(opts.body instanceof File) ? JSON.stringify(opts.body) : opts.body,
    headers: mergeHeaders({ accept: 'application/json' }, opts.headers),
    mode: opts.mode,
  };

  if (methodsWithBody.includes(options.method)) {
    if (!options.body) {
      throw new FetchError('Missing body').setCode(ErrorCode.BodyRequired);
    }

    // only add application/json if no content-type has been provided
    options.headers = mergeHeaders({ 'Content-Type': 'application/json' }, options.headers);
  }

  return fetch(url, options).then((response: Response) => handleJSONResponse(response, includeHeaders));
}

/**
 * Throw error if we're using a crappy browser and fetch doesn't exist
 */
function assertFetchAvailable() {
  if (!('fetch' in window)) {
    throw new FetchError('fetch is not available in this browser').setCode(ErrorCode.FetchNotSupported);
  }
}

/**
 * Decodes the JSON body and returns the response with that data
 */
async function handleJSONResponse(response: Response, includeHeaders: boolean = false): Promise<any> {
  // Some calls, like PUT methods to S3, returns and empty data inside response and that would break response.json()
  const data = await response
    .json()
    .then(res => res)
    .catch(err => ({}));

  // Data could be empty, as specified above, but the response object still has all its attr, like `ok`.
  // FIXME this is used for showing errors in app, but it's not very useful for logging Sentry errors. Need to rework these later.
  if (!response.ok) {
    throw new FetchError(data.message || 'Unable to fetch').setCode(response.status).setData(data);
  }

  // {...response} technically retrieves an empty object so a bit useless now
  let responseToRetrieve = { ...response, data };

  if (includeHeaders) {
    responseToRetrieve = { ...responseToRetrieve, headers: response.headers };
  }

  return responseToRetrieve;
}
