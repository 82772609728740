import { useMemo } from 'react';
import * as React from 'react';
import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import './ChartD3.scss';

export interface ChartData {
  x: string;
  y: number;
}

interface Props {
  data: ChartData[];
  width: number;
  height: number;
  formatTooltip?: (data: ChartData) => JSX.Element;
  formatXVals?: (datum: string | number) => string;
  formatYVals?: (datum: string | number) => string;
  xAxisLabel: string;
  yAxisLabel?: string;
  yAxisMax?: number;
}

const getXValue = (datum: ChartData) => datum.x;
const getYValue = (datum: ChartData) => datum.y;

export default function BarChartD3(props: Props) {
  const { data, width, height } = props;
  const marginTop = 20;
  const marginBottom = 50;
  const leftMargin = 50;
  const rightMargin = 20;
  const xMax = width - leftMargin - rightMargin;
  const yMax = height - marginBottom - marginTop;
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<ChartData>();

  const handleMouseOver = (event: React.MouseEvent<SVGRectElement, MouseEvent>, datum: ChartData, xPos: number, width: number) => {
    const coords = localPoint(event) || { x: 0, y: 0 };
    showTooltip({
      tooltipLeft: xPos + width,
      tooltipTop: coords.y,
      tooltipData: datum
    });
  };

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand<string | number>({
        range: [0, xMax],
        round: true,
        domain: data.map(getXValue),
        padding: 0.4,
      }),
    [xMax, data],
  );
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        // want a max of at least 5 if no max provided
        domain: props.yAxisMax ? [0, props.yAxisMax] : [0, Math.max(Math.ceil(Math.max(...data.map(getYValue))), 5)],
      }),
    [yMax, props.yAxisMax, data],
  );

  const xAxisScale = scaleBand<string | number>({
    domain: data.map(getXValue),
    padding: 0.4,
  });

  const yAxisScale = scaleLinear<number>({
    // want a max of at least 5 if no max provided
    domain: props.yAxisMax ? [0, props.yAxisMax] : [0, Math.max(Math.ceil(Math.max(...data.map(getYValue))), 5)],
  });

  xAxisScale.rangeRound([0, xMax]);
  yAxisScale.range([yMax, 0]);

  return (
    <div className="chart-d3" style={{ position: 'relative' }}>
      <svg width={width} height={height}>
        <rect width={width} height={height} className="chart-d3__background" />
        <Group top={marginTop} left={leftMargin}>
          <GridRows scale={yAxisScale} width={xMax} height={yMax} stroke="#e0e0e0" className="chart-d3__axis-line" numTicks={yScale.ticks().filter(Number.isInteger).length} />
          {data.map((d, i) => {
            const xVal = getXValue(d)
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - (yScale(getYValue(d)) ?? 0);
            const barX = xScale(xVal) || 0;
            const barY = yMax - barHeight;
            return (
              <Bar
                style={{ height: `${barHeight}px` }}
                key={`bar-${i}`}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill="#0bacfe"
                onPointerMove={(e) => handleMouseOver(e, d, barX, barWidth)}
                onClick={(e) => handleMouseOver(e, d, barX, barWidth)}
                onMouseLeave={hideTooltip}
                className="chart-d3__bar"
              />
            );
          })}
          <AxisBottom
            axisClassName="chart-d3__axis-group"
            axisLineClassName="chart-d3__axis-line"
            scale={xAxisScale}
            top={yMax}
            hideZero={true}
            label={props.xAxisLabel}
            labelProps={{ fontSize: 14, x: (width - (width / 10) - rightMargin), textAnchor: 'end' }}
            tickClassName="chart-d3__tick"
            tickFormat={(datum: string) => props.formatXVals ? props.formatXVals(datum) : datum}
          />
          <AxisLeft
            axisClassName="chart-d3__axis-group"
            axisLineClassName="chart-d3__axis-line"
            hideZero={true}
            tickClassName="chart-d3__tick"
            // the values passed to ticks() here is the number of ticks to be rendered
            tickValues={yScale.ticks(height <= 200 ? 5 : undefined).filter(Number.isInteger)}
            tickFormat={(datum: number) => props.formatYVals ? props.formatYVals(datum) : `${datum}`}
            scale={yAxisScale}
            label={props.yAxisLabel}
            labelOffset={25}
            labelProps={{ fontSize: 14, textAnchor: 'middle' }}
          />

        </Group>
      </svg>
      {tooltipOpen && (
        <TooltipWithBounds
          className="chart-d3__tooltip"
          // set this to random so it correctly updates with parent bounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          unstyled={true}
          applyPositionStyle={true}
        >
          {props.formatTooltip
            ? props.formatTooltip(tooltipData as ChartData)
            : `${tooltipData?.x}: ${tooltipData?.y}`
          }
        </TooltipWithBounds>
      )}
    </div>
  )
}
