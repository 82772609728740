import { CompletionPercentage, QlaLevel1, ScorePercentage } from '@ctek/design-system';
import CenturyExactTime from 'century-core/core-components/CenturyTime/CenturyExactTime';
import { Collapse } from 'react-collapse';
import { FormattedMessage } from 'react-intl';
import { getPercentValue,getDDMYYYYHHmmDate } from 'century-core/core-utils/utils/helpers';
import { QLAAttempt } from '../Attempt/QLAAttemptProvider';
import QlaLv2Material from './QlaLv2Material';
import QlaLv2Table from './QlaLv2Table';

interface Props {
  attempt: Ctek.AbacusStudySessionActivity;
  canInspect: boolean;
  order: number;
  expanded: boolean;
  onExpand: () => void;
  loading: boolean;
  qlaAttempt: QLAAttempt | null | undefined;
  initialQuestionIndex?: number;
}

export default function QlaLv1({ attempt, canInspect, order, expanded, onExpand, loading, qlaAttempt, initialQuestionIndex }: Props) {
  return (
    <QlaLevel1>
      <QlaLevel1.Body>
        <QlaLevel1.Row isOpen={expanded} qa="qla-attempt">
          <QlaLevel1.Summary onClick={onExpand} isOpen={expanded} expandable={canInspect} isActive={loading}>
            <QlaLevel1.ColAttempt expandable={canInspect} isOpen={expanded}>
              {order}
            </QlaLevel1.ColAttempt>
            <QlaLevel1.ColCompletion>
              <CompletionPercentage percentage={getPercentValue(attempt.percentComplete)} />
            </QlaLevel1.ColCompletion>
            <QlaLevel1.ColScore>
              {attempt.isClosed ? <ScorePercentage percentage={getPercentValue(attempt.percentScore)} /> : null}
            </QlaLevel1.ColScore>
            <QlaLevel1.ColCorrect>
              {attempt.isClosed ? (
                <FormattedMessage
                  id="question-level-analysis-attempt-detail-question-stat"
                  defaultMessage="{correct}/{total}"
                  values={{
                    correct: attempt.questionsCorrect,
                    total: attempt.questionsInNugget,
                  }}
                />
              ) : null}
            </QlaLevel1.ColCorrect>
            <QlaLevel1.ColMarks>
              {attempt.isClosed ? (
                <FormattedMessage
                  id="question-level-analysis-attempt-detail-points-stat"
                  defaultMessage="{correct}/{total}"
                  values={{
                    correct: attempt.pointsAchieved,
                    total: attempt.pointsPossible,
                  }}
                />
              ) : null}
            </QlaLevel1.ColMarks>
            <QlaLevel1.ColActive>
              <CenturyExactTime time={attempt.durationActual} shortenUnits={true} />
            </QlaLevel1.ColActive>
            <QlaLevel1.Col>{getDDMYYYYHHmmDate(attempt.openTime.toString())}</QlaLevel1.Col>
            <QlaLevel1.Col>
              {attempt.isClosed ? (
                getDDMYYYYHHmmDate(attempt.closeTime.toString())
              ) : (
                <FormattedMessage id="question-level-analysis-attempt-detail-not-closed" defaultMessage="Not yet closed" />
              )}
            </QlaLevel1.Col>
          </QlaLevel1.Summary>
          <Collapse isOpened={expanded} theme={{ collapse: 'qla-level-1-row__collapse', content: 'qla-level--row__collapse-content' }}>
            <QlaLevel1.RowContent isOpen={expanded}>
              <QlaLv2Material attempt={qlaAttempt} />
              <QlaLv2Table attempt={qlaAttempt} initialQuestionIndex={initialQuestionIndex} />
            </QlaLevel1.RowContent>
          </Collapse>
        </QlaLevel1.Row>
      </QlaLevel1.Body>
    </QlaLevel1>
  );
}
