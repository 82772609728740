import * as React from 'react';
import { useDragLayer } from 'react-dnd';

export interface CustomDragLayerProps {
  elementWidthReferenceElement: string;
  DragLayerElement: React.SFC<any>;
}

const CustomDragLayer = (props: CustomDragLayerProps) => {
  const { currentOffset, isDragging, item } = useDragLayer(monitor => ({
    currentOffset: monitor.getSourceClientOffset(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
  }));

  if (!currentOffset || !item || !item.item || !isDragging) {
    return null;
  }
  const { x, y } = currentOffset;

  // Calculate width of element based off one of the already rendered labels
  const elementWidth = document.querySelector(props.elementWidthReferenceElement);
  if (!elementWidth) {
    return null;
  }
  const widthOfLabel = elementWidth.clientWidth;

  const itemTransform = `translate(${x}px, ${y}px)`;
  // This Custom layer is required to render the drag preview when a touch screen is used.
  const layerStyles: React.CSSProperties = {
    height: '100%',
    left: 0,
    pointerEvents: 'none',
    position: 'fixed',
    top: 0,
    transform: itemTransform,
    width: widthOfLabel, // Hack need to work out a way to size the object exactly as before.
    zIndex: 100,
  };

  const { DragLayerElement } = props;

  return isDragging ? (
    <div style={layerStyles}>
      <DragLayerElement item={item.item} />
    </div>
  ) : null;
};

export default CustomDragLayer;
