import { HTTPMethods, ReqOptions, reqJSON } from '../fetch';
import * as sessionStorage from 'century-core/core-utils/utils/sessionStorage/sessionStorage';

type CachedResponse<T> = { data?: T; cacheSet?: number; isCacheValid?: boolean };

type RequestOptions<T> = {
  url: URL | string;
  body?: T;
  token?: string;
  signal?: AbortSignal;
  customHeaders?: Record<string, string>;
  sessionStorageCache?: boolean;
};

/**
 * Helper function to make a request and keep it DRY
 */
const request = async <Response, Payload = unknown>(options: RequestOptions<Payload> & { method: HTTPMethods }) => {
  const { url, token, customHeaders, sessionStorageCache, ...restOptions } = options;

  if (sessionStorageCache) {
    const cachedResponse = getSessionCache(url.toString());

    if (cachedResponse.isCacheValid) {
      return cachedResponse.data as Response;
    }
  }

  const authHeader = token ? { authorization: `Bearer ${token}` } : undefined;
  const headers = {
    ...customHeaders,
    ...authHeader,
  };
  const reqOpts = {
    ...restOptions,
    headers,
  } as ReqOptions;

  const response = await reqJSON<Response>(url.toString(), reqOpts).then(res => res.data);

  if (sessionStorageCache) {
    saveSessionCache(url.toString(), response);
  }

  return response;
};

const generateSessionCacheKey = (url: string): string => {
  return `request__${url.toString()}__${window.location.pathname}`;
};

const saveSessionCache = <T>(url: string, response: T): void => {
  const currentTimeStamp = Math.floor(Date.now() / 1000);
  const responseToCache: CachedResponse<T> = {
    data: response,
    cacheSet: currentTimeStamp,
  };
  const sessionCacheKey = generateSessionCacheKey(url);
  sessionStorage.write(sessionCacheKey, JSON.stringify(responseToCache));
};

const getSessionCache = <T>(url: string): CachedResponse<T> & {} => {
  const sessionCacheKey = generateSessionCacheKey(url);
  const cacheStr = sessionStorage.read(sessionCacheKey);

  let cachedResponse: CachedResponse<T> = { isCacheValid: false };
  if (cacheStr) {
    cachedResponse = { ...cachedResponse, ...JSON.parse(cacheStr) };

    if (cachedResponse.cacheSet) {
      const currentTimeStamp = Math.floor(Date.now() / 1000);
      const threshold = 60; // 1 minute
      const numSeconds = Math.abs(currentTimeStamp - cachedResponse.cacheSet);
      if (numSeconds < threshold) {
        cachedResponse = { ...cachedResponse, isCacheValid: true };
      }
    }
  }

  return cachedResponse;
};

export const GET = async <Response>(options: RequestOptions<never>) => request<Response>({ ...options, method: HTTPMethods.GET });

export const POST = async <Response, Payload = unknown>(options: RequestOptions<Payload>) =>
  request<Response, Payload>({ ...options, method: HTTPMethods.POST });

export const PATCH = async <Response, Payload = unknown>(options: RequestOptions<Payload>) =>
  request<Response, Payload>({ ...options, method: HTTPMethods.PATCH });

export const PUT = async <Response, Payload = unknown>(options: RequestOptions<Payload>) =>
  request<Response, Payload>({ ...options, method: HTTPMethods.PUT });

export const DELETE = async <Response, Payload = unknown>(options: RequestOptions<never>) =>
  request<Response, Payload>({ ...options, method: HTTPMethods.DELETE });
