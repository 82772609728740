import { useAuth } from 'century-core/core-auth/hooks/useAuth';
import { getTokenLoginSessionId, getTokenMainOrgId, getTokenSub, hasFeature } from 'century-core/core-auth/utils';
import { hasGuardianRole, hasLearnerRole } from 'century-core/core-auth/utils';
import { createContext, useCallback, useEffect, useState, useRef, useContext } from 'react';
import { useLearnerProductContext } from './LearnerProductContext';
import { useCurrentSubscription } from './SubscriptionContext';
import { UserCurrentSubscription, UserSubscriptionHistory } from 'century-core/core-subscription/types';
import { useB2C } from 'century-core/core-utils/hooks/useB2C';
import * as LogRocket from 'logrocket';

export enum LogRocketVerificationType {
  None = 'None',
  Core = 'Core',
  RegistrationOrSubscribing = 'Registration or Subscribing',
  B2CLearners = 'B2C Learners',
  B2CLearnersTrialOnly = 'B2C Learners Trial Only',
  B2CGuardians = 'B2C Guardians',
  B2CGuardiansTrialOnly = 'B2C Guardians Trial Only',
}

interface LogrocketContextProviderProps {
  children: React.ReactNode;
}
interface LogrocketContextValue {
  setInitiateLogrocketVerification: (value: LogRocketVerificationType) => void;
}

type ProjectType = 'core' | 'b2c';

const projectConfigMap: Record<ProjectType, { prod: string; dev: string }> = {
  core: { prod: 'webapp-prod-ib8at', dev: 'webapp-dev-tqqae' },
  b2c: { prod: 'b2c-prod', dev: 'b2c-dev' },
};

const defaultLogRocketContextValue: LogrocketContextValue = {
  setInitiateLogrocketVerification: () => LogRocketVerificationType.None,
};

export const LogrocketContext = createContext<LogrocketContextValue>(defaultLogRocketContextValue);

export const LogrocketContextProvider = (props: LogrocketContextProviderProps) => {
  const auth = useAuth();
  const isLogRocketEnabledByFlag = hasFeature(auth, 'log-rocket', 'core');
  const isLogRocketEnabledForB2CAll = hasFeature(auth, 'log-rocket', 'b2c');
  const isLogRocketEnabledForB2CRegistration = hasFeature(auth, 'log-rocket', 'b2c-registration');
  const isLogRocketEnabledForB2CInactiveGuardian = hasFeature(auth, 'log-rocket', 'b2c-inactive-guardian');
  const isLogRocketEnabledForB2CTrialOnly = hasFeature(auth, 'log-rocket', 'b2c-trial-only');

  const isB2C = useB2C();
  const isGuardian = hasGuardianRole(auth);
  const isLearner = hasLearnerRole(auth);

  const [initiateLogrocketVerification, setInitiateLogrocketVerification] = useState<LogRocketVerificationType>(
    LogRocketVerificationType.None
  );
  const { product } = useLearnerProductContext();
  const userSubscription = useCurrentSubscription();
  const isB2CRegistrationRoute = window.location.pathname.includes('/registration') || window.location.pathname.includes('/bond');

  const initializedSessionURL = useRef('');

  useEffect(() => {
    // For Registration
    if (isB2CRegistrationRoute && isLogRocketEnabledForB2CRegistration) {
      setInitiateLogrocketVerification(LogRocketVerificationType.RegistrationOrSubscribing);
      return;
    }

    // For Core
    if (isLogRocketEnabledByFlag) {
      setInitiateLogrocketVerification(LogRocketVerificationType.Core);
      return;
    }

    if (!isB2C) {
      return;
    }

    // For B2C learners
    if (isLearner) {
      // If both All Learners and Learner On Trial Only feature flags are on by accident that, default to Learner On Trial Only
      if (isLogRocketEnabledForB2CTrialOnly) {
        setInitiateLogrocketVerification(LogRocketVerificationType.B2CLearnersTrialOnly);
      } else if (isLogRocketEnabledForB2CAll) {
        setInitiateLogrocketVerification(LogRocketVerificationType.B2CLearners);
      }
    }

    // For B2C Guardians
    if (isGuardian && userSubscription) {
      const V2GuardianCurrentSubscription = userSubscription as UserCurrentSubscription;
      // If active V2 guardian
      if (V2GuardianCurrentSubscription.active) {
        // If both All Guardians and Guardians On Trial Only feature flags are on by accident that, default to Guardians On Trial Only
        if (isLogRocketEnabledForB2CTrialOnly) {
          setInitiateLogrocketVerification(LogRocketVerificationType.B2CGuardiansTrialOnly);
        } else if (isLogRocketEnabledForB2CAll) {
          setInitiateLogrocketVerification(LogRocketVerificationType.B2CGuardians);
        }
      } else {
        // If inactive V2 guardian - Only initiate session for guardians subscribing, not for V1 guardians
        const nonV2SubscribedGuardian = userSubscription as unknown as UserSubscriptionHistory;
        if (!nonV2SubscribedGuardian.subscription.active && isLogRocketEnabledForB2CInactiveGuardian) {
          return setInitiateLogrocketVerification(LogRocketVerificationType.RegistrationOrSubscribing);
        }
      }
    }
  }, [
    isB2CRegistrationRoute,
    isB2C,
    isLogRocketEnabledByFlag,
    isLogRocketEnabledForB2CAll,
    isLogRocketEnabledForB2CInactiveGuardian,
    isLogRocketEnabledForB2CRegistration,
    isLogRocketEnabledForB2CTrialOnly,
    isLearner,
    isGuardian,
    userSubscription,
    initiateLogrocketVerification,
  ]);

  const initLogRocket = useCallback(
    (projectType: ProjectType = 'core', isOnTrial: boolean): void => {
      if (!initializedSessionURL.current) {
        const project = window.ENV === 'prod' ? projectConfigMap[projectType].prod : projectConfigMap[projectType].dev;
        LogRocket.init(`uf0vj9/${project}`, {
          release: window.RELEASE_VERSION,
          shouldCaptureIP: false,
        });
        if (auth.accessToken) {
          LogRocket.identify(getTokenSub(auth), {
            isTest: !!auth.accessTokenData?.context.isTest,
            loginSessionId: getTokenLoginSessionId(auth),
            primaryOrganisation: getTokenMainOrgId(auth),
            logRocketInitiationType: initiateLogrocketVerification,
            isTrial: isB2C ? isOnTrial : 'N/A',
          });
        }
        LogRocket.getSessionURL(sessionURL => {
          initializedSessionURL.current = sessionURL;
        });
      }
    },
    [auth, initiateLogrocketVerification, isB2C]
  );

  useEffect(() => {
    // For Core or Registration
    if (
      initiateLogrocketVerification === LogRocketVerificationType.Core ||
      initiateLogrocketVerification === LogRocketVerificationType.RegistrationOrSubscribing
    ) {
      initLogRocket(initiateLogrocketVerification === LogRocketVerificationType.Core ? 'core' : 'b2c', false);
    }

    // For B2C active V2 guardians
    if (isGuardian && userSubscription && userSubscription.active) {
      const isUserOnTrial =
        (userSubscription as UserCurrentSubscription).trialEndDate === (userSubscription as UserCurrentSubscription).nextInvoiceDueAt;

      if (initiateLogrocketVerification === LogRocketVerificationType.B2CGuardiansTrialOnly && isUserOnTrial) {
        initLogRocket('b2c', isUserOnTrial);
      }
      if (initiateLogrocketVerification === LogRocketVerificationType.B2CGuardians) {
        initLogRocket('b2c', isUserOnTrial);
      }
    }

    // For B2C Learners
    if (isLearner && product) {
      if (initiateLogrocketVerification === LogRocketVerificationType.B2CLearnersTrialOnly) {
        if (product.trialling) {
          initLogRocket('b2c', product.trialling);
        }
      } else if (initiateLogrocketVerification === LogRocketVerificationType.B2CLearners) {
        initLogRocket('b2c', product.trialling);
      }
    }
  }, [initiateLogrocketVerification, isGuardian, isLearner, product, userSubscription, initLogRocket]);

  return <LogrocketContext.Provider value={{ setInitiateLogrocketVerification }}>{props.children}</LogrocketContext.Provider>;
};

export const useLogRocket = () => useContext(LogrocketContext);
