import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { Errors } from 'century-core/entities/ErrorMessage/Errors';
import { Actions, ActionTypes } from '../../actions/dependants/dependants';

export interface Dependants {
  dependants: string[] | Ctek.User[];
  loaded: boolean;
  error: ErrorMessage<Errors> | null;
}

const defaultData = {
  dependants: [],
  error: null,
  loaded: false,
};

export function dependants(state = defaultData, action: Actions): Dependants {
  switch (action.type) {
    case ActionTypes.Loading:
      return { ...state, loaded: false };
    case ActionTypes.Loaded:
      return { ...state, loaded: true, dependants: action.dependants };
    case ActionTypes.Error:
      return { ...state, loaded: true, error: action.error };
    default:
      return state;
  }
}

export default Dependants;
