import { Feedback, FeedbackContexts } from 'century-core/typings/Feedback';
import { getLearnURL } from '../learn/learn';
import { GET, POST } from '../utils';

export function loadFeedbackContexts(accessToken: string) {
  const url = getLearnURL('/feedback/contexts');
  return GET<FeedbackContexts>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to load feedback contexts, ${err}`);
  });
}

export function sendFeedback(feedback: Feedback, accessToken: string) {
  const url = getLearnURL('/reports');

  return POST<{ email: string; status: string }[]>({ url, token: accessToken, body: feedback }).catch((err: Error) => {
    throw Error(`Unable to submit feedback, ${err}`);
  });
}
