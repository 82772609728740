import { ChangeEvent } from 'react';
import { FilterSelect, FilterToolbar, ToggleGroup, FilterToggleGroup } from '@ctek/design-system';
import { useTranslateSubject } from 'century-core/core-components/Subject/SubjectLabel';
import { SelectOption } from 'century-core/entities/SelectOption/SelectOption';
import { FormattedMessage, useIntl } from 'react-intl';
import { ShowFilter, prefix } from './StudentDashboardAssignmentsV1';

interface Props {
  subjects: SelectOption[];
  teacherList: SelectOption[];
  subjectId: string;
  teacherId: string;
  isTeach: boolean;
  selectedType: string;
  onSubjectChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onChangeTeacher: (e: ChangeEvent<HTMLSelectElement>) => void;
  onTypeChange: (type: ShowFilter) => void;
}

export default function StudentDashboardAssignmentsFilters(props: Props) {
  const { subjects, teacherList, subjectId, teacherId, isTeach, selectedType, onTypeChange, onSubjectChange, onChangeTeacher } = props;
  const translateSubject = useTranslateSubject();
  const intl = useIntl();

  return (
    <>
      <FilterToolbar.Block>
        <FilterSelect qa="subject-filter">
          <FilterSelect.Label data-testid="dropdown-subject-filter-title">
            <FormattedMessage id="assignments-sort-subject" defaultMessage="Subject" />
          </FilterSelect.Label>
          <select value={subjectId} onChange={onSubjectChange} data-testid="subject-dropdown">
            <option value="ALL" data-testid="subject-select-option">
              {intl.formatMessage({ id: 'all', defaultMessage: 'All' })}
            </option>
            {subjects
              ? subjects.map(subject => (
                  <option key={subject.value} value={subject.value} data-testid="subject-select-option">
                    {translateSubject(subject.title)}
                  </option>
                ))
              : null}
          </select>
        </FilterSelect>
      </FilterToolbar.Block>
      {isTeach && (
        <FilterToolbar.Block>
          <FilterSelect qa="filter-teacher">
            <FilterSelect.Label data-testid="dropdown-teacher-filter-title">
              <FormattedMessage id="assignments-tab-teacher-filter" defaultMessage="Teacher" />
            </FilterSelect.Label>
            <select value={teacherId} onChange={onChangeTeacher} data-testid="teacher-dropdown">
              <option value="ALL" data-testid="teacher-select-option">
                {intl.formatMessage({ id: 'all', defaultMessage: 'All' })}
              </option>
              {teacherList.map(teacher => (
                <option key={teacher.value} value={teacher.value} data-testid="teacher-select-option" data-private={true}>
                  {teacher.title}
                </option>
              ))}
            </select>
          </FilterSelect>
        </FilterToolbar.Block>
      )}
      <FilterToolbar.Block stretch={true}>
        <FilterToggleGroup qa="show-filter-toggle-group">
          <FilterToggleGroup.Label data-testid="toggle-filter-title">
            <FormattedMessage id={prefix + 'show-toggle'} defaultMessage="Show " />
          </FilterToggleGroup.Label>
          <ToggleGroup qa="show-filter">
            <ToggleGroup.Button active={selectedType === ShowFilter.ALL} onClick={() => onTypeChange(ShowFilter.ALL)} qa="show-filter-all">
              <FormattedMessage id={prefix + 'show-toggle-opts-all'} defaultMessage="All" />
            </ToggleGroup.Button>
            <ToggleGroup.Button
              active={selectedType === ShowFilter.ACTIVE}
              onClick={() => onTypeChange(ShowFilter.ACTIVE)}
              qa="show-filter-active"
            >
              <FormattedMessage id={prefix + 'show-toggle-opts-active'} defaultMessage="Active" />
            </ToggleGroup.Button>
            <ToggleGroup.Button
              active={selectedType === ShowFilter.PAST}
              onClick={() => onTypeChange(ShowFilter.PAST)}
              qa="show-filter-past"
            >
              <FormattedMessage id={prefix + 'show-toggle-opts-past'} defaultMessage="Past" />
            </ToggleGroup.Button>
          </ToggleGroup>
        </FilterToggleGroup>
      </FilterToolbar.Block>
    </>
  );
}
