import { withErrorBoundaryPartial } from 'century-core/core-components/ErrorBoundary/ErrorBoundary';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import CurrentCourseContext from 'century-core/features-dashboard/StudentDashboard/StudentDashboardCourses/CurrentCourseProvider';
import { LoadingSpinner, Text, ThumbnailList, Widget } from '@ctek/design-system';
import { CourseImage } from 'century-core/media/CourseImage';

export enum ActionsFocusesMode {
  ACTIONS,
  FOCUSES,
}

export interface ActionsProps {
  data: Ctek.AbacusCourseRecommendationPopulated[];
  isLoading: boolean;
  mode: ActionsFocusesMode;
}

function ActionsFocuses(props: ActionsProps) {
  const title = useMemo(
    () =>
      props.mode === ActionsFocusesMode.ACTIONS ? (
        <FormattedMessage id="dependant-overview-actions-header" defaultMessage="Stretch With" />
      ) : (
        <FormattedMessage id="dependant-overview-focuses-header" defaultMessage="Focus On To Improve" />
      ),
    [props.mode]
  );

  return (
    <Widget qa={props.mode === ActionsFocusesMode.ACTIONS ? 'actions' : 'focuses'}>
      <Widget.Header>
        <Widget.Title qa="actionsfocuses-title">{title}</Widget.Title>
      </Widget.Header>
      <CurrentCourseContext.Consumer>
        {value =>
          !props.isLoading ? (
            <Widget.Body qa="actionsfocuses-body">
              {!!props.data && props.data.length > 0 ? (
                <ThumbnailList>
                  {props.data.map((datum: Ctek.AbacusCourseRecommendationPopulated, i: number) => (
                    <ThumbnailList.Item key={datum.nuggetId} qa={`actionsfocuses-item-${i}`}>
                      <ThumbnailList.Thumbnail>
                        <CourseImage
                          item={{
                            subject: value?.course?.subject,
                            icon: value?.course?.icon,
                            iconCode: value?.course?.iconCode,
                            src: value?.course?.iconUrl,
                            name: datum.name,
                          }}
                        />
                      </ThumbnailList.Thumbnail>
                      <ThumbnailList.Label>{datum.name}</ThumbnailList.Label>
                    </ThumbnailList.Item>
                  ))}
                </ThumbnailList>
              ) : (
                <div data-testid="no-data-message">
                  <Text.Placeholder>
                    <FormattedMessage id="no-data" defaultMessage="Sorry, we don't have enough information for this yet" />
                  </Text.Placeholder>
                </div>
              )}
            </Widget.Body>
          ) : (
            <div data-testid="actionsfocuses-loading">
              <LoadingSpinner type={'widget'} />
            </div>
          )
        }
      </CurrentCourseContext.Consumer>
    </Widget>
  );
}

export default withErrorBoundaryPartial(ActionsFocuses);
