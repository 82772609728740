import { useContext, useState, useEffect } from 'react';
import { injectIntl, IntlShape, FormattedMessage } from 'react-intl';
import layout from 'layout';
import { getPercentValue, getDDMMMYYYYHHmmDate } from 'century-core/core-utils/utils/helpers';
import { withErrorBoundaryPartial } from 'century-core/core-components/ErrorBoundary/ErrorBoundary';
import { QLANuggetAttemptHistoryInformationContext } from '../QLANuggetAttemptHistoryInformationProvider';
import BarChartD3, { ChartData } from 'layout/charts/BarChartD3';
import { ParentSize } from '@visx/responsive';
import { isRTLLocaleFromTokenLocale } from 'century-core/core-utils/utils/intl/intl';
import { useAuth } from 'century-core/core-auth/hooks/useAuth';

interface Props {
  intl: IntlShape;
}

const QLA_MIN_COLUMNS = 4;

export const QLAChart = ({ intl: { formatMessage } }: Props) => {
  const auth = useAuth();
  const isRTLLocale: boolean = isRTLLocaleFromTokenLocale(auth);
  const nuggetAttemptHistoryInformation = useContext(QLANuggetAttemptHistoryInformationContext);
  const [graphData, setGraphData] = useState<ChartData[] | []>([]);
  const [attemptDates, setAttemptDates] = useState([] as string[]);

  useEffect(() => {
    if (nuggetAttemptHistoryInformation?.nuggetAttemptHistory) {
      const attempts = nuggetAttemptHistoryInformation?.nuggetAttemptHistory.results
        .filter(attempt => attempt.isClosed)
        .map(attempt => attempt.openTime.toString());
      const data: ChartData[] = nuggetAttemptHistoryInformation?.nuggetAttemptHistory.results
        .filter(attempt => attempt.isClosed)
        .map((attempt, i) => {
          return {
            x: `${i + 1}`,
            y: getPercentValue(attempt.percentScore),
          };
        });
      const diff = QLA_MIN_COLUMNS - data.length;
      // Want a minimum number of entries on the x axis
      const buffer =
        diff > 0
          ? Array(diff)
              .fill({})
              .map((datum, i) => ({ x: `${i + data.length + 1}`, y: 0 }))
          : [];
      setGraphData(data.concat(buffer));
      setAttemptDates(attempts);
    }
  }, [nuggetAttemptHistoryInformation?.nuggetAttemptHistory, formatMessage]);

  const formatTooltip = (datum: ChartData) => {
    const attempt = getDDMMMYYYYHHmmDate(attemptDates[Number(datum.x) - 1]);
    return (
      <>
        <div>
          {isRTLLocale ? (
            <span>
              <b>{`${datum.y}% `}</b>
              {formatMessage({ defaultMessage: 'Score:', id: 'qla-chart-tooltip-score-label' })}
            </span>
          ) : (
            <span>
              {formatMessage({ defaultMessage: 'Score:', id: 'qla-chart-tooltip-score-label' })}
              <b>{` ${datum.y}%`}</b>
            </span>
          )}
        </div>
        <div>
          {isRTLLocale ? (
            <span>
              <b>{`${attempt} `}</b>
              {formatMessage({ defaultMessage: 'Attempt:', id: 'qla-chart-tooltip-attempt-label' })}
            </span>
          ) : (
            <span>
              {formatMessage({ defaultMessage: 'Attempt:', id: 'qla-chart-tooltip-attempt-label' })}
              <b>{` ${attempt}`}</b>
            </span>
          )}
        </div>
      </>
    );
  };

  return graphData.length > 0 ? (
    <ParentSize>
      {({ width }) => (
        <BarChartD3
          width={width}
          height={160}
          data={graphData}
          formatTooltip={formatTooltip}
          xAxisLabel={formatMessage({ defaultMessage: 'Nugget Attempts', id: 'question-level-analysis-chart-x-axis-label' })}
          formatYVals={(datum: number) => `${datum}%`}
          yAxisMax={100}
        />
      )}
    </ParentSize>
  ) : (
    <layout.NoDataMessage>
      <FormattedMessage id="no-data" defaultMessage="Sorry, we don't have enough information for this yet" />
    </layout.NoDataMessage>
  );
};

export default withErrorBoundaryPartial(injectIntl(QLAChart));
