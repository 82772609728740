import { FormattedMessage } from 'react-intl';
import { getPercentValue } from 'century-core/core-utils/utils/helpers';
import CenturyExactTime from 'century-core/core-components/CenturyTime/CenturyExactTime';
import { AnalyticsRow, Text, ScorePercentage, CompletionPercentage, CourseInfo, Heading } from '@ctek/design-system';
import CourseImage from 'century-core/media/CourseImage';
import { SubjectColouredLabel } from 'century-core/core-components/Subject/SubjectLabel';

interface Props {
  course: Ctek.Roentgen.CoursePathItem;
}

export default function CoursesWidgetCourseCard(props: Props) {
  return (
    <AnalyticsRow>
      <AnalyticsRow.Details>
        <CourseInfo qa="widget--courses--course-info">
          <CourseInfo.Thumbnail>
            <CourseImage
              item={{
                iconCode: props.course.icon.iconCode,
                subject: {
                  _id: props.course.subject.labelId,
                  name: props.course.subject.name,
                },
                iconUrl: props.course.icon.url,
              }}
            />
          </CourseInfo.Thumbnail>
          <CourseInfo.Main>
            <SubjectColouredLabel subjectName={props.course.subject.name} />
            <Heading.ItemTitle>{props.course.name}</Heading.ItemTitle>
          </CourseInfo.Main>
        </CourseInfo>
      </AnalyticsRow.Details>
      <AnalyticsRow.Stat>
        <Text.Label>
          <FormattedMessage id="student-dashboard-courses-widget-completion" defaultMessage="Completion" />
        </Text.Label>
        <CompletionPercentage
          percentage={getPercentValue(props.course.activity.percentComplete)}
          completed={props.course.activity.completeNuggets}
          total={props.course.activity.totalNuggets}
        />
      </AnalyticsRow.Stat>
      <AnalyticsRow.Stat>
        <Text.Label>
          <FormattedMessage id="student-dashboard-average-last-score" defaultMessage="Average Last Score" />
        </Text.Label>
        <ScorePercentage percentage={getPercentValue(props.course.activity.percentScore)} hasSymbol={false} />
      </AnalyticsRow.Stat>
      <AnalyticsRow.Stat>
        <Text.Label>
          <FormattedMessage id="student-dashboard-time-spent" defaultMessage="Time Spent" />
        </Text.Label>
        <Text.Stat>
          <CenturyExactTime time={props.course.activity.durationActual} shortenUnits={true} />
        </Text.Stat>
      </AnalyticsRow.Stat>
    </AnalyticsRow>
  );
}
