import { Button } from '@ctek/design-system';
import { ErrorReload as CdsErrorReload } from '@ctek/design-system';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { ApolloError } from '@apollo/client';

interface ErrorReloadProps {
  action?: () => any;
  error?: string | Error | ApolloError;
  showActionButton?: boolean;
}

export class ErrorReload extends PureComponent<ErrorReloadProps> {
  public action() {
    return () => {
      const { action } = this.props;
      if (action) {
        action();
      } else {
        window.location.reload();
      }
    };
  }
  public render() {
    const { error, showActionButton = true } = this.props;

    return (
      <CdsErrorReload>
        <CdsErrorReload.Message>
          {(error as Error)?.message || (error as string) || <FormattedMessage id="error-header" defaultMessage="Error getting data." />}
        </CdsErrorReload.Message>
        {showActionButton && (
          <CdsErrorReload.Controls>
            <Button size="medium" onClick={this.action()} variant="primary">
              <FormattedMessage id="error-reload-button-label" defaultMessage="reload" />
            </Button>
          </CdsErrorReload.Controls>
        )}
      </CdsErrorReload>
    );
  }
}
