import {
  FlexGrid,
  FlexGridDirection,
  StatWidget,
  StatWidgetValueType,
  DashboardTestPracticePage,
  CourseTrackerMobile,
} from '@ctek/design-system';
import { FormattedMessage } from 'react-intl';
import { getPercentValue } from 'century-core/core-utils/utils/helpers';
import { ReactNode } from 'react';

// LX PHASE 1 - Once LX is completely rolled out to all BOP and BOPP users, remove this component.
export const StudentDashboardTestPractice = (props: {
  overviewHeader: ReactNode;
  coursePlanForCourse: any;
  nuggetTracker: ReactNode;
  activityWidget: ReactNode;
  nuggetsInCourse?: number;
}) => {
  return (
    <DashboardTestPracticePage>
      <FlexGrid>
        <FlexGrid.Col sm={4} md={4}>
          <FlexGrid direction={FlexGridDirection.COLUMN}>
            <FlexGrid.Col>{props.overviewHeader}</FlexGrid.Col>
            <FlexGrid.Col>
              <StatWidget>
                <StatWidget.Label>
                  <FormattedMessage id="nuggets-completed" defaultMessage="Nuggets Completed" />
                </StatWidget.Label>
                <StatWidget.Value type={StatWidgetValueType.COMPLETION}>
                  {props.nuggetsInCourse ? `${props.coursePlanForCourse.nuggetsCompleted || 0} / ${props.nuggetsInCourse}` : '0'}
                </StatWidget.Value>
              </StatWidget>
            </FlexGrid.Col>
            <FlexGrid.Col>
              <StatWidget>
                <StatWidget.Label>
                  <FormattedMessage id="student-dashboard-average-last-score" defaultMessage="Average Last Score" />
                </StatWidget.Label>
                <StatWidget.Value type={StatWidgetValueType.SCORE} scoreValue={props.coursePlanForCourse.percentScore}>
                  {getPercentValue(props.coursePlanForCourse.percentScore)}
                  <StatWidget.Unit>%</StatWidget.Unit>
                </StatWidget.Value>
              </StatWidget>
            </FlexGrid.Col>
            {/* Temporarily removed until data can be corrected or labelled more adequately
              <FlexGrid.Col>
                <StatWidget>
                  <StatWidget.Label>
                    <FormattedMessage id="student-dashboard-study-time" defaultMessage="Study Time" />
                  </StatWidget.Label>
                  <StatWidget.Value type={StatWidgetValueType.COMPLETION}>
                    <CenturyExactTime time={props.coursePlanForCourse.durationActual!} shortenUnits={true} />
                  </StatWidget.Value>
                </StatWidget>
              </FlexGrid.Col>
            */}
          </FlexGrid>
        </FlexGrid.Col>
        <FlexGrid.Col sm={8} md={8}>
          {props.activityWidget}
        </FlexGrid.Col>
        <FlexGrid.Col md={12}>
          <CourseTrackerMobile sticky={true}>{props.nuggetTracker}</CourseTrackerMobile>
        </FlexGrid.Col>
      </FlexGrid>
    </DashboardTestPracticePage>
  );
};
