import * as React from 'react';

import * as SentryLib from 'century-core/core-utils/lib/sentry';
import { FormattedMessage } from 'react-intl';
import { FallbackProps, withErrorBoundary } from 'react-error-boundary';
import { ErrorReload } from 'century-core/core-components/ErrorReload/ErrorReload';

const onError = (error: Error, info: { componentStack: string }) => {
  SentryLib.captureExceptionWithScope(error, 'error', { extra: { name: 'componentStack', value: info.componentStack } });
};

const PartialErrorComponent = () => <FormattedMessage id="error-boundary-partial-message" defaultMessage="Couldn't render this widget." />;
const pageErrorFallbackRenderer = ({ error }: FallbackProps) => <ErrorReload error={error} />;

export function withErrorBoundaryPartial<P>(Component: React.ComponentType<P>) {
  return withErrorBoundary<P>(Component, {
    FallbackComponent: PartialErrorComponent,
    onError,
  });
}

export function withErrorBoundaryPage<P>(Component: React.ComponentType<P>): React.ComponentType<P> {
  return withErrorBoundary<P>(Component, {
    fallbackRender: pageErrorFallbackRenderer,
    onError,
  });
}
