export interface Config {
  env: string;
  pubapiDomain: string;
  release: string;
}

export const env = {
  int: 'int',
  prod: 'prod',
  sta: 'sta',
};

export const int = {
  env: env.int,
  pubapiDomain: 'https://api.int.century.tech',
  release: 'webapp-v3-dev',
};

export const sta = {
  env: env.sta,
  pubapiDomain: 'https://api.sta.century.tech',
  release: 'webapp-v3-sta',
};

export const prod = {
  env: env.prod,
  pubapiDomain: 'https://api.century.tech',
  release: 'webapp-v3',
};

export default int;

const cfg = { int, sta, prod };

export function getConfig(): Config {
  return cfg[getAppEnv()] || int;
}

export function getAppEnv(): string {
  return (window as any).ENV;
}

export function getReleaseTagWithVersion(): string {
  return getReleaseTag() + '@' + getReleaseVersion();
}

export function getReleaseTag(): string {
  const config = getConfig();
  return config.release;
}

export function getReleaseVersion(): string {
  return (window as any).RELEASE_VERSION;
}

export function getSubDomain(): string {
  const subdomain = window.location.hostname;
  if (subdomain === 'localhost') {
    return 'app.century.tech';
  }
  return subdomain;
}

export function isProductionEnvironment(config?: Config): boolean {
  if (!config) {
    config = getConfig();
  }
  return config.env === cfg.prod.env;
}

export function showVersionButton(config: Config) {
  return isProductionEnvironment(config);
}
