import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import PromptAnswerPair, { PromptAnswerPairProps } from '../PromptAnswerPair/PromptAnswerPair';
import './PromptAnswerList.scss';

export interface Props {
  children: PromptAnswerListProps;
  qa?: string;
}

export interface PromptAnswerListData extends PromptAnswerPairProps {
  id: number | string | undefined;
}

export interface PromptAnswerListProps {
  items: PromptAnswerListData[];
}

export interface ComposablePromptAnswerListProps {
  qa?: string;
  children: { promptAnswerPairs: React.ReactNode[] };
}

export const ComposablePromptAnswerList = (props: ComposablePromptAnswerListProps) => {
  const { qa } = props;
  const { promptAnswerPairs } = props.children;

  return (
    <div className="prompt-answer-list" data-qa={qa} data-testid={qa}>
      <div className="prompt-answer-list__headings">
        <div className="prompt-answer-list__heading prompt-answer-list__heading--prompt">
          <FormattedMessage id="matching-cms-prompt-heading" defaultMessage="Prompt" />
        </div>
        <div className="prompt-answer-list__heading prompt-answer-list__heading--answer">
          <FormattedMessage id="matching-cms-answer-heading" defaultMessage="Answer" />
        </div>
      </div>
      <ul className="prompt-answer-list__item-list">{promptAnswerPairs}</ul>
    </div>
  );
};

const PromptAnswerList = (props: Props) => {
  return (
    <ComposablePromptAnswerList qa={props.qa}>
      {{
        promptAnswerPairs: props.children.items.map(item => (
          <li className="prompt-answer-list__item" key={item.id}>
            <PromptAnswerPair>
              {{
                itemType: item.itemType,
                pair: item.pair,
              }}
            </PromptAnswerPair>
          </li>
        )),
      }}
    </ComposablePromptAnswerList>
  );
};

export default PromptAnswerList;
