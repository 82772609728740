import { ExternalHyperlink, HeadingLevel, Paragraph, TextRun } from 'docx';
import { rehype } from 'rehype';
import { RootContent } from 'hast';
import { convertMarkdownToHtml } from 'century-core/core-utils/utils/markdown';

interface ParagraphOptions {
  type?: string;
  convertBoldedBlocksToHeadings?: boolean;
  listLevel?: number;
}

export const htmlToRehype = async (html: string) => {
  const output = await rehype().data('settings', { fragment: true, emitParseErrors: true, preferUnquoted: true }).parse(html);
  return output;
};

// catchall to ensure no text gets lost; unable to provide reliable typing options
const getRecursiveTextCollection = (content: any) => {
  try {
    if (content.type === 'text') {
      return content.value as string;
    }
    if (content.type === 'element') {
      return content.children!.map(getRecursiveTextCollection).join(' ');
    }
    return '';
  } catch {
    return '';
  }
};

const getFormattedParagraph = (childArray: RootContent[], options?: ParagraphOptions) => {
  return new Paragraph({
    children: childArray.map(c => {
      try {
        if (c.type === 'element' && c.tagName === 'a' && typeof c.properties?.href === 'string') {
          return new ExternalHyperlink({
            children: c.children.map(
              (ch: any) =>
                new TextRun({
                  text: ch?.text || ch?.value,
                  style: 'Hyperlink',
                })
            ),
            link: c.properties?.href,
          });
        }

        const collectedText = getRecursiveTextCollection(c);

        return new TextRun({
          text: collectedText,
          bold: c.tagName === 'strong',
          italics: c.tagName === 'em',
          subScript: c.tagName === 'sub',
          superScript: c.tagName === 'sup',
        });
      } catch (e) {
        return new Paragraph({});
      }
    }),
    style: 'paragraphSpacing',
    bullet: options?.listLevel ? { level: options.listLevel } : undefined,
    heading: options?.type === 'heading' ? HeadingLevel.HEADING_2 : undefined,
  });
};

export const getParagraphFromRootContent = (rootContent: RootContent, options?: ParagraphOptions) => {
  const rehypeNodeChildren = rootContent?.children as RootContent[];
  if (
    options?.convertBoldedBlocksToHeadings &&
    rehypeNodeChildren.length === 1 &&
    rehypeNodeChildren[0].type === 'element' &&
    rehypeNodeChildren[0].tagName === 'strong'
  ) {
    if (rehypeNodeChildren[0].children) {
      const newParagraph = new Paragraph({
        text: rehypeNodeChildren[0].children.map((c: any) => c.value).join('\n'),
        heading: HeadingLevel.HEADING_3,
        style: 'Heading',
      });
      return newParagraph;
    }
  }
  const formatted = getFormattedParagraph(rehypeNodeChildren, options);
  return formatted;
};

export const getParagraphListFromRootContent = (rootContent: RootContent, options?: ParagraphOptions) => {
  const rehypeNodeChildren = rootContent?.children as RootContent[];
  const listParagraph = rehypeNodeChildren
    .filter(v => v.type === 'element')
    .map((ch: RootContent) => {
      return getParagraphFromRootContent(ch, { ...options, listLevel: 1 });
    });
  return listParagraph;
};

export const getHTMLParagraph = async (mediaData: Ctek.SirTrevorContent, options?: ParagraphOptions): Promise<Paragraph[]> => {
  if (mediaData.format === 'html') {
    const text = mediaData.text;
    const parsed = await htmlToRehype(text);
    const children = parsed.children[0];
    if (children.tagName === 'ul') {
      const list = getParagraphListFromRootContent(children, options);
      return list;
    }
    if (children) {
      const paragraph = getParagraphFromRootContent(children, options);
      return [paragraph];
    }
  }
  throw Error('Format not html');
};

export const getMarkdownParagraph = async (mediaData: Ctek.SirTrevorContent, options?: ParagraphOptions): Promise<Paragraph[]> => {
  const markdownText = mediaData.text;
  const htmlText = convertMarkdownToHtml(markdownText);
  return await getHTMLParagraph({ ...mediaData, text: htmlText, format: 'html' }, options);
};

// uses the formatted HTML to ensure markdown formatting matches up, if none available converts
export const getTextContentParagraph = async (mediaData: Ctek.SirTrevorContent, options?: ParagraphOptions): Promise<Paragraph[]> => {
  if (mediaData.format === 'html') {
    return await getHTMLParagraph(mediaData, options);
  }
  if (mediaData.format === 'markdown') {
    return await getMarkdownParagraph(mediaData, options);
  }
  throw Error('Invalid text format provided');
};
