import * as React from 'react'
import { ConnectDropTarget, DropTargetMonitor } from 'react-dnd'
import { DropTarget } from 'react-dnd'
import './DropTarget.scss'

export interface DropTargetProps {
  accepts: string[];
  onDrop: (item: any) => void;
  dropItem: (isOver: boolean, canDrop: boolean) => React.ReactNode;
}

export interface ConnectedDropTargetProps extends DropTargetProps {
  // Collected Props
  canDrop: boolean;
  isOver: boolean;
  connectDropTarget: ConnectDropTarget;
  classNameExt?: string;
}

export const ConnectedDropTarget: React.FC<ConnectedDropTargetProps> = ({
  isOver,
  canDrop,
  classNameExt,
  connectDropTarget,
  dropItem,
}: ConnectedDropTargetProps) => {
  return connectDropTarget(
    <div className={`dnd-drop-target drop-target ${classNameExt ? classNameExt : ''}`}>{dropItem(isOver, canDrop)}</div>
  );
};

export default DropTarget(
  (props: DropTargetProps) => props.accepts,
  {
    drop(props: ConnectedDropTargetProps, monitor: DropTargetMonitor) {
      props.onDrop(monitor.getItem());
    },
  },
  (connect, monitor) => ({
    canDrop: monitor.canDrop(),
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  })
)(ConnectedDropTarget);
