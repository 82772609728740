import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { LearnerActivityMetricMode, LearnerActivityTimeMode } from './LearnerActivityWidget';
import {
  ToggleGroup,
  VanityStat,
  ViewToggleGroup,
  ViewToggleSizes,
  MetricIconTypes,
  WidgetFilters,
  WidgetSelect,
  StepperControl,
  Button,
  previousIcon,
  nextIcon,
} from '@ctek/design-system';
import CenturyExactTime from 'century-core/core-components/CenturyTime/CenturyExactTime';
import { DateTime } from 'luxon';
import { useDeviceInfo } from 'century-core/user-preferences/DeviceInfoContext';

interface Props {
  handleMetricModeChange: (e: LearnerActivityMetricMode) => void;
  handleTimeModeChange: (e: LearnerActivityTimeMode) => void;
  handleTimePeriodChange: (value: string) => void;
  handlePrevClick: () => void;
  handleNextClick: () => void;
  metricMode: LearnerActivityMetricMode;
  rangeOptions: JSX.Element[][];
  rangeIndex: number;
  startTime: string;
  timeMode: LearnerActivityTimeMode;
  nuggetCount: number;
  questionCount: number;
  timeCount: number;
  isFirst: boolean;
  isLast: boolean;
  isSubjectView: boolean;
}

export default function LearnerActivityWidgetControls(props: Props) {
  const intl = useIntl();
  const formattedTime = <CenturyExactTime time={props.timeCount} numSubUnits={1} shortenUnits={true} />;
  const { deviceType } = useDeviceInfo();

  const selectOpts = useMemo(
    () =>
      props.rangeOptions.map((rangeOpts, i) => {
        return props.timeMode !== LearnerActivityTimeMode.MONTH ? (
          <optgroup key={`optGroup-${i}`} label={`${DateTime.fromISO(rangeOpts[0]?.props.value).year}`}>
            {rangeOpts.map(r => r)}
          </optgroup>
        ) : (
          <>{rangeOpts.map(r => r)}</>
        );
      }),
    [props.rangeOptions, props.timeMode]
  );

  return (
    <>
      <WidgetFilters>
        <WidgetFilters.Block>
          <ToggleGroup>
            <ToggleGroup.Button
              active={props.timeMode === LearnerActivityTimeMode.DAY}
              onClick={() => props.handleTimeModeChange(LearnerActivityTimeMode.DAY)}
            >
              {intl.formatMessage({ id: 'learner-activity-widget-mode-week', defaultMessage: 'Week' })}
            </ToggleGroup.Button>
            <ToggleGroup.Button
              active={props.timeMode === LearnerActivityTimeMode.WEEK}
              onClick={() => props.handleTimeModeChange(LearnerActivityTimeMode.WEEK)}
            >
              {intl.formatMessage({ id: 'learner-activity-widget-mode-month', defaultMessage: 'Month' })}
            </ToggleGroup.Button>
            <ToggleGroup.Button
              active={props.timeMode === LearnerActivityTimeMode.MONTH}
              onClick={() => props.handleTimeModeChange(LearnerActivityTimeMode.MONTH)}
            >
              {intl.formatMessage({ id: 'learner-activity-widget-mode-year', defaultMessage: 'Year' })}
            </ToggleGroup.Button>
          </ToggleGroup>
        </WidgetFilters.Block>
        <WidgetFilters.Block>
          <WidgetSelect>
            <select value={props.startTime} onChange={e => props.handleTimePeriodChange(e.target.value)}>
              {selectOpts}
            </select>
          </WidgetSelect>
        </WidgetFilters.Block>
        <WidgetFilters.Block>
          <StepperControl>
            <Button
              icon={previousIcon}
              size="small"
              variant="muted"
              type="button"
              aria="Previous"
              onClick={props.handlePrevClick}
              disabled={props.isFirst}
            />
            <Button
              icon={nextIcon}
              size="small"
              variant="muted"
              type="button"
              aria="Next"
              onClick={props.handleNextClick}
              disabled={props.isLast}
            />
          </StepperControl>
        </WidgetFilters.Block>
      </WidgetFilters>

      <div>
        <ViewToggleGroup size={deviceType !== 'phone' ? ViewToggleSizes.LARGE : ViewToggleSizes.SMALL}>
          <ViewToggleGroup.Button
            active={props.metricMode === LearnerActivityMetricMode.NUGGETS}
            onClick={() => props.handleMetricModeChange(LearnerActivityMetricMode.NUGGETS)}
            size={deviceType !== 'phone' ? ViewToggleSizes.LARGE : ViewToggleSizes.SMALL}
          >
            <VanityStat {...(deviceType !== 'phone' && { icon: MetricIconTypes.NUGGETS })}>
              <VanityStat.Value> {props.nuggetCount} </VanityStat.Value>
              <VanityStat.Label>
                {props.isSubjectView
                  ? intl.formatMessage({ id: 'learner-activity-widget-metric-nuggets-studied', defaultMessage: 'Nuggets Studied' })
                  : intl.formatMessage({ id: 'learner-activity-widget-metric-nuggets', defaultMessage: 'Nuggets Completed' })}
              </VanityStat.Label>
            </VanityStat>
          </ViewToggleGroup.Button>
          {/* <ViewToggleGroup.Button
            active={props.metricMode === LearnerActivityMetricMode.QUESTIONS}
            onClick={() => props.handleMetricModeChange(LearnerActivityMetricMode.QUESTIONS)}
            size={ViewToggleSizes.LARGE}
          >
            <VanityStat icon={MetricIconTypes.QUESTIONS} >
              <VanityStat.Value> {props.questionCount} </VanityStat.Value>
              <VanityStat.Label>
                {intl.formatMessage({ id: 'learner-activity-widget-metric-questions', defaultMessage: 'Questions Answered' })}
              </VanityStat.Label>
            </VanityStat>
          </ViewToggleGroup.Button> */}
          <ViewToggleGroup.Button
            active={props.metricMode === LearnerActivityMetricMode.TIME}
            onClick={() => props.handleMetricModeChange(LearnerActivityMetricMode.TIME)}
            size={deviceType !== 'phone' ? ViewToggleSizes.LARGE : ViewToggleSizes.SMALL}
          >
            <VanityStat {...(deviceType !== 'phone' && { icon: MetricIconTypes.TIME })}>
              <VanityStat.Value> {formattedTime} </VanityStat.Value>
              <VanityStat.Label>
                {intl.formatMessage({ id: 'learner-activity-widget-metric-time', defaultMessage: 'Time Spent Studying' })}
              </VanityStat.Label>
            </VanityStat>
          </ViewToggleGroup.Button>
        </ViewToggleGroup>
      </div>
    </>
  );
}
