import { FormattedMessage, useIntl } from 'react-intl'
import { LoadingSpinner, PercentageRingTooltipped, PercentageRingTypes, Text, ThumbnailList, Widget } from '@ctek/design-system';
import { withErrorBoundaryPartial } from 'century-core/core-components/ErrorBoundary/ErrorBoundary';
import ConditionalLink from 'century-core/core-components/ConditionalLink/ConditionalLink';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';

export enum StrengthWeaknessMode {
  STRENGTHS = 'strengths',
  WEAKNESSES = 'weaknesses',
}

export interface StrengthsWeaknessesProps {
  data: Ctek.AbacusCourseRecommendationPopulated[];
  isLoading: boolean;
  mode: StrengthWeaknessMode;
  widgetMode: DashboardUserMode;
  hasFocusAndStretchEnabled: boolean;
}
function StrengthsWeaknesses(props: StrengthsWeaknessesProps) {
  const { data, isLoading, mode, widgetMode, hasFocusAndStretchEnabled } = props;
  const intl = useIntl();

  return (
    <Widget qa={mode}>
      <Widget.Header>
        <Widget.Title qa="strengthsweaknesses-title">
          {
            <FormattedMessage
              id={`dependant-overview-${mode}-header`}
              defaultMessage={mode === StrengthWeaknessMode.STRENGTHS ? 'Strengths' : 'Areas for Improvement'}
            />
          }
        </Widget.Title>
      </Widget.Header>
      {!isLoading ? (
        <Widget.Body qa="strengthsweaknesses-body">
          {!!data && data.length > 0 ? (
            <ThumbnailList>
              {data.map((datum: Ctek.AbacusCourseRecommendationPopulated & { studyGroupId: string }, i: number) => (
                <ThumbnailList.Item key={datum.nuggetId} qa={`strengthsweaknesses-item-${i}`}>
                  <ThumbnailList.Thumbnail>
                    <PercentageRingTooltipped
                      percentage={datum.percentScore}
                      type={PercentageRingTypes.SCORE}
                      label={intl.formatMessage({ id: 'average-score', defaultMessage: 'Average Score' })}
                    />
                  </ThumbnailList.Thumbnail>
                  <ThumbnailList.Label>
                    <ConditionalLink
                      shouldBeLink={widgetMode === DashboardUserMode.STUDENT && hasFocusAndStretchEnabled}
                      to={{
                        pathname: `/learn/my-dashboard/courses/study-groups/${datum.studyGroupId}/nuggets/${datum.nuggetId}/`,
                        state: { reason: mode, viewLocation: 'StudentDashboard' },
                        search: `startedFrom=${mode}`,
                      }}
                      key={datum.nuggetId}
                    >
                      {datum.name}
                    </ConditionalLink>
                  </ThumbnailList.Label>
                </ThumbnailList.Item>
              ))}
            </ThumbnailList>
          ) : (
            <div data-testid="no-data-message">
              <Text.Placeholder>
                <FormattedMessage id="no-data" defaultMessage="Sorry, we don't have enough information for this yet" />
              </Text.Placeholder>
            </div>
          )}
        </Widget.Body>
      ) : (
        <div data-testid="strengthsweaknesses-loading">
          {' '}
          <LoadingSpinner type={'widget'} />{' '}
        </div>
      )}
    </Widget>
  );
}

export default withErrorBoundaryPartial(StrengthsWeaknesses);
