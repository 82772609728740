import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { userSchema } from 'century-core/entities/User/User';
import { Errors } from 'century-core/entities/ErrorMessage/Errors';
import { StoreState, ThunkExtraArg } from '../../reducers/reducers';
import { Actions as EntitiesActions, addEntities } from '../entities/entities';

export enum ActionTypes {
  Error = 'Dependants.Error',
  Loaded = 'Dependants.Loaded',
  Loading = 'Dependants.Loading',
}

export type Actions = ReturnType<typeof error> | ReturnType<typeof loading> | ReturnType<typeof loaded>;

export const loading = () => ({
  type: ActionTypes.Loading as typeof ActionTypes.Loading,
});

export const loaded = (dependants: string[]) => ({
  dependants,
  type: ActionTypes.Loaded as typeof ActionTypes.Loaded,
});

export const error = (err: ErrorMessage<Errors>) => ({
  error: err,
  type: ActionTypes.Error as typeof ActionTypes.Error,
});

export function fetchDependants(
  userId: string,
  accessToken: string,
  allow404: boolean = false
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return (dispatch: Dispatch<Actions | EntitiesActions>, getState: () => StoreState, { api, normalize }: ThunkExtraArg): Promise<void> => {
    dispatch(loading());

    return api.guardian
      .fetchDependants(userId, accessToken, allow404)
      .then((dependantsData: Ctek.User[]) => {
        const data = normalize(dependantsData, [userSchema]);
        dispatch(addEntities(data.entities));
        dispatch(loaded(data.result));
      })
      .catch((err: ErrorMessage<Errors>) => {
        // for these errors not to be generically aggregated under custom object
        // we need to pass message not the whole object
        dispatch(error(err));
      });
  };
}
