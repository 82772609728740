import { createContext, useContext, useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { PublicApiUrls, useApiContext } from 'century-core/core-apis/ApiProvider';
import { useAccessTokenRef, useAuth } from 'century-core/core-auth/hooks/useAuth';
import localStorageLib from 'century-core/core-utils/utils/localStorage/localStorage';
import { useUserLocalStorage } from './useUserLocalStorage';
import { GET, PATCH } from 'century-core/core-apis/utils';

interface UserPreferencesContextType {
  readLocal: (name: string) => string | undefined;
  writeLocal: (name: string, value: any) => void;
  save: (changedPreferences: Ctek.UserPreferences) => Promise<void>;
  preferences: Ctek.UserPreferences | null;
}

// TODO: localStorage solutions to be replaced by state or user preferences held on the object
export const UserPreferencesContext = createContext<UserPreferencesContextType>({
  readLocal: localStorageLib.read,
  writeLocal: localStorageLib.write,
  save: () => Promise.resolve(),
  preferences: null,
});

export const useUserPreferences = () => useContext(UserPreferencesContext);

export const UserPreferencesProvider = (props: { children: React.ReactNode }) => {
  const { getApi } = useApiContext();
  const auth = useAuth();
  const requestSent = useRef(false);
  const [preferences, setPreferences] = useState(null as any);
  const { read, write } = useUserLocalStorage();
  const accessToken = useAccessTokenRef();
  const reqURL = useMemo(async () => await getApi(PublicApiUrls.Accounts, 2, '/users/me'), [getApi]);

  const getUserAndSetPreferences = useCallback(async () => {
    requestSent.current = true;
    const url = await reqURL;
    url.search = 'populate=true';

    await GET<Ctek.User>({ url, token: accessToken.current })
      .then(user => setPreferences(user.preferences))
      .catch(() =>
        toast.error(
          <FormattedMessage id="user-preferences-get-error" defaultMessage="We're having problems retrieving your user preferences." />
        )
      );
  }, [accessToken, reqURL]);

  const save = async (changedPreferences: Partial<Ctek.UserPreferences>) => {
    const url = await reqURL;
    const body = {
      preferences: {
        ...preferences,
        ...changedPreferences,
      },
    };

    PATCH<{ id: string; _id: string }>({ url, token: accessToken.current, body })
      .then(getUserAndSetPreferences)
      .catch(() =>
        toast.error(
          <FormattedMessage id="user-preferences-save-error" defaultMessage="We're having problems saving your user preferences." />
        )
      );
  };

  useEffect(() => {
    if (!requestSent.current && auth.accessToken) {
      getUserAndSetPreferences();
    }
  }, [preferences, getUserAndSetPreferences, auth.accessToken]);

  return (
    <UserPreferencesContext.Provider
      value={{
        readLocal: read,
        writeLocal: write,
        save,
        preferences,
      }}
    >
      {props.children}
    </UserPreferencesContext.Provider>
  );
};
