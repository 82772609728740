import { getCmsURL } from '../cms';
import { GET } from 'century-core/core-apis/utils';

export interface StoryPlan {
  id: string;
  nickname: string;
  interval: 'month' | 'year';
  subjects: number;
  tiers: {
    up_to: number;
    flat_amount: number;
    unit_amount: number;
  }[];
}

export interface StoryCourse {
  courseId: string;
  courseName: string;
  adviceForAssigning: string;
  level: string;
}

export interface StoryConfig {
  plans: StoryPlan[];
  courses: Record<string, StoryCourse[]>;
  recaptchaSiteKey: string;
  stripePublicKey: string;
  viralLoopsId: string;
  freshChatKey: string;
}

export async function getB2CConfig(): Promise<StoryConfig> {
  const url = getCmsURL('/consumer/config');
  return await GET<StoryConfig>({ url });
}
