import * as SentryLib from 'century-core/core-utils/lib/sentry';

import Auth from 'century-core/entities/Auth/Auth';
import { renewToken } from 'century-core/core-apis/sentinel/auth/auth';
import {
  parseAuthFromLocalstorage,
  writeAuthToLocalstorage,
  scheduleRenewToken,
  delayRenewToken,
  addUserAndOrgIdToSentry,
} from 'century-core/core-auth/utils';
import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { Errors } from 'century-core/entities/ErrorMessage/Errors';
import logout from './logout';

function isReduxAuthUserSynced(reduxAuth: Auth) {
  const localStorageAuth: Auth = parseAuthFromLocalstorage() || {};
  return reduxAuth.accessTokenData?.sub === localStorageAuth?.accessTokenData?.sub;
}

export default async function renewAccessToken(
  auth: Auth,
  renewTokenTimer: NodeJS.Timeout,
  setAuthEverywhere: (auth: Auth) => void
): Promise<Auth | void> {
  const refreshToken: string = auth.refreshToken || '';
  const localStorageAndReduxUserMatch = isReduxAuthUserSynced(auth);

  // skip token renew if localStorage does not match with redux
  if (!localStorageAndReduxUserMatch) {
    return Promise.resolve();
  }

  return renewToken(refreshToken)
    .then((newAuth: Auth) => {
      writeAuthToLocalstorage(newAuth);

      addUserAndOrgIdToSentry(auth);

      setAuthEverywhere(newAuth);

      scheduleRenewToken(newAuth, () => renewAccessToken(newAuth, renewTokenTimer, setAuthEverywhere), renewTokenTimer);

      return newAuth;
    })
    .catch((err: ErrorMessage<Errors>) => {
      if (err.code === 'localised-errors-offline') {
        // try again in 5sec
        delayRenewToken(() => renewAccessToken(auth, renewTokenTimer, setAuthEverywhere), renewTokenTimer, 5000);
        return;
      }
      SentryLib.captureException(new Error(err?.message));

      logout(renewTokenTimer);
    });
}
