import { gql, useQuery } from '@apollo/client';
import { getCoursePath } from 'century-core/core-apis/roentgen/queries/studentDashboard'
import { useMemo } from 'react';
import QueryWrapper from 'century-core/features-dashboard/Widgets/QueryWrapper';
import { Widget } from '@ctek/design-system';
import WelcomeWidgetBody from './WelcomeWidgetBody';

const welcomeWidgetFragment = gql`
  fragment welcomeWidgetFragment on CoursePathItem {
    planEndTime
  }
`;

export const coursePathQuery = getCoursePath(welcomeWidgetFragment, 'welcomeWidgetFragment')

interface Props {
  firstName: string;
  userId: string;
}

const WelcomeWidget = (props: Props) => {
  const welcomeWidgetInputs = useMemo(() => ({
    input: {
      userId: props.userId,
      courseTypes: ['assignment'],
      mode: 'assignment-due',
    },
  }), [props.userId]);

  const query = useQuery(
    coursePathQuery,
    {
      variables: welcomeWidgetInputs,
      fetchPolicy: 'network-only',
    }
  );

  const endDates = useMemo(() => {
    if (query?.data?.coursePath?.courses?.length) {
      return query.data.coursePath.courses.map(({ planEndTime }: any) => planEndTime);
    }
    else return [];
  }, [query.data]);

  return (
    <Widget qa="widget--welcome-message">
      <QueryWrapper query={query}>
        {query.data &&
          <WelcomeWidgetBody
            payload={query.data}
            assignmentEndDates={endDates}
            firstName={props.firstName}
          />
        }
      </QueryWrapper>
    </Widget>
  )
}

export default WelcomeWidget;
