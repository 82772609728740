import * as Sentry from '@sentry/browser';

type SentryData = {
  tags?: object;
  extra?: {
    name: string;
    value: any;
  };
  context?: {
    name: string;
    value: any;
  };
};

export function configureScope(userId: string, orgId: string) {
  Sentry.configureScope(scope => {
    scope.setUser({ id: userId });
    scope.setTag('organisationId', orgId);
  });
}

export function captureException(error: Error) {
  Sentry.captureException(error);
}

export function captureMessage(message: string) {
  Sentry.captureMessage(message);
}

export function captureExceptionWithScope(error: Error, level: 'error' | 'fatal', sentryData: SentryData = {}) {
  Sentry.withScope((scope: any) => {
    const { tags, extra, context } = sentryData;

    scope.setLevel(level);

    if (!!tags) {
      Object.keys(tags).forEach(key => {
        scope.setTag(key, tags[key]);
      });
    }

    if (!!extra && extra.name) scope.setExtra(extra.name, extra.value);

    if (!!context && context.name) scope.setContext(context.name, context.value);

    Sentry.captureException(error);
  });
}
