/* eslint-disable no-useless-escape */
import '@edtr-io/mathquill/build/mathquill.css';
import 'mathquill4quill/mathquill4quill.js';
import 'mathquill4quill/mathquill4quill.css';
import * as React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { MediaLibraryFile } from 'century-core/core-apis/mediaLibrary/mediaLibrary';
import QuillConstants from 'century-core/core-utils/utils/quill/constants';
import './formula.scss';
import { LoadingSpinner } from '@ctek/design-system';
import { registerQuillModules } from './registerQuillModules';

registerQuillModules();
interface State {
  enabled: boolean;
  events: string[];
  selection?: object;
  loading: boolean;
}

// This is because defer(fn) queues the calls and causing an issue with graphql
// setup before functions
let typingTimer: any; // timer identifier // tslint:disable-next-line

// Needs to be require as webpack picks it up and puts jquery on the window for this library
// tslint:disable-next-line
/* eslint import/no-webpack-loader-syntax: off */ const MathQuill = require('exports-loader?window.MathQuill!imports-loader?window.jQuery=jquery!@edtr-io/mathquill/build/mathquill.js');

// Operators for the formula keyboard of MathQuill
const keyboardOperators = [
  ['\\times', '\\times'],
  ['\\div', '\\div'],
  ['\\leq', '\\leq'],
  ['\\geq', '\\geq'],
  ['\\pm', '\\pm'],
  ['\\sqrt{x}', '\\sqrt'],
  ['\\sqrt[n]{x}', '\\nthroot'],
  ['\\frac{x}{y}', '\\frac'],
  ['a^{x}', '\\superscript'],
  ['a_{x}', '\\subscript'],
  ['\\neq', '\\neq'],
  ['\\approx', '\\approx'],
  ['\\cap', '\\cap'],
  ['\\cup', '\\cup'],
  ['\\subset', '\\subset'],
  ['\\subseteq', '\\subseteq'],
  ['\\varnothing', '\\varnothing'],
  ['\\therefore', '\\therefore'],
  ['\\because', '\\because'],
  ['\\isin', '\\isin'],
  ['\\infin', '\\infin'],
  ['\\int', '\\int'],
  ['^\\circ', '\\circ'],
  ['\\angle', '\\angle'],
  ['\\xi', '\\xi'],
  ['\\pi', '\\pi'],
  ['\\theta', '\\theta'],
  ['\\sigma', '\\sigma'],
  ['\\mu', '\\mu'],
  ['\\lambda', '\\lambda'],
  ['\\phi', '\\phi'],
  ['\\sum', '\\sum'],
  ['\\N', '\\N'],
  ['\\mathbb{Q}', '\\Q'],
  ['\\reals', '\\reals'],
  ['\\Z', '\\Z'],

  // FUTURE MATH OPERATORS TO BE ADDED WHEN https://github.com/c-w/mathquill4quill/issues/39 IS FIXED

  //   ['\\bar{y}', '\\overline{y}'],
  //   ['\\overrightarrow{AB}', '\\overrightarrow{AB}'],
  //   ['\\dot{a}', '\\dot{a}'],
  //   [`\\begin{pmatrix}
  //   a & b \\\\
  //   c & d
  // \\end{pmatrix}
  // `, `\\begin{pmatrix}
  // a & b \\\\
  // c & d
  // \\end{pmatrix}
  // `],
  //   ['\\binom{n}{k}', '\\binom{n}{k}'],
  //   ['\\displaystyle\\sum_{i=1}^n', '\\displaystyle\\sum_{i=1}^n'],
  //   ['\\lim\\limits_x', '\\lim\\limits_x']
];

declare global {
  interface Window {
    DefaultText: any;
    mathquill4quill: any;
  }
}

class QuillEditor extends React.Component<Props, State> {
  get getEditorSelection() {
    return this.props.innerRef.current ? this.props.innerRef.current.getSelection() : null;
  }

  public state = {
    enabled: true,
    events: [],
    selection: {},
    loading: false,
  };

  // only 50 if autosave is exactly false, if undefined or null want to keep 1500 so as to have minimum impact on other people using Quill editors
  private doneTypingInterval = this.props.autosave === false ? 50 : 1500;

  public componentDidMount() {
    // Used to fix bug where ic_player sets window.Text to something else. This then breaks quill editors which try to use
    // window.Text later on. This can be removed when ic_player is removed.
    if (window && window.DefaultText && window.Text !== window.DefaultText) {
      window.Text = window.DefaultText;
    }

    if (window && this.props.innerRef.current) {
      // Annoyingly mathquill has to be set on the window...
      const enableMathQuillFormulaAuthoring = window.mathquill4quill({ MathQuill, Quill });
      enableMathQuillFormulaAuthoring(this.props.innerRef.current.getEditor(), {
        operators: keyboardOperators,
      });
    }
  }

  public onEditorChangeSelection = (range: object) => {
    const { events } = this.state;
    this.setState({
      events: ['selection-change(' + this.formatRange(range) + ')'].concat(events),
      selection: range,
    });
  };

  public formatRange(range: any) {
    return range ? [range.index, range.index + range.length].join(',') : 'none';
  }

  public render() {
    const { value, uploadMediaToMediaLibrary } = this.props;
    const {
      className,
      onBlur,
      readonly = false,
      toolbar = {
        container: QuillConstants.toolbar('ltr', true),
        handlers: {
          image: uploadMediaToMediaLibrary ? this.imageHandler : undefined,
          videoUpload: uploadMediaToMediaLibrary ? this.videoHandler : undefined,
        },
      },
    } = this.props;

    // By default all links in quill are relative if they are not prefixed with `http`
    const Link = (ReactQuill as any).Quill.import('formats/link');
    Link.sanitize = (url: string) => {
      if (url.startsWith('http')) {
        return url;
      } else {
        return `http://${url}`;
      }
    };

    const hasFormulaInToolbar = [].concat.apply([], toolbar.container).findIndex((toolbarItem: string) => toolbarItem === 'formula') !== -1;

    return (
      <div style={{ position: 'relative' }} className={`quill quill-input-bounds ${className ? className : ''}`}>
        {this.state.loading && (
          <div style={{ right: '5px', bottom: '5px', position: 'absolute', zIndex: 1 }}>
            <LoadingSpinner type="widget" />
          </div>
        )}
        <ReactQuill
          ref={this.props.innerRef}
          theme={'snow'}
          onChange={this.onEditorChange}
          onBlur={onBlur}
          value={value}
          modules={{
            clipboard: QuillConstants.clipboard,
            formula: readonly ? false : hasFormulaInToolbar,
            toolbar: readonly ? false : toolbar,
            imageResize: readonly
              ? false
              : {
                  displaySize: true,
                  handleStyles: {
                    backgroundColor: 'black',
                    border: 'none',
                    color: 'white',
                  },
                  modules: ['Resize', 'DisplaySize', 'Toolbar'],
                },
          }}
          formats={QuillConstants.formats}
          placeholder={this.placeholder()}
          readOnly={readonly}
          onKeyDown={this.props.onKeyDown}
        />
      </div>
    );
  }

  private imageHandler = (image: any, callback: any) => {
    // Creates image selection prompt
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;
      if (file) {
        this.setState({ ...this.state, loading: true });
        const url =
          this.props.uploadMediaToMediaLibrary && this.props.accessToken
            ? await this.props.uploadMediaToMediaLibrary(file, this.props.accessToken)
            : null;
        if (!this.props.innerRef.current) {
          return;
        }

        const range = await this.props.innerRef.current.getEditor().getSelection();
        if (!range) {
          return;
        }

        // insert the image in the quill editor
        // by 'image' option below, you just have to put src(link) of img here.
        this.props.innerRef.current.getEditor().insertEmbed(range.index, 'image', url);

        // Used to update controlled version of this editor when images are uploaded to allow for updating the contents of the editor in state of parent components
        this.props.onChange(this.getEditorContents(this.props.innerRef.current.getEditor()));
        this.setState({ ...this.state, loading: false });
      }
    };
  };

  // TODO - merge this logic with imageHandler - struggled originally due to quill losing the context of this in curried functions.
  private videoHandler = (image: any, callback: any) => {
    // Creates image selection prompt
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'video/*');
    input.click();
    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;
      if (file) {
        this.setState({ ...this.state, loading: true });
        const url =
          this.props.uploadMediaToMediaLibrary && this.props.accessToken
            ? await this.props.uploadMediaToMediaLibrary(file, this.props.accessToken)
            : null;
        if (!this.props.innerRef.current) {
          return;
        }

        const range = await this.props.innerRef.current.getEditor().getSelection();
        if (!range) {
          return;
        }

        // insert the image in the quill editor
        // by 'image' option below, you just have to put src(link) of img here.
        this.props.innerRef.current.getEditor().insertEmbed(range.index, 'video', url);

        // Used to update controlled version of this editor when images are uploaded to allow for updating the contents of the editor in state of parent components
        this.props.onChange(this.getEditorContents(this.props.innerRef.current.getEditor()));
        this.setState({ ...this.state, loading: false });
      }
    };
  };

  private getEditorContents(editor: any) {
    return editor.getContents();
  }

  private delayStateFn = (myInput: any, fn: (...args: any[]) => void) => {
    clearTimeout(typingTimer);
    if (myInput.length) {
      typingTimer = setTimeout(fn, this.doneTypingInterval);
    }
  };

  private placeholder = (): string | undefined => {
    const {
      intl: { formatMessage },
    } = this.props;

    return this.props.placeholder
      ? this.props.placeholder
      : !this.props.readonly
      ? formatMessage({ id: 'task-quill-placeholder', defaultMessage: 'Please type something here...' })
      : undefined;
  };

  private onEditorChange = (content: any, delta: any, source: any, editor: any) => {
    if (source === 'user') {
      const currentEditorContents = () => this.getEditorContents(editor);
      this.delayStateFn(currentEditorContents(), () => this.props.onChange(currentEditorContents()));
    }
  };
}

// This wrapper will provide the quill editor with a ref unless one is provided by a parent element
// This allows components using the QuillEditor to directly access it.
const QuillRefWrapper = (props: QuillRefWrapperProps) => {
  const domRef = props.innerRef === undefined ? React.createRef<Quill & ReactQuill & QuillEditor>() : props.innerRef;

  return <QuillEditor innerRef={domRef} {...props} />;
};

export default injectIntl(QuillRefWrapper);

interface QuillEditorBaseProps {
  placeholder?: string;
  value: any; // TODO quill Delta type
  onBlur?: () => void;
  onChange: (newValue: any) => void;
  uploadMediaToMediaLibrary?: (quillMediaFile: MediaLibraryFile, accessToken: string) => void; // Required for uploading images.
  accessToken?: string;
  className?: string;
  readonly?: boolean | undefined;
  toolbar?: { container: string[] | string[][]; handlers?: object };
  onKeyDown?: (event: Event) => void;
  /*
   ** This is a solution to a problem in new question types.
   ** This autosave flag allows the reduction of typing delay which was causing issues, but should probably be revisited at some point, to find a more elegant solution
   */
  autosave?: boolean;
}

export interface ExternalQuillEditorProps extends QuillEditorBaseProps {
  innerRef?: React.RefObject<Quill & ReactQuill & QuillEditor>;
}

interface QuillRefWrapperProps extends ExternalQuillEditorProps {
  intl: IntlShape;
}

interface QuillEditorWithoutIntl extends QuillEditorBaseProps {
  innerRef: React.RefObject<Quill & ReactQuill & QuillEditor>;
}

// FIXME find out how to get @types.quill working with Quill as it requires allowSyntheticDefaultImports due to no default export on a package
interface Props extends QuillEditorWithoutIntl {
  intl: IntlShape;
}
