import { useState } from 'react';
import { read, write } from 'century-core/core-utils/utils/localStorage/localStorage';

export function useLocalStorage(key: string, initialValue: any) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = read(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });
  const setValue = (value: any) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      write(key, JSON.stringify(valueToStore));
    } catch (error) {
      return error;
    }
  };
  return [storedValue, setValue];
}
