import * as React from 'react';
import { ResultTypes } from '../LabelPair/LabelPair';
import './PromptAnswerPair.scss';

export interface Props {
  children: PromptAnswerPairProps;
  qa?: string;
  result?: ResultTypes | 'reveal';
  classNameExt?: string;
}

export interface PromptAnswerPairProps {
  pair: React.ReactNode[];
  itemType: Ctek.MatchType;
}

export const PromptAnswerPair = (props: Props) => {
  const { qa, result } = props;
  const { pair, itemType } = props.children;
  const resultClassName = result && result !== ResultTypes.SKIPPED ? `prompt-answer-pair--submitted prompt-answer-pair--${result}` : '';
  const classNameExt = props.classNameExt ? props.classNameExt : '';

  return (
    <div className={`prompt-answer-pair ${resultClassName} ${classNameExt}`} data-qa={qa} data-testid={qa}>
      <div className="prompt-answer-pair__container">
        {pair.map((item, index) => (
          <div
            className={`prompt-answer-pair__field prompt-answer-pair__field--${itemType}`}
            key={index}
            data-testid="prompt-answer-pair-field"
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PromptAnswerPair;
