import { Quill } from 'react-quill'
import ImageResize from 'quill-image-resize-module-react'
import QuillBase from 'quill'

const BlockEmbed = Quill.import('blots/block/embed');
const icons = Quill.import('ui/icons');
icons.videoUpload = icons.video;
icons.video = `<svg 
viewBox="0 0 180 180" white-space: "normal" fill="#444">
<g>
<path transform="translate(8, 8)" d="M34.857,3.613C20.084-4.861,8.107,2.081,8.107,19.106v125.637c0,17.042,11.977,23.975,26.75,15.509L144.67,97.275
 c14.778-8.477,14.778-22.211,0-30.686L34.857,3.613z"/>
</g>
</svg>`;

class VideoBlot extends BlockEmbed {
  static create(url: string) {
    if (url.includes('youtube') || url.includes('youtu.be')) {
      const node = super.create();
      node.setAttribute('src', url);
      node.setAttribute('frameborder', '0');
      node.setAttribute('allowfullscreen', true);
      return node;
    }

    const node = document.createElement('video');
    node.setAttribute('src', url);
    // node.setAttribute('allowfullscreen', 'true');
    node.setAttribute('style', 'max-width:720px');
    node.setAttribute('controls', '');
    node.setAttribute('controlsList', 'nodownload');
    node.setAttribute('frameborder', '0');
    node.removeAttribute('width');
    return node;
  }

  static formats(node: any) {
    const format: any = {};
    if (node.hasAttribute('height')) {
      format.height = node.getAttribute('height');
    }
    if (node.hasAttribute('width')) {
      const value = node.getAttribute('width');
      if (value === 'true') {
        node.removeAttribute('width');
      } else {
        format.width = node.getAttribute('width');
      }
    }

    if (!node.hasAttribute('height') && !node.hasAttribute('width')) {
      node.setAttribute('width', '300');
      node.setAttribute('height', '180');
    }
    return format;
  }

  static value(node: any) {
    return node.getAttribute('src');
  }

  format(name: string, value: any) {
    if (name === 'height' || name === 'width') {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name, value);
      }
    } else {
      super.format(name, value);
    }
  }
}
VideoBlot.blotName = 'video';
VideoBlot.tagName = 'iframe';

export const registerQuillModules = () => {
  QuillBase.register(VideoBlot, true);
  QuillBase.register(
    {
      'modules/imageResize': ImageResize,
      'ui/icons': icons,
    },
    true
  );
};
