import * as React from 'react';

const ClickSelectAndDrop = React.createContext({selectedItemId: null as string| null, setSelectedItem: (id: string | null) => {return;}});

export const ClickSelectAndDropProvider = (props: {children: React.ReactNode}) => {
    const [selectedItemId, setSelectedItem] = React.useState(null as string | null);

    // If a selected item is clicked a second time, de-select it.
    const setAndUnsetSelectedItem = (id: string | null) => {
        id === selectedItemId ? setSelectedItem(null) : setSelectedItem(id);
    };

    return (
        <ClickSelectAndDrop.Provider value={{selectedItemId, setSelectedItem: setAndUnsetSelectedItem}} >
            {props.children}
        </ClickSelectAndDrop.Provider>
    );
};

export default ClickSelectAndDrop;