import { GET } from 'century-core/core-apis/utils';
import { getContentURL } from '../contentV2';

export function getNugget(nuggetId: string, accessToken: string, populate?: boolean) {
  const url = getContentURL(`/nuggets/${nuggetId}`);
  if (populate) {
    url.search += '?populate=true';
  }

  return GET<Ctek.Nugget>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get nugget by id ${nuggetId}, ${err}`);
  });
}

type NuggetPreview = {
  nugget: Omit<Ctek.Nugget, 'fragments' | 'assessments'> & {
    fragments: Ctek.Fragment[];
    assessments: Ctek.Assessment[];
  };
};
export function getNuggetPreview(nuggetId: string, token: string, signal?: AbortSignal) {
  const url = getContentURL(`/nuggets/${nuggetId}/preview`);

  return GET<NuggetPreview>({ url, token, signal })
    .then(res => res.nugget)
    .catch((err: Error) => {
      throw Error(`Unable to get nugget preview by id ${nuggetId}, ${JSON.stringify(err)}`);
    });
}
