import { gql } from '@apollo/client';

const NUGGET_FIELDS = gql`
  fragment NuggetFields on NuggetRecommendation {
    nuggetId
    nuggetName
    studySessionRecommendationReason {
      reason
    }
    subjectId
    subjectName
    studyGroupId
    courseIconCode
  }
`;

export const FOCUS_AND_STRETCH = gql`
  query focusAndStretch($input: FocusAndStretchInput!) {
    focusAndStretch(input: $input) {
      focus {
        ...NuggetFields
      }
      stretch {
        ...NuggetFields
      }
    }
  }
  ${NUGGET_FIELDS}
`;
