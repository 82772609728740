import { GET } from '../utils';
import { getCmsURL } from './cms';
export interface LearnerProduct<Key> {
  subscriptionPlanId: string;
  productKey: Key;
  priceId: string;
  stripeSubscriptionItemId?: string;
  learner?: {
    userId: string;
    date?: Date;
  };
  trialling: boolean;
  createdAt: string;
  isExpired: boolean;
}

export async function getLearnerProductDetails<ProductKey>(accessToken: string) {
  try {
    const url = getCmsURL('/consumer/learner/type');

    return await GET<LearnerProduct<ProductKey>>({ url, token: accessToken });
  } catch (err) {
    throw Error(`Unable to get learner product details: ${err.message}`);
  }
}
