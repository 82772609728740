import { createContext, useState } from 'react';
import * as React from 'react';
import { getFragment as getFragmentFromAPI } from 'century-core/core-apis/contentV2/fragment/fragment';
import { useAccessToken } from 'century-core/core-auth/hooks/useAuth';

export const FragmentContext = createContext<{ getFragments: (ids: string[]) => Promise<Ctek.Fragment[]> } | null>(null);
export default FragmentContext;

export const FragmentProvider = (props: { children: React.ReactNode }) => {
  const accessToken = useAccessToken();
  const [allFragments, updateFragments] = useState([] as Ctek.Fragment[]);

  const getFragments = async (ids: string[]) => {
    const fragments: Ctek.Fragment[] = [];
    await Promise.all(
      ids.map(async id => {
        const cachedFragment = allFragments.find(cf => cf._id === id);
        if (cachedFragment) {
          fragments.push(cachedFragment);
        } else {
          try {
            fragments.push(await getFragmentFromAPI(id, accessToken, true));
          } catch (e) {
            throw e;
          }
        }
      })
    );
    updateFragments(fragments);
    return fragments;
  };

  return <FragmentContext.Provider value={{ getFragments }}>{props.children}</FragmentContext.Provider>;
};
