import { useUserId } from 'century-core/core-auth/hooks/useAuth';
import localStorageLib from 'century-core/core-utils/utils/localStorage/localStorage';
import { useMemo } from 'react';

const getUserKey = (key: string) => `user.${key}`;

export const readAllUsers = (key: string, defaultUserId?: string) => {
  const userKey = getUserKey(key);
  let value = {};
  try {
    value = JSON.parse(localStorageLib.read(userKey) || '{}');
    if (value && defaultUserId && value[defaultUserId]) {
      return value;
    }
  } catch (e) {
    value = {};
  }
  const globallyAppliedValue = localStorageLib.read(key);
  if (globallyAppliedValue && defaultUserId) {
    return { ...value, [defaultUserId]: globallyAppliedValue };
  }
  return value;
};

export const writeToUserStorage = (userId: string) => (key: string, value: any) => {
  const newValue = { [userId]: value };
  const oldValues = readAllUsers(key, userId);
  try {
    localStorageLib.write(getUserKey(key), JSON.stringify({ ...oldValues, ...newValue }));
  } catch {
    localStorageLib.write(getUserKey(key), JSON.stringify(newValue));
  }
};

// requires fallbacks to previous localStorage format
export const useUserLocalStorage = (): typeof localStorageLib => {
  const userId = useUserId();

  return useMemo(() => {
    const read = (key: string) => readAllUsers(key, userId)[userId];
    const write = writeToUserStorage(userId);
    const remove = (key: string) => {
      const allUsers = readAllUsers(key);
      delete allUsers[userId];
      localStorageLib.write(getUserKey(key), JSON.stringify(allUsers));
    };
    return { read, write, remove };
  }, [userId]);
};
