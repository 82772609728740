import { useEffect, useState } from 'react';
import * as L from 'luxon';
import WelcomeWidgetOverdueAssignments from './WelcomeWidgetOverdueAssignments';
import WelcomeWidgetUpcomingAssignments from './WelcomeWidgetUpcomingAssignments';
import { Button } from '@ctek/design-system';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

interface Props {
  assignmentEndDates: string[]
  todayRange: L.Interval
  tomorrowRange: L.Interval
  yesterdayRange: L.Interval
}

const isBetweenDates = (date: L.DateTime, interval: L.Interval) => {
  return date >= interval.start && date <= interval.end;
}

export default function WelcomeWidgetAssignmentsMode(props: Props) {
  const [overdueCount, setOverdueCount] = useState(0);
  const [todayCount, setTodayCount] = useState(0);
  const [tomorrowCount, setTomorrowCount] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const overdueCount = props.assignmentEndDates.filter((a: string) => isBetweenDates(L.DateTime.fromISO(a), props.yesterdayRange)).length;
    const todayCount = props.assignmentEndDates.filter((a: string) => isBetweenDates(L.DateTime.fromISO(a), props.todayRange)).length;
    const tomorrowCount = props.assignmentEndDates.filter((a: string) => isBetweenDates(L.DateTime.fromISO(a), props.tomorrowRange)).length;
    setOverdueCount(overdueCount);
    setTodayCount(todayCount);
    setTomorrowCount(tomorrowCount);
  }, [props.assignmentEndDates, props.todayRange, props.yesterdayRange, props.tomorrowRange]);

  const handleGoAssignmentsClick = () => {
    history.push('/learn/assignments');
  }

  return (
    <>
      <WelcomeWidgetOverdueAssignments count={overdueCount} />
      <WelcomeWidgetUpcomingAssignments todayCount={todayCount} tomorrowCount={tomorrowCount} />
      <Button variant="secondary" onClick={handleGoAssignmentsClick} qa="widgets--welcome--assignments-button">
        <FormattedMessage id="welcome-widget-go-to-assignments" defaultMessage="Go To Assignments" />
      </Button>
    </>
  )
}
