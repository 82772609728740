import { useState, useEffect, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import LearnerActivityWidgetBody from './LearnerActivityWidgetBody';
import { ChartContainerHeights, Widget } from '@ctek/design-system';
import { DateTime, Info } from 'luxon';
import { useMixpanel } from 'century-core/core-components/MixpanelUtils';
import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import {
  formatddMMM,
  getUtcIsoDateTimeFromObject,
  getDateTimeFromObject,
  getUtcDateTimeFromIso,
} from 'century-core/core-utils/utils/date/date';
import { TestPracticeFilter } from 'century-core/core-apis/roentgen/queries/myCoursesStudent.graphql';

// This will display as Week, Month, Year in the selector. It's in this format to play nicely with GQL
export enum LearnerActivityTimeMode {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}
export type DefaultTimeMode = Exclude<LearnerActivityTimeMode, LearnerActivityTimeMode.MONTH>;

export enum LearnerActivityMetricMode {
  NUGGETS = 'Nuggets Completed',
  TIME = 'Time Spent Studying',
  // QUESTIONS = 'Questions Answered',
}

export const getCourseTypeFromFilter = (filter?: TestPracticeFilter) => {
  if (filter === TestPracticeFilter.STANDARD_ONLY) {
    return 'standard';
  }
  if (filter === TestPracticeFilter.TEST_PRACTICE_ONLY) {
    return 'test-practice';
  }
  if (filter === TestPracticeFilter.ALL) {
    return 'all';
  }
  return;
};

interface WeekRangeObject {
  start: string;
  end: string;
}

interface Props {
  userId: string;
  courseIds?: string[];
  defaultTimeMode: DefaultTimeMode;
  chartHeight: ChartContainerHeights;
  subjectIds?: string[];
  subjectGroupIds?: string[];
  testPracticeFilter?: TestPracticeFilter;
  widgetMode: DashboardUserMode;
}

const LearnerActivityWidget = (props: Props) => {
  const intl = useIntl();
  const mixpanel = useMixpanel();
  const [startTime, setStartTime] = useState(
    props.defaultTimeMode === LearnerActivityTimeMode.DAY
      ? DateTime.local().toUTC().startOf('week').toISO()
      : DateTime.local().toUTC().startOf('month').toISO()
  );
  const [endTime, setEndTime] = useState(
    props.defaultTimeMode === LearnerActivityTimeMode.DAY
      ? DateTime.local().toUTC().endOf('week').toISO()
      : DateTime.local().toUTC().endOf('month').toISO()
  );
  const [timeMode, setTimeMode] = useState(props.defaultTimeMode as LearnerActivityTimeMode);
  const [metricMode, setMetricMode] = useState(LearnerActivityMetricMode.NUGGETS);
  const [rangeOptions, setRangeOptions] = useState([] as JSX.Element[][]);
  const [rangeIndex, setRangeIndex] = useState(0);

  useEffect(() => {
    setRangeOptions(getRangeOptions(timeMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeMode]);

  // A flattened version of the select options (only the dates), for managing the selected date via the select itself and also the next/prev buttons
  const flattenedOptions = useMemo(() => {
    return rangeOptions.reduce((accOpts, currOpt) => [...accOpts, ...currOpt.map(item => item.props.value)], []);
  }, [rangeOptions]);

  const title =
    props.testPracticeFilter === TestPracticeFilter.ALL
      ? props.widgetMode === DashboardUserMode.STUDENT
        ? intl.formatMessage({ id: 'learner-activity-widget-title-study', defaultMessage: 'My Study Activity' })
        : intl.formatMessage({ id: 'guardian-learner-activity-widget-title-study', defaultMessage: 'Study Activity' })
      : props.widgetMode === DashboardUserMode.STUDENT
      ? intl.formatMessage({ id: 'learner-activity-widget-title', defaultMessage: 'My Learning Activity' })
      : intl.formatMessage({ id: 'guardian-learner-activity-widget-title', defaultMessage: 'Learning Activity' });

  const handleMetricModeChange = (newMetricMode: LearnerActivityMetricMode) => {
    setMetricMode(newMetricMode);
    const commonMixpanelKeys = {
      [MixpanelKeys.SelectedMetric]: newMetricMode,
      [MixpanelKeys.CourseType]: getCourseTypeFromFilter(props.testPracticeFilter),
    };
    if (props.widgetMode === DashboardUserMode.STUDENT) {
      mixpanel.track(MixpanelEventTypes.StudentDashboardMetricChanged, commonMixpanelKeys);
    }
    if (props.widgetMode === DashboardUserMode.GUARDIAN) {
      mixpanel.track(MixpanelEventTypes.GuardianDashboardMetricChanged, {
        ...commonMixpanelKeys,
        [MixpanelKeys.DependantId]: props.userId,
      });
    }
    if (props.widgetMode === DashboardUserMode.TEACHER) {
      mixpanel.track(MixpanelEventTypes.TeacherDashboardStudentOverviewMetricChanged, {
        ...commonMixpanelKeys,
        [MixpanelKeys.SelectedMetric]: newMetricMode,
      });
    }
  };

  const handleTimeModeChange = (newTimeMode: LearnerActivityTimeMode) => {
    setRangeIndex(0);
    setTimeMode(newTimeMode);
    switch (newTimeMode) {
      case LearnerActivityTimeMode.DAY:
        const currentYear = DateTime.local().year;
        const numWeeksInYear = getDateTimeFromObject({ weekYear: currentYear }).weeksInWeekYear;
        const numWeeks = DateTime.local().weekNumber;
        const { start, end } = getWeekRange(numWeeks, numWeeksInYear, currentYear);
        setStartTime(start);
        setEndTime(end);
        break;
      case LearnerActivityTimeMode.WEEK:
        setStartTime(DateTime.local().toUTC().startOf('month').toISO());
        setEndTime(DateTime.local().toUTC().endOf('month').toISO());
        break;
      case LearnerActivityTimeMode.MONTH:
        setStartTime(DateTime.local().toUTC().startOf('year').toISO());
        setEndTime(DateTime.local().toUTC().endOf('year').toISO());
        break;
      default:
        break;
    }

    const commonMixpanelKeys = {
      [MixpanelKeys.SelectedTimeMetric]: newTimeMode,
      [MixpanelKeys.CourseType]: getCourseTypeFromFilter(props.testPracticeFilter),
    };
    if (props.widgetMode === DashboardUserMode.STUDENT) {
      mixpanel.track(MixpanelEventTypes.StudentDashboardTimeMetricChanged, commonMixpanelKeys);
    }
    if (props.widgetMode === DashboardUserMode.GUARDIAN) {
      mixpanel.track(MixpanelEventTypes.GuardianDashboardTimeMetricChanged, {
        ...commonMixpanelKeys,
        [MixpanelKeys.DependantId]: props.userId,
      });
    }
    if (props.widgetMode === DashboardUserMode.TEACHER) {
      mixpanel.track(MixpanelEventTypes.TeacherDashboardStudentOverviewTimeMetricChanged, {
        ...commonMixpanelKeys,
        [MixpanelKeys.StudentId]: props.userId,
      });
    }
  };

  const handleTimePeriodChange = (value: string) => {
    const selectedTime = getUtcDateTimeFromIso(value);
    const index = flattenedOptions.findIndex(o => o === value);
    setRangeIndex(index >= 0 ? index : 0);
    let start;
    switch (timeMode) {
      case LearnerActivityTimeMode.DAY:
        start =
          selectedTime.startOf('week').year < selectedTime.year
            ? selectedTime.startOf('year').toISO()
            : selectedTime.startOf('week').toISO();
        const end =
          selectedTime.endOf('week').year > selectedTime.year ? selectedTime.endOf('year').toISO() : selectedTime.endOf('week').toISO();
        setStartTime(start);
        setEndTime(end);
        break;
      case LearnerActivityTimeMode.WEEK:
        start = selectedTime.startOf('month').toISO();
        setStartTime(start);
        setEndTime(selectedTime.endOf('month').toISO());
        break;
      case LearnerActivityTimeMode.MONTH:
        start = selectedTime.startOf('year').toISO();
        setStartTime(start);
        setEndTime(selectedTime.endOf('year').toISO());
        break;
      default:
        break;
    }

    if (start) {
      const commonMixpanelKeys = {
        [MixpanelKeys.SelectedTimeStart]: getUtcDateTimeFromIso(start).toISO(),
        [MixpanelKeys.SelectedTimeMetric]: timeMode,
        [MixpanelKeys.CourseType]: getCourseTypeFromFilter(props.testPracticeFilter),
      };
      if (props.widgetMode === DashboardUserMode.STUDENT) {
        mixpanel.track(MixpanelEventTypes.StudentDashboardTimePeriodChanged, commonMixpanelKeys);
      }
      if (props.widgetMode === DashboardUserMode.GUARDIAN) {
        mixpanel.track(MixpanelEventTypes.GuardianDashboardTimePeriodChanged, {
          ...commonMixpanelKeys,
          [MixpanelKeys.DependantId]: props.userId,
        });
      }
      if (props.widgetMode === DashboardUserMode.TEACHER) {
        mixpanel.track(MixpanelEventTypes.TeacherDashboardStudentOverviewTimePeriodChanged, {
          ...commonMixpanelKeys,
          [MixpanelKeys.StudentId]: props.userId,
        });
      }
    }
  };

  // These functions look the wrong way around, but this is because the list of dates is in reverse order (most recent at the top)
  const handleNextClick = () => {
    const index = rangeIndex - 1;
    handleTimePeriodChange(flattenedOptions[index]);
  };

  const handlePrevClick = () => {
    const index = rangeIndex + 1;
    handleTimePeriodChange(flattenedOptions[index]);
  };

  const getRangeOptions = useCallback((mode: LearnerActivityTimeMode): JSX.Element[][] => {
    const currentYear = DateTime.local().year;
    const opts = [];
    switch (mode) {
      case LearnerActivityTimeMode.DAY:
        // TODO: change this to only include only years since the user has been onboarded
        for (let i = currentYear; i > 2015; i--) {
          const numWeeksInYear = getDateTimeFromObject({ weekYear: i }).weeksInWeekYear;
          const numWeeks = i === currentYear ? DateTime.local().weekNumber : numWeeksInYear;
          const weekOpts = [];
          for (let j = numWeeks; j > 0; j--) {
            const { start, end } = getWeekRange(j, numWeeksInYear, i);
            weekOpts.push(<option key={j} value={start}>{`${formatddMMM(start, true)} - ${formatddMMM(end, true)} ${i}`}</option>);

            // These conditions fix problems with leap weeks
            const startYear = getDateTimeFromObject({ weekYear: i, startOf: 'year', toUTC: true });
            if (j === 1 && getUtcDateTimeFromIso(start) > startYear) {
              const endWeek = startYear.endOf('week').endOf('day');
              weekOpts.push(
                <option key={j - 1} value={startYear.toISO()}>{`${formatddMMM(startYear, true)} - ${formatddMMM(endWeek)} ${i}`}</option>
              );
            }
            if (j === numWeeks && shouldAddFinalWeek(end, i)) {
              const endYear = getDateTimeFromObject({ year: i, endOf: 'year', toUTC: true });
              const startWeek = endYear.startOf('week');
              weekOpts.unshift(
                <option key={j + 1} value={startWeek.toISO()}>{`${formatddMMM(startWeek)} - ${formatddMMM(endYear)} ${i}`}</option>
              );
            }
          }
          opts.push([...weekOpts]);
        }
        return opts;
      case LearnerActivityTimeMode.WEEK:
        const prevYearOpts = [];
        const thisMonth = DateTime.local().month;
        const months = Info.months().slice();
        const fullMonthOpts = (year: number) =>
          months.map((month, i) => {
            return (
              <option key={i} value={getUtcIsoDateTimeFromObject({ year, month: i + 1, startOf: 'month' })}>{`${month} ${year}`}</option>
            );
          });
        const currentYearOpts = fullMonthOpts(currentYear).slice(0, thisMonth).reverse();
        // TODO: change this to only include only years since the user has been onboarded
        for (let i = currentYear - 1; i > 2015; i--) {
          prevYearOpts.push([...fullMonthOpts(i).reverse()]);
        }
        return [[...currentYearOpts], ...prevYearOpts];
      case LearnerActivityTimeMode.MONTH:
        // TODO: change this to only include years since the user has been onboarded
        for (let i = currentYear; i > 2015; i--) {
          opts.push(
            <option key={i} value={DateTime.fromJSDate(new Date(i.toString())).toUTC().toISO()}>
              {i}
            </option>
          );
        }
        return [[...opts]];
      default:
        return [[]];
    }
  }, []);

  const getWeekRange = (i: number, numWeeksInYear: number, year: number): WeekRangeObject => {
    // Only set to end of year if week overflows into january
    if (i === numWeeksInYear && getDateTimeFromObject({ weekYear: year, weekNumber: i, endOf: 'week' }).month === 1) {
      return {
        start: getUtcIsoDateTimeFromObject({ weekYear: year, weekNumber: i, startOf: 'week' }),
        end: getUtcIsoDateTimeFromObject({ year, endOf: 'year' }),
      };
    }
    // only set to beginning of year if the first week of the year technically begins in december
    if (i === 1 && getDateTimeFromObject({ weekYear: year, weekNumber: i, startOf: 'week' }).month === 12) {
      return {
        start: getUtcIsoDateTimeFromObject({ year, startOf: 'year' }),
        end: getUtcIsoDateTimeFromObject({ weekYear: year, weekNumber: i, endOf: 'week' }),
      };
    }
    return {
      start: getUtcIsoDateTimeFromObject({ weekYear: year, weekNumber: i, startOf: 'week' }),
      end: getUtcIsoDateTimeFromObject({ weekYear: year, weekNumber: i, endOf: 'week' }),
    };
  };

  const shouldAddFinalWeek = (end: string, year: number) => {
    return (
      getUtcDateTimeFromIso(end) < getDateTimeFromObject({ year, toUTC: true, endOf: 'year' }) &&
      // stop current year options adding the final week of the year
      getUtcDateTimeFromIso(end).plus({ days: 7 }).year !== year
    );
  };

  return (
    <Widget qa="learner-activity-widget">
      <Widget.Header>
        <Widget.Title qa="learner-activity-widget-title">{title}</Widget.Title>
      </Widget.Header>
      <Widget.Body qa="learner-activity-widget-body">
        <LearnerActivityWidgetBody
          startTime={startTime}
          endTime={endTime}
          timeBucketType={timeMode}
          userId={props.userId}
          metricMode={metricMode}
          handleMetricModeChange={handleMetricModeChange}
          handleTimeModeChange={handleTimeModeChange}
          handleTimePeriodChange={handleTimePeriodChange}
          handleNextClick={handleNextClick}
          handlePrevClick={handlePrevClick}
          rangeOptions={rangeOptions}
          rangeIndex={rangeIndex}
          isFirst={rangeIndex === flattenedOptions.length - 1}
          isLast={rangeIndex === 0}
          courseIds={props.courseIds}
          chartHeight={props.chartHeight}
          subjectIds={props.subjectIds}
          subjectGroupIds={props.subjectGroupIds}
          testPracticeFilter={props.testPracticeFilter}
        />
      </Widget.Body>
    </Widget>
  );
};

export default LearnerActivityWidget;
