import { Button } from '@ctek/design-system';
import { useAccessTokenRef } from 'century-core/core-auth/hooks/useAuth';
import { fetchDocuments } from 'century-core/media/MediaWrapper/fetchDocument';
import { useCallback, useMemo } from 'react';
import { downloadCollectedDocumentsDocx } from './docx.utils';

export const NuggetFilteredDocsDownloadButton = (props: { fragmentContent: Ctek.NuggetContent[], filters?: string[] }) => {
  const filteredFragments = useMemo(() => {
    // V3: nuggets.content?.filter(content => content.type === 'fragment') for v3
    // Polymer: nuggets.fragments
    return props.fragmentContent
      .map(content => content.items as Ctek.Fragment[])
      .flat(1)
      .filter(item => (!props.filters || props.filters.includes((item.fragmentType as Ctek.Label).name)))
      .map(item => ({
        name: item.name,
        documents: (item.documents as Ctek.Document[]).map(doc => ({
          title: doc.name,
          documentId: doc._id,
        })),
      }))
  }, [props.fragmentContent, props.filters]);
  const accessTokenRef = useAccessTokenRef();

  const downloadNuggetFilteredDocs = useCallback(async () => {
    if (filteredFragments) {
      await Promise.all((filteredFragments?.map(async fragment =>
        await Promise.all(fragment.documents.map(doc => 
          fetchDocuments(doc.documentId!, accessTokenRef.current)
        )).then((mediaDocs: Ctek.PopulatedMediaDocument[]) => ({
          fragment: fragment.name,
          documents: mediaDocs,
        })
      )))).then((docs: { fragment: string; documents: Ctek.PopulatedMediaDocument[]}[] ) => {
        downloadCollectedDocumentsDocx(docs, 'any');
      })
    }
  }, [filteredFragments, accessTokenRef])

  if (filteredFragments?.length) {
    return (
      <Button type="button" onClick={downloadNuggetFilteredDocs}>
        {props.filters?.join(' ') || 'all'} Docx
      </Button>
    )
  }
  return null;
}
export default NuggetFilteredDocsDownloadButton;
