import { gql } from '@apollo/client';
import { getNuggetPath } from 'century-core/core-apis/roentgen/queries/studentDashboard';

const recommendedNuggetsFragment = gql`
  fragment nuggetsRecommendedFragment on NuggetPathItem {
    courses {
      courseId
      name
      studyGroupIds {
        studyGroupId
      }
      icon {
        iconCode
        url
      }
      subject {
        name
      }
    }
    nugget {
      nuggetId
      name
    }
    lastResult {
      studySessionId
      isClosed
      isGraded
      endTime
      percentComplete
      percentScore
      isComplete
    }
    orderingFactorMainContributor {
      orderingFactorType
    }
  }
`;

export const recommendedNuggetsWidgetQuery = getNuggetPath(recommendedNuggetsFragment, 'nuggetsRecommendedFragment');
