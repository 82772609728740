import { Entities } from '../../reducers/entities/entities';
// https://github.com/paularmstrong/normalizr/blob/master/examples/redux/src/redux/actions.js

export type Actions = ReturnType<typeof addEntities>;

export enum ActionTypes {
  AddEntities = 'entities.AddEntities'
}

export interface AddEntitiesAction {
  entities: Entities;
  type: ActionTypes.AddEntities;
}

/**
 * Global Action to update entity data. All our data is normalised and stored into State.entities using normalizr.
 * Each slice in the reducer will only old private data for that slice and ids(refs) for the normalised entities
 *
 * @param entities Data generated by normalizr
 */
export const addEntities = (entities: Entities): AddEntitiesAction => ({
  entities,
  type: ActionTypes.AddEntities as typeof ActionTypes.AddEntities
});
