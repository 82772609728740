// customised implementation of Widget wrapper for handling loading and errors
// TODO assess if this is of any use, may have issues with forcing new renders of stateful components via loading
import { useState, useEffect, ReactElement, ReactNode } from 'react';
import { LoadingSpinner, WidgetEmptyState } from '@ctek/design-system';
import { QueryResult } from '@apollo/client';
import { ErrorReload } from 'century-core/core-components/ErrorReload/ErrorReload';

type Props = {
  query: QueryResult;
  localLoading?: boolean;
  retry?: any;
  children: any;
  emptyState?: { title: string | ReactNode; message?: string };
  isEmpty?: boolean;
  hideOldDataWhenLoading?: boolean;
};

const QueryWrapper = ({ query, children, emptyState, isEmpty, localLoading, hideOldDataWhenLoading }: Props): ReactElement => {
  const { data, loading, error, refetch } = query;
  const [initialLoad, setInitialLoadStatus] = useState(false);

  useEffect(() => {
    if (!initialLoad && !loading && data !== null) {
      setInitialLoadStatus(true);
    }
  }, [data, loading, initialLoad]);

  if (error) {
    return <ErrorReload action={() => refetch()} />;
  }

  if (emptyState && data && isEmpty) {
    return (
      <WidgetEmptyState hasPanel={true} qa="widget--empty">
        {emptyState.title && <WidgetEmptyState.Title>{emptyState.title}</WidgetEmptyState.Title>}
        {emptyState.message && <WidgetEmptyState.Message>{emptyState.message}</WidgetEmptyState.Message>}
      </WidgetEmptyState>
    );
  }

  return (
    <>
      {initialLoad && (!loading || !hideOldDataWhenLoading) && children}
      {loading && !localLoading && <LoadingSpinner type="widget" overlay={true} />}
    </>
  );
};

export default QueryWrapper;
