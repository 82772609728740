import { getContentURL } from 'century-core/core-apis/contentV2/contentV2';
import { GET } from 'century-core/core-apis/utils';

export const fetchDocuments = (documentId: string, accessToken: string): Promise<Ctek.PopulatedMediaDocument> => {
  if (!documentId || !accessToken) {
    throw Error(`Unable to fetch document with documentId: ${documentId}, either document id or access token have not been provided`);
  }
  const url = getContentURL(`/documents/${documentId}`);
  url.search = 'convert=true&populateMedia=true';

  return GET<Ctek.PopulatedMediaDocument>({ url, token: accessToken }).catch(err => {
    throw Error(`Unable to fetch document with Id: ${documentId} - ${err.message}`);
  });
};
