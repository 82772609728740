import { populateAuth } from 'century-core/core-auth/utils'; // eslint-disable-line import/no-restricted-paths
import { FetchError, FetchErrorData } from 'century-core/core-apis/fetch/utils';
import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { getDomainSettingsAccounts } from '../../accountsV3/domains/domains';
import { Auth } from 'century-core/entities/Auth/Auth';
import { EndpointPath, legacyGetUrl, PublicApiUrls } from '../../ApiProvider';
import { errors, Errors, localisedErrors } from 'century-core/entities/ErrorMessage/Errors';
import { handlePromiseRejection } from 'century-core/core-apis/utils/create-fetch-request';
import { createLocalisedErrorMessage } from 'century-core/entities/ErrorMessage/createLocalisedErrorMessage';
import { GET, PATCH, POST } from 'century-core/core-apis/utils';

interface TokenResponse {
  accessToken: string;
  refreshToken: string;
  tokenType?: string;
}

type loginWithTokenServices = 'google_id_token' | 'office365_token' | 'auth0_token' | 'openid_token';

export function loginWithPassword(username: string, password: string) {
  return createTokenAndGetAuth({
    grant_type: 'password',
    password,
    username,
  });
}

export function renewToken(refreshToken: string) {
  return createTokenAndGetAuth({
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
  });
}

export function loginWithToken(token: string, service: loginWithTokenServices) {
  return createTokenAndGetAuth({
    grant_type: service,
    [service]: token,
  });
}

function createTokenAndGetAuth<T extends object>(payload: T) {
  const url = legacyGetUrl(PublicApiUrls.Sentinel, 1, '/tokens');
  url.pathname = 'auth/v1/tokens';

  return POST<TokenResponse>({ url, body: payload })
    .then((res): Auth => {
      const data = res || {};

      return populateAuth(data);
    })
    .catch(err => {
      throw handleSentinelErrors(err);
    });
}

export function getDomainSettings(domain: string): Promise<Ctek.Accounts.DomainResponse | void> {
  if (!domain) {
    throw Error('Domain is required');
  }

  return getDomainSettingsAccounts(domain)
    .then((res: Ctek.Accounts.DomainResponse[]): Ctek.Accounts.DomainResponse => {
      return res[0];
    })
    .catch(handleAccountsErrors);
}

export function getDomainOrgSettings(): Promise<Ctek.Accounts.DomainResponse | void> {
  let subdomain = window.location.hostname;
  if (subdomain === 'localhost') {
    subdomain = 'app.century.tech';
  }
  return getDomainSettings(subdomain);
}

export function sendRecoverPasswordEmail(username: string) {
  const url = legacyGetUrl(PublicApiUrls.Sentinel, 1, '/users/recover');
  url.pathname = 'auth/v1/users/recover';

  return POST<{ status: 'ok' }>({ url, body: { username } })
    .then(res => {
      const data = res || {};

      if (data.status !== 'ok') {
        throw Error('Invalid Response');
      }

      return Promise.resolve();
    })
    .catch(err => {
      throw handleSentinelErrors(err);
    });
}

// Sentinel required for cyber essentials lockout
export function changePasswordSentinel(accessToken: string | undefined, password: string, userId?: string) {
  const selectedUser: EndpointPath = userId ? `/users/${userId}` : '/users/me';
  const url = legacyGetUrl(PublicApiUrls.Sentinel, 1, selectedUser);

  return PATCH<{ id: string; _id: string }>({ url, token: accessToken, body: { password } });
}

export function getUserLockoutStatus(accessToken: string, username: string, orgId: string, classId: string) {
  const url = legacyGetUrl(PublicApiUrls.Sentinel, 1, `/users/lockout-status/organisation/${orgId}`);
  url.search += `classId=${classId}`;
  url.search += `&userNames=${username}`;

  return GET<{ isLocked: boolean; username: string }[]>({ url, token: accessToken })
    .then(res => res?.[0]?.isLocked)
    .catch((err: Error) => {
      throw Error(`Unable to get user: ${username} lockout status in org id: ${orgId} and class id: ${classId} , ${err}`);
    });
}

export function unlockUser(accessToken: string, username: string) {
  const url = legacyGetUrl(PublicApiUrls.Sentinel, 1, '/users/unlock');

  return POST<{ status: 'ok' }>({ url, token: accessToken, body: { userIds: [username] } }).catch((err: Error) => {
    throw Error(`Unable to unlock user: ${username}, error:  ${err}`);
  });
}

function handleSentinelErrors(err: FetchError<FetchErrorData> | Error) {
  if (err instanceof TypeError && err.message === 'Failed to fetch') {
    return createLocalisedErrorMessage('localised-errors-offline');
  }

  if (!(err instanceof FetchError)) {
    return createLocalisedErrorMessage('localised-errors-webapp-error');
  }

  const errType = err.errorData && err.errorData.errType;
  const errKey = errors[errType] || 'localised-errors-service-error';

  if (errType === 'LockoutErr') {
    const error = new ErrorMessage<Errors>(errKey, localisedErrors[errKey]);
    error.values = Number(err.errorData.message.split(',')[1].trim());
    return error;
  }

  return createLocalisedErrorMessage(errKey);
}

function handleAccountsErrors(err: FetchError<FetchErrorData> | Error) {
  handlePromiseRejection('localised-errors-user-error')(err);
}

export function googleSignIn(googleIdToken: string) {
  return loginWithToken(googleIdToken, 'google_id_token');
}

export function office365SignIn(office365Token: string) {
  return loginWithToken(office365Token, 'office365_token');
}

export function auth0SignIn(auth0Token: string) {
  return loginWithToken(auth0Token, 'auth0_token');
}

export function openIdSignIn(openIdToken: string) {
  return loginWithToken(openIdToken, 'openid_token');
}
