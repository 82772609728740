import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { WidgetList, WidgetEmptyState, ModalBox } from '@ctek/design-system';
import RecentlyAttemptedWidgetListItem from './RecentlyAttemptedWidgetListItem';
import { withErrorBoundaryPartial } from 'century-core/core-components/ErrorBoundary/ErrorBoundary';
import QLAWrapper from 'century-core/features-dashboard/common/QuestionLevelAnalysis/QLAWrapper';
import { useDeviceInfo } from 'century-core/user-preferences/DeviceInfoContext';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
interface RecentNuggetProps {
  nuggets: Ctek.Roentgen.NuggetPathItem[];
  userId: string;
  widgetMode: DashboardUserMode;
}

const RecentlyAttemptedWidgetList = ({ nuggets, userId, widgetMode }: RecentNuggetProps) => {
  const [shouldShowQLA, setQLASwitch] = useState(false);
  const [nuggetIdForQLA, setNuggetIdForQLA] = useState('');
  const intl = useIntl();
  const { deviceType } = useDeviceInfo();

  const handleNuggetClick = (id: string) => {
    setNuggetIdForQLA(id);
    setQLASwitch(true);
  };

  if (nuggets.length === 0) {
    return (
      <WidgetEmptyState hasPanel={true}>
        <WidgetEmptyState.Title>
          <FormattedMessage id="widget--recently-completed-nuggets--empty-title" defaultMessage="Go and attempt a nugget!" />
        </WidgetEmptyState.Title>
      </WidgetEmptyState>
    );
  }

  return (
    <>
      <WidgetList>
        {nuggets.map((nugget: Ctek.Roentgen.NuggetPathItem) => (
          <RecentlyAttemptedWidgetListItem key={nugget.nugget.nuggetId} nugget={nugget} handleNuggetClick={handleNuggetClick} />
        ))}
      </WidgetList>
      {
        <ModalBox
          dismissLabel={intl.formatMessage({ id: 'modal-dismiss-button', defaultMessage: 'Dismiss' })}
          floatingDismiss={deviceType === 'phone' ? false : true}
          modalActive={shouldShowQLA}
          onExit={() => setQLASwitch(false)}
          size="qla"
          qa="qla-modal"
        >
          <ModalBox.Body>
            <QLAWrapper nuggetId={nuggetIdForQLA} userId={userId} />
          </ModalBox.Body>
        </ModalBox>
      }
    </>
  );
};

export default withErrorBoundaryPartial(RecentlyAttemptedWidgetList);
