import { GET } from 'century-core/core-apis/utils';
import { getLearnURL } from '../learn';

export interface GetCourseplansOpts {
  courseplanIds: string[];
  userId: string;
  returnEmptyActivity?: boolean;
  restrictByCoursePlanStartTime?: boolean;
}

/**
 * Retrieves coursePlan data from analytics
 */
export function getCourseplans(opts: GetCourseplansOpts, accessToken: string) {
  const url = getLearnURL(`/analytics/users/${opts.userId}/courseplans/${opts.courseplanIds.join(',')}`);
  const search: string[] = [];

  if (opts.returnEmptyActivity) {
    search.push('returnEmptyActivity=true');
  }
  if (opts.restrictByCoursePlanStartTime) {
    search.push('restrictByCoursePlanStartTime=true');
  }

  if (search.length > 0) {
    url.search = '?' + search.join('&');
  }
  return GET<Ctek.CoursePlan[]>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get courseplans for user id ${opts.userId}  and courseplans id ${opts.courseplanIds.join(',')},
       ${err}`);
  });
}
