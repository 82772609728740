import QuestionContent from 'century-core/core-components/QuestionContent/QuestionContent';
import { withErrorBoundaryPartial } from 'century-core/core-components/ErrorBoundary/ErrorBoundary';

interface QLAResponseProps {
  answerGroup: Ctek.EnrichedAnswerGroup;
  analytics: Ctek.AbacusStudySessionQuestionResult;
  genericIncorrectFeedback?: Ctek.Content[];
}

export const QuestionResponse = ({ answerGroup, analytics, genericIncorrectFeedback }: QLAResponseProps) => {
  const answer = answerGroup.answers.find(a => a.id === (analytics as any).answers[0]?.answerId) || null;

  if (answer?.responseContent?.length! > 0) {
    return (
      <>
        {answer?.responseContent?.map(content => (
          <QuestionContent content={content} key={content.id} />
        ))}
      </>
    );
  }
  if (analytics.correct) {
    if (answerGroup.defaultCorrectResponse?.length! > 0) {
      return (
        <>
          {answerGroup.defaultCorrectResponse?.map(content => (
            <QuestionContent content={content} key={content.id} />
          ))}
        </>
      );
    }
  }
  if (!analytics.correct) {
    if (answerGroup.defaultIncorrectResponse?.length! > 0) {
      return (
        <>
          {answerGroup.defaultIncorrectResponse?.map(content => (
            <QuestionContent content={content} key={content.id} />
          ))}
        </>
      );
    }
  }
  if (!analytics.correct && genericIncorrectFeedback && genericIncorrectFeedback?.length > 0) {
    return (
      <>
        {genericIncorrectFeedback?.map(content => (
          <QuestionContent content={content} key={content.id} />
        ))}
      </>
    );
  }
  return null;
};

export default withErrorBoundaryPartial(QuestionResponse);
