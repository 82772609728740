import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { FetchError } from './utils';
import { createLocalisedErrorMessage } from 'century-core/entities/ErrorMessage/createLocalisedErrorMessage';

export function parseAPIError(apiError: any, defaultError: ErrorMessage<any>) {
  if (apiError instanceof TypeError && apiError.message === 'Failed to fetch') {
    return createLocalisedErrorMessage('localised-errors-offline');
  }

  try {
    if (apiError instanceof FetchError && apiError.errorData.errors && Array.isArray(apiError.errorData.errors)) {
      const innerErrors = (apiError.errorData.errors as any[])
        .map(innerError => {
          if ('id' in innerError && 'defaultMessage' in innerError) {
            const e = new ErrorMessage<string>(innerError.id, innerError.defaultMessage);
            e.values = innerError.values;
            return e;
          }
          return defaultError;
        })
        .filter((e, i, es) => {
          const dupeIndex = es.findIndex(e2 => e2.code === e.code && JSON.stringify(e2.values) === JSON.stringify(e.values));
          if (dupeIndex !== i) {
            return false;
          }
          return true;
        });

      if (innerErrors.length > 1) {
        const error = new ErrorMessage<string>(innerErrors[0].code, innerErrors[0].message);
        error.values = innerErrors[0].values;
        error.innerErrors = innerErrors;
        return error;
      } else if (innerErrors.length === 1) {
        return innerErrors[0];
      }
    }
  } catch (e) {
    // if parsing fails, don't do anything special - just throw the default error
  }
  throw defaultError;
}
