import { Avatar, EntityHeader, ProgressThumbnail } from '@ctek/design-system';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  completionPercentage: number;
  scorePercentage: number;
  name: string;
  avatar: any;
  changeCourseSelection: (a: string) => void;
  courses: Ctek.Course[];
  selectedCourse: string;
}

export default function LearnerOverviewHeaderMain(props: Props) {
  const { courses, selectedCourse, changeCourseSelection } = props;
  const intl = useIntl();

  const coursesOptions = useMemo(
    () =>
      courses.map((course, i) => (
        <option data-testid={`course-option-item-${i}`} key={course._id} value={course._id}>
          {course.name}
        </option>
      )),
    [courses]
  );

  const currentCourseName = useMemo(() => courses.find(c => c._id === selectedCourse)?.name, [courses, selectedCourse]);

  const getAdjacentCourse = useCallback(
    (idxChange: number) => {
      const courseIdx = courses?.findIndex(v => v._id === selectedCourse);
      if (courseIdx < 0 || !courses?.length) {
        return;
      }
      let newIdx = courseIdx + idxChange;
      newIdx = newIdx >= courses?.length ? 0 : newIdx < 0 ? courses?.length - 1 : newIdx;
      if (courses?.[newIdx]?._id) {
        changeCourseSelection(courses?.[newIdx]?._id!);
      }
    },
    [courses, selectedCourse, changeCourseSelection]
  );
  const getNextCourse = useCallback(() => getAdjacentCourse(1), [getAdjacentCourse]);
  const getPrevCourse = useCallback(() => getAdjacentCourse(-1), [getAdjacentCourse]);

  return (
    <EntityHeader qa="student-dashboard-courses-header">
      <EntityHeader.Main>
        <ProgressThumbnail
          size={120}
          completionPercentage={props.completionPercentage}
          scorePercentage={props.scorePercentage}
          hasPlaceholder={true}
          qa="learner-overview-header-progress"
        >
          <Avatar src={props.avatar} qa="learner-overview-header-avatar" />
        </ProgressThumbnail>
        <EntityHeader.Title>
          <span data-private={true}>{props.name}</span>
        </EntityHeader.Title>
      </EntityHeader.Main>
      <EntityHeader.Switcher>
        <EntityHeader.PreviousButton onClick={getPrevCourse} qa="prev-course-button" />
        <EntityHeader.SwitcherMenu>
          <EntityHeader.CurrentView>{currentCourseName}</EntityHeader.CurrentView>
          <select
            value={props.selectedCourse}
            onChange={e => {
              const newCourse = courses.find(c => c._id === e.target.value);
              if (newCourse?._id) {
                changeCourseSelection(newCourse._id);
              }
            }}
            data-testid="learner-overview-header-course-select"
          >
            <option disabled={true}>{intl.formatMessage({ id: 'course-select-placeholder', defaultMessage: 'Select Course' })}</option>
            {coursesOptions}
          </select>
        </EntityHeader.SwitcherMenu>
        <EntityHeader.NextButton onClick={getNextCourse} qa="next-course-button" />
      </EntityHeader.Switcher>
    </EntityHeader>
  );
}
