import Auth from 'century-core/entities/Auth/Auth';
import logout from 'century-core/core-auth/lib/logout';
import renewAccessToken from 'century-core/core-auth/lib/renewAccessToken';
import { isAccessTokenValid, parseAuthFromLocalstorage } from 'century-core/core-auth/utils';

function isReduxAuthTokenSynced(reduxAuth: Auth) {
  const localStorageAuth: Auth = parseAuthFromLocalstorage() || {};
  return reduxAuth.accessToken && localStorageAuth.accessToken && reduxAuth.accessToken === localStorageAuth.accessToken;
}

// HACK This if mostly for Polymer routes to have a proper token
export function checkAccessTokenOnRouteChange(
  auth: Auth,
  renewTokenTimer: NodeJS.Timeout,
  setAuthEverywhere: (auth: Auth) => void
): Promise<void> {
  const localStorageAuth: Auth = parseAuthFromLocalstorage() || {};

  // If no local storage auth found, we log tab out
  if (!localStorageAuth?.refreshToken && auth?.refreshToken) {
    logout(renewTokenTimer, window.location.pathname);
    return Promise.resolve();
  }
  // no action as no local storage auth and no context auth
  if (!localStorageAuth?.refreshToken && !auth?.refreshToken) {
    return Promise.resolve();
  }

  // if localstorage auth is valid and context does not match, update context
  if (localStorageAuth?.refreshToken && isAccessTokenValid(localStorageAuth)) {
    if (!isReduxAuthTokenSynced(auth)) {
      setAuthEverywhere(localStorageAuth);
      // update context to use local storage auth
      // if not, no action as local storage matches context
    }
    return Promise.resolve();
  }

  if (localStorageAuth?.accessToken && !auth.accessToken) {
    return Promise.resolve();
  }

  // if all else fails renew accessToken
  renewAccessToken(localStorageAuth || {}, renewTokenTimer, setAuthEverywhere);
  return Promise.resolve();
}
