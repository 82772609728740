import { getClassCategories } from 'century-core/core-apis/accountsV2/classCategories/classCategories';
import React from 'react';
import { useDataLoader } from '../hooks/use-data-loader';
import { createContextAndHook } from './context-factory';
import { useAccessToken } from 'century-core/core-auth/hooks/useAuth';
import { sortDateData } from '../selectors/sortSelectors/sortSelectors';

type ClassCategoriesContext = {
  classCategories: Ctek.ClassCategory[];
  currentClassCategory?: Ctek.ClassCategory;
};

const [Provider, useClassCategories] = createContextAndHook<ClassCategoriesContext>('ClassCategories');

interface Props {
  children: React.ReactNode;
}

const ClassCategoriesProvider = ({ children }: Props) => {
  const fetchClassCategories = React.useCallback((token: string, signal: AbortSignal) => getClassCategories(token, undefined, signal), []);
  const { data, load } = useDataLoader(fetchClassCategories);
  const classCategories = sortDateData(data ?? [], 'startDate', false);
  const currentClassCategory = selectCurrentClassCategory(classCategories) ?? classCategories[0];

  // there's a race condition where token is not always available available on mount so can't use fetchOnMount from useDataLoader
  const accessToken = useAccessToken();
  const isLoaded = React.useRef(false);
  React.useEffect(() => {
    if (!isLoaded.current && accessToken) {
      isLoaded.current = true;
      load();
    }
  }, [accessToken, load]);

  return <Provider value={{ classCategories, currentClassCategory }}>{children}</Provider>;
};

export { ClassCategoriesProvider, useClassCategories };

const selectCurrentClassCategory = (classCategories: Ctek.ClassCategory[]) => {
  const now = Date.now();
  return classCategories.find(cat => {
    const startDate = Date.parse(cat.startDate);
    const endDate = Date.parse(cat.endDate || '');
    return now >= startDate && now <= endDate;
  });
};
