import { Auth, ViewState } from 'century-core/entities/Auth/Auth';
import { Actions, ActionTypes } from '../../actions/auth/auth';

export const defaultData: Auth = {
  accessToken: undefined,
  accessTokenData: undefined,
  error: undefined,
  orgSetting: undefined,
  refreshToken: undefined,
  refreshTokenData: undefined,
  viewState: undefined,
};

export default function auth(state = defaultData, action: Actions): Auth {
  switch (action.type) {
    case ActionTypes.LoggingIn:
      return { ...state, viewState: ViewState.LoggingIn };
    case ActionTypes.LoggedOut:
      return { ...defaultData, viewState: ViewState.LoginForm };
    case ActionTypes.LoggedIn:
      return {
        ...state,
        ...action.auth,
        viewState: ViewState.LoggedIn,
      };
    case ActionTypes.ShowLoginForm:
      return {
        ...state,
        viewState: ViewState.LoginForm,
      };
    case ActionTypes.ClearError:
      return {
        ...state,
        error: undefined,
        viewState: ViewState.LoginForm,
      };
    case ActionTypes.Error:
      return { ...state, viewState: ViewState.Error, error: action.error };
    case ActionTypes.AddOrgSettings:
      return {
        ...state,
        orgSetting: action.settings || null,
      };
    default:
      return state;
  }
}
