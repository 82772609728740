import { useEffect, useState } from 'react';
import {
  StreakOverview,
  Heading,
  StreakStatItem,
  StreakStatIconTypes,
  StreakStatIcon,
  StreakProgress,
  StreakNuggetGoal,
  StreakNuggetCount,
  LoadingSpinner,
  TooltipHelp,
  TooltipHelpPlacements,
  TriggerIcons,
  StreakProgressFlame,
  PageContent,
} from '@ctek/design-system';
import { getUserStreaks } from 'century-core/core-apis/scout';
import { ErrorReload } from 'century-core/core-components/ErrorReload/ErrorReload';
import { FormattedMessage } from 'react-intl';
import { streaksNuggetsCdsCount } from 'century-core/core-utils/streaks/streaksHelper';

interface Props {
  userid: string;
  accesstoken: string;
}

enum StreakType {
  THIS_WEEK = 'nuggets_this_week',
  ALL_TIME = 'nugget_streak',
}

export default function StreaksWidget(props: Props) {
  const [thisWeekStreak, setThisWeekStreak] = useState<Ctek.Scout.Achievement | null>(null);
  const [allTimeStreak, setAllTimeStreak] = useState<Ctek.Scout.Achievement | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchStreak();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStreak = async () => {
    if (props.userid && props.accesstoken) {
      try {
        const streaks = await getUserStreaks(props.userid, props.accesstoken);
        const thisWeekStreak = streaks.achievements.find(a => a.name === StreakType.THIS_WEEK) || null;
        const allTimeStreak = streaks.achievements.find(a => a.name === StreakType.ALL_TIME) || null;
        setThisWeekStreak(thisWeekStreak);
        setAllTimeStreak(allTimeStreak);
        setLoading(false);
      } catch {
        setError(true);
      }
    }
  };

  if (error) {
    return <ErrorReload action={() => fetchStreak()} />;
  }

  if (loading) {
    return <LoadingSpinner type="widget" />;
  }

  if (!thisWeekStreak || !allTimeStreak) {
    return <div>No streaks to display</div>;
  }

  return (
    <PageContent>
      <StreakOverview>
        <StreakOverview.Header>
          <Heading.SectionTitle>
            <FormattedMessage id="streaks-title" defaultMessage="My Streak" />
          </Heading.SectionTitle>
          <StreakOverview.Help>
            <TooltipHelp
              qa="rlp-info-button"
              label={<FormattedMessage id="help-tooltip-label" defaultMessage="Help" />}
              placement={TooltipHelpPlacements.BOTTOM_END}
              triggerIcon={TriggerIcons.HELP}
            >
              <FormattedMessage
                id="streaks-tooltip"
                defaultMessage={
                  // could potentially handle interval dynamically?
                  thisWeekStreak.progressRequired
                    ? 'Maintain a streak by completing {stepsTotal} {stepsTotal, plural, =1 {nugget} other {nuggets}} every week'
                    : 'Maintain a streak by completing your goal every week'
                }
                values={{ stepsTotal: thisWeekStreak.progressRequired }}
              />
            </TooltipHelp>
          </StreakOverview.Help>
        </StreakOverview.Header>
        <StreakOverview.Current>
          <StreakProgress>
            {/* <StreakProgress.Deadline>
            <Heading.ItemTitle>You have 6 days remaining to reach your goal</Heading.ItemTitle>
          </StreakProgress.Deadline> */}
            <StreakProgress.Displays>
              <StreakProgress.Meter>
                <StreakProgressFlame streakComplete={thisWeekStreak.progressAchieved >= thisWeekStreak.progressRequired} />
              </StreakProgress.Meter>
              <StreakProgress.Count>
                <StreakNuggetGoal>
                  <StreakNuggetGoal.Progress>
                    <StreakNuggetGoal.Completed>{thisWeekStreak.progressAchieved}</StreakNuggetGoal.Completed>
                    <StreakNuggetGoal.Aim>/ {thisWeekStreak.progressRequired}</StreakNuggetGoal.Aim>
                  </StreakNuggetGoal.Progress>
                </StreakNuggetGoal>
                <StreakNuggetCount
                  nuggetsCompleted={streaksNuggetsCdsCount(thisWeekStreak.progressAchieved)}
                  nuggetsGoal={thisWeekStreak.progressRequired}
                />
              </StreakProgress.Count>
            </StreakProgress.Displays>
          </StreakProgress>
        </StreakOverview.Current>
        <StreakOverview.Stats>
          <StreakStatItem>
            <StreakStatIcon icon={StreakStatIconTypes.CURRENT} />
            <StreakStatItem.Details>
              <StreakStatItem.Title>
                <FormattedMessage id="streaks-current-streak" defaultMessage="Current streak" />
              </StreakStatItem.Title>
              <StreakStatItem.Duration>
                <StreakStatItem.Count>{allTimeStreak.progressAchieved}</StreakStatItem.Count>
                <StreakStatItem.Period>
                  {allTimeStreak.progressAchieved === 1 ? (
                    <FormattedMessage id="time-component-weeks-singular" defaultMessage="week" />
                  ) : (
                    <FormattedMessage id="time-component-weeks-plural" defaultMessage="weeks" />
                  )}
                </StreakStatItem.Period>
              </StreakStatItem.Duration>
            </StreakStatItem.Details>
          </StreakStatItem>
          <StreakStatItem>
            <StreakStatIcon icon={StreakStatIconTypes.LONGEST} />
            <StreakStatItem.Details>
              <StreakStatItem.Title>
                <FormattedMessage id="streaks-longest-streak" defaultMessage="Longest streak" />
              </StreakStatItem.Title>
              <StreakStatItem.Duration>
                <StreakStatItem.Count>{allTimeStreak.progressBest}</StreakStatItem.Count>
                <StreakStatItem.Period>
                  {allTimeStreak.progressBest === 1 ? (
                    <FormattedMessage id="time-component-weeks-singular" defaultMessage="week" />
                  ) : (
                    <FormattedMessage id="time-component-weeks-plural" defaultMessage="weeks" />
                  )}
                </StreakStatItem.Period>
              </StreakStatItem.Duration>
            </StreakStatItem.Details>
          </StreakStatItem>
        </StreakOverview.Stats>
      </StreakOverview>
    </PageContent>
  );
}
