import { gql } from '@apollo/client';
import { getCoursePath } from 'century-core/core-apis/roentgen/queries/studentDashboard';

const coursesFragmentAttributes = gql`
  fragment coursesListFragment on CoursePathItem {
    name
    courseId
    labels
    subject {
      name
      labelId
    }
    activity {
      durationActual
      percentComplete
      completeNuggets
      totalNuggets
      percentScore
    }
    icon {
      iconCode
      url
    }
  }
`;

export const coursesWidgetQuery = getCoursePath(coursesFragmentAttributes, 'coursesListFragment');
