const avatars: string[] = [
  'female/female1.png',
  'female/female2.png',
  'female/female3.png',
  'female/female4.png',
  'female/female5.png',
  'female/female6.png',
  'female/female7.png',
  'female/female8.png',
  'female/female9.png',
  'female/female10.png',
  'female/female11.png',
  'female/female12.png',
  'female/female13.png',
  'female/female14.png',
  'female/female15.png',
  'female/female16.png',
  'female/female17.png',
  'female/female18.png',
  'female/female19.png',
  'female/female20.png',
  'female/female21.png',
  'female/female22.png',
  'female/female23.png',
  'female/female24.png',
  'male/male1.png',
  'male/male2.png',
  'male/male3.png',
  'male/male4.png',
  'male/male5.png',
  'male/male6.png',
  'male/male7.png',
  'male/male8.png',
  'male/male9.png',
  'male/male10.png',
  'male/male11.png',
  'male/male12.png',
  'male/male13.png',
  'male/male14.png',
  'male/male15.png',
  'male/male16.png',
  'male/male17.png',
  'male/male18.png',
  'male/male19.png',
  'male/male20.png',
  'male/male21.png',
  'male/male22.png',
  'male/male23.png',
  'male/male24.png',
  'noavatar.png',
];

// TODO to rework following improvement of how avatars are being serve
export function getAvatars() {
  return Promise.resolve(avatars.map(avatar => `@ctek/ct-shared-media/dist/img/avatars/${avatar}`));
}
