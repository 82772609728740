import { atoms } from './atoms';
import { charts } from './charts';
import elementsLearn from './elementsLearn';
import { molecules } from './molecules';
import { MultipleChoiceQuestion } from '../century-core/question-types/elementsQuestionTypes/MultipleChoice/MultipleChoice';
import { ExactAnswer } from '../century-core/question-types/elementsQuestionTypes/ExactAnswer/ExactAnswer';
import {
  AlternativesNav,
  AssessmentReveals,
  AssignedUser,
  AssignmentPanel,
  Avatar,
  AvatarList,
  Checkbox,
  CheckboxList,
  CheckboxListSimple,
  CircularStatusIndicator,
  ClickArea,
  ControlsAllNone,
  DismissButton,
  Dropdown,
  DropdownControls,
  Hr,
  ErrorReload,
  Img,
  InputError,
  LearnerProgress,
  LearnerProgressList,
  LoadingSpinner,
  MultilineCheckboxTree,
  NoDataMessage,
  OptionsDropdown,
  Panel,
  SelectionCounter,
  InputDate,
  InputNumberDeprecated,
  InputPasswordDeprecated,
  InputRange,
  InputSuggested,
  InputTextDeprecated,
  Tag,
  Toasts,
  TooltipHelp,
  TreeCheckboxList,
  TreeNodeList,
  User,
} from '@ctek/design-system';
export const layout = {
  AlternativesNav,
  AssessmentReveals,
  AssignedUser,
  AssignmentPanel,
  Avatar,
  AvatarList,
  Checkbox,
  CheckboxList,
  CheckboxListSimple,
  CircularStatusIndicator,
  ClickArea,
  ControlsAllNone,
  DismissButton,
  Dropdown,
  DropdownControls,
  ErrorReload,
  ExactAnswer,
  Hr,
  Img,
  InputError,
  Label: atoms.Label,
  LearnerProgress,
  LearnerProgressList,
  LoadingSpinner,
  MatchingAnswerDraggable: elementsLearn.MatchingAnswerDraggable,
  MultilineCheckboxTree,
  MultipleChoiceQuestion,
  NoDataMessage,
  OptionsDropdown,
  Panel,
  SelectionCounter,
  Tag,
  Toasts,
  TooltipHelp,
  TreeCheckboxList,
  TreeNodeList,
  User,
  Wizard: molecules.Wizard,
  charts: {
    BarChart: charts.BarChartD3,
  },
  forms: {
    FormikFormField: molecules.FormikFormField,
  },
  inputs: {
    InputDate,
    InputNumberDeprecated,
    InputPasswordDeprecated,
    InputRange,
    InputSuggested,
    InputTextDeprecated,
  },
};
export const Learn = elementsLearn;
export default layout;
