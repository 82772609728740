import { SubjectLabel as SubjectLabelCds } from '@ctek/design-system';
import { formatPhraseName } from 'century-core/core-utils/utils/intl/intl';
import { FormattedMessage, useIntl } from 'react-intl';

export const SubjectColouredLabel = ({ subjectName }: { subjectName: string }) => {
  const subjectKey = formatPhraseName(subjectName);

  return (
    <SubjectLabelCds subject={subjectKey}>
      <FormattedMessage id={`label-subject-${subjectKey}`} defaultMessage={subjectName} />
    </SubjectLabelCds>
  );
};

export const SubjectName = ({ subjectName }: { subjectName: string }) => {
  const subjectKey = formatPhraseName(subjectName);

  return <FormattedMessage id={`label-subject-${subjectKey}`} defaultMessage={subjectName} />;
};

export const useTranslateSubject = () => {
  const { formatMessage } = useIntl();

  return (subjectName?: string) => {
    if (!subjectName) {
      return formatMessage({ id: 'unknown-field', defaultMessage: 'Unknown' });
    }

    const subjectKey = formatPhraseName(subjectName);

    return formatMessage({ id: `label-subject-${subjectKey}`, defaultMessage: subjectName });
  };
};
