const reportedErrors = {
  'localised-errors-assignments-error': "We're unable to get the assignments data.",
  'localised-errors-b2c-assign-courses-error': "We're unable to assign courses.",
  'localised-errors-b2c-cancel-subscription-error': "We're unable to cancel your subscription.",
  'localised-errors-b2c-delete-learner-error': "We're unable to delete this learner.",
  'localised-errors-b2c-error': "We're unable to fetch the data for your account.",
  'localised-errors-b2c-register-error': "We're unable to register your account",
  'localised-errors-b2c-register-learner-error': "We're unable to set up this learner account",
  'localised-errors-b2c-restore-subscription-error': "We're unable to restore your subscription.",
  'localised-errors-b2c-retrieve-courses-error': "We're unable to fetch this learner's courses",
  'localised-errors-b2c-subscription-exceeded': 'You have exceeded the number of learners allowed by your subscription.',
  'localised-errors-b2c-update-error': "We're unable to update your subscription.",
  'localised-errors-b2c-update-learner-error': "We're unable to update this learner.",
  'localised-errors-b2c-update-learner-courses-error': "We're unable to update this learners courses.",
  'localised-errors-b2c-get-region-error': 'There was an error detecting your current region.',
  'localised-errors-cdn-error': "We're unable to connect to the cdn.",
  'localised-errors-corrupt-data': 'There is a problem with your user. Please contact support.',
  'localised-errors-get-goal-plans-error': "We're unable to get existing goal plans.",
  'localised-errors-get-goals-error': "We're unable to get assignment goals.",
  'localised-errors-get-grading-error': "We're unable to get task data.",
  'localised-errors-get-submissions-error': "We're unable to get submissions data.",
  'localised-errors-guardian-dependants-error': "We're unable to get the guardian dependants list.",
  'localised-errors-guardian-overview-error': "We're unable to get the guardian overview data.",
  'localised-errors-media-file-error': "We're unable to manage this file.",
  'localised-errors-my-assignments-tab-error': "We're unable to get the assignments data for my assignments tab.",
  'localised-errors-my-task-tab-error': "We're unable to get the task data for my tasks tab.",
  'localised-errors-post-grading-feedback-error': "We're unable to submit the feedback",
  'localised-errors-post-submissions-feedback-error': "We're unable to submit the feedback",
  'localised-errors-qla-error': "We're unable to get the QLA data.",
  'localised-errors-s3-error': "We're unable to connect to s3.",
  'localised-errors-study-session-create-error': 'Study session could not be created',
  'localised-errors-study-session-duplicate-error': 'Study session opened twice for assignment task',
  'localised-errors-task-submission-error': 'External or theory task could not be submitted.',
  'localised-errors-update-goals-error': "We're unable to update goals.",
  'localised-errors-user-error': "We're unable to get the user profile.",
  'localised-errors-user-profile-generic-error': 'There was an error with your user profile.',
  'localised-errors-webapp-error': "We're unable to log you into the application. Please contact support.",
};
// TODO add messages for task error

export const minorErrors = {
  'generic-fetch-error': 'Request failed.',
  'localised-errors-add-email-duplicate-error': 'Sorry, this email address is already being used by someone else.',
  'localised-errors-change-username-duplicate-error': 'Duplicated Username.',
  'localised-errors-class-code-error': "We're unable to validate that class code.",
  'localised-errors-feedback-fetch-error': "We're unable to retrieve the available options.",
  'localised-errors-feedback-submit-error': "We're unable to submit your feedback.",
  'localised-errors-invalid-credentials': 'User not found or password incorrect.',
  'localised-errors-locked-out': 'Your account has been locked out for 5 minutes due to too many incorrect login attempts',
  'localised-errors-offline': 'Unable to connect to the server. Please check your internet connection.',
  'localised-errors-organisation-expired': 'Your Organisation has expired.',
  'localised-errors-password-not-set': 'Your account was never enabled with a password. Please contact support.',
  'localised-errors-recover-password-expired-token': 'Please request a new password reset email if you need to change your password.',
  'localised-errors-service-error': 'There is a problem with the authentication service. Please try again in a couple of minutes.',
  'localised-errors-learner-missing-email': 'To reset your password, you need to have an email address added to your profile.',
  'localised-errors-table-options-error': "Table options 'Show Coloured Cells' and 'Show Symbols' can't both be true.",
  'localised-errors-task-error': 'To be defined',
  'localised-errors-fetch-assessment-cohorts': 'Oops, something went wrong and we are unable to fetch your assessment cohorts.',
  'localised-errors-fetch-smart-assessments-for-class': 'Oops, something went wrong and we are unable to fetch your smart assessments.',
  'localised-errors-reset-smart-assessment-for-student':
    'Oops, something went wrong and we are unable to reset the assessment of this student.',
  'localised-errors-fetch-bulk-certificate-download-link':
    'Oops, something went wrong and we were unable to fetch the certificate download link',
  'b2c-localised-error-invalid-field-billingTerm': 'This billing term is invalid.',
  'b2c-localised-error-invalid-field-consentGiven': 'You must agree to the terms.',
  'b2c-localised-error-invalid-field-email': 'You must provide a valid email address.',
  'b2c-localised-error-invalid-field-firstName': 'You must provide a valid first name.',
  'b2c-localised-error-invalid-field-lastName': 'You must provide a valid last name.',
  'b2c-localised-error-invalid-field-learnerId': 'You must provide a valid learner.',
  'b2c-localised-error-invalid-field-learners': 'You must provide a valid number of learners.',
  'b2c-localised-error-invalid-field-name': 'You must provide a valid name.',
  'b2c-localised-error-invalid-field-password': 'You must provide a password of at least 6 characters.',
  'b2c-localised-error-invalid-field-plan': 'You must select a valid subscription plan.',
  'b2c-localised-error-invalid-field-subjects': 'You must provide a valid set of subjects.',
  'b2c-localised-error-invalid-field-token': 'You must provide a valid payment method',
  'b2c-localised-error-invalid-field-username': 'You must provide a valid username',
  'b2c-localised-error-fetch-available-plans': 'Oops, something went wrong and we are unable to get all available plans for you',
  'b2c-localised-error-fetch-order-summary': 'Oops, something went wrong and we are unable to fetch your order summary',
  'b2c-localised-error-promo-code-validation': 'This is not a valid coupon',
  'b2c-localised-error-fetch-user-subscription-information':
    'Oop, something went wrong and we are unable to fetch your subscription information',
  'b2c-localised-error-cancel-subscription': 'Oops, something went wrong and we cannot cancel your subscription',
  'b2c-localised-error-restore-subscription': 'Oops, something went wrong and we cannot restore your subscription',
  'b2c-localised-error-update-subscription': 'Oops, something went wrong and we cannot update your subscription',
  'b2c-localised-error-assign-learner-to-product': 'Oops, something went wrong and we cannot assign this learner to the chosen plan',
  'b2c-localised-errors-privileged-create-user-duplicate-email': 'This email already belongs to an account',
  'b2c-localised-errors-privileged-create-user-duplicate-username': 'This username is already being used',
  'b2c-localised-error-fetch-user-subscription-history': 'Oop, something went wrong and we are unable to fetch your subscription history',
  'b2c-localised-error-add-customer-source': 'Oop, something went wrong and we are unable to your payment details',
  'admin-localised-error-class-name-already-exists':
    'Validation failed - A class with that name already exists in your institution. Please choose a different name.',
  'b2c-localised-error-create-setup-intent': 'Oops, something went wrong and we are unable to create a payment session.',
  'b2c-localised-error-reactivate-scheduled-product-removal': 'Oop, something went wrong and we are unable to reactivate this learner',
  'b2c-localised-error-assign-learner-mock-test': 'Oop! Sorry we cannot assign mock test to this learner. Please try again later.',
  'b2c-localised-error-uassign-learner-mock-test': 'Oop! Sorry we cannot unassign mock test to this learner. Please try again later.',
  'b2c-localised-error-fetch-plan-data': 'Oops, something went wrong and we were unable to get the course list.',
  'b2c-localised-error-update-learner-mock-test': 'Oop! Sorry we cannot update mock test to this learner. Please try again later.',
};

export const localisedErrors = Object.assign(minorErrors, reportedErrors);

export type Errors = keyof typeof localisedErrors;

export const errors: Record<string, Errors> = {
  ClassCodeErr: 'localised-errors-class-code-error',
  ComeBackLaterErr: 'localised-errors-organisation-expired',
  CredentialsInvalidErr: 'localised-errors-invalid-credentials',
  DatabaseErr: 'localised-errors-service-error',
  DownstreamUnauthorizedErr: 'localised-errors-service-error',
  DuplicateEmailErr: 'localised-errors-add-email-duplicate-error',
  DuplicateUsernameErr: 'localised-errors-change-username-duplicate-error',
  FeedbackFetchError: 'localised-errors-feedback-fetch-error',
  FeedbackSubmitError: 'localised-errors-feedback-submit-error',
  InvalidRoleUnauthorizedErr: 'localised-errors-service-error',
  LockoutErr: 'localised-errors-locked-out',
  NotSupportedErr: 'localised-errors-service-error',
  ObjectInvalidErr: 'localised-errors-corrupt-data',
  ObjectNotFoundErr: 'localised-errors-invalid-credentials',
  OrganisationExpired: 'localised-errors-organisation-expired',
  PasswordCorruptErr: 'localised-errors-corrupt-data',
  PasswordNotSetErr: 'localised-errors-password-not-set',
  QuillFileErr: 'localised-errors-media-file-error',
  S3Err: 'localised-errors-s3-error',
  TaskSubmissionErr: 'localised-errors-task-submission-error',
  TokenUnauthorizedErr: 'localised-errors-service-error',
  UnknownErr: 'localised-errors-service-error',
  UserProfileGenericErr: 'localised-errors-user-profile-generic-error',
  EmailNotSetErr: 'localised-errors-learner-missing-email'
};
