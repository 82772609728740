/*
  An advanced wrapper which contains all of the relevant providers to use:
    - AuthContext
    - GraphQL requests
    - Mixpanel events
    - Internationalization
*/
import { useCallback, useEffect, useRef, useState } from 'react';
import { parseAuthFromLocalstorage } from 'century-core/core-auth/utils/utils';
import Auth from 'century-core/entities/Auth/Auth';
import { AuthContext, AuthUpdateEvent } from 'century-core/core-auth/AuthContext';

const PolymerToReactAuthProvider = (props: any) => {
  const [auth, setAuth] = useState<Auth>(parseAuthFromLocalstorage()!);
  const authRef = useRef<Auth | undefined>(auth);
  const renewTokenTimer = 0 as unknown as NodeJS.Timeout;

  const setAuthEverywhere = useCallback((newAuth: Auth) => {
    setAuth(newAuth);
    // no need to have dispatch(loggedIn(newAuth)) since the polymer components that use this provider don't use apollo
  }, []);

  const updateStorageIfRequired = useCallback(
    (e: AuthUpdateEvent) => {
      const newAuth = e.detail.auth;
      if (newAuth?.accessToken && newAuth.accessToken !== authRef.current?.accessToken) {
        setAuthEverywhere(newAuth);
        authRef.current = newAuth;
      }
    },
    [setAuthEverywhere]
  );

  // listens to localStorage events and updates auth if required
  useEffect(() => {
    document.addEventListener('authUpdated', updateStorageIfRequired);
    return () => {
      document.removeEventListener('authUpdated', updateStorageIfRequired);
    };
  }, [updateStorageIfRequired]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        loadingAuth: false,
        renewTokenTimer,
        orgSettings: null,
        setAuthEverywhere,
        createSession: () => '',
        setError: () => '',
        emailAddressRecovery: '',
        setEmailAddressRecovery: () => '',
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default PolymerToReactAuthProvider;
