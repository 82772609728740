import { GET } from 'century-core/core-apis/utils';
import { getLearnURL } from '../learn';

export function getRecommendations(courseplanId: string, userId: string, accessToken: string, limit: number = 5) {
  const url = getLearnURL(`/analytics/recommendations/${courseplanId}`);
  url.search = '?limit=' + limit + '&userId=' + userId;

  return GET<Ctek.Recommendation>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get recommendations for user id ${userId} and courseplan id ${courseplanId}, ${err}`);
  });
}
