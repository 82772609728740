import * as SentryLib from 'century-core/core-utils/lib/sentry';
import { getLearnURL } from 'century-core/core-apis/learn/learn';
import { FetchErrorWithData } from 'century-core/core-apis/fetch';
import { GET } from 'century-core/core-apis/utils';

/**
 * Retrieves an array of available availableMoods for the user to select from the learn api.
 */
export const retrieveAvailableMoods = (accessToken: string): Promise<Ctek.ElaMood[]> => {
  const url = getLearnURL('/ela/available-localised');

  return GET<Ctek.ElaMood[]>({ url, token: accessToken }).catch((err: FetchErrorWithData) => {
    SentryLib.captureMessage('Unable to retrieve available moods');
    return [];
  });
};
