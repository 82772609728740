import { createContext, Dispatch, SetStateAction, useState } from 'react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface PolymerLoadedContextType {
  loadingState: PolymerLoadingState;
  translationsSet: boolean;
  loadPolymer: () => Promise<void>;
  setTranslationsSet: Dispatch<SetStateAction<boolean>>;
}

const PolymerLoadedContext = createContext({} as PolymerLoadedContextType);
export default PolymerLoadedContext;

export enum PolymerLoadingState {
  NOT_LOADED,
  LOADING,
  LOADED,
}

export const PolymerLoadedProvider = (props: { children: React.ReactNode; polymerAvailableReactComponents: Record<string, React.FC> }) => {
  const [loadingState, setLoadingState] = useState(PolymerLoadingState.NOT_LOADED);
  const [translationsSet, setTranslationsSet] = useState(false);

  const loadPolymer = React.useCallback(async () => {
    setLoadingState(PolymerLoadingState.LOADING);
    await loadPolymerWithWcm(props.polymerAvailableReactComponents);

    setLoadingState(PolymerLoadingState.LOADED);
  }, [props.polymerAvailableReactComponents]);

  return (
    <PolymerLoadedContext.Provider value={{ loadingState, loadPolymer, translationsSet, setTranslationsSet }}>
      {props.children}
    </PolymerLoadedContext.Provider>
  );
};

const loadPolymerWithWcm = async (polymerAvailableReactComponents: Record<string, React.FC>): Promise<void> => {
  /**
   * WCM loading of polymer components, styles and libs for Cerberus interaction.
   * Read AppShellPolymer README
   */
  return Promise.all([
    WCM.loadable({ src: '/node_modules/tslib/tslib.js' }, 'script'),
    WCM.loadable({ src: '/node_modules/webcomponents.js/webcomponents-lite.min.js' }, 'script'),
  ])
    .then(() => {
      return WCM.loadable(
        {
          href: 'node_modules/polymer/polymer.html',
          rel: 'import',
        },
        'link'
      );
    })
    .then(() => {
      window.Polymer({
        is: 'polymer-to-react',

        properties: {
          element: String,
          props: Object,
        },

        ready() {
          this.target = this.appendChild(document.createElement('div'));
        },

        render() {
          ReactDOM.render(React.createElement(polymerAvailableReactComponents[this.element], this.props), this.target);
        },
      });

      window.Reactify = (is: string, props: object): void => {
        const config = {
          properties: {
            props: {
              type: Object,
              value: Object,
            },
          },
        };

        Object.keys(props).forEach((prop: string): void => {
          config.properties[prop] = {
            type: props[prop].type,
            value: props[prop].value,
          };

          if (props[prop].observe) {
            config['_observer' + prop] = function (this: any, newValue: any, oldValue: any) {
              if (newValue !== oldValue) {
                this.props[prop] = newValue;
                this.firstElementChild.render();
              }
            };

            config.properties[prop].observer = '_observer' + prop;
          }
        });
        window.Polymer({ is, ...config });
      };
      return;
    })
    .catch(err => {
      return Promise.resolve();
    });
};
