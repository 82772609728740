import * as React from 'react';
import QuillEditor, { ExternalQuillEditorProps } from '../../atoms/QuillEditor/QuillEditor';
import { CdsSmallRichTextEditor } from '@ctek/design-system'

// This is a wrapper arround the quill editor that causes the toolbar to only be visible when the quill editor is focused.
const SmallRichTextEditor = (props: ExternalQuillEditorProps) => {

  const [focused, setFocused] = React.useState(false);

  const containerEl = React.useRef<HTMLInputElement>(null);

  const handleClick = (event: React.MouseEvent) => {
    if (containerEl && containerEl.current) {
      if (containerEl.current.contains(event.target as any)) {
        // Clicked into editor
        setFocused(true);
      } else {
        // Clicked out of editor
        setFocused(false);
      }
    }
  };

  React.useEffect(() => {
    if (props.readonly) {
      return;
    }

    // Bind the event listener
    document.addEventListener('mouseup', (handleClick as unknown) as EventListenerOrEventListenerObject);
    document.addEventListener('touchend', (handleClick as unknown) as EventListenerOrEventListenerObject);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mouseup', (handleClick as unknown) as EventListenerOrEventListenerObject);
      document.removeEventListener('touchend', (handleClick as unknown) as EventListenerOrEventListenerObject);
    };
  });

  return (
    <CdsSmallRichTextEditor focused={focused} ref={containerEl}>
      <QuillEditor {...props} autosave={false} className="quill-small" />
    </CdsSmallRichTextEditor>
  );
};

export default SmallRichTextEditor;
