import { useMemo } from 'react'
import { withErrorBoundaryPartial } from 'century-core/core-components/ErrorBoundary/ErrorBoundary'
import QueryWrapper from 'century-core/features-dashboard/Widgets/QueryWrapper';
import { recommendedNuggetsWidgetQuery } from './recommendedNuggetsWidget.graphql'
import { Props } from './RecommendedNuggetsWidget'
import RecommendedNuggetCard from './RecommendedNuggetCard'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { useIntl } from 'react-intl'
import { DashboardUserMode } from 'century-core/core-utils/utils/utils'

const VALID_ORDERING_FACTORS = ['rlp-orderingfactor-spacedlearning', 'rlp-orderingfactor-failureadaptation'];

const RecommendedNuggetsBody = (props: Props) => {
  const { subjectGroupIds, subjectIds } = props;
  const intl = useIntl();
  const queryParams: QueryHookOptions = useMemo(
    () => ({
      variables: {
        input: {
          userId: props.userId,
          mode: 'spaced-learning' as Ctek.RlpMode,
          limit: 1,
          courseTypes: ['standard'],
          subjectGroupIds,
          subjectIds,
        },
      },
      fetchPolicy: 'network-only',
    }),
    [props.userId, subjectIds, subjectGroupIds]
  );

  const nuggetsQuery = useQuery(recommendedNuggetsWidgetQuery, queryParams);

  const recommendedNugget = useMemo(() => {
    if (!nuggetsQuery?.data) {
      return null;
    }
    return nuggetsQuery.data?.nuggetPath?.nuggets?.find?.((nugget: Ctek.Roentgen.NuggetPathItem) =>
      VALID_ORDERING_FACTORS.includes(nugget.orderingFactorMainContributor.orderingFactorType)
    );
  }, [nuggetsQuery]);

  const emptyState = useMemo(
    () => ({
      title: intl.formatMessage({
        id: 'learner-analytics--no-recommended-nuggets--title',
        defaultMessage: 'No recommendations yet',
      }),
      message:
        props.widgetMode === DashboardUserMode.STUDENT
          ? intl.formatMessage({
              id: 'learner-analytics--no-recommended-nuggets--message',
              defaultMessage:
                'As you begin to complete nuggets, your pathway will start recommending different nuggets for you. Some of those nuggets will be shown here for you to study.',
            })
          : intl.formatMessage({
              id: 'guardian-recommended-widget-empty-info',
              defaultMessage:
                'As students begin to complete nuggets, their pathway will start recommending different nuggets for them. Some of those nuggets will be shown here for them to study.',
            }),
    }),
    [intl, props.widgetMode]
  );

  return (
    <QueryWrapper query={nuggetsQuery} emptyState={emptyState} isEmpty={!recommendedNugget}>
      {recommendedNugget && <RecommendedNuggetCard nugget={recommendedNugget} widgetMode={props.widgetMode} />}
    </QueryWrapper>
  );
};

export default withErrorBoundaryPartial(RecommendedNuggetsBody);
