import { gql } from '@apollo/client';

export enum TestPracticeFilter {
  TEST_PRACTICE_ONLY = 'AllowOnlyTestPrepCourses',
  STANDARD_ONLY = 'AllowOnlyNonTestPrepCourses',
  ALL = 'AllowEveryCourse',
}

export const MY_COURSES_COUNT_QUERY = gql`
query myCourseCount($input: ReadMyCoursesStudentInput!) {
  myCoursesStudent(input: $input) {
    count
  }
}
`;