import { FormSelectDeprecated, addIcon, Button, minusIcon } from '@ctek/design-system';
import * as React from 'react';
import { Popover } from 'react-tiny-popover';
import { getConfig, getReleaseTagWithVersion, isProductionEnvironment } from 'century-core/core-utils/utils/config/config';
import { hasFeature } from 'century-core/core-auth/utils';
import { useAuth } from 'century-core/core-auth/hooks/useAuth';

const contentStyles = {
  background: 'rgb(255, 255, 255, 0.7)',
  border: '1px solid black',
  borderRadius: '5px',
  fontSize: '12px',
  padding: '3px',
} as React.CSSProperties;

const buttonStyles = {
  bottom: '0',
  position: 'absolute',
  right: '0',
} as React.CSSProperties;

export const AppVersionDetailsContext = React.createContext({ rPMode: undefined as string | undefined });

const AppVersionDetails = (props: { children: React.ReactElement }) => {
  const config = getConfig();
  const auth = useAuth();
  const [isOpen, setOpen] = React.useState(false);

  const rlpModeSelectorEnabled = hasFeature(auth, 'internal-features', 'rlpModeSelectorEnabled');
  const [rPMode, setRPMode] = React.useState<string | undefined>(undefined);

  const isProductionEnv = isProductionEnvironment(config);
  if (isProductionEnv) {
    return props.children;
  }

  const releaseVersion = getReleaseTagWithVersion();
  const gitBranch = (window as any).METEDATA_GIT_BRANCH;
  const gitHash = (window as any).METEDATA_GIT_HASH;

  return (
    <AppVersionDetailsContext.Provider value={{ rPMode }}>
      {props.children}
      <div className="version-details-popover">
        <Popover
          isOpen={isOpen}
          positions={['left', 'top']} // if you'd like, supply an array of preferred positions ordered by priority
          padding={3} // adjust padding here!
          content={
            <div style={contentStyles}>
              <p className="version-details-popover_env">Env: {config.env}</p>
              <p className="version-details-popover_api">API: {config.pubapiDomain}</p>
              <p className="version-details-popover_release">Release: {releaseVersion}</p>
              {gitBranch && <p className="version-details-popover_branch">Git Branch: {gitBranch}</p>}
              {gitHash && <p className="version-details-popover_hash">Git Hash: {gitHash}</p>}
              {rlpModeSelectorEnabled && (
                <div>
                  <br />
                  <p className="version-details-popover_rp_select_title">RP Mode</p>
                  {/* Eventually this values would come from labels, follow up ticket: TECH-23281 */}
                  <FormSelectDeprecated>
                    {{
                      name: 'version-details-popover_rp_select_dropdown',
                      onChange: (evt: any) => {
                        setRPMode(evt.target.value);
                      },
                      selectItems: [
                        { value: 'default', title: 'Default', disabled: false },
                        { value: 'grow', title: 'Grow', disabled: false },
                        { value: 'strengthen', title: 'Strengthen', disabled: false },
                        { value: 'default-pearson', title: 'Default Pearson', disabled: false },
                        { value: 'linear', title: 'Linear', disabled: false },
                        { value: 'linear-pearson', title: 'Linear Pearson', disabled: false },
                        { value: 'linear-continue', title: 'Linear Continue', disabled: false },
                        { value: 'linear-continue-pearson', title: 'Linear Continue Pearson', disabled: false },
                        { value: 'assignment', title: 'Assignment', disabled: false },
                        { value: 'assignment-due', title: 'Assignment Due', disabled: false },
                        { value: 'assignment-continue', title: 'Assignment Continue', disabled: false },
                        { value: 'past-bytime', title: 'Past Bytime', disabled: false },
                        { value: 'past-mostrecentcompleted', title: 'Past Most Recent Completed', disabled: false },
                        { value: 'spaced-learning', title: 'Spaced Learning', disabled: false },
                        { value: 'ai-only', title: 'AI Only', disabled: false },
                      ],
                      selectedOption: rPMode || 'default',
                      size: 'small',
                    }}
                  </FormSelectDeprecated>
                </div>
              )}
            </div>
          }
        >
          <div style={buttonStyles}>
            <Button variant="secondary" onClick={() => setOpen(!isOpen)} icon={isOpen ? minusIcon : addIcon} size="tiny" />
          </div>
        </Popover>
      </div>
    </AppVersionDetailsContext.Provider>
  );
};

export default AppVersionDetails;
