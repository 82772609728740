import { Actions, ActionTypes } from '../../actions/recoverPassword/recoverPassword';

export interface RecoverPassword {
  emailAddress: string;
}

export const defaultData: RecoverPassword = {
  emailAddress: '',
};

export function recoverPassword(state = defaultData, action: Actions): RecoverPassword {
  switch (action.type) {
    case ActionTypes.SetEmailAddress:
      return { ...state, emailAddress: action.emailAddress };
    default:
      return state;
  }
}

export default RecoverPassword;
