import { useMemo } from 'react';
import * as React from 'react';
import { Circle, Line } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { Text } from '@visx/text';
import { localPoint } from '@visx/event';
import './ChartD3.scss';
import './ScatterGraphD3.scss';
import { useIntl } from 'react-intl';
export interface ChartData {
  x: number;
  y: number;
  key: string;
}

interface Props {
  data: ChartData[];
  width: number;
  height: number;
  formatTooltip?: (data: ChartData) => JSX.Element;
  formatXVals?: (datum: string | number) => string;
  formatYVals?: (datum: string | number) => string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  isWidget?: boolean;
  isRTLLocale?: boolean;
}

const getXValue = (datum: ChartData) => datum.x;
const getYValue = (datum: ChartData) => datum.y;

const colorSeries = [
  '#0BACFE',
  '#0CBDBE',
  '#0ED07F',
  '#86D33F',
  '#FFD700',
  '#FFAF00',
  '#FF8800',
  '#F05607',
  '#E2260E',
  '#FB114B',
  '#C31D8E',
  '#8C2AD2',
  '#4B6AE7',
  '#4C7CA8',
  '#066798',
  '#077172',
  '#087C4C',
  '#507E25',
  '#998100',
  '#996900',
  '#995100',
  '#903304',
  '#871608',
  '#960A2D',
  '#751155',
  '#54197E',
  '#2D3F8A',
  '#2D4A64',
  '#85D5FE',
  '#85DEDE',
  '#86E7BF',
  '#C2E99F',
  '#FFEB80',
  '#FFD780',
  '#FFC380',
  '#F7AA83',
  '#F09286',
  '#FD88A5',
  '#E18EC6',
  '#C594E8',
  '#A5B4F3',
  '#A5BDD3',
];

export default function ScatterGraphD3(props: Props) {
  const { data, width, height, isWidget, isRTLLocale } = props;
  let marginTop = 20;
  let marginBottom = 50;
  let leftMargin = 50;
  let rightMargin = 20;
  if (isWidget) {
    marginTop = 10;
    marginBottom = 10;
    leftMargin = 10;
    rightMargin = 10;
  }

  const xMax = width - leftMargin - rightMargin;
  const yMax = height - marginBottom - marginTop;

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<ChartData>();
  const handleMouseOver = (event: React.MouseEvent, datum: ChartData, xPos: number) => {
    const coords = localPoint(event) || { x: 0, y: 0 };
    showTooltip({
      tooltipLeft: xPos,
      tooltipTop: coords.y,
      tooltipData: datum
    });
  };
  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleLinear<string | number>({
        range: [0, xMax],
        domain: [0, Math.max(Math.ceil(Math.max(...data.map(getXValue))), 100)],
      }),
    [xMax, data],
  );
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        domain: [0, Math.max(Math.ceil(Math.max(...data.map(getYValue))), 100)],
      }),
    [yMax, data],
  );
  const xAxisScale = scaleLinear<number>({
    domain: [0, Math.max(Math.ceil(Math.max(...data.map(getXValue))), 100)],
  });
  const yAxisScale = scaleLinear<number>({
    domain: [0, Math.max(Math.ceil(Math.max(...data.map(getYValue))), 100)],
  });
  xAxisScale.rangeRound([0, xMax]);
  yAxisScale.range([yMax, 0]);
  const intl = useIntl();
  return (
    <div className="chart-d3 scatter-graph-d3" style={{ position: 'relative' }}>
      <svg width={width} height={height}>
        <rect x={0} y={0} width={width} height={height} className="chart-d3__background" rx={14} />
        <Line
          className="scatter-graph-d3__average-line"
          stroke="#0bacfe"
          strokeWidth="1"
          strokeDasharray="5,5"
          from={{
            x: marginTop + (isWidget ? 0 : 30),
            y: height / 2 - (isWidget ? 0 : 15)
          }}
          to={{
            x: width + (isWidget ? 0 : 30),
            y: height / 2 - (isWidget ? 0 : 15)
          }}
        />
        {!props.isWidget && (
          <Text
            className="scatter-graph-d3__average-text"
            fill="#0bacfe"
            verticalAnchor="end"
            textAnchor={isRTLLocale ? 'start': 'end'}
            x={width - 20}
            y={height / 2 - 25}
          >
            {intl.formatMessage({ id: 'intervention-grid-x', defaultMessage: 'average' })}
          </Text>
        )}
        <Line
          className="scatter-graph-d3__average-line"
          stroke="#0bacfe"
          strokeWidth="1"
          strokeDasharray="5,5"
          from={{
            x: width / 2 + (isWidget ? 0 : 15),
            y: leftMargin - (isWidget ? 0 : 30)
          }}
          to={{
            x: width / 2 + (isWidget ? 0 : 15),
            y: height - marginTop - (isWidget ? 0 : 30)
          }}
        />
        {!props.isWidget && (
          <Text
            className="scatter-graph-d3__average-text"
            angle={-90}
            fill="#0bacfe"
            verticalAnchor="end"
            textAnchor={isRTLLocale ? 'start': 'end'}
            x={width / 2 + 5}
            y={20}
          >
            {intl.formatMessage({ id: 'intervention-grid-y', defaultMessage: 'average' })}
          </Text>
        )}
        <Group top={marginTop} left={leftMargin}>
          <AxisBottom
            axisClassName="chart-d3__axis-group"
            axisLineClassName="chart-d3__axis-line"
            scale={xAxisScale}
            top={yMax}
            label={props.xAxisLabel}
            labelProps={{ fontSize: 14, x: width - width / 10 - rightMargin, textAnchor: 'end' }}
            tickClassName="chart-d3__tick"
            tickFormat={(datum: number) => (props.formatXVals ? props.formatXVals(datum) : `${datum}`)}
            tickLength={6}
          />
          <AxisLeft
            axisClassName="chart-d3__axis-group"
            axisLineClassName="chart-d3__axis-line"
            tickClassName="chart-d3__tick"
            tickFormat={(datum: number) => (props.formatYVals ? props.formatYVals(datum) : `${datum}`)}
            tickLength={6}
            // the values passed to ticks() here is the number of ticks to be rendered
            tickValues={yScale.ticks(height <= 200 ? 5 : undefined).filter(Number.isInteger)}
            scale={yAxisScale}
            label={props.yAxisLabel}
            labelOffset={25}
            labelProps={{ fontSize: 14, textAnchor: 'middle' }}
          />

          <Text
            className={
              `scatter-graph-d3__intervention scatter-graph-d3__intervention--top-left${
                props.isWidget ? ' scatter-graph-d3__intervention--is-widget' : ''
              }`
            }
            fill="#0bacfe"
            verticalAnchor="start"
            x={props.isWidget ? 10 : 40}
            y={props.isWidget ? 10 : 40}
            textAnchor={isRTLLocale ? 'end': 'start'}
          >
            {intl.formatMessage({ id: 'intervention-stretch', defaultMessage: 'Needs stretch' })}
          </Text>
          <Text
            className={
              `scatter-graph-d3__intervention scatter-graph-d3__intervention--bottom-left${
                props.isWidget ? ' scatter-graph-d3__intervention--is-widget' : ''
              }`
            }
            fill="#EDB000"
            verticalAnchor="end"
            x={props.isWidget ? 10 : 40}
            y={props.isWidget ? (height - 30) : (height - 110)}
            textAnchor={isRTLLocale ? 'end': 'start'}
          >
            {intl.formatMessage({ id: 'intervention-effort', defaultMessage: 'Needs more effort' })}
          </Text>
          <Text
            className={
              `scatter-graph-d3__intervention scatter-graph-d3__intervention--bottom-right${
                props.isWidget ? ' scatter-graph-d3__intervention--is-widget' : ''
              }`
            }
            fill="#FF6C02"
            textAnchor={isRTLLocale ? 'start': 'end'}
            verticalAnchor="end"
            x={props.isWidget ? (width - 30) : (width - 110)}
            y={props.isWidget ? (height - 30) : (height - 110)}
          >
            {intl.formatMessage({ id: 'intervention-support', defaultMessage: 'Needs support' })}
          </Text>
          <Text
            className={
              `scatter-graph-d3__intervention scatter-graph-d3__intervention--top-right${
                props.isWidget ? ' scatter-graph-d3__intervention--is-widget' : ''
              }`
            }
            fill="#32cd32"
            textAnchor={isRTLLocale ? 'start': 'end'}
            verticalAnchor="start"
            x={ props.isWidget ? (width - 30) : (width - 110) }
            y={ props.isWidget ? 10 : 40}
          >
            {intl.formatMessage({ id: 'intervention-praise', defaultMessage: 'Praise for commitment' })}
          </Text>
          {data.map((d, i) => {
            const barX = xScale(getXValue(d));
            const barY = yScale(getYValue(d));
            const color = colorSeries[i % colorSeries.length];
            return (
              <Circle
                key={`point-${i}`}
                className="scatter-graph-d3__point"
                cx={barX}
                cy={barY}
                r={6}
                fill={color}
                stroke={color}
                onClick={e => handleMouseOver(e, d, barX as number)}
                onMouseOver={e => handleMouseOver(e, d, barX as number)}
                onMouseLeave={hideTooltip}
              />
            );
          })}
        </Group>
      </svg>
      {tooltipOpen && (
        <TooltipWithBounds
          className="chart-d3__tooltip"
          // set this to random so it correctly updates with parent bounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          unstyled={true}
          applyPositionStyle={true}
        >
          {props.formatTooltip ? props.formatTooltip(tooltipData as ChartData) : `${tooltipData?.x}: ${tooltipData?.y}`}
        </TooltipWithBounds>
      )}
    </div>
  );
}
