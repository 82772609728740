import { VideoPlayer, VideoPlayerProps } from '@ctek/design-system';
import { getSignedURL } from 'century-core/core-apis/mediaLibrary/mediaLibrary';
import { useAuthRef } from 'century-core/core-auth/hooks/useAuth';
import { hasFeature } from 'century-core/core-auth/utils';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { getSubtitleConfig } from './Media/getSubtitleConfig';
import { useSubtitleMode } from 'century-core/core-utils/hooks/useSubtitleMode';

type VideoWrapperProps = {
  content: Ctek.MediaContentType | Ctek.EnrichedContent;
  onProgress?: any;
  documentId?: string;
  hideSubtitle?: boolean;
  playedSeconds?: number;
};
const loadSignedMediaContent = async (
  content: Ctek.MediaContentType | Ctek.EnrichedContent,
  accessToken: string,
  documentId?: string,
  allowFallback = true,
) => {
  const data = (content.data as Ctek.VideoContentData | Ctek.ImageContentData | Ctek.EnrichedContentData)
  let imgSrc = null;
  try {
    const fileId = data.value?.files[0].id
    const itemId = data?.id || documentId

    if (fileId && itemId) {
      const { url } = await getSignedURL(itemId, fileId, accessToken)
      imgSrc = url
    }
  } catch {
    if (allowFallback) {
      const src = data.value?.files[0].url || '';
      imgSrc = data ? src : '';
    }
  }
  return imgSrc
}

export const useLoadSignedMedia = () => {
  const authRef = useAuthRef()
  const loadMedia = useCallback((content: Ctek.MediaContentType | Ctek.EnrichedContent, documentId?: string, allowFallback?: boolean) => {
    return loadSignedMediaContent(content, authRef.current.accessToken!, documentId, allowFallback)
  }, [authRef])
  if (hasFeature(authRef.current, 'study-session', 'signed-media', false)) {
    return loadMedia
  }
  return null
}

export const VideoWrapper = forwardRef((props: VideoWrapperProps, ref?: React.ForwardedRef<VideoPlayerProps['ref']>) => {
  const { content, documentId, onProgress, hideSubtitle, playedSeconds = 0 } = props
  const [data, setData] = useState<string | null>(null)
  const loadSignedMedia = useLoadSignedMedia()

  const loadVideo = useCallback(
    async () => {
      setData(
        loadSignedMedia
          ? await loadSignedMedia(content, documentId || content.id!)
          : (content.data as Ctek.VideoContentData).value?.files[0].url || ''
      )
    },
    [content, documentId, loadSignedMedia]
  );

  useEffect(() => {
    loadVideo()
  }, [loadVideo])

  const videoItem = (content.data as Ctek.VideoContentData)?.value
  const videoId = videoItem?.files[0].url.match(/\/([^/]+)\.[^/.]+$/)?.[1] ?? '';
  const isSubtitlesOn = useSubtitleMode(ref || null, videoId, playedSeconds);

  const config = useMemo(() => getSubtitleConfig(videoItem, isSubtitlesOn, hideSubtitle),
    [videoItem, isSubtitlesOn, hideSubtitle]
  );

  return <VideoPlayer
    data-testid="video-player"
    ref={ref}
    key={content.id}
    src={data || content.data || ''}
    config={config}
    onProgress={onProgress}
  />
});