import { GET, setUrlSearchParams } from 'century-core/core-apis/utils';
import { chunk } from 'lodash';
import { getContentURL } from '../contentV2';

export interface GetCoursesOpts {
  select?: string;
  type?: string;
}

export function getCourse(courseId: string, accessToken: string, opts?: GetCoursesOpts) {
  const url = getContentURL(`/courses/${courseId}`);
  const select = (opts && opts.select) || 'type,name,subject,strands';
  url.search += '&select=' + select;
  url.search += '&populate=true';

  return GET<Ctek.Course>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get course by id ${courseId}, ${err}`);
  });
}

export function getCourses(courseIds: string[], token: string, opts?: GetCoursesOpts, signal?: AbortSignal) {
  const idChunks = chunk(courseIds, 30);

  const promises = idChunks.map((ids: string[]) => {
    const url = getContentURL('/courses');
    const select = opts?.select ?? 'type,name,icon,subject,strands,strands.nuggets,labels';
    const type = opts?.type ?? 'standard';

    setUrlSearchParams(url, {
      ids: ids.join(),
      select,
      type,
      populate: true,
    });

    return GET<Ctek.Course[]>({ url, token, signal }).catch((err: Error) => {
      throw Error(`Unable to get courses for ids ${ids}, ${err}`);
    });
  });

  return Promise.all(promises).then(res => res.flat());
}

export function getAllAssignableOrgCourses(token: string, opts?: GetCoursesOpts, signal?: AbortSignal) {
  const url = getContentURL('/courses/search');

  url.searchParams.set('select', opts?.select ?? '_id,name');
  url.searchParams.set('type', opts?.type ?? 'standard');
  url.searchParams.set('populate', 'true');
  url.searchParams.set('isAssignable', 'true');
  url.searchParams.set('limit', '500');
  url.search = decodeURIComponent(url.searchParams.toString());

  return GET<Ctek.Course[]>({ url, token, signal }).catch((err: Error) => {
    throw Error(`Unable to get all assignable organisation courses. Error: ${err.message}`);
  });
}
