import { createSelector } from 'reselect';
import { getTokenLoginSessionId, getTokenMainOrgId, getTokenSub } from 'century-core/core-auth/utils';
import * as stateSelectors from './stateSelectors';

export const getAccessToken = createSelector([stateSelectors.getAuth], auth => auth.accessToken || '');

export const getLoginSessionId = createSelector([stateSelectors.getAuth], auth => getTokenLoginSessionId(auth));

export const getTokenSubFromAuth = createSelector([stateSelectors.getAuth], auth => getTokenSub(auth));

export const getOrgId = createSelector([stateSelectors.getAuth], auth => getTokenMainOrgId(auth));

export const getAuth = createSelector([stateSelectors.getAuth], auth => auth);
