import { ProgressThumbnail, RingsBlock, ToggleGrid } from '@ctek/design-system';
import { TrackerNugget } from 'century-core/core-utils/selectors/nuggetActivity'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl';

export const StrandFilter = (props: {
  strands: Ctek.Strand[];
  nuggetActivities: TrackerNugget[];
  strandFilter: string | null;
  setStrandFilter: (strandId: string | null) => void;
}) => {

  // there's a lot more information available before transformNuggetActivities filters out data relating to the current attempt
  const activityByStrand = useMemo(() => {
    return props.strands?.filter(s => s.name.toLowerCase() !== 'all topics')?.map(strand => ({
      ...strand,
      nuggets: (strand.nuggets as Ctek.Nugget[]).map(nugget => ({
        ...nugget,
        activity: props.nuggetActivities.find(v => v._id === nugget._id)
      }))
    })).map(s => {
      const nuggetsCompleted = s.nuggets.filter(n => n.activity?.latestActivityTimeClosed).length

      return {
        name: s.name,
        id: s.id,
        nuggets: s.nuggets,
        completionPercentage: Math.round((nuggetsCompleted * 100) / s.nuggets.length),
        averageLastScore: nuggetsCompleted
          ? Math.round(s.nuggets.reduce((acc, n) => acc + (n.activity?.lastScore || 0), 0) / nuggetsCompleted)
          : 0,
      }
    })
  }, [props.strands, props.nuggetActivities])

  const totalActivity = useMemo(() => {
    const nuggetsFlattened = activityByStrand?.map(s => s.nuggets).flat() || [];
    const completedNuggets = nuggetsFlattened?.filter(n => n.activity?.latestActivityTimeClosed)
    const averageCompletedNuggetsScore = completedNuggets.length
      ? completedNuggets.reduce((acc, n) => acc + (n.activity?.lastScore || 0), 0) / completedNuggets.length
      : 0
    return {
      score: Math.round(averageCompletedNuggetsScore || 0),
      completion: Math.round(100 * completedNuggets.length / nuggetsFlattened.length)
    }
  }, [activityByStrand])

  return (
    <ToggleGrid qa="course-strand-filters">
      <ToggleGrid.Item
        qa="course-strand-filter--all"
        active={!props.strandFilter}
        onClick={() => props.setStrandFilter(null)}
      >
        <RingsBlock>
          <RingsBlock.Rings>
            <ProgressThumbnail
              size={30}
              completionPercentage={totalActivity.completion}
              scorePercentage={totalActivity.score}
              hasPlaceholder={false}
            />
          </RingsBlock.Rings>
          <RingsBlock.Legend>
            <FormattedMessage id="label--all-topics" defaultMessage="All topics" />
          </RingsBlock.Legend>
        </RingsBlock>
      </ToggleGrid.Item>
      {activityByStrand.map(s => (
        <ToggleGrid.Item
          qa={'course-strand-filter--single'}
          key={s.id}
          active={props.strandFilter === s.id}
          onClick={() => props.setStrandFilter(s.id!)}
        >
          <RingsBlock>
            <RingsBlock.Rings>
              <ProgressThumbnail
                size={30}
                completionPercentage={s.completionPercentage}
                scorePercentage={s.averageLastScore}
                hasPlaceholder={false}
              />
            </RingsBlock.Rings>
            <RingsBlock.Legend>
              {s.name}
            </RingsBlock.Legend>
          </RingsBlock>
        </ToggleGrid.Item>
      ))}
    </ToggleGrid>
  )
}