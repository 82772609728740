import { Auth } from 'century-core/entities/Auth/Auth';
import { getTokenSettingsLocale } from 'century-core/core-auth/utils';

/**
 * Locale utils copied from webapp-components-core-components/main/src utils.ts
 */
export type TextDirection = 'rtl' | 'ltr' | 'auto';
export type HorizontalAlignDirection = 'right' | 'left';

export const DEFAULT_TEXT_DIRECTION: TextDirection = 'ltr';

// as per https://en.wikipedia.org/wiki/Right-to-left
// and https://r12a.github.io/app-subtags/
export const RTLLocales = [
  'ar',
  'he',
  'ur',
  'syc',
  'mid',
  'rhg',
  'ff',
  'sam',
  'men',
  'man',
  'dv',
  'ae',
  'acy',
  'phn',
  'arc',
  'oar',
  'pal',
  'sog',
  'xmn',
  'xld',
  'nqo',
  'yi',
  'tmr',
  'bjf',
  'pnb',
  'ps',
  'sd',
  'ug',
  'bcc',
];

/**
 * Returns true if the locale is RTL
 * @param locale  string
 */
export function isRTLLocale(locale: string): boolean {
  const language = locale.split('-')[0].toLowerCase();
  return !!RTLLocales.find((loc: string) => language === loc);
}

/**
 * Returns true if the locale is RTL
 * @param locale  string
 */
export function isRTLLocaleFromTokenLocale(auth: Auth): boolean {
  const locale: string = getTokenSettingsLocale(auth);
  const language = locale.split('-')[0].toLowerCase();
  return !!RTLLocales.find((loc: string) => language === loc);
}

/**
 * returns text direction based on the provided locale
 *
 * @param locale
 */
export function getTextDirection(locale: string): TextDirection {
  const language = locale.split('-')[0].toLowerCase();
  return RTLLocales.find((loc: string) => language === loc) ? 'rtl' : 'ltr';
}

/**
 * returns horizontal align direction based on the provided locale
 *
 * @param locale
 */
export function getHorizontalAlignDirection(locale: string): HorizontalAlignDirection {
  return isRTLLocale(locale) ? 'right' : 'left';
}

/**
 * returns text direction based on the locale set in the auth token
 *
 * @param auth
 */
export function getTextDirectionFromTokenLocale(auth: Auth): TextDirection {
  return getTextDirection(getTokenSettingsLocale(auth));
}

export const formatPhraseName = (name: string) => name.toLowerCase().replace(' ', '-');
