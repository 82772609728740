import { ApolloError } from '@apollo/client';

import * as SentryLib from 'century-core/core-utils/lib/sentry';

export function handleApolloError(error: ApolloError, contextName: string) {
  // Destructure relevant error information
  const { graphQLErrors, message, networkError } = error;
  if (networkError && 'result' in networkError) {
    const serverErrorDetails = {
      name: networkError.name,
      resultName: networkError.result.name,
      resultMessage: networkError.result.message,
      statusCode: networkError.statusCode,
    };
    const errorMessage = `ApolloError - ${contextName}: ${message}
    GraphQL Errors: ${JSON.stringify(graphQLErrors)}
    Network Error:
      Name: ${serverErrorDetails.name}
      Status Code: ${serverErrorDetails.statusCode}
      Result Name: ${serverErrorDetails.resultName}
      Result Message: ${serverErrorDetails.resultMessage}
    `;

    const customError = new Error(errorMessage);

    // Capture the custom error with Sentry
    SentryLib.captureExceptionWithScope(customError, 'error', {
      context: {
        name: contextName,
        value: {
          graphQLErrors,
          message,
          networkError: serverErrorDetails,
        },
      },
    });
  } else {
    SentryLib.captureException(new Error(`ApolloError - ${contextName}: ${message}`));
  }
}
