import { MixpanelParams } from 'century-core/core-utils/utils/mixpanel/mixpanel';
import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';

export const trackMoodSelected = (
  elaArea: string,
  emotion: string,
  emotionId: string,
  track: (name: MixpanelEventTypes, params: MixpanelParams) => void
) => {
  track(MixpanelEventTypes.ElaMoodSelected, {
    [MixpanelKeys.ElaArea]: elaArea,
    [MixpanelKeys.Emotion]: emotion,
    [MixpanelKeys.EmotionId]: emotionId,
  });
};
