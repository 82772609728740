import { BrokenImage, LoadingSpinner } from '@ctek/design-system';
import { useCallback, useEffect, MouseEvent, useState } from 'react';
import { getImageBase64FromUrl } from 'century-core/core-utils/utils/helpers';
import { useMixpanel } from 'century-core/core-components/MixpanelUtils';
import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { useLoadSignedMedia } from 'century-core/media/Video';

interface OptimisedImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  alt: string;
  studySessionId?: string;
  content?: Ctek.MediaContentType;
  documentId?: string;
  src?: string // fallback due to quill Matching/Labelling questions
}

export const OptimisedImage = (props: OptimisedImageProps) => {
  const { alt, onClick, studySessionId, content, documentId, src } = props;
  const mixpanel = useMixpanel();
  const source = (content?.data as Ctek.EnrichedContentData)?.value?.files[0].url || src

  const [imageData, setImageData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [retries, setRetries] = useState(0);
  const loadSignedMedia = useLoadSignedMedia()

  const loadImage = useCallback(
    async (src: string) => {
      let imgSrc = src;
      if (content && documentId && loadSignedMedia) {
        const signedSrc = await loadSignedMedia(content, documentId || content.id)
        if (signedSrc) {
          imgSrc = signedSrc
        }
      }

      try {
        let data;

        // TODO: B2C-600 optimised media endpoint does not work for signed URLs
        if (!imgSrc.includes('?X-Amz-Algorithm=')) {
          data = await getImageBase64FromUrl(imgSrc.replace('/media/', '/media-optimised/'));
        }
        if (!data) {
          data = await getImageBase64FromUrl(imgSrc)
        }

        if (!data && retries) {
          mixpanel.track(MixpanelEventTypes.ImageNotLoaded, {
            [MixpanelKeys.ImageSrc]: src,
            [MixpanelKeys.RetryAttempt]: retries,
            [MixpanelKeys.StudySessionId]: studySessionId,
          });
        }

        setImageData(data);
        setLoading(false);
      } catch (err) {
        setImageData(null);
        setLoading(false);
      }
    },
    [mixpanel, retries, studySessionId, content, documentId, loadSignedMedia]
  );

  useEffect(() => {
   if (loading && !!source) {
     async function loadImageData() {
       await loadImage(source as string);
     }
     loadImageData();
   }
 }, [source, loading, loadImage]);

  const retry = (event: MouseEvent) => {
    event.stopPropagation();
    setRetries(retries + 1);
    setLoading(true);
  };

  return loading ? (
    <BrokenImage>
      <BrokenImage.Loading>
        <LoadingSpinner type="widget" />
      </BrokenImage.Loading>
    </BrokenImage>
  ) : !imageData ? (
    <BrokenImage qa="broken-image">
      <BrokenImage.Btn onClick={retry} />
    </BrokenImage>
  ) : (
    <img {...(onClick && { className: 'img--clickable' })} src={imageData} alt={alt} />
  );
};
