import { MockTest, MockTestStatus, ProductKey, PurchasedProduct, UserCurrentSubscription, UserSubscriptionHistory } from './types';
// import { getFeatureFlagData } from 'century-core/core-auth/utils';
import { Auth } from 'century-core/entities/Auth/Auth';

export const isCurrentUserAndDuringTrial = (userSubscription?: UserCurrentSubscription | UserSubscriptionHistory) => {
  return (userSubscription as UserCurrentSubscription).active
    ? (userSubscription as UserCurrentSubscription).trialEndDate &&
        (userSubscription as UserCurrentSubscription).trialEndDate === (userSubscription as UserCurrentSubscription).nextInvoiceDueAt
    : false;
};

export const PRODUCT_KEYS = [ProductKey.CENTURY, ProductKey.Bond, ProductKey.BondPro];

// FIXME B2C-1303 hotfix, we need to have this hack thing back until we fix sentinel not returning the plans in the FF.
const MOCK_TEST_PRODUCTS: ProductKey[] = [ProductKey.Bond, ProductKey.BondPro];
export const hasProductMockTests = (auth: Auth, productKey: ProductKey) => {
  // const mockTestProducts = getFeatureFlagData<string[]>(auth, 'mock-tests', 'plans')
  // return mockTestProducts?.includes(productKey)
  return MOCK_TEST_PRODUCTS.includes(productKey);
};

export const getLearnerProductFromSubscription = (currentSubscription: UserCurrentSubscription, learnerId: string) =>
  currentSubscription.products.find(product => product.learner?.userId === learnerId);

export const getLearnerProductKey = (currentSubscription: UserCurrentSubscription, learnerId: string) =>
  getLearnerProductFromSubscription(currentSubscription, learnerId)?.subscriptionItem?.productKey;

export const MockTestsInfo = {
  'baf9131a-e46e-4bba-95b6-ce0f288a3a42': {
    testName: 'ISEB: English',
    testSubject: 'english',
    iconCode: 'iseb-001',
  },
  '62c5b4b2-ef76-4219-8002-d9d532eebfe8': {
    testName: 'ISEB: Mathematics',
    testSubject: 'mathematics',
    iconCode: 'iseb-001',
  },
  '241edeef-e794-428d-a98c-43abdec9093a': {
    testName: 'ISEB: Non-Verbal Reasoning',
    testSubject: 'non-verbal-reasoning',
    iconCode: 'iseb-001',
  },
  'ef845bdb-b3a0-4978-9ec0-7eb20a328e02': {
    testName: 'ISEB: Verbal Reasoning',
    testSubject: 'verbal-reasoning',
    iconCode: 'iseb-001',
  },
  '280e6283-ea44-4025-a353-c13b3700a281': {
    testName: '11+ English',
    testSubject: 'english',
    iconCode: 'bond-004',
  },
  '3d092b9e-2b85-44ba-ac63-8f4ce6f5f3fc': {
    testName: '11+ Maths',
    testSubject: 'mathematics',
    iconCode: 'bond-003',
  },
  'f1f98e30-d58e-4ab1-bf82-c072bb189684': {
    testName: '11+ Non-Verbal Reasoning',
    testSubject: 'non-verbal-reasoning',
    iconCode: 'bond-005',
  },
  '27895351-0053-4210-b24a-8338df65bf63': {
    testName: '11+ Verbal Reasoning',
    testSubject: 'verbal-reasoning',
    iconCode: 'bond-002',
  },
};
export const filterMockTestsByStatus = (learner: PurchasedProduct['learner'], status: MockTestStatus) =>
  learner?.tests?.filter(t => t.testStatus === status).sort((a: MockTest, b: MockTest) => (a.testName > b.testName ? 1 : -1)) || [];
