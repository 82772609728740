import { ReactElement } from 'react';
import { DateTime, Interval } from 'luxon';
import { injectIntl, IntlShape } from 'react-intl';

export enum CenturyRelativeTimeFormat {
  RELATIVE_TODAY,
  RELATIVE_NOW,
}

export enum CenturyRelativeTimeDisplayMode {
  // 1 Oct
  SHORT = 'd LLL',
  // 1 October
  STANDARD = 'd LLLL',
}

const yearFormat = 'yyyy';

interface Props {
  alwaysIncludeYear?: boolean;
  includeTimeStamp?: boolean;
  includeTimeAndDate?: boolean; // display something like "25 Oct 2021 at 12:00"
  dateTime: string;
  displayMode?: CenturyRelativeTimeDisplayMode;
  timeFormat: CenturyRelativeTimeFormat;
  // yesterday, today and tomorrow will display as words instead of dates
  relativeSurroundingDays?: boolean;
  showTodayAsText?: boolean;
  intl: IntlShape;
}

const now = DateTime.local();
const yesterday = now.minus({ days: 1 });
const tomorrow = now.plus({ days: 1 });
const todayRange = Interval.fromDateTimes(now.startOf('day'), now.endOf('day'));
const yesterdayRange = Interval.fromDateTimes(yesterday.startOf('day'), yesterday.endOf('day'));
const tomorrowRange = Interval.fromDateTimes(tomorrow.startOf('day'), tomorrow.endOf('day'));
const surroundingDaysRange = Interval.fromDateTimes(yesterday.startOf('day'), tomorrow.endOf('day'));

function CenturyRelativeTime(props: Props): ReactElement | null {
  const { dateTime, relativeSurroundingDays, displayMode, alwaysIncludeYear } = props;
  const dt = DateTime.fromISO(dateTime);

  if (!dt.isValid) {
    return null;
  }

  switch (props.timeFormat) {
    case CenturyRelativeTimeFormat.RELATIVE_TODAY:
      return (
        <span data-testid="century-relative-time">
          {getFormattedOutput(dt, displayMode, relativeSurroundingDays, alwaysIncludeYear, props)}
        </span>
      );
    case CenturyRelativeTimeFormat.RELATIVE_NOW:
      return <span data-testid="century-relative-time">{dt.toRelative({ style: 'short', locale: 'en' })}</span>;
    default:
      return null;
  }
}

const isTodayOrSurrounding = (dateTime: DateTime): boolean => {
  return dateTime >= surroundingDaysRange.start && dateTime <= surroundingDaysRange.end;
};

// Override surrounding days with today, yesterday, tomorrow
const overrideOutput = (dateTime: DateTime, props: Props): string => {
  let output = '';
  if (yesterdayRange.contains(dateTime)) {
    output = props.intl.formatMessage({ id: 'time-component-relative-yesterday', defaultMessage: 'Yesterday' });
  }
  if (todayRange.contains(dateTime)) {
    output = props.intl.formatMessage({ id: 'time-component-relative-today', defaultMessage: 'Today' });
  }
  if (tomorrowRange.contains(dateTime)) {
    output = props.intl.formatMessage({ id: 'time-component-relative-tomorrow', defaultMessage: 'Tomorrow' });
  }
  if (props.includeTimeStamp) {
    output = output.concat(' at ', dateTime.toFormat('HH:mm'));
  }

  return output;
};

const formatDate = (DateTime: DateTime, displayMode: CenturyRelativeTimeDisplayMode, withYear: boolean, props: Props) => {
  const { includeTimeAndDate, intl, showTodayAsText } = props;
  const atText = intl.formatMessage({ id: 'calendar-at', defaultMessage: 'at' });

  if (showTodayAsText && DateTime.hasSame(now, 'day')) {
    const todayText = intl.formatMessage({ id: 'century-time-component-today', defaultMessage: 'today' });
    return `${todayText} ${atText} ${DateTime.toFormat('HH:mm')}`;
  } else {
    let format = withYear ? `${displayMode} ${yearFormat}` : displayMode;
    if (includeTimeAndDate) {
      format += ` '${atText}' HH:mm`;
    }
    return DateTime.toFormat(format);
  }
};

const getFormattedOutput = (
  dateTime: DateTime,
  displayMode: CenturyRelativeTimeDisplayMode | undefined,
  relativeSurroundingDays: boolean | undefined,
  includeYear: boolean | undefined,
  props: Props
) => {
  let withYear = false;
  const mode: CenturyRelativeTimeDisplayMode = displayMode ? displayMode : CenturyRelativeTimeDisplayMode.SHORT;
  let relativeOutput: string = '';

  if (isTodayOrSurrounding(dateTime) && relativeSurroundingDays === true) {
    relativeOutput = overrideOutput(dateTime, props);
  }
  if (!dateTime.hasSame(now, 'year') || includeYear === true) {
    withYear = true;
  }

  return relativeOutput.length ? relativeOutput : formatDate(dateTime, mode, withYear, props);
};

export default injectIntl(CenturyRelativeTime);
