import { DropdownSimple, FilterDropdownList, FilterDropdownTrigger, FilterToolbar, SwitchRow } from '@ctek/design-system';
import { FormattedMessage } from 'react-intl';
import { Option, prefix } from './StudentDashboardAssignmentsV1';

interface Props {
  showBars: boolean;
  showNumbers: boolean;
  showSymbols: boolean;
  colouredCells: boolean;
  onOptionChange: (option: Option, isChecked: boolean) => void;
}

export default function StudentDashboardAssignmentsOptions(props: Props) {
  const { showBars, showNumbers, showSymbols, colouredCells, onOptionChange } = props;

  return (
    <FilterToolbar.Block>
      <DropdownSimple onToggle={() => null} alignEnd={true} qa="option-dropdown">
        <DropdownSimple.Toggle qa="sd-button-menu-options-open">
          <FilterDropdownTrigger qa="option-dropdown-trigger">
            <FilterDropdownTrigger.Label data-testid="options-dropdown-title">
              <FormattedMessage id="table-options-title-option" defaultMessage="Options" />
            </FilterDropdownTrigger.Label>
          </FilterDropdownTrigger>
        </DropdownSimple.Toggle>

        <DropdownSimple.Content offsetY={0} qa="option-dropdown-content">
          <FilterDropdownList qa="option-dropdown-list">
            <FilterDropdownList.Item>
              <SwitchRow qa="switch-bars">
                <SwitchRow.Label data-testid="options-show-bars-label">
                  <FormattedMessage id={prefix + 'option-show-bars'} defaultMessage="Show Bars" />
                </SwitchRow.Label>
                <input
                  type="checkbox"
                  checked={showBars}
                  onChange={e => onOptionChange(Option.BARS, e.target.checked)}
                  data-testid="show-bars-input-toggle"
                />
              </SwitchRow>
            </FilterDropdownList.Item>
            <FilterDropdownList.Item>
              <SwitchRow qa="switch-numbers">
                <SwitchRow.Label data-testid="options-show-numbers-label">
                  <FormattedMessage id={prefix + 'option-show-numbers'} defaultMessage="Show Numbers" />
                </SwitchRow.Label>
                <input
                  type="checkbox"
                  checked={showNumbers}
                  onChange={e => onOptionChange(Option.NUMBERS, e.target.checked)}
                  data-testid="show-numbers-input-toggle"
                />
              </SwitchRow>
            </FilterDropdownList.Item>
            <FilterDropdownList.Item>
              <SwitchRow qa="switch-symbols">
                <SwitchRow.Label data-testid="options-show-symbols-label">
                  <FormattedMessage id={prefix + 'option-show-symbols'} defaultMessage="Show Symbols" />
                </SwitchRow.Label>
                <input
                  type="checkbox"
                  checked={showSymbols}
                  onChange={e => onOptionChange(Option.SYMBOLS, e.target.checked)}
                  data-testid="show-symbols-input-toggle"
                />
              </SwitchRow>
            </FilterDropdownList.Item>
            <FilterDropdownList.Item>
              <SwitchRow qa="switch-colours">
                <SwitchRow.Label data-testid="options-coloured-cells-label">
                  <FormattedMessage id={prefix + 'option-coloured-cells'} defaultMessage="Coloured Cells" />
                </SwitchRow.Label>
                <input
                  type="checkbox"
                  checked={colouredCells}
                  onChange={e => onOptionChange(Option.CELLS, e.target.checked)}
                  data-testid="coloured-cells-input-toggle"
                />
              </SwitchRow>
            </FilterDropdownList.Item>
          </FilterDropdownList>
        </DropdownSimple.Content>
      </DropdownSimple>
    </FilterToolbar.Block>
  );
}
