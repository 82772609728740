export function initialise() {
  // Polyfill for `reportValidity` in Edge 16 as this is being used on multiple forms around the app.
  // Explanation here: https://www.tjvantoll.com/2015/01/28/reportvalidity/
  if (!HTMLFormElement.prototype.reportValidity) {
    HTMLFormElement.prototype.reportValidity = function(): boolean {
      const submitButtons: any[] = Array.from(this.querySelectorAll('button, input[type=submit]'));
      for (const button of submitButtons) {
        if (button.type === 'submit') {
          button.click();
          return true;
        }
      }
      return false;
    };
  }

  // Polyfill for `:focus-visible` CSS pseudo-class
  // Used to determine whether focus should be visible based on input modality (mouse vs. keyboard)
  (async () => {
    if (!CSS.supports('selector(A > B)') || !CSS.supports('selector(:focus-visible)')) {
      await import('focus-visible');
    }
  })();
}
