import { useEffect, useCallback, useState } from 'react';
import * as L from 'luxon';
import { useIntl } from 'react-intl';
import { getWelcomeMessages } from './utils';
import { Heading } from '@ctek/design-system';

interface Props {
  firstName: string
  now: L.DateTime
}

const noon = L.DateTime.fromObject({ hour: 12 });
const endOfAfternoon = L.DateTime.fromObject({ hour: 16 }).endOf('hour');
const startOfEve = noon.set({ hour: 17 });
const endOfEve = endOfAfternoon.set({ hour: 23 });

export default function WelcomeWidgetWelcomeMessage(props: Props) {
  const intl = useIntl();
  const [welcomeMessage, setWelcomeMessage] = useState('');

  const getTimeMessage = useCallback(() => {
    let timeMessage = intl.formatMessage({ id: 'welcome-widget-good-morning', defaultMessage: 'Good Morning' });
    if (props.now >= noon && props.now <= endOfAfternoon) {
      timeMessage = intl.formatMessage({ id: 'welcome-widget-good-afternoon', defaultMessage: 'Good Afternoon' });
    }
    if (props.now >= startOfEve && props.now <= endOfEve) {
      timeMessage = intl.formatMessage({ id: 'welcome-widget-good-evening', defaultMessage: 'Good Evening' });
    }
    return timeMessage;
  }, [intl, props.now]);

  useEffect(() => {
    // 33% chance to show name, we can experiment and adjust as we see fit
    const includeName = Math.random() <= 0.33;

    const messagesList = getWelcomeMessages(intl);
    // + 1 to include time based message possibility
    const rand = Math.floor(Math.random() * Math.floor(messagesList.length + 1));

    const msg = rand > messagesList.length - 1 ? getTimeMessage() : messagesList[rand];

    const welcomeMsg = includeName ? `${msg}, ${props.firstName}` : msg;
    setWelcomeMessage(welcomeMsg);
  }, [getTimeMessage, setWelcomeMessage, props.firstName, intl])

  return (
    <Heading.Headline>
      {welcomeMessage}
    </Heading.Headline>
  )
}
