import { DateTime, Duration, ToRelativeUnit } from 'luxon';
import { FieldKey } from './LearnerActivityBarChart';
import { LearnerActivityTimeMode } from './LearnerActivityWidget';
import { ChartData } from 'layout/charts/BarChartD3';

export const getTotalNuggetCount = (data: { userActivity: { summary: { nuggetsCompleted: number } } }) => {
  return data.userActivity?.summary?.nuggetsCompleted > -1 ? data.userActivity?.summary?.nuggetsCompleted : 0;
};

export const getTotalQuestionCount = (data: { userActivity: { timeBuckets: Ctek.Roentgen.UsageMetrics[] } }) => {
  let total = 0;
  data.userActivity.timeBuckets.forEach(datum => {
    total = datum.questionsAnswered.sum && datum.questionsAnswered.sum ? total + datum.questionsAnswered.sum : total;
  });
  return total;
};

// This is pretty the same calculation used in Learn API to populate the study time in LX Activity.
export const getTotalTimeCount = (data: { userActivity: { timeBuckets: Ctek.Roentgen.UsageMetrics[] } }) => {
  let total = 0;
  data.userActivity.timeBuckets.forEach(datum => {
    total = datum.durationActualSec.sum && datum.durationActualSec.sum ? total + datum.durationActualSec.sum : total;
  });
  return total;
};

export const getTooltipTimeFrame = (date: string, timeMode: LearnerActivityTimeMode) => {
  switch (timeMode) {
    case LearnerActivityTimeMode.DAY:
      return DateTime.fromISO(date).toUTC().toFormat('dd MMM');
    case LearnerActivityTimeMode.WEEK:
      // TODO: change this to include week format when it is implemented
      return DateTime.fromISO(date).toUTC().toFormat('dd MMM');
    case LearnerActivityTimeMode.MONTH:
      return DateTime.fromISO(date).toUTC().toFormat('MMMM');
    default:
      return;
  }
};

export const getTooltipValue = (bucket: Ctek.Roentgen.UsageMetrics, fieldKey: FieldKey) => {
  switch (fieldKey) {
    case FieldKey.NUGGETS_KEY:
      return bucket.nuggetsCompleted;
    case FieldKey.QUESTIONS_KEY:
      return !!bucket.questionsAnswered.sum && bucket.questionsAnswered.sum! >= 0 ? bucket.questionsAnswered.sum! : 0;
    case FieldKey.TIME_KEY:
      return !!bucket.durationActualSec.sum && bucket.durationActualSec.sum! >= 0 ? bucket.durationActualSec.sum! : 0;
    default:
      return 0;
  }
};

export const getChartValue = (bucket: Ctek.Roentgen.UsageMetrics, fieldKey: FieldKey) => {
  switch (fieldKey) {
    case FieldKey.NUGGETS_KEY:
      return bucket.nuggetsCompleted;
    case FieldKey.QUESTIONS_KEY:
      return !!bucket.questionsAnswered.sum && bucket.questionsAnswered.sum! >= 0 ? bucket.questionsAnswered.sum! : 0;
    case FieldKey.TIME_KEY:
      return !!bucket.durationActualSec.sum && bucket.durationActualSec.sum! >= 0 ? bucket.durationActualSec.sum! : 0;
    default:
      return 0;
  }
};

export const formatTimeItems = (items: ChartData[]): { chartItems: ChartData[]; chosenUnit: ToRelativeUnit } => {
  let chosenField: ToRelativeUnit = 'seconds';
  const timeObjItems = items.map(item => {
    return Duration.fromObject({ seconds: item.y }).shiftTo('hours', 'minutes', 'seconds').toObject();
  });

  if (timeObjItems.some(item => item.minutes! > 0)) {
    chosenField = 'minutes';
  }
  if (timeObjItems.some(item => item.hours! > 0)) {
    chosenField = 'hours';
  }

  return {
    chartItems: items.map(item => ({
      y: Duration.fromObject({ seconds: item.y }).as(chosenField),
      x: item.x,
    })),
    chosenUnit: chosenField,
  };
};

export const getDefaultData = (timeMode: LearnerActivityTimeMode, startTime: string) => {
  const data: ChartData[] = [];
  switch (timeMode) {
    case LearnerActivityTimeMode.DAY:
      for (let i = 0; i < 7; i++) {
        data.push({ x: DateTime.fromISO(startTime).toUTC().plus({ days: i }).toISO(), y: 0 });
      }
      return data;
    case LearnerActivityTimeMode.WEEK:
      const end = DateTime.fromISO(startTime).toUTC().daysInMonth;
      for (let i = 0; i < end; i++) {
        data.push({ x: DateTime.fromISO(startTime).toUTC().plus({ days: i }).toISO(), y: 0 });
      }
      return data;
    case LearnerActivityTimeMode.MONTH:
      for (let i = 0; i < 12; i++) {
        data.push({ x: DateTime.fromISO(startTime).toUTC().plus({ months: i }).toISO(), y: 0 });
      }
      return data;
    default:
      return [];
  }
};
