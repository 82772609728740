import { TranslationDocument } from 'entities/TranslationDocument/TranslationDocument';
import { GET, handlePromiseRejection } from 'century-core/core-apis/utils';

export function getCdnURL(): URL {
  const url = new URL('/', 'https://cdn.glo.century.tech');

  return url;
}

export function fetchTranslationMessages(locale: string) {
  const url = getCdnURL();
  url.pathname = 'components/translations/webapp-v3/latest/' + locale + '.json';
  return GET<TranslationDocument>({ url }).catch(handlePromiseRejection('localised-errors-cdn-error'));
}
