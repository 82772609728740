import { GET } from 'century-core/core-apis/utils';
import { getContentURL } from '../contentV2';

export function getFragment(fragmentId: string, accessToken: string, populate?: boolean) {
  const url = getContentURL(`/fragments/${fragmentId}`);
  if (populate) {
    url.search += '&populate=true';
  }

  return GET<Ctek.Fragment>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get fragment by id ${fragmentId}, ${err}`);
  });
}
