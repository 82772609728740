import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import RecentlyAttemptedWidgetList from './RecentlyAttemptedWidgetList';
import { Widget } from '@ctek/design-system';
import { getNuggetProp, getStudentProp, shouldDisplayNugget, getCourseProp } from 'century-core/core-utils/utils/qla/studentOverviewDashboard'
import { QLANavigationInformationProvider } from 'century-core/features-dashboard/common/QuestionLevelAnalysis/QLANavigationInformationProvider'
import { QLALensInformationProvider } from 'century-core/features-dashboard/common/QuestionLevelAnalysis/QLALensInformationProvider'
import QueryWrapper from 'century-core/features-dashboard/Widgets/QueryWrapper';
import { recentlyAttemptedWidgetQuery } from './recentlyAttemptedWidget.graphql';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import { TestPracticeFilter } from 'century-core/core-apis/roentgen/queries/myCoursesStudent.graphql';
import { useQuery } from '@apollo/client';

export interface Props {
  userId: string;
  subjectIds: string[];
  subjectGroupIds: string[];
  testPracticeFilter?: TestPracticeFilter;
  widgetMode: DashboardUserMode;
};

const RecentlyAttemptedWidgetBody = ({ subjectIds, subjectGroupIds, testPracticeFilter, userId, widgetMode }: Props) => {
  const intl = useIntl();
  const queryParams = useMemo(() => ({
    input: {
      userId,
      subjectGroupIds,
      subjectIds,
      testPrepFilterSelector: testPracticeFilter,
      limit: 5,
      mode: 'past-mostrecentcompleted' as Ctek.RlpMode,
      courseTypes: ['standard', 'assignment'],
    },
  }), [userId, subjectGroupIds, subjectIds, testPracticeFilter]);

  const query = useQuery(
    recentlyAttemptedWidgetQuery,
    {
      variables: queryParams,
      fetchPolicy: 'network-only',
    },
  );

  const emptyContent = widgetMode === DashboardUserMode.STUDENT
    ? ({ title: intl.formatMessage({ id: 'widget--recently-completed-nuggets--empty-title', defaultMessage: 'Go and attempt a nugget!', }) })
    : ({ title: intl.formatMessage({ id: 'guardian-recently-completed-widget-empty-message', defaultMessage: 'No recently attempted nuggets', }) });

  const isEmpty = useMemo(() => (
    Array.isArray(query.data?.nuggetPath?.nuggets) && query.data.nuggetPath.nuggets.length === 0
  ), [query.data]);

  return (
    <Widget.Body>
      <QueryWrapper
        query={query}
        emptyState={emptyContent}
        isEmpty={isEmpty}
      >
        <QLALensInformationProvider nuggets={query.data?.nuggetPath?.nuggets || []} getProp={getCourseProp}>
          <QLANavigationInformationProvider nuggets={query.data?.nuggetPath?.nuggets || []} students={[{ _id: userId }]}
            shouldDisplayNugget={shouldDisplayNugget} getNuggetProp={getNuggetProp} getStudentProp={getStudentProp}>
            <RecentlyAttemptedWidgetList nuggets={query.data?.nuggetPath?.nuggets || []} userId={userId} widgetMode={widgetMode} />
          </QLANavigationInformationProvider>
        </QLALensInformationProvider>
      </QueryWrapper>
    </Widget.Body>
  );
}

export default RecentlyAttemptedWidgetBody;
