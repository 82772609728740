export enum B2CDataLayerEvent {
  APP_EMAIL_REGISTRATION = 'app_email_registration',
  VIEW_ITEM = 'view_item',
  APP_SUBSCRIPTION = 'app_subscription',
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  APP_PAYMENT = 'app_payment',
  ADD_PAYMENT_INFO = 'add_payment_info',
  BEGIN_CHECKOUT = 'begin_checkout',
  PURCHASE = 'purchase',
}

export interface DataLayerEventObjectItem<T> {
  item_name: T;
  item_id: T;
  item_brand: string;
  item_category: string;
  quantity?: number;
  price: number;
  currency: string;
}

export interface DataLayerEventEcommercePurchaseObject<T> extends DataLayerEventEcommerceObject<T> {
  currency: string;
  value: number;
  transaction_id: string;
  coupon: string;
  action: 'update' | 'create';
  subscription_id: string;
}

export interface DataLayerEventEcommerceObject<T> extends DataLayerEventObject {
  ecommerce: {
    payment_type?: 'card'; // only this option for now
    items: DataLayerEventObjectItem<T>[];
  };
}

export interface DataLayerEventObject {
  event: string;
}

export interface DataLayerUserIdObject {
  user_id: string;
}

function initialiseDataLayer() {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
}

export function push<T>(
  object: DataLayerUserIdObject | DataLayerEventObject | DataLayerEventEcommerceObject<T> | DataLayerEventEcommercePurchaseObject<T>
) {
  initialiseDataLayer();
  window.dataLayer?.push?.(object);
}

const dataLayerLib = { push };

export default dataLayerLib;
