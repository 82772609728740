import { MenuItemData, OptionsDropdown } from '@ctek/design-system';
import { OptimisedImage } from 'century-core/core-components/OptimisedImage/OptimisedImage';
import { PureComponent } from 'react';
import './UploadedImage.scss';

export enum ValidationStatus {
  WARNING = 'warning',
}

export interface Props {
  children: UploadedImageProps;
  qa?: string;
}

export interface UploadedImageProps {
  controls?: MenuItemData[];
  validationStatus?: ValidationStatus;
  imgSrc: string | null;
}

export class UploadedImage extends PureComponent<Props, {}> {
  public render() {
    const { qa } = this.props;
    const { controls, validationStatus, imgSrc } = this.props.children;

    let className = 'uploaded-image';

    if (validationStatus) {
      className += ` uploaded-image--${validationStatus}`;
    }

    return (
      <div className={className} data-qa={qa} data-testid={qa}>
        {controls && !!controls.length && (
          <div className="uploaded-image__controls">
            <OptionsDropdown>
              {{
                menuItems: controls,
              }}
            </OptionsDropdown>
          </div>
        )}
        {imgSrc !== null && <OptimisedImage src={imgSrc || ''} alt="uploaded" />}
      </div>
    );
  }
}

export default UploadedImage;
