import { Button } from '@ctek/design-system';
import * as R from 'ramda';
import { useContext, useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import { FormattedMessage } from 'react-intl';
import AlternativeBoardSubmitted from 'century-core/core-components/DraggableQuestionTypes/components/AlternativeBoardSubmitted/AlternativeBoardSubmitted';
import UploadedImage from 'century-core/core-components/DraggableQuestionTypes/components/UploadedImage/UploadedImage';
import { emptyDelta } from 'century-core/core-utils/utils/quill/constants';
import ClickSelectAndDrop, {
  ClickSelectAndDropProvider,
} from '../../DragDrop/ClickSelectAndDrop/ClickSelectAndDropContext';
import ClickSelectItem from '../../DragDrop/ClickSelectAndDrop/ClickSelectItem';
import CustomDragLayer from '../../DragDrop/CustomDragLayer';
import DragSource from '../../DragDrop/DragSource';
import DropTarget from '../../DragDrop/DropTarget';
import ItemTypes from '../ItemTypes';
import { MatchingOrLabellingQuestionTypeProps } from '../../../typings/DraggableQuestionTypes';
import { AnsweredItem, PossibleAnswer } from '../../../typings/DraggableQuestionTypes';
import CachedImageUrlContext from 'century-core/media/hooks/useGetImagePublicUrlCache';
import useMatchingAndLabellingStateManager from '../useMatchingAndLabellingStateManager';
import { getPossibleAnswerById } from '../utils';
import CustomLayerImage from './CustomLayerImage';
import DroppableLabelPair from './DroppableLabelPair';
import CustomLayerLabel from './QuestionLabels/CustomLayerLabel';
import { DraggableLabel, LabelContainer, NoDragLabel } from './QuestionLabels/Label';
import { useAccessToken } from 'century-core/core-auth/hooks/useAuth';

export enum AnswerAction {
  Skip = 'skip',
  Answer = 'answer',
}

const LabellingQuestionType = (props: MatchingOrLabellingQuestionTypeProps) => {
  const { setSelectedItem } = useContext(ClickSelectAndDrop);

  const labellingManager = useMatchingAndLabellingStateManager({ ...props, setSelectedItem });
  const { dragComponent, hideControls, cantSkip, onAnswersChanged } = props;
  const {
    answeredItems,
    getAnswers,
    getPossibleAnswers,
    getContent,
    handleDrop,
    isNoItemLabelled,
    handleAnswerSubmitted,
    nextQuestionLoading,
    isAllItemsLabelled,
    isDropped,
    removeIdFromAnsweredItems,
    resetAnswers,
  } = labellingManager;

  const answers = getAnswers();
  const possibleAnswers = getPossibleAnswers();
  const content = getContent();
  const quillDelta: object = R.pathOr(emptyDelta, [0, 'data'], content);

  useEffect(() => {
    onAnswersChanged?.(answeredItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answeredItems]);

  const customDragLayer = isMobile ? (
    dragComponent === ('text' as Ctek.MatchType) ? (
      <CustomDragLayer DragLayerElement={CustomLayerLabel} elementWidthReferenceElement=".draggable-label-item" />
    ) : (
      <CustomDragLayer DragLayerElement={CustomLayerImage} elementWidthReferenceElement=".drag-image" />
    )
  ) : null;

  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend} context={window}>
      <DropTarget
        accepts={[ItemTypes.ASSIGNED_LABEL]}
        onDrop={item => removeIdFromAnsweredItems(item.id)}
        dropItem={(isOverContainer: boolean, canDropContainer: boolean) => (
          <AlternativeBoardSubmitted>
            {{
              additionals: (
                <LabelContainer handleDrop={id => removeIdFromAnsweredItems(id) }>
                  {dragComponent === ('text' as Ctek.MatchType)
                    ? draggablePossibleAnswer(isDropped, possibleAnswers)
                    : draggableImages(isDropped, possibleAnswers)}
                </LabelContainer>
              ),
              controls: hideControls ? null : (
                <>
                  { !cantSkip && (
                    <Button
                      loading={nextQuestionLoading}
                      onClick={(evt: any) => handleAnswerSubmitted(evt, AnswerAction.Skip)}
                      classNameExt="idk"
                      qa="button-skip"
                    >
                      <FormattedMessage id="study-session-skip-question-btn" defaultMessage="I don't know" />
                    </Button>
                  )}
                  <Button loading={nextQuestionLoading} disabled={isNoItemLabelled()} onClick={resetAnswers} qa="button-reset">
                    <FormattedMessage id="reset-button" defaultMessage="Reset" />
                  </Button>
                  <Button
                    loading={nextQuestionLoading}
                    disabled={!isAllItemsLabelled()}
                    type="button"
                    variant="secondary"
                    onClick={(evt: any) => handleAnswerSubmitted(evt, AnswerAction.Answer)}
                    qa="button-submit"
                  >
                    <FormattedMessage id="study-session-submit-answer-btn" defaultMessage="Submit answer" />
                  </Button>
                </>
              ),
              pairs: renderLabelPairs(handleDrop, possibleAnswers, answeredItems, answers, dragComponent),
              questionContentValue: quillDelta,
            }}
          </AlternativeBoardSubmitted>
        )}
      />
      {customDragLayer}
    </DndProvider>
  );
};

export const ClickDropProviderLabellingQuestionType = (props: MatchingOrLabellingQuestionTypeProps) => {
  return (
    <ClickSelectAndDropProvider>
      <LabellingQuestionType {...props} />
    </ClickSelectAndDropProvider>
  );
};

const renderLabelPairs = (
  onDrop: (index: number, item: { id: string }) => void,
  labels: PossibleAnswer[],
  answeredItems: AnsweredItem[],
  answers: Ctek.Answer[],
  dragComponent: Ctek.MatchType
) => {
  return answers.map((answer, index) => {
    const relatedAnsweredItem = answeredItems.find(item => item.answerGroupAnswerId === answer.id);
    const possibleAnswer = relatedAnsweredItem ? getPossibleAnswerById(relatedAnsweredItem.possibleAnswerId, labels) : undefined;
    return (
      <DroppableLabelPair
        key={answer?.id?.substring(6) + '_' + possibleAnswer?.id}
        accepts={[ItemTypes.ASSIGNED_LABEL, ItemTypes.UNASSIGNED_LABEL]}
        onDrop={onDrop}
        index={index}
        label={dragComponent === ('text' as Ctek.MatchType) ? possibleAnswer : answer}
        itemBox={dragComponent === ('text' as Ctek.MatchType) ? answer : possibleAnswer}
        dragComponent={dragComponent}
      />
    );
  });
};

const draggablePossibleAnswer = (isDropped: (id: string) => boolean, possibleAnswers: PossibleAnswer[]) => {
  return possibleAnswers.map((possibleAnswer, index) => {
    if (isDropped(possibleAnswer.id)) {
      return <NoDragLabel inactive={true} key={index} label={possibleAnswer} />
    }
    return <DraggableLabel key={index} label={possibleAnswer} type={ItemTypes.UNASSIGNED_LABEL} />;
  });
};

const draggableImages = (isDropped: (id: string) => boolean, possibleAnswers: PossibleAnswer[]) => {
  return possibleAnswers.map((possibleAnswer, index) => (
    <DraggableImage key={index} possibleAnswer={possibleAnswer} isDropped={isDropped} />
  ));
};

const DraggableImage = ({ isDropped, possibleAnswer }: { isDropped: (id: string) => boolean; possibleAnswer: PossibleAnswer }) => {
  const [imageUrl, setImageUrl] = useState(null as string | null);
  // TODO: refactor - when QLA is in React, we can get auth in a more conventional ways
  const token = useAccessToken();
  const { getMediaPublicUrl } = useContext(CachedImageUrlContext);

  useEffect(() => {
    async function getImageUrl() {
      // TODO add error handling rather than silently fail?
      const imageURL = (await getMediaPublicUrl(possibleAnswer.content[0].id, token)) as string;
      setImageUrl(imageURL);
    }

    getImageUrl();
  }, [getMediaPublicUrl, possibleAnswer.content, token]);

  if (!imageUrl) {
    return null;
  }
  const inactive = isDropped(possibleAnswer.id);
  return (
    <div className={`drag-image draggable-label-item draggable-label-item__${inactive ? 'inactive' : 'active'}`}>
      <DragSource
        dragItem={(isDragging: boolean) => (
          <ClickSelectItem id={possibleAnswer.id}>
            <UploadedImage>{{ imgSrc: imageUrl }}</UploadedImage>
          </ClickSelectItem>
        )}
        type={ItemTypes.UNASSIGNED_LABEL}
        id={possibleAnswer.id}
        item={imageUrl}
      />
    </div>
  );
};
