import { InputError, LoadingSpinner } from '@ctek/design-system';
import * as React from 'react';
import InputFile from '../InputFile/InputFile';
import UploadedImage, { UploadedImageProps, ValidationStatus } from '../UploadedImage/UploadedImage';
import './ImageArea.scss';

interface ImageAreaProps extends UploadedImageProps {
  warnings?: React.ReactElement<typeof InputError>[] | React.ReactElement<typeof InputError>;
  id?: string;
  onDrop: (e: any) => void;
  onImgChange: ((e: any) => void) | undefined;
  uploadActive?: boolean;
}
const ImageArea = (props: ImageAreaProps) => {
  const { warnings, onDrop, onImgChange, controls, id, imgSrc } = props;
  const uploadActive = props.uploadActive || false;
  const validationStatus = warnings ? ValidationStatus.WARNING : undefined;

  if (imgSrc) {
    return (
      <div className="image-area">
        <UploadedImage>
          {{
            controls,
            imgSrc,
            validationStatus,
          }}
        </UploadedImage>
        {warnings}
      </div>
    );
  }

  return (
    <div className="image-area">
      {uploadActive ? (
        <div className="image-area__spinner">
          <LoadingSpinner type="widget" />
        </div>
      ) : (
        <InputFile accept="image/*" id={id} name={`fileUpload${id ? `${id}` : undefined}`} onChange={onImgChange} onDrop={onDrop} />
      )}
      {warnings}
    </div>
  );
};

export default ImageArea;
