import { QlaLevel2, QlaTable } from '@ctek/design-system';
import CenturyExactTime from 'century-core/core-components/CenturyTime/CenturyExactTime';
import { FormattedMessage } from 'react-intl';
import { QLAAttempt } from '../Attempt/QLAAttemptProvider';

interface Props {
  attempt: QLAAttempt | undefined | null;
}

export default function QlaLv2Material({ attempt }: Props) {
  if (!attempt) {
    return null;
  }
  return (
    <QlaTable qa="qla-fragments">
      <QlaLevel2.HeadRow>
        <QlaLevel2.ColName>
          <FormattedMessage id="question-level-analysis-attempt-detail-fragments-header-name" defaultMessage="Learning Material" />
        </QlaLevel2.ColName>
        <QlaLevel2.ColType>
          <FormattedMessage id="question-level-analysis-attempt-detail-fragments-header-type" defaultMessage="Media type" />
        </QlaLevel2.ColType>
        <QlaLevel2.Col>
          <FormattedMessage id="question-level-analysis-attempt-detail-fragments-header-active" defaultMessage="Time spent" />
        </QlaLevel2.Col>
      </QlaLevel2.HeadRow>
      {attempt.studySessionResult.fragments &&
        attempt.fragments.length > 0 &&
        attempt?.studySessionResult.fragments.map(item => {
          const fragment = attempt.fragments.find(contentFragment => contentFragment._id === item.fragmentId) as Ctek.Fragment;
          return (
            <QlaLevel2.Row key={fragment._id}>
              <QlaLevel2.ColName>{fragment.name}</QlaLevel2.ColName>
              <QlaLevel2.ColType>
                {(fragment.fragmentType as Ctek.Label).name ? (
                  <FormattedMessage
                    id={`fragment-type-${(fragment.fragmentType as Ctek.Label).name}`}
                    defaultMessage={(fragment.fragmentType as Ctek.Label).name}
                  />
                ) : (
                  <FormattedMessage id="fragment-type-unknown" defaultMessage="Unknown" />
                )}
              </QlaLevel2.ColType>
              <QlaLevel2.Col>
                <CenturyExactTime time={item.durationActual} shortenUnits={true} />
              </QlaLevel2.Col>
            </QlaLevel2.Row>
          );
        })}
    </QlaTable>
  );
}
