import * as React from 'react';

export interface InputDeprecatedProps {
  errorMessage?: React.ReactNode | null;
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  min?: any;
  max?: any;
  maxLength?: number;
  minLength?: number;
  name?: any;
  pattern?: string | RegExp;
  placeholder?: string;
  spellCheck?: boolean;
  title?: string;
  type?: 'password' | 'text' | 'email' | 'date' | 'number' | 'range' | 'file' | 'search';
  value?: string | number;
  qa?: string;
  accept?: string;
  autoCapitalize?: 'off' | 'on';
  autoComplete?: 'bday' | 'current-password' | 'email' | 'family-name' | 'given-name' | 'new-password' | 'off' | 'username';
  autoCorrect?: 'off' | 'on';
  refFunction?: any;
  list?: string;
  readonly?: boolean;
  role?: string;
  style?: object;
  id?: number | string;
  inputMode?: any;
}

export class InputDeprecated extends React.Component<InputDeprecatedProps, {}> {
  public render() {
    const type = this.props.type || 'text';
    const {
      spellCheck,
      placeholder,
      name,
      value,
      qa,
      autoCapitalize,
      autoComplete,
      autoCorrect,
      refFunction,
      onChange,
      onBlur,
      min,
      max,
      maxLength,
      minLength,
      list,
      accept,
      id,
      onFocus,
      role,
      style,
      readonly,
      inputMode,
    } = this.props;

    return (
      <div className={`form-input form-input--${type}`}>
        <input
          onBlur={onBlur}
          onFocus={onFocus}
          className="input"
          style={style}
          role={role}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          spellCheck={spellCheck}
          type={type}
          value={value}
          data-testid={qa}
          autoCapitalize={autoCapitalize}
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          ref={refFunction}
          id={id ? `${id}` : undefined}
          list={list}
          min={min}
          max={max}
          maxLength={maxLength}
          minLength={minLength}
          readOnly={readonly}
          accept={accept}
          inputMode={inputMode}
        />
      </div>
    );
  }
}
