import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import App from './App';
import { Actions as AuthActions } from 'state/actions/auth/auth';
import { StoreState, ThunkExtraArg } from 'state/reducers/reducers';
import * as Select from 'state/selectors';

export type OwnProps = RouteComponentProps;

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => {
  return {
    auth: Select.getAuth(state),
    history: Select.getHistory(state, ownProps),
    location: Select.getLocation(state, ownProps),
    match: Select.getMatch(state, ownProps),
    user: Select.getCurrentUser(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<StoreState, ThunkExtraArg, AuthActions>) => ({});

const mergeProps = (propsFromState: StateProps, propsFromDispatch: DispatchProps, ownProps: OwnProps) => propsFromState;

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type MergeProps = ReturnType<typeof mergeProps>;

export type ConnectedProps = MergeProps;

export default compose(
  withRouter,
  connect<StateProps, DispatchProps, OwnProps>(mapStateToProps, mapDispatchToProps, mergeProps)
)(App as unknown as React.ComponentClass<OwnProps>);
