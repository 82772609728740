import { createContext, useState, useContext } from 'react';
import * as React from 'react';
import { getStudySessionResult } from 'century-core/core-apis/learn/studySessionResult/studySessionResult'
import { QuestionGroupContext } from '../QuestionGroupProvider'
import { FragmentContext } from '../FragmentProvider'
import { useAccessToken } from 'century-core/core-auth/hooks/useAuth';

export const QLAAttemptContext = createContext<{
  attempt: QLAAttempt | null,
  getAttempt: (studySessionId: string) => Promise<void>
} | null>(null);
export default QLAAttemptContext;

export interface QLAAttempt {
  studySessionResult: Ctek.StudySessionResult;
  fragments: Ctek.Fragment[];
  questionGroups: Ctek.EnrichedQuestionGroup[];
}

interface StudySessionResultsCache {
  [id: string]: Ctek.StudySessionResult;
}

export const QLAAttemptProvider = (props: { children: React.ReactNode }) => {
  const accessToken = useAccessToken();
  const [studySessionResultsCache, updateStudySessionsResultsCache] = useState(null as null | StudySessionResultsCache)
  const [attempt, setAttempt] = useState(null as null | QLAAttempt)
  const qgContext = useContext(QuestionGroupContext)
  const fragmentContext = useContext(FragmentContext)

  const getAttempt = async (studySessionId: string) => {
    let attempt = {
      fragments: [] as Ctek.Fragment[],
      questionGroups: [] as Ctek.EnrichedQuestionGroup[],
      studySessionResult: {} as Ctek.StudySessionResult,
    };
    if (studySessionResultsCache && studySessionResultsCache[studySessionId]) {
      attempt = { ...attempt, studySessionResult: studySessionResultsCache[studySessionId] };
    } else {
      const studySessionResult = await getStudySessionResult(studySessionId, accessToken);
      attempt = { ...attempt, studySessionResult };
      updateStudySessionsResultsCache({ ...studySessionResultsCache, [studySessionId]: studySessionResult })
    }
    attempt.fragments = await fragmentContext?.getFragments(attempt.studySessionResult.fragments.map(f => f.fragmentId)) as Ctek.Fragment[]
    attempt.questionGroups = await qgContext?.getQuestionGroup(attempt.studySessionResult.questions.map(q => q.questionGroupId)) as Ctek.EnrichedQuestionGroup[]
    setAttempt(attempt)
  }

  return <QLAAttemptContext.Provider value={{ attempt, getAttempt }}>{props.children}</QLAAttemptContext.Provider>;
};
