import { useState, useContext, useEffect } from 'react';
import LabelPairBase from 'century-core/core-components/DraggableQuestionTypes/components/LabelPair/LabelPairBase';
import UploadedImage from 'century-core/core-components/DraggableQuestionTypes/components/UploadedImage/UploadedImage';
import ClickSelectDrop from '../../DragDrop/ClickSelectAndDrop/ClickSelectDrop';
import ClickSelectItem from '../../DragDrop/ClickSelectAndDrop/ClickSelectItem';
import DragSource from '../../DragDrop/DragSource';
import DropTarget from '../../DragDrop/DropTarget';
import ItemTypes from '../ItemTypes';
import { PossibleAnswer } from '../../../typings/DraggableQuestionTypes';
import CachedImageUrlContext from 'century-core/media/hooks/useGetImagePublicUrlCache';
import { DraggableLabel, NoDragLabel } from './QuestionLabels/Label';
import { useAccessToken } from 'century-core/core-auth/hooks/useAuth';

export interface DroppableLabelPairProps {
  accepts: string[];
  onDrop: (index: number, item: { id: string }) => void;
  index: number;
  label: PossibleAnswer | Ctek.Answer | null | undefined;
  itemBox: Ctek.Answer | PossibleAnswer | undefined;
  dragComponent: Ctek.MatchType;
}

const DroppableLabelPair = (props: DroppableLabelPairProps) => {
  const [imageUrl, setImageUrl] = useState(null as null | string);
  const token = useAccessToken();
  const { getMediaPublicUrl } = useContext(CachedImageUrlContext);

  useEffect(() => {
    async function fetchImageUrl() {
      if (props.itemBox) {
        // TODO add error handling rather than displaying empty fields?
        // Get token from window as this is not guaranteed to have a redux context above it.
        const fetchedUrl = await getMediaPublicUrl(props.itemBox.content?.[0].id, token);
        setImageUrl(fetchedUrl);
      }
    }

    fetchImageUrl();
  }, [getMediaPublicUrl, props.itemBox, token]);

  const { label, accepts, index, itemBox, dragComponent, onDrop } = props;
  return (
    <DropTarget
      accepts={accepts}
      onDrop={item => onDrop(index, item)}
      dropItem={(isOver: boolean, canDrop: boolean) => (
        <ClickSelectDrop onDrop={(id: string) => onDrop(index, { id })}>
          {dragComponent === ('text' as Ctek.MatchType) ? (
            <LabelPairBase key={index} classNameExt={isOver && canDrop ? 'label-pair--hover' : undefined}>
              {{
                image: imageUrl ? (
                  <UploadedImage>
                    {{
                      imgSrc: imageUrl,
                    }}
                  </UploadedImage>
                ) : (
                  <NoImagePlaceholder />
                ),
                label: label ? <DraggableLabel label={label} type={ItemTypes.ASSIGNED_LABEL} /> : <NoDragLabel />,
              }}
            </LabelPairBase>
          ) : (
            <LabelPairBase classNameExt={isOver && canDrop ? 'label-pair--hover' : undefined}>
              {{
                image: itemBox ? (
                  <DragSource
                    dragItem={(isDragging: boolean) => (
                      <ClickSelectItem id={itemBox.id as string}>
                        <UploadedImage>
                          {{
                            imgSrc: imageUrl || null,
                          }}
                        </UploadedImage>
                      </ClickSelectItem>
                    )}
                    type={ItemTypes.ASSIGNED_LABEL}
                    id={itemBox.id as string}
                    item={imageUrl ? imageUrl : ''}
                  />
                ) : (
                  <NoImagePlaceholder />
                ),
                label: label ? <NoDragLabel label={label} noDragHandle={true} /> : <NoDragLabel noDragHandle={true} />,
              }}
            </LabelPairBase>
          )}
        </ClickSelectDrop>
      )}
      key={index}
    />
  );
};

export default DroppableLabelPair;

export const NoImagePlaceholder = () => (
  <div className="no-image-placeholder">
    <svg
      height="100%"
      width="100%"
      version="1.1"
      preserveAspectRatio="none"
      viewBox="0.0 0.0 960.0 960.0"
      fill="none"
      stroke="none"
      strokeLinecap="square"
      strokeMiterlimit="10"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <g clip-path="url(#p.0)" height="100%" width="100%"> */}
      <rect width="100%" height="100%" fill="#D1D4D8" rx="48px" ry="48px" />
      {/* </g> */}
    </svg>
  </div>
);
