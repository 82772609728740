import { getCmsURL } from './cms';
import { POST } from '../utils';

export type CoursesPayload = { name: string; id: string }[];
export type JobIdsPayload = string[];

export async function updateLearnerCourses(learnerId: string, courses: CoursesPayload, accessToken: string) {
  const url = getCmsURL(`/consumer/learner/${learnerId}/courses`);
  return await POST<{ [learnerId: string]: JobIdsPayload }>({ url, token: accessToken, body: courses });
}
