import { useSubscriptionContext } from 'century-core/core-subscription/hooks/SubscriptionContext';
import { useAuthContext } from 'century-core/core-auth/AuthContext';
import { ReactNode, useMemo } from 'react';
import { MixpanelProvider } from 'react-mixpanel';
import { getMixpanelWithDefaultProps } from 'century-core/core-utils/utils/mixpanel/mixpanel';
import { useLearnerProductContext } from 'century-core/core-subscription/hooks/LearnerProductContext';
import { useB2C } from 'century-core/core-utils/hooks/useB2C';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';

// This exists to avoid a lot of noise on App.tsx; following the migration into century-core this will need a re-working
export const CenturyMixpanelProvider = (props: { children: ReactNode }) => {
  const { auth, orgSettings } = useAuthContext();
  const isB2C = useB2C();
  const { subscriptionTrackingDetails } = useSubscriptionContext();
  const learnerProductContext = useLearnerProductContext();

  const b2cTrackingDetails = useMemo(() => {
    if (!isB2C) {
      return null;
    }
    if (learnerProductContext?.product) {
      return { [MixpanelKeys.LearnerProductKey]: learnerProductContext?.product?.productKey };
    }
    return subscriptionTrackingDetails;
  }, [subscriptionTrackingDetails, learnerProductContext, isB2C]);

  return (
    <MixpanelProvider mixpanel={getMixpanelWithDefaultProps(auth, orgSettings, isB2C ? b2cTrackingDetails : {})}>
      {props.children}
    </MixpanelProvider>
  );
};
