import { Link, LinkProps } from 'react-router-dom';

interface ConditionalLinkProps {
  shouldBeLink: boolean;
  children: React.ReactNode;
  to: LinkProps['to'];
}
export default function ConditionalLink({ children, shouldBeLink, ...props }: ConditionalLinkProps) {
  return shouldBeLink && props.to ? <Link {...props}>{children}</Link> : <>{children}</>;
}
