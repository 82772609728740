import { useCallback, useMemo, useState } from 'react';
import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { FlexGrid, FlexGridDirection, LoadingSpinner, PageBody, PageContent, PageSubheader } from '@ctek/design-system';

import { getCoursePath } from 'century-core/core-apis/roentgen/queries/studentDashboard';
import StudentDashboardNoCourses from 'century-core/features-dashboard/StudentDashboard/StudentDashboardNoCourses';
import ActivityCalendarWidget from 'century-core/features-dashboard/common/Widgets/ActivityCalendarWidget/ActivityCalendarWidget';
import CoursesWidget from 'century-core/features-dashboard/common/Widgets/CoursesWidget/CoursesWidget';
import LearnerActivityWidget, {
  LearnerActivityTimeMode,
} from 'century-core/features-dashboard/common/Widgets/LearnerActivityWidget/LearnerActivityWidget';
import RecentlyCompleted from 'century-core/features-dashboard/common/Widgets/RecentlyAttemptedWidget/RecentlyAttemptedWidget';
import RecommendedNuggetsWidget from 'century-core/features-dashboard/common/Widgets/RecommendedNuggetsWidget/RecommendedNuggetsWidget';
import { ErrorReload } from 'century-core/core-components/ErrorReload/ErrorReload';
import { Filter, SubjectFilter } from 'century-core/features-dashboard/TeacherDashboard/TeacherDashboardClassOverview/ClassOverview';
import UserSubjectsGroupFilter from 'century-core/features-dashboard/Widgets/SubjectGroupsFilter/UserSubjectsGroupFilter';
import { DashboardUserMode } from 'century-core/core-utils/utils/utils';
import { TestPracticeFilter } from 'century-core/core-apis/roentgen/queries/myCoursesStudent.graphql';
import WelcomeWidget from 'century-core/features-dashboard/common/Widgets/WelcomeWidget/WelcomeWidget';
import { useDeviceInfo } from 'century-core/user-preferences/DeviceInfoContext';
import { usePageViewEvent } from 'century-core/core-components/MixpanelUtils';

type OverviewProps = {
  qa: string;
  userMode: DashboardUserMode;
  user: {
    firstName?: string;
    userId: string;
  };
  isBondLearner?: boolean;
};

export const coursesCountQuery = getCoursePath(
  gql`
    fragment studentCourseFragment on CoursePathItem {
      courseId
    }
  `,
  'studentCourseFragment'
);

const mixpanelPageViewEvents = {
  [DashboardUserMode.STUDENT]: 'student-dashboard',
  [DashboardUserMode.TEACHER]: 'teacher-dashboard-student-overview',
  [DashboardUserMode.GUARDIAN]: 'guardian-dependant-overview',
};

export default function StudentDashboardOverview(props: OverviewProps) {
  const { user, userMode, qa, isBondLearner } = props;
  const studentId = user.userId;
  const { deviceType } = useDeviceInfo();
  const [selectedSubjectFilters, setSubjectFilters] = useState<Filter>({ subjectIds: [], subjectGroupIds: [] });

  const testPracticeFilter = isBondLearner ? TestPracticeFilter.ALL : TestPracticeFilter.STANDARD_ONLY;

  usePageViewEvent(mixpanelPageViewEvents[userMode]);

  // We just need the 'count' value, hence the 'offset: 0, limit: 0'
  const coursesCountQueryData = {
    variables: {
      input: {
        userId: studentId,
        courseTypes: ['standard'],
        mode: 'linear-complete',
        offset: 0,
        limit: 0,
        testPrepFilterSelector: TestPracticeFilter.ALL,
      },
    },
    fetchPolicy: 'network-only',
  };

  const {
    data: courseData,
    loading: isLoadingCourseCount,
    error: courseCountError,
    refetch: retryCourseCountRequest,
  } = useQuery(coursesCountQuery, coursesCountQueryData as QueryHookOptions);

  const onFilterSubjects = useCallback((filters: { subjects: SubjectFilter[]; subjectGroups: SubjectFilter[] }) => {
    setSubjectFilters({
      subjectIds: filters.subjects.map(s => s.id),
      subjectGroupIds: filters.subjectGroups.map(sg => sg.id),
    });
  }, []);

  const courses = useMemo(
    () =>
      courseData?.coursePath?.count ? (
        <FlexGrid.Col fillSpace={!isBondLearner} minHeight={240}>
          <CoursesWidget
            userId={studentId}
            widgetMode={userMode}
            subjectGroupIds={selectedSubjectFilters.subjectGroupIds}
            subjectIds={selectedSubjectFilters.subjectIds}
            testPracticeFilter={testPracticeFilter}
            isBondLearner={isBondLearner}
          />
        </FlexGrid.Col>
      ) : null,
    [
      courseData?.coursePath?.count,
      isBondLearner,
      studentId,
      userMode,
      selectedSubjectFilters.subjectGroupIds,
      selectedSubjectFilters.subjectIds,
      testPracticeFilter,
    ]
  );

  if (courseCountError) {
    return <ErrorReload action={() => retryCourseCountRequest()} />;
  }

  if (isLoadingCourseCount) {
    return <LoadingSpinner type="fullpage" />;
  }

  if (!courseData?.coursePath?.count) {
    return <StudentDashboardNoCourses />;
  }

  return (
    <PageBody qa={qa}>
      <PageContent>
        <PageSubheader>
          {userMode === DashboardUserMode.TEACHER && (
            <PageSubheader.Controls>
              <UserSubjectsGroupFilter onSubjectFilterSet={onFilterSubjects} userId={studentId} />
            </PageSubheader.Controls>
          )}
        </PageSubheader>
        <FlexGrid>
          <FlexGrid.Col xs={12} md={7} stretch={true}>
            <FlexGrid direction={FlexGridDirection.COLUMN}>
              {userMode === DashboardUserMode.STUDENT && (
                <FlexGrid.Col xs="auto">
                  <WelcomeWidget firstName={user.firstName!} userId={user.userId} />
                </FlexGrid.Col>
              )}
              <FlexGrid.Col xs="auto" minHeight={444}>
                <LearnerActivityWidget
                  userId={studentId}
                  chartHeight={232}
                  widgetMode={userMode}
                  // LearnerActivityTimeMode is undefined in unit tests so need this caveat
                  defaultTimeMode={LearnerActivityTimeMode ? LearnerActivityTimeMode.WEEK : ('week' as any)}
                  subjectGroupIds={selectedSubjectFilters.subjectGroupIds}
                  subjectIds={selectedSubjectFilters.subjectIds}
                  testPracticeFilter={testPracticeFilter}
                />
              </FlexGrid.Col>
              {deviceType !== 'phone' && courses}
            </FlexGrid>
          </FlexGrid.Col>
          <FlexGrid.Col xs={12} md={5} stretch={true}>
            <FlexGrid direction={FlexGridDirection.COLUMN}>
              <FlexGrid.Col xs="auto">
                <RecentlyCompleted
                  userId={studentId}
                  widgetMode={userMode}
                  subjectGroupIds={selectedSubjectFilters.subjectGroupIds}
                  subjectIds={selectedSubjectFilters.subjectIds}
                  testPracticeFilter={testPracticeFilter}
                />
              </FlexGrid.Col>
              <FlexGrid.Col xs="auto">
                <ActivityCalendarWidget
                  userId={studentId}
                  widgetMode={userMode}
                  subjectGroupIds={selectedSubjectFilters.subjectGroupIds}
                  subjectIds={selectedSubjectFilters.subjectIds}
                  testPracticeFilter={testPracticeFilter}
                />
              </FlexGrid.Col>
              {deviceType === 'phone' && courses}
              <FlexGrid.Col xs="auto">
                <RecommendedNuggetsWidget
                  userId={studentId}
                  widgetMode={userMode}
                  subjectGroupIds={selectedSubjectFilters.subjectGroupIds}
                  subjectIds={selectedSubjectFilters.subjectIds}
                />
              </FlexGrid.Col>
            </FlexGrid>
          </FlexGrid.Col>
        </FlexGrid>
      </PageContent>
    </PageBody>
  );
}
