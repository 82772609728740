import * as R from 'ramda';
import { Actions, ActionTypes } from '../../actions/entities/entities';

export type Entities = Partial<{
  users: Record<string, Ctek.User>;
  courseAchievements: Record<string, Ctek.AbacusGradingStats>;
  courseplans: Record<string, Ctek.CoursePlan>;
  courses: Record<string, Ctek.Course>;
  nuggets: Record<string, Ctek.Nugget>;
  mediaFiles: Record<string, Ctek.EnrichedFile>;
  mediaItems: Record<string, Ctek.Media>;
  nuggetActivities: Record<string, Ctek.AbacusNuggetActivity>;
  questionGroups: Record<string, Ctek.QuestionGroup>;
  questions: Record<string, Ctek.Question>;
  recommendations: Record<string, Ctek.Recommendation>;
  studygroups: Record<string, Ctek.StudyGroup>;
  subjects: Record<string, Ctek.Label>;
}>;

export function entities(state = {}, action: Actions): Entities {
  switch (action.type) {
    case ActionTypes.AddEntities:
      return R.mergeDeepRight(state, action.entities);
    default:
      return state;
  }
}

export default entities;
