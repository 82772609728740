import { InputError } from '@ctek/design-system';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import * as React from 'react';
import { emptyDelta } from 'century-core/core-utils/utils/quill/constants';
import QuillEditor from '../QuillEditor/QuillEditor';
import './Label.scss';
import { sanitize } from 'dompurify';
import { useIntl } from 'react-intl';

export interface Props {
  children: LabelProps;
  labelLengthLimit?: number;
  qa?: string;
}

export interface LabelProps {
  errorMessage?: React.ReactNode | null;
  id?: number;
  enterAndTabEnabled?: boolean;
  value?: any;
  onBlur?: () => void;
  onChange: (newVal: any) => void;
  placeholder?: string;
  readonly?: boolean;
  noDragHandle?: boolean;
}

const Label = (props: Props) => {
  const { qa, labelLengthLimit = 30 } = props;
  const { formatMessage } = useIntl();
  const defaultPlaceholder = formatMessage({ id: 'cms-labelling-label-pair-label-placeholder', defaultMessage: 'Name this label' });
  const { value, onBlur, onChange, placeholder = defaultPlaceholder, readonly = false, noDragHandle, errorMessage } = props.children;
  const toolbar = {
    container: [
      ['bold', 'italic', 'underline', 'formula'], // toolbar buttons TODO define these.
    ],
  };

  const allowedKeys = [
    'Backspace',
    'Shift',
    'ArrowUp',
    'Up',
    'ArrowDown',
    'Down',
    'ArrowLeft',
    'Left',
    'ArrowRight',
    'Right',
    'Home',
    'End',
  ];
  const quillRef = React.createRef<any>();
  React.useEffect(() => {
    // Unbind the enter and tab key listeners that quill uses so that we can prevent the enter key from being used.
    if (quillRef && quillRef.current && !props.children.enterAndTabEnabled) {
      const keyboard = quillRef.current.getEditor().getModule('keyboard');
      delete keyboard.bindings[9];
      delete keyboard.bindings[13];
    }
  }, [props.children.enterAndTabEnabled, quillRef]);

  if (readonly) {
    const parsedHtml = getQuillHtml(value);

    return (
      <div className={`rc-label rc-label--readonly ${!value || noDragHandle ? 'no-drag-handle' : ''}`} data-qa={qa} data-testid={qa}>
        <div className="rc-label--readonly-content" dangerouslySetInnerHTML={{ __html: sanitize(parsedHtml.body.innerHTML) }} />
      </div>
    );
  }

  // For labels, prevent the user typing more text than allowed
  // and blur the label on hitting enter/tab.
  // In future would be nice for tab to focus on the next editor using tabindex
  const keydownHandler = (event: KeyboardEvent) => {
    if (
      !props.children.enterAndTabEnabled &&
      (event.keyCode === 13 || event.keyCode === 9) &&
      // Only exit if the editor is the target firing the keyboard event. Needed as
      // the formula input should submit when enter is hit.
      (event.target as any).className.includes('ql-editor')
    ) {
      event.preventDefault();
      quillRef.current.blur();
      return;
    }

    if (!labelLengthLimit) {
      return;
    }

    if (quillRef.current.getEditor().getLength() > labelLengthLimit && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  // If they try to paste content that will push the length of text over the maximum,
  // prevent the event.
  // In future it would be nice to only paste the remaining available number of characters worth of material.
  const handlePaste = (event: React.ClipboardEvent) => {
    const editor = quillRef.current.getEditor();
    const textToPaste = event.clipboardData.getData('text/plain');
    const spaceLeft = labelLengthLimit - editor.getLength() + 1;

    if (textToPaste.length > spaceLeft) {
      event.preventDefault();
    }
  };

  return (
    <div className={`rc-label rc-label--${errorMessage ? 'invalid' : 'valid'}`} data-qa={qa} data-testid={qa} onPaste={handlePaste}>
      <QuillEditor
        autosave={false}
        toolbar={toolbar}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        value={value ? value : emptyDelta}
        readonly={readonly}
        onKeyDown={keydownHandler}
        innerRef={quillRef}
      />
      {errorMessage && 
        <InputError qa={`${qa}-error`}>
          {errorMessage}
        </InputError>
      }
    </div>
  );
};

export default Label;

export function getQuillHtml(value: any) {
  const converter = new QuillDeltaToHtmlConverter(value ? value.ops : emptyDelta, {});
  const html = converter.convert();
  // Quill delta to html converter does not handle formula
  // parse html, find formula and use katex to update them.
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(html, 'text/html');
  const formulae = parsedHtml.querySelectorAll('span.ql-formula') as NodeListOf<HTMLElement>;
  if (formulae.length > 0) {
    formulae.forEach(formula => {
      window.katex.render(formula.innerText, formula, {
        throwOnError: false,
      });
    });
  }
  return parsedHtml;
}
