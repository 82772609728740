import { schema } from 'normalizr';

export interface Dependant {
  userId: string;
  avatar: string;
  name: string;
}

export interface UserProfileInformation extends Dependant {
  birthDate: Date | undefined;
  emails: Ctek.UserContactEmail[];
  username: string;
}

export interface UserCheck {
  isKnownUser: boolean;
}

// normalizr schema
export const userSchema = new schema.Entity('users', {}, { idAttribute: '_id' });
