import { ThumbnailList } from '@ctek/design-system';
import { CourseImage } from 'century-core/media/CourseImage';
import ConditionalLink from 'century-core/core-components/ConditionalLink/ConditionalLink';

type ViewLocation = 'StudentDashboard' | 'Homepage';
type PathnameArea = 'my-dashboard/courses' | 'my-path';
interface FocusStretchNuggetsProps {
  FocusStretchNuggetsList: Ctek.Roentgen.FocusAndStretch[];
  mode: FocusStretchMode;
  pathArea: PathnameArea;
  enableNuggetLink: boolean;
  viewLocation: ViewLocation;
}
export enum FocusStretchMode {
  STRETCH = 'stretch',
  FOCUS = 'focus',
}

const FocusStretchNuggets = (props: FocusStretchNuggetsProps) => {
  const { FocusStretchNuggetsList, mode, pathArea, enableNuggetLink, viewLocation } = props;

  const qa = `kt-${mode === FocusStretchMode.FOCUS ? 'focus' : 'stretch'}-`;

  return (
    <ThumbnailList>
      {FocusStretchNuggetsList.map((datum: Ctek.Roentgen.FocusAndStretch, i: number) => (
        <ThumbnailList.Item key={datum.nuggetId} qa={`${qa}-item-${i}`}>
          <ThumbnailList.Thumbnail>
            <CourseImage
              item={{
                subject: { id: datum.subjectId, name: datum.subjectName },
                iconCode: datum.courseIconCode,
                name: datum.nuggetName,
              }}
              qa={`${qa}-course-icon`}
            />
          </ThumbnailList.Thumbnail>
          <ConditionalLink
            shouldBeLink={enableNuggetLink}
            to={{
              pathname: `/learn/${pathArea}/study-groups/${datum.studyGroupId}/nuggets/${datum.nuggetId}/`,
              state: { reason: datum.studySessionRecommendationReason.reason, viewLocation },
              search: `startedFrom=${mode}`,
            }}
            data-testid={`${qa}-link`}
            key={datum.nuggetId}
          >
            <ThumbnailList.Label qa={`${qa}-nugget-name`}>{datum.nuggetName}</ThumbnailList.Label>
          </ConditionalLink>
        </ThumbnailList.Item>
      ))}
    </ThumbnailList>
  );
};

export default FocusStretchNuggets;
