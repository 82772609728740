import * as SentryLib from 'century-core/core-utils/lib/sentry';

export function read(key: string): string | undefined {
  try {
    const sessionStorageItem = sessionStorage.getItem(key);
    if (sessionStorageItem === null) {
      return undefined;
    }
    return sessionStorageItem;
  } catch (e) {
    SentryLib.captureException(e);
    return undefined;
  }
}

export function write(key: string, value: any): void {
  try {
    const serializedState = typeof value !== 'string' ? JSON.stringify(value) : value;
    sessionStorage.setItem(key, serializedState);
  } catch (e) {
    SentryLib.captureException(e);
  }
}

const localStorageLib = { read, write };

export default localStorageLib;
