import { B2cPartnerLogo, PartnerLogoType, Tag, TagTypes } from '@ctek/design-system';
import { NuggetsActivityPropertiesMeta } from 'century-core/core-apis/learn/analytics/analytics';
import * as BehaviourLabels from 'century-core/core-utils/utils/behaviours';

const nuggetPartnerLogosByLabel = {
  [BehaviourLabels.BOND_CONTENT_LABEL_ID]: PartnerLogoType.BOND,
  [BehaviourLabels.BOND_CEM_CONTENT_LABEL_ID]: PartnerLogoType.BOND_CEM,
  [BehaviourLabels.ISEB_CONTENT_LABEL_ID]: PartnerLogoType.ISEB,
};

export const getNuggetPartnerLogo = (nuggetLabels: string[]): React.ReactNode | null => {
  if (nuggetLabels?.includes(BehaviourLabels.LEARN_CONTENT_LABEL_ID)) return <Tag tag="Learn" variant={TagTypes.MUTED} />;

  const labelKey = Object.keys(nuggetPartnerLogosByLabel).find(key => nuggetLabels?.includes(key));
  return labelKey ? <B2cPartnerLogo type={nuggetPartnerLogosByLabel[labelKey]} /> : null;
};

export type NuggetPartnerName = 'Learn' | 'Bond' | 'Bond CEM' | 'ISEB' | null;

// Order of these keys is VERY important, do not change it unless specified.
const nuggetPartnerNamesByLabel = {
  [BehaviourLabels.LEARN_CONTENT_LABEL_ID]: 'Learn',
  [BehaviourLabels.BOND_CONTENT_LABEL_ID]: 'Bond',
  [BehaviourLabels.BOND_CEM_CONTENT_LABEL_ID]: 'Bond CEM',
  [BehaviourLabels.ISEB_CONTENT_LABEL_ID]: 'ISEB',
};
const nuggetPartnerNamesByLabelOrder = Object.values(nuggetPartnerNamesByLabel);

export const getNuggetPartnerName = (nuggetLabels: string[]): NuggetPartnerName => {
  const labelKey = Object.keys(nuggetPartnerNamesByLabel).find(key => nuggetLabels?.includes(key));
  return labelKey ? nuggetPartnerNamesByLabel[labelKey] : null;
};

export const getUniqueSortedNuggetTypes = (nuggets: Ctek.Nugget[] | NuggetsActivityPropertiesMeta[]): NuggetPartnerName[] => {
  const nuggetTypes = nuggets
    .map(nugget => {
      const labels = 'labels' in nugget ? nugget.labels : (nugget as NuggetsActivityPropertiesMeta).nuggetLabels;
      return getNuggetPartnerName(labels as string[]);
    })
    .filter(nuggetType => !!nuggetType);

  const uniqueNuggetTypes = Array.from(new Set(nuggetTypes));

  return uniqueNuggetTypes.sort((a, b) => nuggetPartnerNamesByLabelOrder.indexOf(a!) - nuggetPartnerNamesByLabelOrder.indexOf(b!));
};
