import { gql } from '@apollo/client';
import { getNuggetPath } from 'century-core/core-apis/roentgen/queries/studentDashboard';

const recentlyAttemptedFragment = gql`
  fragment nuggetAttemptsFragment on NuggetPathItem {
    courses {
      courseId
      name
      studyGroupIds {
        studyGroupId
      }
      icon {
        iconCode
        url
      }
      subject {
        name
      }
    }
    nugget {
      nuggetId
      name
    }
    activity {
      lastAttempt {
        studySessionId
        isClosed
        isGraded
        endTime
        percentComplete
        percentScore
        isComplete
        durationActual
      }
    }
  }
`;

export const recentlyAttemptedWidgetQuery = getNuggetPath(recentlyAttemptedFragment, 'nuggetAttemptsFragment');
