import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import './MatchingAdditionalList.scss';

export interface Props {
  children: Children;
  qa?: string;
  listType: Ctek.MatchType;
}

export interface Children {
  additionals: React.ReactNode[];
}

export class MatchingAdditionalList extends React.PureComponent<Props, {}> {
  public render() {
    const { qa, listType } = this.props;
    const { additionals } = this.props.children;
    return (
      <div className={`matching-additional-list matching-additional-list--${listType}`} data-qa={qa} data-testid={qa}>
        <div className="matching-additional-list__heading">
          <FormattedMessage id="cms-matching-additional-answers-heading" defaultMessage="Additional Answers" />
        </div>
        <ul className="matching-additional-list__item-list">
          {additionals.map((item, index) => (
            <li className="matching-additional-list__item" key={index}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default MatchingAdditionalList;
