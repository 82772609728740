import { QueryResult, useQuery } from '@apollo/client';
import { USER_SUBJECTS } from 'century-core/core-apis/roentgen/queries/studentDashboard';
import { useCallback } from 'react';
import SubjectGroupsFilter, { GetDataFromQuery, SubjectGroupsFilterProps } from './components/SubjectGroupsFilter';

interface Props extends Omit<SubjectGroupsFilterProps, 'query' | 'getSubjectFilterDataFromQuery'> {
  userId: string;
}

const UserSubjectsGroupFilter = (props: Props) => {
  const { userId, ...restProps } = props; 
  const query = useQuery(USER_SUBJECTS, {
    variables: { input: { userId } },
    fetchPolicy: 'network-only',
  });

  const getSubjectFilterDataFromQuery: GetDataFromQuery = useCallback((query: QueryResult) => query.data?.userSubjects, [])

  return (
    <SubjectGroupsFilter
      query={query}
      getSubjectFilterDataFromQuery={getSubjectFilterDataFromQuery}
      {...restProps}
    />
  );
};

export default UserSubjectsGroupFilter;
