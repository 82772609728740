
import { Card } from '@stripe/stripe-js';
import { getCmsURL } from 'century-core/core-apis/cms/cms';
import { GET } from 'century-core/core-apis/utils';

export interface PaymentDetails {
  card: Card;
}

export async function getPaymentDetails(accessToken: string): Promise<PaymentDetails> {
  // Will fail if user has more than one card due to old logic
  // corresponding branch on CMS api has a tweak that fixes this to use default card
  // const url = new URL('http://localhost:3040/cmsapi');
  const url = getCmsURL('/consumer/subscription/payment');

  return await GET<PaymentDetails>({ url, token: accessToken });
}
