import { DropdownSimple, DropdownTrigger, FilterDropdownList, WidgetDropdownTrigger } from '@ctek/design-system';
import { DateTime } from 'luxon';
import React from 'react';
import { useCallback, useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDropdownToggle } from 'react-overlays';

export enum TimeSpanKey {
  AllTime = 'AllTime',
  OneDay = 'OneDay',
  SevenDays = 'SevenDays',
  TwentyEightDays = 'TwentyEightDays',
  CustomRange = 'CustomRange'
}

export const timeSpanControls: { [k: string]: { getPhrase: any; getValue?: () => string } } = {
  AllTime: {
    getValue: () => DateTime.local(2016).toUTC().startOf('day').toISO(),
    getPhrase: (intl: IntlShape) => intl.formatMessage({ id: 'timestamp-filter-option-all', defaultMessage: 'All time' }),
  },
  OneDay: {
    getValue: () => DateTime.local().toUTC().startOf('day').toISO(),
    getPhrase: (intl: IntlShape) => intl.formatMessage({ id: 'timestamp-filter-option-today', defaultMessage: 'Today' }),
  },
  SevenDays: {
    getValue: () => DateTime.local().toUTC().minus({ week: 1 }).startOf('day').toISO(),
    getPhrase: (intl: IntlShape) => intl.formatMessage({ id: 'previous-n-days', defaultMessage: 'Previous 7 days' }, { dayCount: '7' }),
  },
  TwentyEightDays: {
    getValue: () => DateTime.local().toUTC().minus({ week: 4 }).startOf('day').toISO(),
    getPhrase: (intl: IntlShape) =>
      intl.formatMessage(
        {
          id: 'previous-n-days',
          defaultMessage: 'Previous 28 days',
        },
        { dayCount: '28' }
      ),
  },
  CustomRange: {
    getPhrase: (intl: IntlShape) => intl.formatMessage({ id: 'timestamp-filter-option-custom-range', defaultMessage: 'Custom' }),
  },
};

export const useFormattedTime = (
  timeSpan?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  testEndTime?: string | null
) => {
  return useMemo(() => {
    const start = startTime || timeSpanControls[timeSpan || TimeSpanKey.AllTime]?.getValue?.()!;
    const end = endTime || testEndTime || DateTime.local().toUTC().endOf('day').toISO();

    const endISO = DateTime.fromISO(end).toUTC().hasSame(DateTime.local().toUTC(), 'day')
      ? DateTime.local().toUTC().toISO()
      : DateTime.fromJSDate(new Date(end)).toUTC().endOf('day').toISO();

    if (timeSpan === TimeSpanKey.CustomRange) {
      return {
        startTime: DateTime.fromJSDate(new Date(start)).toUTC().toISO(),
        endTime: endISO,
      };
    }
    return {
      startTime: timeSpanControls[timeSpan || TimeSpanKey.AllTime]?.getValue?.() || DateTime.local(2016).toUTC().startOf('day').toISO(),
      endTime: endISO,
    };
  }, [timeSpan, startTime, endTime, testEndTime]);
};

export const dateRangeFormatted = (intl: IntlShape, dateRange: { startDate?: string; endDate?: string }) =>
  intl.formatMessage(
    {
      id: 'date-range',
      defaultMessage: '{startDate, select, none {} other {{startDate} to }}{endDate, select, none {} other {{endDate}}}',
    },
    {
      startDate: dateRange.startDate ? DateTime.fromJSDate(new Date(dateRange.startDate)).toUTC().toFormat('dd LLL') : 'none',
      endDate: dateRange.endDate ? DateTime.fromJSDate(new Date(dateRange.endDate)).toUTC().toFormat('dd LLL') : 'none',
    }
  );

export const TimeSpanDropdownContent = (
  props: { onTimeSpanChange: (key?: string, name?: string) => any; timeSpan?: string }
) => {
    const { timeSpan, onTimeSpanChange } = props;
  const intl = useIntl();
  const [, { show, toggle }] = useDropdownToggle();

  const onClickOption = useCallback((newTimeSpan: TimeSpanKey) => {
    toggle(show);
    onTimeSpanChange(newTimeSpan, timeSpanControls[newTimeSpan].getPhrase(intl));
  }, [show, toggle, onTimeSpanChange, intl]);

  return (
    <DropdownSimple.Content offsetY={0}>
      <FilterDropdownList>
        {Object.entries(timeSpanControls).map(([k, v]) => (
          <React.Fragment key={k}>
            {k === TimeSpanKey.CustomRange && <FilterDropdownList.Divider />}
            <FilterDropdownList.Item isCurrent={timeSpan === k}>
              <button type="button" onClick={() => onClickOption(k as TimeSpanKey)}>
                {v.getPhrase(intl)}
              </button>
            </FilterDropdownList.Item>
            {k === TimeSpanKey.AllTime && <FilterDropdownList.Divider />}
          </React.Fragment>
        ))}
      </FilterDropdownList>
    </DropdownSimple.Content>
  );
}

export const TimeSpanDropdown = (props: {
  timeSpan?: string;
  startDate?: string;
  endDate?: string;
  onDateFilterChange: (key?: string, name?: string) => any;
  isWidget?: boolean;
}) => {
  const { timeSpan, startDate, endDate, onDateFilterChange, isWidget } = props;
  const intl = useIntl();

  const Trigger = useMemo(() => {
    if (isWidget) {
      return WidgetDropdownTrigger;
    }
    return DropdownTrigger;
  }, [isWidget])

  return (
    <DropdownSimple onToggle={() => null} alignEnd={true} qa="time-span-dropdown">
      <DropdownSimple.Toggle>
        <Trigger aria-label="Time" title="Time">
          {
            timeSpan === TimeSpanKey.CustomRange
              ? dateRangeFormatted(intl, { startDate, endDate })
              : timeSpanControls[timeSpan || TimeSpanKey.AllTime]?.getPhrase(intl)
          }
        </Trigger>
      </DropdownSimple.Toggle>
      <TimeSpanDropdownContent
        timeSpan={timeSpan}
        onTimeSpanChange={onDateFilterChange}
      />
    </DropdownSimple>
  );
}