import { useEffect, useState } from 'react';
import * as L from 'luxon';
import WelcomeWidgetWelcomeMessage from './WelcomeWidgetWelcomeMessage';
import WelcomeWidgetMessagesMode from './WelcomeWidgetMessagesMode';
import WelcomeWidgetAssignmentsMode from './WelcomeWidgetAssignmentsMode';
import { WelcomeWidgetMode, MessageMode } from './utils';
import { Widget } from '@ctek/design-system';

interface Props {
  payload?: Ctek.Roentgen.UserWelcomePayload;
  firstName: string;
  assignmentEndDates: string[];

}

const now = L.DateTime.local();
const yesterday = now.minus({ days: 1 });
const tomorrow = now.plus({ days: 1 });
const todayRange = L.Interval.fromDateTimes(now.startOf('day'), now.endOf('day'));
const yesterdayRange = L.Interval.fromDateTimes(yesterday.startOf('day'), yesterday.endOf('day'));
const tomorrowRange = L.Interval.fromDateTimes(tomorrow.startOf('day'), tomorrow.endOf('day'));
const surroundingDaysRange = L.Interval.fromDateTimes(yesterday.startOf('day'), tomorrow.endOf('day'));

const decideWidgetMode = (assignmentEndDates: string[]) => {
  if (assignmentEndDates.length === 0) {
    return WelcomeWidgetMode.MESSAGES;
  }
  const validDates = assignmentEndDates.filter((endDate: string) => (
    L.DateTime.fromISO(endDate) >= surroundingDaysRange.start && L.DateTime.fromISO(endDate) <= surroundingDaysRange.end
  ));
  return !!validDates.length ? WelcomeWidgetMode.ASSIGNMENTS : WelcomeWidgetMode.MESSAGES;
}

function WelcomeWidgetBody(props: Props) {
  const [widgetMode, setDashboardUserMode] = useState(WelcomeWidgetMode.ASSIGNMENTS);
  const [messageMode, setMessageMode] = useState(MessageMode.COGNITIVE);

  useEffect(() => {
    const mode = decideWidgetMode(props.assignmentEndDates);
    setDashboardUserMode(mode);
    if (mode === WelcomeWidgetMode.MESSAGES) {
      // TODO: switch this to >= 0.5 and implement user usage when it's ready.
      const mMode = Math.random() >= 0 ? MessageMode.COGNITIVE : MessageMode.USAGE;
      setMessageMode(mMode);
    }
  }, [props.assignmentEndDates]);

  // This depends on the userWelcome query and therefore cannot be used currently
  // // decides whether to show assignments version of the widget or the messages version of the widget
  // const decideDashboardUserMode = () => {
  //   if (
  //     !props.payload.assignments || !props.payload.assignments.length) {
  //     return WelcomeWidgetMode.MESSAGES
  //   }
  //   const validAssignments = props.payload.assignments!.filter((assignment: Ctek.Roentgen.UserWelcomeCourse) => {
  //     return !!assignment!.deadline && L.DateTime.fromISO(assignment.deadline) >= surroundingDaysRange.start && L.DateTime.fromISO(assignment.deadline) <= surroundingDaysRange.end;
  //   });

  //   return !!validAssignments.length ? WelcomeWidgetMode.ASSIGNMENTS : WelcomeWidgetMode.MESSAGES;
  // }

  return (
    <Widget.Body qa="widget--welcome-message--body">
      <WelcomeWidgetWelcomeMessage now={now} firstName={props.firstName} />
      {widgetMode === WelcomeWidgetMode.MESSAGES
        ? <WelcomeWidgetMessagesMode
          messageMode={messageMode}
          /* cognitiveMessages={props.payload.cognitiveMessages} */
          usage={props?.payload?.allTimeAnalytics}
        />
        : <WelcomeWidgetAssignmentsMode
          assignmentEndDates={props.assignmentEndDates}
          todayRange={todayRange}
          tomorrowRange={tomorrowRange}
          yesterdayRange={yesterdayRange}
        />
      }
    </Widget.Body>
  );
}

WelcomeWidgetBody.defaultProps = {
  payload: { assignments: [] },
};

export default WelcomeWidgetBody;
