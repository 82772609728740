import { createContext, useState, useEffect, useCallback } from 'react';
import * as React from 'react';
import { getNugget } from 'century-core/core-apis/contentV2/nuggets/nuggets';
import { useAccessToken } from 'century-core/core-auth/hooks/useAuth';

export const QLANuggetInformationContext = createContext<{ nugget: Ctek.Nugget | null; nuggetError: null | string } | null>(null);
export default QLANuggetInformationContext;

interface AllNuggets {
  [id: string]: Ctek.Nugget;
}

export const QLANuggetInformationProvider = (props: { nuggetId: string; children: React.ReactNode }) => {
  const accessToken = useAccessToken();
  const [allNuggets, updateAllNuggets] = useState({} as {} | AllNuggets);
  const [nugget, setNugget] = useState(null as null | Ctek.Nugget);
  const [nuggetError, setNuggetError] = useState(null as null | string);

  const updateNugget = useCallback(async () => {
    if (allNuggets[props.nuggetId]) {
      setNugget(allNuggets[props.nuggetId]);
    } else {
      try {
        const newNugget = await getNugget(props.nuggetId, accessToken);
        updateAllNuggets({ ...allNuggets, [newNugget._id as string]: newNugget });
        setNugget(newNugget);
      } catch (e) {
        setNuggetError('e');
      }
    }
  }, [props.nuggetId, accessToken, allNuggets]);

  useEffect(() => {
    if (nugget?._id !== props.nuggetId) {
      updateNugget();
    }
  }, [nugget, props.nuggetId, updateNugget]);

  return <QLANuggetInformationContext.Provider value={{ nugget, nuggetError }}>{props.children}</QLANuggetInformationContext.Provider>;
};
