import * as contentV2Courses from 'century-core/core-apis/contentV2/courses/courses';

export async function getCourses(courseIds: string[], accessToken: string) {
  const courses = await fetchCourses(courseIds, [], accessToken);
  // this is needed because the course picked must be the first one in a-z order
  return courses.sort((a: Ctek.Course, b: Ctek.Course) => {
    if (a && a.name === null) {
      return 1;
    } else if (b && b.name === null) {
      return -1;
    } else {
      const nameA = a.name as string;
      const nameB = b.name as string;
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    }
  });
}

/**
 * Fetches courses in chunks of 20 to ensure the path isn't too long
 */
export async function fetchCourses(courseIds: string[], courses: Ctek.Course[], accessToken: string): Promise<Ctek.Course[]> {
  const remainingCourseIds = courseIds.slice(20);
  const requestedCourseIds = courseIds.slice(0, 20); // slice does not include the endIndex
  return contentV2Courses
    .getCourses(requestedCourseIds, accessToken)
    .then((requestedCourses: Ctek.Course[]) => {
      requestedCourses.map(crs => courses.push(crs));
      if (remainingCourseIds.length === 0) {
        return courses;
      } else {
        return fetchCourses(remainingCourseIds, courses, accessToken);
      }
    })
    .catch((e: Error) => {
      throw new Error(`Unable to retrieve courses for course ids ${JSON.stringify(requestedCourseIds)},
       ${e}`);
    });
}
