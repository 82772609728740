import { createContext, ReactNode, useState, useCallback, useContext, useEffect, useMemo } from 'react';
import * as SentryLib from 'century-core/core-utils/lib/sentry';

import { getUserName } from 'century-core/core-utils/selectors/users';
import { isRTLLocaleFromTokenLocale } from 'century-core/core-utils/utils/intl/intl';
import { useAuth } from 'century-core/core-auth/hooks/useAuth';
import { getUser } from 'century-core/core-apis/accountsV2/users/users';
import { getTokenSub } from 'century-core/core-auth/utils';

export interface UserProfile {
  avatar: string;
  birthDate: Date | null;
  emails: Ctek.UserContactEmail[];
  firstName: string;
  lastName: string;
  name: string;
  userId: string;
  username: string;
}

export interface UserProfileContextProps {
  user: Ctek.User | null;
  loadUserProfileData: () => void;
}

const UserProfileContext = createContext<UserProfileContextProps>({ user: null, loadUserProfileData: () => null });

export const useUserProfileContext = () => useContext(UserProfileContext);

export const useUser = () => useContext(UserProfileContext).user;

export const useUserName = (isCommaSeparated?: boolean) => {
  const user = useUser();
  const auth = useAuth();
  const isRtl = isRTLLocaleFromTokenLocale(auth);
  if (user) {
    return getUserName(user, isRtl);
  }
  return '';
};

export const useUserId = () => {
  const { user } = useUserProfileContext();

  return user?._id as string;
};

export const useUserRoles = () => {
  const { user } = useUserProfileContext();

  return useMemo(() => {
    const roleSet = new Set(user?.profile?.groups?.roles);
    user?.profile?.groups?.organisations?.forEach(o => o.roles?.forEach(r => roleSet.add(r)));
    return Array.from(roleSet);
  }, [user]);
};

export const useUserProfile = () => {
  const { user } = useUserProfileContext();

  return {
    avatar: user?.profile?.avatar || '',
    birthDate: user?.personal?.birthDate,
    emails: user?.contact?.emails || [],
    firstName: user?.personal?.name?.first || '',
    lastName: user?.personal?.name?.last,
    name: `${user?.personal?.name?.first} ${user?.personal?.name?.last}`,
    userId: user?._id as string,
    username: user?.profile?.ids?.username || '',
  } as UserProfile;
};

export const UserProfileProvider = (props: { children: ReactNode }) => {
  const auth = useAuth();
  const [user, setUser] = useState<Ctek.User | null>(null);

  const loadUserProfileData = useCallback(async () => {
    try {
      getTokenSub(auth);
      const user = await getUser(auth?.accessTokenData?.sub || '', auth?.accessToken || '');
      setUser(user);
    } catch (e) {
      SentryLib.captureExceptionWithScope(e, 'error', {});
    }
  }, [auth]);

  // Load user data
  useEffect(() => {
    if (auth.accessToken) {
      loadUserProfileData();
    }
  }, [auth, loadUserProfileData]);

  return <UserProfileContext.Provider value={{ user, loadUserProfileData }}>{props.children}</UserProfileContext.Provider>;
};
