import queryString from 'query-string';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import { Auth } from 'century-core/entities/Auth/Auth';
import { parseAuthFromLocalstorage } from 'century-core/core-auth/utils/utils';
import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { Errors } from 'century-core/entities/ErrorMessage/Errors';
import localStorage from 'century-core/core-utils/utils/localStorage/localStorage';
import { MixpanelEventTypes } from 'century-core/core-utils/utils/mixpanel/MixpanelEventTypes';
import { isValidHttpUrl } from 'century-core/core-utils/utils/utils';
import { MixpanelKeys } from 'century-core/core-utils/utils/mixpanel/MixpanelKeys';
import { StoreState, ThunkExtraArg } from '../../reducers/reducers';
import { Actions as EntitiesActions } from '../entities/entities';
import { loginWithPassword, renewToken } from 'century-core/core-apis/sentinel/auth/auth';
import * as SentryLib from 'century-core/core-utils/lib/sentry';

import { mapAuthForLocalStorage, mapAuthForRedux, addUserAndOrgIdToSentry, scheduleRenewToken } from 'century-core/core-auth/utils';
import { hasGuardianRole, hasLearnerRole } from 'century-core/core-auth/utils';
import { getDefaultMixpanelProps } from 'century-core/core-utils/utils/mixpanel/mixpanel';

export enum ActionTypes {
  LoggingIn = 'Auth.LoggingIn',
  LoggedIn = 'Auth.LoggedIn',
  Error = 'Auth.Error',
  ClearError = 'Auth.ClearError',
  ShowLoginForm = 'Auth.ShowLoginForm',
  LoggedOut = 'Auth.loggedOut',
  UpdateAccessToken = 'Auth.UpdateAccessToken',
  AddOrgSettings = 'Auth.AddOrgSettings',
}

export type Actions =
  | ReturnType<typeof loggingIn>
  | ReturnType<typeof loggedIn>
  | ReturnType<typeof loggedOut>
  | ReturnType<typeof errors>
  | ReturnType<typeof clearError>
  | ReturnType<typeof showLoginForm>
  | ReturnType<typeof addOrgSettings>
  | ReturnType<typeof updateAccessToken>;

export interface GoogleAccessToken {
  tokenObj: {
    accessToken: string;
    id_token: string;
  };
}

export let renewTokenTimer: any = 0;

export const loggingIn = () => ({
  type: ActionTypes.LoggingIn as typeof ActionTypes.LoggingIn,
});

export const loggedIn = (auth: Auth) => {
  // FIXME move into some middleware, maybe?
  addUserAndOrgIdToSentry(auth);

  return {
    auth,
    type: ActionTypes.LoggedIn as typeof ActionTypes.LoggedIn,
  };
};

export const loggedOut = () => ({
  type: ActionTypes.LoggedOut as typeof ActionTypes.LoggedOut,
});

export const errors = (err: ErrorMessage<Errors>) => ({
  error: err,
  type: ActionTypes.Error as typeof ActionTypes.Error,
});

export const clearError = () => ({
  type: ActionTypes.ClearError as typeof ActionTypes.ClearError,
});

export const showLoginForm = () => ({
  type: ActionTypes.ShowLoginForm as typeof ActionTypes.ShowLoginForm,
});

const writeTokenToLocalStorage = (auth: Auth) => {
  localStorage.write('auth', mapAuthForLocalStorage(auth));
};

export const updateAccessToken = (auth: Auth) => {
  /**
   * TECH-15664 - TEMPORARY USE OF POLYMER LOGIN:
   * - writeLocalstorageAuth added for backwards compatibility with polymer app
   * Read AppShellPolymer README.
   */
  writeTokenToLocalStorage(auth);

  return {
    auth,
    type: ActionTypes.UpdateAccessToken as typeof ActionTypes.UpdateAccessToken,
  };
};

export const addOrgSettings = (settings: Ctek.Accounts.DomainResponse) => {
  return {
    settings,
    type: ActionTypes.AddOrgSettings as typeof ActionTypes.AddOrgSettings,
  };
};

function accountEntryProcess(props: {
  apiCall: () => Promise<Auth | void>;
  track: (...props: any) => void;
  createSession: (auth: Auth, mixpanelTrack: () => void) => void;
  setAuthError: (error: ErrorMessage<Errors> | undefined) => void;
  accountSwitchSource?: 'Dashboard' | 'Sidebar';
  customRedirect?: (auth: Auth) => void;
}) {
  const { apiCall, track, createSession, setAuthError, accountSwitchSource, customRedirect } = props;
  // TODO need to add viewState.LoggingIn
  // TODO need to add viewState.Error
  return (
    dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions | EntitiesActions>,
    getState: () => StoreState,
    { api, normalize }: ThunkExtraArg
  ): Promise<void> => {
    if (renewTokenTimer) {
      clearTimeout(renewTokenTimer);
    }
    setAuthError(undefined);
    dispatch(loggingIn());
    const previousAuth = getState().auth;
    let hadTimeout = false;
    if (renewTokenTimer) {
      clearTimeout(renewTokenTimer);
      hadTimeout = true;
    }
    return apiCall()
      .then(async (auth: Auth) => {
        if (renewTokenTimer) {
          clearTimeout(renewTokenTimer);
        }
        await createSession(auth, track);
        return auth;
      })
      .then((auth: Auth) => {
        const parsedAuth = mapAuthForRedux(auth);
        if (customRedirect) {
          customRedirect(auth);
        }
        if (accountSwitchSource) {
          track(MixpanelEventTypes.AccountSwitchCompleted, {
            ...getDefaultMixpanelProps(auth),
            [MixpanelKeys.Role]: hasLearnerRole(previousAuth) ? 'Learner' : hasGuardianRole(previousAuth) ? 'Guardian' : undefined,
            [MixpanelKeys.AccountSwitchedFrom]: previousAuth.accessTokenData?.sub,
            [MixpanelKeys.AccountSwitchedTo]: parsedAuth.accessTokenData?.sub,
            [MixpanelKeys.AccountSwitchLocation]: window.location.pathname,
            [MixpanelKeys.AccountSwitchedSource]: accountSwitchSource,
          });
        }
        gotoRedirectUrlFromQueryString();
      })
      .catch((err: ErrorMessage<Errors>) => {
        dispatch(errors(err));
        setAuthError(err);

        const localStorageAuth: Auth = parseAuthFromLocalstorage() || {};
        if (hadTimeout && previousAuth.accessToken === localStorageAuth.accessToken) {
          scheduleRenewToken(
            previousAuth,
            () => dispatch(loginWithRefreshToken(previousAuth.refreshToken || '', track, createSession, setAuthError)),
            renewTokenTimer
          );
        }
      });
  };
}

// TODO Both 'switchUser' and 'login' are pretty similar, there's space for refactoring here when moving to century-core
export function switchUser(
  getNewAuth: () => Promise<Auth | void>,
  track: () => void,
  createSession: (auth: Auth, mixpanelTrack: () => void) => void,
  setAuthError: (error: ErrorMessage<Errors>) => void,
  accountSwitchSource: 'Sidebar' | 'Dashboard',
  customRedirect: (auth: Auth) => void
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return accountEntryProcess({
    apiCall: getNewAuth,
    track,
    createSession,
    setAuthError,
    accountSwitchSource,
    customRedirect,
  });
}

// TL-783 we don't actually need to pass `createSession` from the form, but we'll refactor later when this function is moved.
export function login(
  username: string,
  password: string,
  track: (...props: any) => void,
  createSession: (auth: Auth, mixpanelTrack: () => void) => void,
  setAuthError: (error: ErrorMessage<Errors>) => void,
  accountSwitchSource?: 'Sidebar' | 'Dashboard',
  customRedirect?: (auth: Auth) => void
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return accountEntryProcess({
    apiCall: () => loginWithPassword(username, password),
    track,
    createSession,
    setAuthError,
    accountSwitchSource,
    customRedirect,
  });
}

// TL-783 we don't actually need to pass `createSession` from the form, but we'll refactor later when this function is moved.
export function loginWithRefreshToken(
  refreshToken: string,
  track: (...props: any) => void,
  createSession: (auth: Auth, mixpanelTrack: () => void) => void,
  setAuthError: (error: ErrorMessage<Errors>) => void
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return accountEntryProcess({
    apiCall: () => renewToken(refreshToken),
    track,
    createSession,
    setAuthError,
  });
}

// TL-783 we don't actually need to pass `createSession` from the form, but we'll refactor later when this function is moved.
export function googleSignIn(
  googleIdToken: string,
  track: (...props: any) => void,
  createSession: (auth: Auth, mixpanelTrack: () => void) => void
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return (
    dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions | EntitiesActions>,
    getState: () => StoreState,
    { api, normalize }: ThunkExtraArg
  ): Promise<void> => {
    return api.auth
      .googleSignIn(googleIdToken)
      .then((auth: Auth) => createSession(auth, track))
      .then(() => gotoRedirectUrlFromQueryString())
      .catch((err: ErrorMessage<Errors>) => {
        SentryLib.captureExceptionWithScope(new Error(err?.message), 'error', { tags: { googleIdToken } });
        dispatch(errors(err));
      });
  };
}

// TL-783 we don't actually need to pass `createSession` from the form, but we'll refactor later when this function is moved.
export function msTeamsSignIn(
  office365Token: string,
  track: (...props: any) => void,
  createSession: (auth: Auth, mixpanelTrack: () => void) => void
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return (
    dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions | EntitiesActions>,
    getState: () => StoreState,
    { api, normalize }: ThunkExtraArg
  ): Promise<void> => {
    return api.auth
      .office365SignIn(office365Token)
      .then((auth: Auth) => createSession(auth, track))
      .then(() => gotoRedirectUrlFromQueryString())
      .catch((err: ErrorMessage<Errors>) => {
        SentryLib.captureExceptionWithScope(new Error(err?.message), 'error', { tags: { office365Token } });
        dispatch(errors(err));
      });
  };
}

// TL-783 we don't actually need to pass `createSession` from the form, but we'll refactor later when this function is moved.
export function office365SignIn(
  office365Token: string,
  track: (...props: any) => void,
  createSession: (auth: Auth, mixpanelTrack: () => void) => void
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return (
    dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions | EntitiesActions>,
    getState: () => StoreState,
    { api, normalize }: ThunkExtraArg
  ): Promise<void> => {
    return api.auth
      .office365SignIn(office365Token)
      .then((auth: Auth) => createSession(auth, track))
      .then(() => gotoRedirectUrlFromQueryString())
      .catch((err: ErrorMessage<Errors>) => {
        SentryLib.captureExceptionWithScope(new Error(err?.message), 'error', { tags: { office365Token } });
        dispatch(errors(err));
      });
  };
}

// TL-783 we don't actually need to pass `createSession` from the form, but we'll refactor later when this function is moved.
export function auth0SignIn(
  auth0Token: string,
  track: (...props: any) => void,
  createSession: (auth: Auth, mixpanelTrack: () => void) => void
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return (
    dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions | EntitiesActions>,
    getState: () => StoreState,
    { api, normalize }: ThunkExtraArg
  ): Promise<void> => {
    return api.auth
      .auth0SignIn(auth0Token)
      .then((auth: Auth) => createSession(auth, track))
      .then(() => gotoRedirectUrlFromQueryString())
      .catch((err: ErrorMessage<Errors>) => {
        dispatch(errors(err));
      });
  };
}

// TL-783 we don't actually need to pass `createSession` from the form, but we'll refactor later when this function is moved.
export function openIdSignIn(
  openIdToken: string,
  track: (...props: any) => void,
  createSession: (auth: Auth, mixpanelTrack: () => void) => void
): ThunkAction<Promise<void>, StoreState, ThunkExtraArg, Actions | EntitiesActions> {
  return (
    dispatch: ThunkDispatch<StoreState, ThunkExtraArg, Actions | EntitiesActions>,
    getState: () => StoreState,
    { api, normalize }: ThunkExtraArg
  ): Promise<void> => {
    return api.auth
      .openIdSignIn(openIdToken)
      .then((auth: Auth) => createSession(auth, track))
      .then(() => gotoRedirectUrlFromQueryString())
      .catch((err: ErrorMessage<Errors>) => {
        dispatch(errors(err));
      });
  };
}

/**
 * TODO https://centurytech.atlassian.net/browse/TL-76 && https://centurytech.atlassian.net/browse/TL-689
 * Note for future selves:
 * Working on TL-689, I'm pretty sure this function can be removed and just let 'getRedirectRouteByRole'
 * in src/century-core/core-auth/utils/roles.ts do its job instead, logging in doesn't actually redirects you,
 * it just routes you into `Page.tsx` from `App.tsx`, instead of going to `Login.tsx`. The redirect in `Page.tsx`
 * sends you to `getRedirectRouteByRole` and it does its magic.
 * But since the pentesting is releatively urgent and I'm worried about losing some functionality with SSO logins
 * I'll leave it for now and recheck this as part of the work for TL-76.
 */
const gotoRedirectUrlFromQueryString = () => {
  const redirect = queryString.parse(window.location.search).redirect as string;
  if (redirect && !redirect.includes('javascript') && (!isValidHttpUrl(redirect) || new URL(redirect).origin === window.location.origin)) {
    window.location.href = redirect;
  }
};
