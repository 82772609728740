import { getFile } from 'century-core/core-apis/mediaLibrary/mediaLibrary';
import { getMediaPublicUrl } from 'century-core/core-apis/mediaLibrary/mediaLibrary';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { CdsCourseImage } from '@ctek/design-system';
import { CourseCode } from '@ctek/design-system/cds/CourseImage/CourseImageTypes';
import { useAccessToken } from 'century-core/core-auth/hooks/useAuth';
export interface CourseImageAttributes {
  icon?: Ctek.Course['icon'];
  iconCode?: string;
  subject?: Ctek.Label | string;
  iconUrl?: string;
}

export interface CourseIcon {
  subject: string;
  src: string;
  'image-code': CourseCode;
}

export interface Props<T extends CourseImageAttributes> {
  alt?: string;
  item: T;
  qa?: string;
}

export const DEFAULT_ICON_CODE = 'Other_008';

export const getAndSetIconUrl = (icon: string | Ctek.Media | Ctek.Icon, accessToken: string, setImageUrl: (url: string) => void) => {
  if (typeof icon === 'string') {
    getMediaPublicUrl(icon as string, accessToken).then(file => setImageUrl(file as string));
    return;
  }
  getFile((icon as Ctek.Media)._id!, (icon as Ctek.Media).files[0].id!, accessToken).then(file => setImageUrl(file.url));
};

export function CourseImage<T extends CourseImageAttributes>(props: Props<T>) {
  const { item, qa } = props;
  const [imageUrl, setImageUrl] = useState(item.iconUrl);
  const accessToken = useAccessToken();
  const { formatMessage } = useIntl();
  // This is used for BPP only currently as they have their own custom icons.
  useEffect(() => {
    const { icon, iconUrl } = item;
    // for when we get url directly, which should happen in most cases
    if (iconUrl) {
      return;
    }
    if (icon) {
      getAndSetIconUrl(icon, accessToken, setImageUrl);
      return;
    }
    setImageUrl(undefined);
  }, [accessToken, item, item.icon]);

  const alt = props.alt || formatMessage({ id: 'course-image', defaultMessage: 'Thumbnail' });

  return (
    <CdsCourseImage
      qa={qa}
      src={item.iconUrl || imageUrl}
      alt={alt}
      iconCode={item.iconCode}
      subject={(item.subject as Ctek.Label)?.name || (item.subject as string)}
    />
  );
}

export default CourseImage;
