import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { MediaWrapper } from '@ctek/design-system'
import './ExactAnswer.scss'

export type ExactAnswerType = 'default' | 'equation';

export interface Props {
  children: Children;
  qa?: string;
  controls?: React.ReactNode | React.ReactNode[];
  reveals?: string | React.ReactNode;
}

export interface Children {
  result: 'correct' | 'incorrect' | 'skipped' | 'unanswered' | 'answered-no-result' | 'loading';
  exactAnswer?: string | JSX.Element;
  question: React.ReactNode | React.ReactNode[];
  response?: React.ReactNode | React.ReactNode[] | null;
  type: ExactAnswerType;
}

export class ExactAnswer extends React.Component<Props, {}> {
  public render() {
    const { controls, reveals } = this.props;
    const { result, exactAnswer, question, response, type } = this.props.children;

    const classNames = ['exact-answer exact-answer--exact'];

    if (!controls) classNames.push('exact-answer--qla');
    classNames.push(`exact-answer--${type}`);

    return (
      <div className={classNames.join(' ')} data-qa={this.props.qa} data-testid={this.props.qa}>
        <div className="exact-answer__question" data-testid="exact-answer-question">
          <MediaWrapper>
            {question}
          </MediaWrapper>
        </div>
        {exactAnswer && (
          <div className={`exact-answer__exact-answer exact-answer__exact-answer--${result}`} data-testid="exact-answer-container">
            {exactAnswer}
          </div>
        )}
        {result !== 'unanswered' && response && (
          <div className="exact-answer__response">
            <div className="multi-question__explanation" data-testid="question-response">
              {response}
            </div>
          </div>
        )}
        {reveals && (
          <div className="exact-answer__reveals">
            <div className="exact-answer__reveals-header">
              <FormattedMessage id="correct-answer-reveal" defaultMessage="Correct answer is:" />
            </div>
            <div className="exact-answer__correct-answer" data-testid="reveal-answer">
              {reveals}
            </div>
          </div>
        )}
        {controls && <div className="exact-answer__controls">{controls}</div>}
      </div>
    );
  }
}

export default ExactAnswer;
