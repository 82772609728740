import { Grid, StatWidget, StatWidgetValueType } from '@ctek/design-system';
import { FormattedMessage } from 'react-intl';

interface Props {
  nuggetsCompleted: number;
  score: number;
}

export default function LearnerOverviewHeaderStats(props: Props) {
  return (
    <Grid cols={4}>
      <Grid.Item span={2}>
        <StatWidget qa="stat-widget-nuggets">
          <StatWidget.Label>
            <FormattedMessage id="nuggets-completed" defaultMessage="Nuggets Completed" />
          </StatWidget.Label>
          <StatWidget.Value type={StatWidgetValueType.COMPLETION}>{props.nuggetsCompleted}</StatWidget.Value>
        </StatWidget>
      </Grid.Item>
      <Grid.Item span={2}>
        <StatWidget qa="stat-widget-score">
          <StatWidget.Label>
            <FormattedMessage id="average-last-score" defaultMessage="Average Last Score" />
            {' '}
          </StatWidget.Label>
          <StatWidget.Value type={StatWidgetValueType.SCORE} scoreValue={props.score}>
            {props.score}
            <StatWidget.Unit>%</StatWidget.Unit>
          </StatWidget.Value>
        </StatWidget>
      </Grid.Item>
    </Grid>
  )
}
