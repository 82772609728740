import * as React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import {
  Redirect,
  Link,
  matchPath,
  Route,
  Switch,
  RouteComponentProps,
  withRouter
} from 'react-router-dom';

export interface WizardStep<T = {}> {
  label: React.ReactElement;
  link: string;
  exact?: boolean;
  // TODO: type inference isn't very powerful here
  props?: T;
  component: React.ComponentType<WizardStepComponentProps<T>>;
}

export type WizardStepComponentProps<T, P = {}> = RouteComponentProps<P> & T & {
  next: () => void;
  prev: () => void;
}

interface Props extends RouteComponentProps {
  steps: WizardStep[];
  onComplete: () => any;
  hideProgressBar?: boolean;
}

const WizardComponent: React.SFC<Props> = props => {
  const { steps, history, location, onComplete, hideProgressBar } = props;

  let stepClass = styles.completed;

  const currIndex = steps.findIndex(s =>
    matchPath(s.link, {
      path: location.pathname,
    })
  );
  const nextUrl = steps[currIndex + 1]?.link;
  const prevUrl = steps[currIndex - 1]?.link;
  const injectedProps = {
    next: React.useCallback(() => nextUrl ? history.push(nextUrl) : onComplete(), [history, nextUrl, onComplete]),
    prev: React.useCallback(() => prevUrl && history.push(prevUrl), [
      history,
      prevUrl,
    ]),
  };

  if (steps.length < 1) {
    return null;
  }
  const links = steps.map((s, i) => {
    let className = stepClass;

    // we do our own matching instead of using NavLink so we can style past steps too
    if (i === currIndex) {
      className = styles.current;
      stepClass = styles.future;
    }

    return (
      <Link
        to={s.link}
        key={s.link}
        className={classnames(styles.step, className)}>
        <div>{s.label}</div>
      </Link>
    );
  });

  const routes = steps.map(s => (
    <Route
      path={s.link}
      key={s.link}
      exact={s.exact}
      render={props => <s.component {...props} {...injectedProps} {...s.props} />}
    />
  ));

  return (
    <>
      {!hideProgressBar && links.length > 2 ? <div className={classnames(styles.progress)}>{links}</div> : null}
      <Switch>
        {routes}
        <Redirect to={steps[0].link} />
      </Switch>
    </>
  );
};

export const Wizard = withRouter(WizardComponent);
