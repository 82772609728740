import './MultipleChoice.scss';
import { MediaWrapper } from '@ctek/design-system';
import { useHoldEnterManager } from 'century-core/question-types/utils/useHoldEnterManager';
import { useState } from 'react';

export interface Props {
  children: Children;
  qa?: string;
  controls?: React.ReactNode | React.ReactNode[];
  onClick?: (id: string) => void;
  selectedId?: string | null;
  correctAnswerId?: string;
  submit?: () => void;
}

export interface Children {
  result: 'correct' | 'incorrect' | 'skipped' | 'unanswered';
  options: OptionListData[];
  question: React.ReactNode | React.ReactNode[] | null;
  response?: React.ReactNode | React.ReactNode[] | null;
}

export interface OptionListData {
  answerContent: React.ReactNode | React.ReactNode[];
  id: string;
}

export const MultipleChoiceQuestion = (props: Props) => {
  const { onClick, selectedId, controls, correctAnswerId, submit } = props;
  const { options, result, question, response } = props.children;
  const [activeEl, setActiveEl] = useState<HTMLInputElement | null>(null);
  useHoldEnterManager(activeEl, submit);

  const handleClick = (e: any, id: string) => {
    setActiveEl(e.target);
    if (onClick && result === 'unanswered') {
      onClick(id);
    }
  };

  const getAriaLabel = (id: string) => {
    if (selectedId !== id && correctAnswerId !== id) {
      return 'blank';
    }

    if (correctAnswerId === id) {
      return 'correct';
    }

    if (result === 'unanswered') {
      return 'selected';
    }

    return result;
  };

  return (
    <div className={`multi-question multi-question--multi ${!controls ? 'multi-question--qla' : ''}`} data-qa={props.qa}>
      <div data-testid="multiple-choice-question" className="multi-question__question">
        <MediaWrapper>{question}</MediaWrapper>
      </div>
      <div className="multi-question__options">
        <ul className="multi-question__optionslist">
          {options.map((item, index) => (
            <li key={item.id} data-testid={correctAnswerId && getAriaLabel(item.id) === 'correct' ? 'reveal-answer' : undefined}>
              <label
                className={`multi-question__answer multi-question__answer--${getAriaLabel(item.id)}
                  ${result === 'unanswered' && 'multi-question__answer--unanswered'}`}
                {...(item.id === selectedId && { 'aria-label': getAriaLabel(item.id) })}
              >
                <input
                  name="multi-question"
                  data-testid={`multiple-choice-answer-${index}`}
                  className="multi-question__input"
                  onClick={e => handleClick(e, item.id)}
                  type="radio"
                  disabled={result !== 'unanswered' ? true : false}
                />
                <MediaWrapper>{item.answerContent}</MediaWrapper>
              </label>
            </li>
          ))}
        </ul>
      </div>
      {result !== 'unanswered' && response && (
        <div className="multi-question__response">
          <div className="multi-question__explanation" data-testid="question-response">
            {response}
          </div>
        </div>
      )}
      {controls && <div className="multi-question__controls">{controls}</div>}
    </div>
  );
};

export default MultipleChoiceQuestion;
