import * as SentryLib from 'century-core/core-utils/lib/sentry';

export function read(key: string): string | undefined {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return serializedState;
  } catch (e) {
    SentryLib.captureException(e);
    return undefined;
  }
}

export function remove(key: string): void {
  localStorage.removeItem(key);
}

export function write(key: string, value: any): void {
  try {
    const serializedState = typeof value !== 'string' ? JSON.stringify(value) : value;
    localStorage.setItem(key, serializedState);
  } catch (e) {
    SentryLib.captureException(e);
  }
}

const localStorageLib = { read, write, remove };

export default localStorageLib;
