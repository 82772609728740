import { getAccountsUrl } from '../accountsV2';
import { DELETE, GET } from 'century-core/core-apis/utils';

export function getStudyGroupById(studyGroupId: string, accessToken: string) {
  const url = getAccountsUrl(`/study-groups/${studyGroupId}`);

  return GET<Ctek.StudyGroup>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get StudyGroup ${studyGroupId}, ${err}`);
  });
}

export function getStudyGroupsForClass(classId: string, accessToken: string) {
  const url = getAccountsUrl('/study-groups/');
  url.search += `&filters.classes=${classId}`;
  url.search += '&include=course,status,coursePlan,history';

  return GET<Ctek.StudyGroup[]>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get StudyGroup by id classId ${classId}, ${err}`);
  });
}

export function removeStudyGroupFromClass(studyGroupId: string, accessToken: string) {
  const url = getAccountsUrl(`/study-groups/${studyGroupId}`);

  return DELETE({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to delete study group of id ${studyGroupId}`);
  });
}
