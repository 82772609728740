import { Auth } from 'century-core/entities/Auth/Auth';
import { loginWithPassword } from '../../century-core/core-apis/sentinel/auth/auth';
import { patchMe } from '../../century-core/core-apis/accountsV2/users/users';
import { createLocalisedErrorMessage } from 'century-core/entities/ErrorMessage/createLocalisedErrorMessage';
import { createFetchRequestWithLocalisedError } from 'century-core/core-apis/utils';

export async function changeUsername(oldUsername: string, newUsername: string, password: string) {
  const auth = await createFetchRequestWithLocalisedError(
    () => loginWithPassword(oldUsername, password),
    'localised-errors-invalid-credentials'
  );

  return createFetchRequestWithLocalisedError(
    () => {
      const accessToken = (auth as Auth).accessToken;
      return patchMe(accessToken!, {
        profile: {
          ids: {
            username: newUsername,
          },
        },
      });
    },
    undefined,
    true
  ).catch(e => {
    if (e.status === 409) {
      throw createLocalisedErrorMessage('localised-errors-change-username-duplicate-error');
    } else {
      throw createLocalisedErrorMessage('localised-errors-user-profile-generic-error');
    }
  });
}
