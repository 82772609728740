import { getCmsURL } from '../cms';
import { POST } from 'century-core/core-apis/utils';

export interface RegisterB2CAdminPayload {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  consentGiven: boolean;
  gdprAccepted: boolean;
  safeguardingAccepted: boolean;
  recaptcha: string;
}

export async function registerB2CAdmin(user: RegisterB2CAdminPayload) {
  const url = getCmsURL('/consumer');
  return await POST<{ id: string; _id: string }>({ url, body: user });
}
