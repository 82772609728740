import { parseAPIError } from 'century-core/core-apis/fetch/utils';
import { Errors, localisedErrors } from 'century-core/entities/ErrorMessage/Errors';
import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { getB2CConfig as getConfig } from '../cms/b2cDashboard';

export async function getB2CConfig() {
  try {
    const config = await getConfig();
    return { config };
  } catch (err) {
    throw parseAPIError(err, new ErrorMessage<Errors>('localised-errors-b2c-error', localisedErrors['localised-errors-b2c-error']));
  }
}
