export interface Headers {
  [key: string]: string;
}

/**
 * Merges headers with defaults while forcing header keys to lowercase.
 * HTTP headers should be case insensitive
 *
 * headers['somekey'] will overwrite defaults['somekey']
 */
export function mergeHeaders(defaults: Headers, headers: Headers = {}) {
  const obj = {};

  Object.keys(defaults).forEach(k => (obj[k.toLowerCase()] = defaults[k]));
  Object.keys(headers).forEach(k => (obj[k.toLowerCase()] = headers[k]));

  return obj;
}

export enum ErrorCode {
  FetchNotSupported = 0,
  BodyRequired = 1,

  // https://en.wikipedia.org/wiki/List_of_HTTP_status_codes
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  RequestTimeout = 408,
  Conflict = 409,

  InternalServerError = 500,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
  // TODO aditional codes
}

/**
 * Custom error for fetch lib.
 *
 * Usage
 * ```
 * new FetchError(msg).setCode(404)
 * ```
 * or
 * ```
 * new FetchError(msg).setCode(ErrorCode.NotFound)
 * ```
 */
export class FetchError<T = {}> {
  public errorCode: ErrorCode = ErrorCode.FetchNotSupported;
  public errorData: T;

  constructor(public message: string) {}

  public setCode(code: ErrorCode) {
    this.errorCode = code;
    return this;
  }

  public setData(data: any) {
    this.errorData = data || {};
    return this;
  }
}

export interface FetchErrorData {
  errType: string;
  message: string;
}

export type FetchErrorWithData = FetchError<FetchErrorData>;

export { parseAPIError } from './parseAPIError';
