import { Fragment, useContext, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { hasLeadershipRole, hasTeacherRole, hasGuardianRole } from 'century-core/core-auth/utils';
import { getPercentValue, getDDMMMYYYYHHmmDate } from 'century-core/core-utils/utils/helpers';
import { getUserName } from 'century-core/core-utils/selectors/users';
import * as SortSelectors from 'century-core/core-utils/selectors/sortSelectors/sortSelectors';
import { withErrorBoundaryPartial } from 'century-core/core-components/ErrorBoundary/ErrorBoundary';
import { Spinner } from 'century-core/core-components/Spinner/Spinner';
import { QLANuggetInformationContext } from './QLANuggetInformationProvider';
import { QLAStudentInformationContext } from './QLAStudentInformationProvider';
import { QLANuggetAttemptHistoryInformationContext } from './QLANuggetAttemptHistoryInformationProvider';
import { QLALensInformationContext } from './QLALensInformationProvider';
import QLAChart from './QLAChart/QLAChart';
import QLAAttemptWrapper from './Attempt/QLAAttemptWrapper';
import {
  CdsAssignmentGradeCard,
  CdsCourseImage,
  CdsStatValue,
  CdsStatValueSize,
  FlexGrid,
  Heading,
  ProgressThumbnail,
  ModalBox,
  QlaAlert,
  QlaLevel1,
  QlaPage,
  StatIndicator,
  StatIndicatorSizes,
  StatIndicatorTypes,
  Text,
  Widget,
  ChartContainer,
} from '@ctek/design-system';
import { useDeviceInfo } from 'century-core/user-preferences/DeviceInfoContext';
import { useAuth } from 'century-core/core-auth/hooks/useAuth';
import { hasFeature } from 'century-core/core-auth/utils';

const QLA = ({ nuggetId, expandFirstAttempt }: { nuggetId: string, expandFirstAttempt?: boolean }) => {
  const auth = useAuth();
  const orgRolesCanAccessQLA = hasFeature(auth, 'qla', 'hasAccess');
  const lens = useContext(QLALensInformationContext);
  const courseInfo = lens?.getCourseForNugget(nuggetId);
  const nuggetInformation = useContext(QLANuggetInformationContext);
  const studentInformation = useContext(QLAStudentInformationContext);
  const nuggetAttemptHistoryInformation = useContext(QLANuggetAttemptHistoryInformationContext);
  const [canInspect, setCanInspect] = useState(false);
  const { deviceType, orientation } = useDeviceInfo();

  useEffect(() => {
    setCanInspect(orgRolesCanAccessQLA || hasTeacherRole(auth) || hasLeadershipRole(auth) || hasGuardianRole(auth));
  }, [auth, orgRolesCanAccessQLA]);

  if (nuggetInformation?.nuggetError || studentInformation?.studentError || nuggetAttemptHistoryInformation?.nuggetAttemptHistoryError) {
    return (
      <QlaAlert type="no-data">
        <Heading.SectionSubtitle>
          <FormattedMessage
            id="question-level-analysis-error-message"
            defaultMessage="We're having trouble retrieving data for this section. Please try again later."
          />
        </Heading.SectionSubtitle>
      </QlaAlert>
    );
  }
  if (nuggetInformation?.nugget && studentInformation?.student && nuggetAttemptHistoryInformation?.nuggetAttemptHistory) {
    const attempts = SortSelectors.sortDateData(nuggetAttemptHistoryInformation?.nuggetAttemptHistory.results, 'closeTime', false);
    const lastCompletedAttempt = attempts.find(a => a.isClosed) as Ctek.AbacusStudySessionActivity;
    return (
      <QlaPage>
        <ModalBox.Header qa="qla-nugget-header">
          <Heading.Headline noMargin={true}>{getUserName(studentInformation.student)}</Heading.Headline>
          <Heading.SectionTitle>{nuggetInformation.nugget.name || ''}</Heading.SectionTitle>
          <Heading.SectionSubtitle>{courseInfo?.courseName || ''}</Heading.SectionSubtitle>
        </ModalBox.Header>
        <FlexGrid>
          <FlexGrid.Col md={5} xs={12} stretch={true}>
            <Widget>
              <CdsAssignmentGradeCard qa="qla-last-attempt">
                <CdsAssignmentGradeCard.Thumb>
                  <ProgressThumbnail
                    size={deviceType === 'phone' && orientation === 'portrait' ? 90 : 120}
                    completionPercentage={getPercentValue(lastCompletedAttempt.percentComplete)}
                    scorePercentage={getPercentValue(lastCompletedAttempt.percentScore)}
                    hasPlaceholder={true}
                  >
                    <CdsCourseImage
                      src={courseInfo?.iconUrl || ''}
                      subject={courseInfo?.subjectName || ''}
                      iconCode={courseInfo?.iconCode}
                    />
                  </ProgressThumbnail>
                </CdsAssignmentGradeCard.Thumb>
                <CdsAssignmentGradeCard.Info>
                  <CdsAssignmentGradeCard.Summary>
                    <Heading.SectionTitle>
                      <FormattedMessage id="question-level-analysis-last-attempt-title" defaultMessage="Last Attempt" />
                    </Heading.SectionTitle>
                    <CdsAssignmentGradeCard.SummaryStats>
                      <CdsStatValue
                        size={deviceType === 'phone' && orientation === 'portrait' ? CdsStatValueSize.SMALL : CdsStatValueSize.MEDIUM}
                      >
                        <CdsStatValue.Label>
                          <CdsStatValue.Indicator>
                            <StatIndicator
                              type={StatIndicatorTypes.COMPLETION}
                              size={
                                deviceType === 'phone' && orientation === 'portrait' ? StatIndicatorSizes.SMALL : StatIndicatorSizes.MEDIUM
                              }
                              score={getPercentValue(lastCompletedAttempt.percentComplete)}
                            />
                          </CdsStatValue.Indicator>
                          <FormattedMessage id="question-level-analysis-completion-title" defaultMessage="Completion" />
                        </CdsStatValue.Label>
                        <CdsStatValue.Value>
                          {getPercentValue(lastCompletedAttempt.percentComplete)}
                          <CdsStatValue.Unit>%</CdsStatValue.Unit>
                        </CdsStatValue.Value>
                      </CdsStatValue>
                      <CdsStatValue
                        size={deviceType === 'phone' && orientation === 'portrait' ? CdsStatValueSize.SMALL : CdsStatValueSize.MEDIUM}
                      >
                        <CdsStatValue.Label>
                          <CdsStatValue.Indicator>
                            <StatIndicator
                              type={StatIndicatorTypes.SCORE}
                              size={
                                deviceType === 'phone' && orientation === 'portrait' ? StatIndicatorSizes.SMALL : StatIndicatorSizes.MEDIUM
                              }
                              score={getPercentValue(lastCompletedAttempt.percentScore)}
                            />
                          </CdsStatValue.Indicator>
                          <FormattedMessage id="question-level-analysis-score-title" defaultMessage="Score" />
                        </CdsStatValue.Label>
                        <CdsStatValue.Value>
                          {getPercentValue(lastCompletedAttempt.percentScore)}
                          <CdsStatValue.Unit>%</CdsStatValue.Unit>
                        </CdsStatValue.Value>
                      </CdsStatValue>
                    </CdsAssignmentGradeCard.SummaryStats>
                    <Text.Label>{getDDMMMYYYYHHmmDate(lastCompletedAttempt.closeTime.toString())}</Text.Label>
                  </CdsAssignmentGradeCard.Summary>
                </CdsAssignmentGradeCard.Info>
              </CdsAssignmentGradeCard>
            </Widget>
          </FlexGrid.Col>
          <FlexGrid.Col md={7} xs={12}>
            <Widget qa="qla-attempt-chart-widget">
              <Widget.Header>
                <Widget.Title>
                  <FormattedMessage id="question-level-analysis-chart-header" defaultMessage="Attempts" />
                </Widget.Title>
              </Widget.Header>
              <ChartContainer chartHeight={160}>
                <QLAChart />
              </ChartContainer>
            </Widget>
          </FlexGrid.Col>
        </FlexGrid>
        <Fragment>
          <QlaLevel1>
            <QlaLevel1.Header>
              <QlaLevel1.HeadRow>
                <QlaLevel1.ColAttempt>
                  <FormattedMessage id="question-level-analysis-detail-header-title-attempt" defaultMessage="Attempt" />
                </QlaLevel1.ColAttempt>
                <QlaLevel1.ColCompletion>
                  <FormattedMessage id="question-level-analysis-detail-header-title-completion" defaultMessage="Completion" />
                </QlaLevel1.ColCompletion>
                <QlaLevel1.ColScore>
                  <FormattedMessage id="question-level-analysis-detail-header-title-score" defaultMessage="Score" />
                </QlaLevel1.ColScore>
                <QlaLevel1.ColCorrect>
                  <FormattedMessage id="question-level-analysis-detail-header-title-correct" defaultMessage="Correct" />
                </QlaLevel1.ColCorrect>
                <QlaLevel1.ColMarks>
                  <FormattedMessage id="question-level-analysis-detail-header-title-marks" defaultMessage="Marks" />
                </QlaLevel1.ColMarks>
                <QlaLevel1.ColActive>
                  <FormattedMessage id="question-level-analysis-detail-header-title-activeTime" defaultMessage="Active time" />
                </QlaLevel1.ColActive>
                <QlaLevel1.Col>
                  <FormattedMessage id="question-level-analysis-detail-header-title-start" defaultMessage="Start" />
                </QlaLevel1.Col>
                <QlaLevel1.Col>
                  <FormattedMessage id="question-level-analysis-detail-header-title-end" defaultMessage="End" />
                </QlaLevel1.Col>
              </QlaLevel1.HeadRow>
            </QlaLevel1.Header>
          </QlaLevel1>
          {attempts.map((result, i) => {
            return (
              <QLAAttemptWrapper
                key={'AttemptDetail-' + result.studySessionId}
                attempt={result}
                order={(nuggetAttemptHistoryInformation.nuggetAttemptHistory?.results || []).length - i}
                canInspect={canInspect}
                expandFirstAttempt={expandFirstAttempt && i === 0}
              />
            );
          })}
        </Fragment>
      </QlaPage>
    );
  }

  return <Spinner type={'widget'} />;
};

export default withErrorBoundaryPartial(QLA);
