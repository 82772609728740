import * as React from 'react';
import ImageArea from '../ImageArea/ImageArea';
import { UploadedImageProps } from '../UploadedImage/UploadedImage';
import './LabelPair.scss';
import LabelPairBase from './LabelPairBase';

// TODO this needs to be abstracted as it's used in QLA as well
export enum ResultTypes {
  CORRECT = 'correct',
  INCORRECT = 'incorrect',
  SKIPPED = 'skipped',
}

export interface Props {
  children: LabelPairProps;
  qa?: string;
}

export interface LabelPairProps extends UploadedImageProps {
  id?: number;
  label: React.ReactNode;
  onImgChange?: (e: any) => void;
  onDrop: (e: any) => void;
  result?: ResultTypes;
  uploadActive?: boolean;
}

export class LabelPair extends React.PureComponent<Props, {}> {
  public render() {
    const { qa } = this.props;
    const { label, onDrop, onImgChange, id, controls, imgSrc, result, uploadActive } = this.props.children;

    return (
      <LabelPairBase>
        {{
          image: (
            <ImageArea
              onImgChange={onImgChange}
              id={`${id}`}
              controls={controls}
              imgSrc={imgSrc}
              onDrop={onDrop}
              uploadActive={uploadActive}
            />
          ),
          label,
          qa,
          result,
        }}
      </LabelPairBase>
    );
  }
}

export default LabelPair;
