import * as R from 'ramda';
import { timeSpanControls, TimeSpanKey } from 'century-core/features-dashboard/TeacherDashboard/common/utils/timeSpanUtils';
import { DateTime } from 'luxon';
import { CompletionNugget, CompletionStudent, DateRange, getStudentsNuggetData, NuggetHash, Student, TimeBasedNuggetActivity } from 'century-core/core-apis/learn/aggregations/aggregations';

export function getCompletionNuggets(nuggets: Ctek.Nugget[]): any[] {
  return nuggets.map((nugget: Ctek.Nugget) => ({
    _id: nugget._id,
    name: nugget.name,
    students: {},
    isActive: false,
  }));
}
export async function getNuggetCompletionData(
  accessToken: string,
  users: Ctek.User[],
  strands: Ctek.Strand[],
  selectedStrands: string[],
  dateRange?: DateRange,
  timeSpan?: TimeSpanKey
): Promise<CompletionNugget[]> {
  const selectedDateRange =
    !timeSpan || timeSpan === TimeSpanKey.CustomRange
      ? dateRange
      : {
          startDate: timeSpanControls[timeSpan || TimeSpanKey.AllTime].getValue?.() || undefined,
          endDate: DateTime.local().toISO(),
        };
  let completedNuggets: CompletionNugget[] = [];

  let selectedNuggets: any[] = [];
  selectedStrands.forEach((selectedStrand: string) => {
    const thisStrand = strands?.find((strand: Ctek.Strand) => strand.id === selectedStrand);
    const nuggs = thisStrand?.nuggets.map((nugget: Ctek.Nugget) => nugget);
    if (!!nuggs?.length) {
      selectedNuggets = [...selectedNuggets, nuggs];
    }
  });
  selectedNuggets = selectedNuggets.flat();
  completedNuggets = getCompletionNuggets(selectedNuggets);
  const students: any[] = users.map(user => {
    return {
      _id: user._id,
      name: `${user.personal?.name?.first} ${user.personal?.name?.last}`,
    };
  });
  return getStudentsNuggetData(accessToken, completedNuggets, students, selectedDateRange)
    .then<TimeBasedNuggetActivity[]>(R.flatten as any)
    .then((allData: TimeBasedNuggetActivity[]) => {
      return mixInNuggetResults(students, completedNuggets, getNuggetHash(allData));
    });
}
export function mixInNuggetResults(students: Student[], uniqueNuggets: CompletionNugget[], nuggetHash: NuggetHash): CompletionNugget[] {
  const enrichedNuggets: any[] = [];
  uniqueNuggets.forEach((nugget: CompletionNugget) => {
    const enrichedStudents: any = {};
    let isActive: boolean = false;
    students.forEach((student: CompletionStudent) => {
      const lastResultScore: number = R.pathOr(null, [`${student._id}`, `${nugget._id}`, 'lastResult', 'score'], nuggetHash);
      isActive = isActive || typeof lastResultScore === 'number';
      enrichedStudents[student._id as string] = {
        bestResult: R.path([`${student._id}`, `${nugget._id}`, 'bestResult'], nuggetHash),
        averageResult: R.path([`${student._id}`, `${nugget._id}`, 'averageResult'], nuggetHash),
        lastResult: {
          score: lastResultScore,
          date: R.pathOr(null, [`${student._id}`, `${nugget._id}`, 'lastResult', 'date'], nuggetHash),
        },
      };
    });

    enrichedNuggets.push({
      ...nugget,
      students: enrichedStudents,
      isActive,
    });
  });
  return enrichedNuggets;
}
export function getNuggetHash(nuggetResults: TimeBasedNuggetActivity[]): NuggetHash {
  const returnObject: NuggetHash = {};
  const userIds = R.pluck('userId', nuggetResults);
  R.uniq(userIds).forEach((id: string) => {
    (returnObject as any)[id] = R.mergeAll(
      R.map((result: TimeBasedNuggetActivity) => {
        if (result.attemptsClosed > 0) {
          return {
            [result.nuggetId]: {
              lastResult: {
                score: result.percentScore.closed.latest === -1 ? -1 : Math.round(result.percentScore.closed.latest / 100),
                date: result.latestActivityTimeClosed,
              },
              bestResult: result.percentScore.closed.max === -1 ? -1 : Math.round(result.percentScore.closed.max / 100),
              averageResult: result.percentScore.closed.average === -1 ? -1 : Math.round(result.percentScore.closed.average / 100),
              hasNoQuestions: result.percentScore.all.max === -1
            },
          };
        } else {
          return {};
        }
      }, R.filter(R.propEq('userId', id), nuggetResults))
    );
  });
  return returnObject;
}