import { Pagination } from '@ctek/design-system';

export interface PaginationProps {
  maxPages: number;
  currentPage: number;
  goToPage: (page: number) => void;
  onNextClick: () => void;
  onPrevClick: () => void;
}

export default function PaginationWidget(props: PaginationProps) {
  const { goToPage, onNextClick, onPrevClick, currentPage, maxPages } = props;
  const onPageNumberClick = (pageNumber: number) => goToPage(pageNumber);
  if (maxPages === 1) {
    return null;
  }

  const isCurrentPageFirst = currentPage === 1;
  const isCurrentPageLast = currentPage === maxPages;

  let isPageNumberOutOfRange: boolean;

  const pageNumbers = Array.from({ length: maxPages }).map((_, i): any => {
    const pageNumber = i + 1;
    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === maxPages;
    const isCurrentPageWithTwoPageNumbers = Math.abs(pageNumber - currentPage) <= 2;

    if (isPageNumberFirst || isPageNumberLast || isCurrentPageWithTwoPageNumbers) {
      isPageNumberOutOfRange = false;

      return (
        <Pagination.Item key={pageNumber} current={pageNumber === currentPage}>
          <button key={pageNumber} onClick={() => onPageNumberClick(pageNumber)}>
            {pageNumber}
          </button>
        </Pagination.Item>
      );
    }
    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return (
        <span data-testid="pagination-ellipsis">
          <Pagination.Ellipsis key={pageNumber} />
        </span>
      );
    }
    return null;
  });

  return (
    <Pagination ariaLabel="Pagination">
      <Pagination.Prev disabled={isCurrentPageFirst}>
        <button onClick={onPrevClick} disabled={isCurrentPageFirst} data-testid="pagination-prev-button" />
      </Pagination.Prev>
      {pageNumbers}

      <Pagination.Next disabled={isCurrentPageLast}>
        <button onClick={onNextClick} disabled={isCurrentPageLast} data-testid="pagination-next-button" />
      </Pagination.Next>
    </Pagination>
  );
}
