import { parseAPIError } from 'century-core/core-apis/fetch/utils';
import { Errors, localisedErrors } from 'century-core/entities/ErrorMessage/Errors';
import ErrorMessage from 'century-core/entities/ErrorMessage/ErrorMessage';
import { CoursesPayload, updateLearnerCourses as update } from 'century-core/core-apis/cms/updateLearnerCourses';

export async function updateLearnerCourses(learnerId: string, courses: CoursesPayload, accessToken: string) {
  try {
    return await update(learnerId, courses, accessToken);
  } catch (err) {
    throw parseAPIError(
      err,
      new ErrorMessage<Errors>(
        'localised-errors-b2c-update-learner-courses-error',
        localisedErrors['localised-errors-b2c-update-learner-courses-error']
      )
    );
  }
}
