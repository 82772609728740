import { DELETE, GET, PATCH } from 'century-core/core-apis/utils';
import { getContentURL } from '../contentV2';

interface PatchResponse {
  _id: string;
  id: string;
}

// FIXME need to remove the entity and ensure it's all good for new question types - it's not a simple substitution (v weird)
export function getQuestionGroup(questionGroupId: string, accessToken: string, populate?: boolean) {
  const url = getContentURL(`/question-groups/${questionGroupId}`);
  if (populate) {
    url.search = '&populate=true';
  }

  return GET<Ctek.QuestionGroup | Ctek.EnrichedQuestionGroup>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get question group by id ${questionGroupId}, ${err}`);
  });
}

export function patchQuestionGroup(questionGroupId: string, questionGroup: Partial<Ctek.QuestionGroup>, accessToken: string) {
  const url = getContentURL(`/question-groups/${questionGroupId}`);

  return PATCH<PatchResponse>({ url, token: accessToken, body: questionGroup })
    .then(res => res._id)
    .catch((err: Error) => {
      throw Error(`Unable to patch question group ${questionGroupId}, ${err}`);
    });
}

export function deleteQuestionGroup(questionGroupId: string, accessToken: string) {
  const url = getContentURL(`/question-groups/${questionGroupId}`);

  return DELETE<PatchResponse>({ url, token: accessToken })
    .then(res => res._id)
    .catch((err: Error) => {
      throw Error(`Unable to delete question group ${questionGroupId}, ${err}`);
    });
}

export const getQuestionGroups = (questionGroups: string[], accessToken: string): Promise<Ctek.QuestionGroup[]> => {
  const url = getContentURL('/question-groups/');
  url.search += `?ids=${questionGroups.join(',')}`;
  return GET<Ctek.QuestionGroup[]>({ url, token: accessToken });
};
